import { createSelector } from 'reselect';
import { PAYMENT_TYPES } from '~shared/features/payment-methods/constants';
import { paymentType } from '~shared/features/payment-methods/helpers/paymentType';
import { type ReduxStateShared } from '~shared/types';

export const getPaymentMethods = (state: ReduxStateShared) => state.paymentMethods.paymentMethods || [];

export const getLastRequestAttemptedAt = (state: ReduxStateShared) => state.paymentMethods.lastRequestAttemptedAt;

export const getRequestAttemptCount = (state: ReduxStateShared) => state.paymentMethods.requestAttemptCount;

export const getLastRequestSucceededAt = (state: ReduxStateShared) => state.paymentMethods.lastRequestSucceededAt;

export const getCreditCardPaymentMethods = createSelector([getPaymentMethods], (paymentMethods) => {
  return paymentMethods.filter((pm) => paymentType(pm) !== PAYMENT_TYPES.ACH);
});

export const getACHPaymentMethods = createSelector([getPaymentMethods], (paymentMethods) => {
  return paymentMethods.filter((pm) => paymentType(pm) === PAYMENT_TYPES.ACH);
});
