import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModalHeader, H3 } from '@alto/pocky';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { useCartData } from '~shared/features/cart/hooks/useCartData';

const StyledHeader = styled(DeprecatedModalHeader)`
  border-top-left-radius: ${BORDERS.RADIUS.LG.px};
  border-top-right-radius: ${BORDERS.RADIUS.LG.px};
`;

const StyledModalClose = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

type Props = {
  readonly onClose: () => void;
};

export const CartItemHeader = ({ onClose }: Props) => {
  const { cartSize } = useCartData();

  return (
    <StyledHeader
      verticallyAlignContent
      evenlySpaceContent
      padding={`${SPACING.STATIC.MD.px} ${SPACING.STATIC.LG.px}`}
      spacing={SPACING.STATIC.MD.px}
    >
      <H3>Your Cart {cartSize > 0 ? `(${cartSize})` : ''}</H3>
      <StyledModalClose
        color={COLORS.TEXT_COLORS.GREY}
        icon={faTimes}
        onClick={onClose}
        tabIndex={0}
      />
    </StyledHeader>
  );
};
