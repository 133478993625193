// @owners { team: patients-team }
import { BORDERS, SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Card,
  Description,
  Illustration,
  InlineAlert,
  ListDescription,
  ListItem,
  LoadingEmptyState,
  MdPadding,
  SecondaryPage,
  SmPadding,
  XlPadding,
  XlSpacing,
} from '@alto/design-system';
import { useQueryMedConfirmationConfigs } from '@alto/features';
import React, { useCallback, useEffect } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { updateOnboardingUser } from '~shared/actions/onboarding';
import medPlaceHolder from '~shared/assets/images/med_image_placeholder.png';
import { getMedicationsFromPrescriptions } from '~shared/features/my-meds/selectors/getMedicationsFromPrescriptions';
import {
  MED_CONFIRMATION,
  ONBOARD_STATE_MED_CONFIRMATION,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { getDoctorsFromPrescriptions } from '~shared/features/onboarding/selectors/getDoctorsFromPrescriptions';
import getError from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { getErrorMessage } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { previousWebURL } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type LightDoctor } from '~shared/types';
import { FAQs } from '~web/features/onboarding/components/redesign/FAQs';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';
import { nextUrl } from '~web/features/onboarding/helpers/urlHandler';
import { getSentenceCaseDosageForm } from '~web/features/onboarding/helpers/utils';

const prescriberName = (doctors: LightDoctor[]) => {
  if (!doctors.length) {
    return '';
  }

  if (doctors[0].first_name || doctors[0].last_name) {
    return `, ${doctors[0].first_name} ${doctors[0].last_name}`;
  }

  return '';
};

export const OnboardingMedConfirmation = () => {
  const dispatch = useDispatchShared();
  const { trackPageView } = useAnalytics();
  const medications = useSelectorShared(getMedicationsFromPrescriptions);
  const medicationsCount = medications.length;
  const doctors = useSelectorShared(getDoctorsFromPrescriptions);
  const nextRoute = useSelectorShared((state) => nextUrl(state, MED_CONFIRMATION));
  const previousWebUrl = useSelectorShared(previousWebURL);
  const origin = previousWebUrl ? routeFromPath(previousWebUrl) : '';
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const { fetchPrescriptionsLoading, fetchActivePrescriptionsLoading } = useSelectorShared(getLoading);
  const { isFetching: configsLoading, headerBackgroundColor, headerLogoUrl } = useQueryMedConfirmationConfigs();
  const prescriptionsLoading = (fetchPrescriptionsLoading || fetchActivePrescriptionsLoading) && medicationsCount > 0;
  const loading = configsLoading || prescriptionsLoading;
  const { fetchPrescriptionsError: error } = useSelectorShared(getError);

  useEffect(() => {
    // eslint-disable-next-line import/no-deprecated
    dispatch(updateOnboardingUser({ onboard_state: ONBOARD_STATE_MED_CONFIRMATION }));
  }, [dispatch]);

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__MED_CONFIRMATION_VIEWED,
      params: {
        origin,
        meds_seen: medicationsCount,
        is_progyny_rx_view: showProgyny,
      },
    });
  }, [dispatch, medicationsCount, origin, showProgyny, trackPageView]);

  const handlePressContinue = useCallback(() => {
    dispatch(push(nextRoute));
  }, [dispatch, nextRoute]);

  if (configsLoading) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <SecondaryPage
      withoutWebFramingElementHeights
      buttons={
        !error && !loading
          ? [
              <Button
                type="primary"
                key="continue"
                label="Continue"
                onPress={handlePressContinue}
              />,
            ]
          : []
      }
      headerBackgroundColor={headerBackgroundColor}
      HeaderContent={
        <OnboardingHeader
          title="Here are your medications"
          subtitle={
            <Body center>
              We received these from your prescriber<Body fontFamily="semibold">{prescriberName(doctors)}.</Body>
            </Body>
          }
          error={error}
          loading={loading}
        />
      }
      NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
      NavBarRightPressable={<FAQs />}
      footerPlacementContext="webScreenWithoutPolicyFooter"
    >
      {loading ? <LoadingEmptyState /> : null}
      {error ? (
        <>
          <InlineAlert type="error">
            <MdPadding>
              <Description>
                {getErrorMessage(error) ||
                  "Looks like we're having some technical difficulty. Please refresh this page."}
              </Description>
            </MdPadding>
          </InlineAlert>
          <XlSpacing />
        </>
      ) : null}
      {!loading && !error ? (
        <Card>
          <SmPadding leftPadding={SPACING.STATIC.XXS}>
            {medications.map(({ key, dosageForm, medicationName, representativePrescription: { image_url } }) => (
              <ListItem
                key={key}
                title={medicationName}
                LeftContent={
                  <Illustration
                    borderRadius={BORDERS.RADIUS.MD.value}
                    source={image_url || medPlaceHolder}
                  />
                }
                descriptions={[
                  <ListDescription key={`${key}-description`}>{getSentenceCaseDosageForm(dosageForm)}</ListDescription>,
                ]}
              />
            ))}
          </SmPadding>
        </Card>
      ) : null}
    </SecondaryPage>
  );
};
