import {
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInYears,
  format,
  getMinutes,
  isAfter,
  isFuture,
  isSameDay,
  isSameYear as isSameYearFn,
  isTomorrow as isTomorrowFn,
  isValid,
  isYesterday as isYesterdayFn,
  parse,
  parseISO,
} from 'date-fns';

// NOTE: the date-fns v2 format and parse uses unicode tokens
// https://date-fns.org/v2.8.1/docs/Unicode-Tokens
export const DATE_FORMATS = {
  ABBREVIATED_WEEKDAY: 'EEE', // Mon, Tue, Wed...
  DATE_INPUT_PLACEHOLDER: 'MM / DD / YYYY', // patient-facing for presentation only; not for parsing dates
  DATE_INPUT_FORMAT: 'MM / dd / yyyy', // '07 / 01 / 2023'
  DELIVERY_WINDOW_CUTOFF: 'yyyy-MM-dd HH:mm:ss xx', // //2023-10-03 16:00:00 -0700
  FULL_MONTH_ORDINAL_DAY: 'MMMM do', // January 1st
  HOUR_MINUTES_MERIDIEM: 'h:mm aa', // 1:36 PM
  LOCALIZED_TIME: 'p', // 12:00 AM
  WEEKDAY_COMMA_MONTH_DATE: 'EEE, MMM d', // Mon, Dec 31
  WEEKDAY_MONTH_DATE_YEAR: 'EEE MMM d yyyy', // Mon Dec 31 2023
  WEEKDAY_MONTH_ORDINAL_DATE: 'EEE, MMM do', // Mon, Dec 31st
  WEEKDAY_MONTH_ORDINAL_DATE_YEAR: 'EEE, MMM do, yyyy', // Mon, Dec 31st, 2023
  MONTH_DAY_SHORT_YEAR_SLASHED: 'MM/dd/yy',
  MONTH_DAY_YEAR_DASHED: 'MM-dd-yyyy', // 07-01-2023
  MONTH_DAY_YEAR_SLASHED: 'MM/dd/yyyy', // '07/01/2023'
  MONTH_NAME_DAY_YEAR: 'MMM d, yyyy', // Dec 31, 2023
  MONTH_ORDINAL_DATE: 'MMM do', // Dec 31st
  YEAR_MONTH_DAY_DASHED: 'yyyy-MM-dd', // '2023-07-01'
};

/**
 * Convert given date to UTC
 * @link https://github.com/date-fns/date-fns/issues/556#issuecomment-391048347
 * @param date | plain JS date object
 * @return Date
 */
export const toUTC = (date: Date) => {
  const offset = date.getTimezoneOffset();

  return addMinutes(date, offset);
};

/**
 *
 * @param date
 * @returns string ('MM/dd/yyyy')
 *
 * 2019-07-15 -> 07/15/2019
 *
 * 2019-07 -> 07/01/2019
 *
 * 07/15/2019 -> null
 */
export function normalizeDateString(date: string) {
  let parsedDate = parse(date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, new Date());
  if (isValid(parsedDate)) {
    return format(parsedDate, DATE_FORMATS.MONTH_DAY_YEAR_SLASHED);
  }
  parsedDate = parse(date, 'yyyy-MM', new Date());
  if (isValid(parsedDate)) {
    return format(parsedDate, DATE_FORMATS.MONTH_DAY_YEAR_SLASHED);
  }

  return null;
}

/**
 *
 * @param date
 * @returns string ('MM/dd/yyyy')
 *
 * 2019-07-15 -> 07/15/2019
 *
 * 07/15/2019 -> 07/15/2019
 *
 * 2019-07 -> null
 */
export function normalizeDateStringStrict(date: string) {
  const parsedDate = parse(date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, new Date());
  if (isValid(parsedDate)) {
    return format(parsedDate, DATE_FORMATS.MONTH_DAY_YEAR_SLASHED);
  } else if (isValid(parse(date, DATE_FORMATS.MONTH_DAY_YEAR_SLASHED, new Date()))) {
    return date;
  }

  return null;
}

const DATE_PARSERS = [
  DATE_FORMATS.YEAR_MONTH_DAY_DASHED,
  DATE_FORMATS.MONTH_DAY_YEAR_SLASHED,
  DATE_FORMATS.WEEKDAY_MONTH_DATE_YEAR,
  DATE_FORMATS.DATE_INPUT_FORMAT,
];

function parseDate(date: string) {
  const validDates = DATE_PARSERS.map((parser) => parse(date, parser, 0)).filter(isValid);
  if (validDates.length > 0) return validDates[0];

  const parsedISODate = parseISO(date);
  if (isValid(parsedISODate)) {
    return parsedISODate;
  }

  const parsedDate = parse(date.split('T')[0], DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  if (isValid(parsedDate)) {
    return parsedDate;
  }

  return null;
}

/**
 *
 * @param date
 * @returns string
 *
 * 2019-07-15
 */
export function formatDateStringForAPI(date: string) {
  const parsedDate = parseDate(date);
  return parsedDate ? format(parsedDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED) : '';
}

/**
 *
 * @param date
 * @returns string
 *
 * Mon, Jul 15th
 * If year is not current year, display year as well (eg. Mon, Jul 15th, 2018).
 * Date could be void because of the LightDelivery type
 */
export function formatDate(date: string | Date | null | undefined) {
  if (!date) return '';

  const parsedDate = date instanceof Date ? date : parseDate(date);

  if (parsedDate && isValid(parsedDate)) {
    if (isSameYearFn(parsedDate, new Date())) {
      return format(parsedDate, DATE_FORMATS.WEEKDAY_MONTH_ORDINAL_DATE);
    }

    return format(parsedDate, DATE_FORMATS.WEEKDAY_MONTH_ORDINAL_DATE_YEAR);
  }

  return '';
}

/**
 *
 * @param date
 * @returns string
 *
 * 07/15/19
 */
export function formatShortDate(date: string | Date) {
  const parsedDate = date instanceof Date ? date : parseDate(date);
  if (parsedDate && isValid(parsedDate)) {
    return format(parsedDate, DATE_FORMATS.MONTH_DAY_SHORT_YEAR_SLASHED);
  }

  return '';
}

/**
 *
 * @param date
 * @returns string
 *
 * Jul 15, 2019
 */
export function formatDateWithYear(date: string | Date) {
  const parsedDate = date instanceof Date ? date : parseDate(date);
  if (parsedDate && isValid(parsedDate)) {
    return format(parsedDate, DATE_FORMATS.MONTH_NAME_DAY_YEAR);
  }

  return '';
}

/**
 *
 * @param date
 * @returns string
 *
 * Jul 15th
 */
export function formatDateWithoutYear(date: string | Date) {
  const parsedDate = date instanceof Date ? date : parseDate(date);
  if (parsedDate && isValid(parsedDate)) {
    return format(parsedDate, DATE_FORMATS.MONTH_ORDINAL_DATE);
  }

  return '';
}

/**
 *
 * @param date
 * @returns string
 *
 * Monday 07/15/19
 */
export function formatDateWithDowAndYear(date: string | Date | null | undefined) {
  if (!date) {
    return '';
  }

  const parsedDate = date instanceof Date ? date : parseDate(date);
  if (parsedDate && isValid(parsedDate)) {
    return format(parsedDate, 'EEEE MM/dd/yy');
  }

  return '';
}

/**
 *
 * @param dateTime
 * @returns string
 *
 * Mon 07/15/19 9:39am
 */
export function formatDateTimeWithDow(dateTime: string | Date | null | undefined) {
  if (!dateTime) {
    return '';
  }

  const parsedDate = dateTime instanceof Date ? dateTime : parseDate(dateTime);
  if (parsedDate && isValid(parsedDate)) {
    return format(parsedDate, 'eee MM/dd/yy h:mmaaa');
  }

  return '';
}

/**
 *
 * @param date
 * @returns string
 *
 * Monday, July 15th
 */
export function formatDateWithDowWithoutYear(date: string | Date | null | undefined) {
  if (!date) {
    return '';
  }

  const parsedDate = date instanceof Date ? date : parseDate(date);
  if (parsedDate && isValid(parsedDate)) {
    return format(parsedDate, 'EEEE, MMMM do');
  }

  return '';
}

/**
 *
 * @param date
 * @returns string
 *
 * July 15th
 */
export function formatDateWithoutDowWithoutYear(date: string | Date | null | undefined) {
  if (!date) {
    return '';
  }

  const parsedDate = date instanceof Date ? date : parseDate(date);
  if (parsedDate && isValid(parsedDate)) {
    return format(parsedDate, 'MMMM do');
  }

  return '';
}

/**
 *
 * @param date
 * @param makeLowerCase
 * @returns string
 *
 * Today, September 28th
 *
 * Tomorrow, September 29th
 *
 * September 28th, 2020 (if not current year)
 */
export function formatRelativeDateWithDow(date: string | Date, makeLowerCase?: boolean) {
  return formatRelativeDate(date, { includeRelative: true, makeLowerCase, includeDayOfWeek: true });
}

type formatRelativeDateOptions = {
  abbreviated?: boolean;
  includeRelative?: boolean;
  makeLowerCase?: boolean;
  includeDayOfWeek?: boolean;
};

/**
 *
 * @param date
 * @param options
 * @returns string
 *
 * Today, September 28th
 *
 * Tomorrow, September 29th
 *
 * September 28th, 2020 (if not current year)
 *
 * Friday, September 30th (if same year and includeDayOfWeek = true)
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function formatRelativeDate(
  date: string | Date,
  { abbreviated, includeRelative, makeLowerCase, includeDayOfWeek }: formatRelativeDateOptions = {
    abbreviated: false,
    includeRelative: false,
    makeLowerCase: false,
    includeDayOfWeek: false,
  },
) {
  const parsedDate = date instanceof Date ? date : parseDate(date);
  if (!parsedDate || !isValid(parsedDate)) {
    return '';
  }

  const now = new Date();
  const isToday = isSameDay(parsedDate, now);
  const isTomorrow = isTomorrowFn(parsedDate);
  const isYesterday = isYesterdayFn(parsedDate);
  const isSameYear = isSameYearFn(parsedDate, now);
  let formattedAbsoluteDate;
  const shouldIncludeDayOfWeek = includeDayOfWeek && isSameYear && !(isToday || isTomorrow || isYesterday);
  if (shouldIncludeDayOfWeek) {
    formattedAbsoluteDate = format(parsedDate, 'EEEE, MMMM do');
  } else if (abbreviated) {
    formattedAbsoluteDate =
      isSameYear && !(isToday || isTomorrow || isYesterday)
        ? format(parsedDate, 'EE, MMM do')
        : format(parsedDate, isSameYear ? 'MMM do' : 'MMM do, yyyy');
  } else if (isSameYear) {
    formattedAbsoluteDate = formatDateWithoutDowWithoutYear(date);
  } else {
    formattedAbsoluteDate = format(parsedDate, 'MMMM do, yyyy');
  }

  if (isToday || isTomorrow || isYesterday) {
    let relativeDay;
    if (isToday) {
      relativeDay = 'Today';
    } else if (isTomorrow) {
      relativeDay = 'Tomorrow';
    } else {
      relativeDay = 'Yesterday';
    }

    if (makeLowerCase) {
      relativeDay = relativeDay.toLowerCase();
    }

    return includeRelative ? `${relativeDay}, ${formattedAbsoluteDate}` : relativeDay;
  }

  return formattedAbsoluteDate;
}

/**
 * @param delivered_at dateISOString
 * @returns string
 *
 * Delivered Today at 6:21 PM
 *
 * Delivered Yesterday at 6:21 PM
 *
 * Delivered Mon, July 29 at 6:21 PM
 */
export function formatDeliveredAt(delivered_at: string) {
  const parsedDeliveredAt = new Date(delivered_at);
  if (isValid(parsedDeliveredAt)) {
    const dateString = formatRelativeDate(parsedDeliveredAt, {
      includeRelative: false,
      makeLowerCase: false,
      includeDayOfWeek: true,
    });
    return `${dateString} at ${format(parsedDeliveredAt, 'h:mm aa')}`;
  }

  return '';
}

/**
 *
 * @param isoDateTime
 * @param meridiem
 * @param options - { includeRelative, makeLowerCase }
 * @returns string
 *
 * 5PM today
 *
 * 5PM Today
 *
 * 5PM Today, July 15th
 *
 * 12:30PM tomorrow
 *
 * 12:30PM Tomorrow, July 15th
 *
 * 12:30PM Monday, July 15th
 *
 */
export function formatRelativeTimeDate(
  isoDateTime: string,
  meridiem: string,
  { includeRelative, makeLowerCase }: Pick<formatRelativeDateOptions, 'includeRelative' | 'makeLowerCase'> = {
    includeRelative: false,
    makeLowerCase: false,
  },
): string {
  const parsedDate = new Date(isoDateTime);
  if (isValid(parsedDate)) {
    const formattedTime = format(parsedDate, `h${getMinutes(parsedDate) > 0 ? ':mm' : ''}${meridiem}`);
    const formattedDate = formatRelativeDate(isoDateTime, { includeRelative, makeLowerCase, includeDayOfWeek: false });
    return `${formattedTime} ${formattedDate}`;
  }

  return '';
}

export function formatRelativeDateAndWindow(
  date: string,
  startTime: string | undefined,
  endTime: string | undefined,
): string {
  const relativeDate = formatRelativeDateWithDow(date);
  const window = formatWindow(startTime, endTime, '-', 'a', false);
  return window ? `${relativeDate}, ${window}` : relativeDate ?? '';
}

/**
 *
 * @param windowTime Date | isoDate | String("HH:MM PM")
 * @param meridiem
 * @param forceReturnMinutes return minutes even if 0
 * @returns string
 *
 * 10AM - 12PM or 12:30PM - 2:30PM
 */
export function formatWindowTime({
  windowTime,
  meridiem,
  forceReturnMinutes = false,
  useUTC = true,
  separator = '',
}: {
  windowTime: string | Date;
  meridiem: string;
  forceReturnMinutes?: boolean;
  useUTC?: boolean;
  separator?: string;
}): string {
  let date: Date;

  if (windowTime instanceof Date) {
    date = windowTime;
  } else if (deliveryWindowRegex.test(windowTime)) {
    date = parse(windowTime, DATE_FORMATS.HOUR_MINUTES_MERIDIEM, 0);
  } else if (useUTC) {
    date = toUTC(new Date(windowTime));
  } else {
    date = new Date(windowTime);
  }

  if (getMinutes(date) > 0 || forceReturnMinutes) {
    return format(date, `h:mm${separator}${meridiem}`); // eg 11:30AM
  }

  return format(date, `h${separator}${meridiem}`); // eg 11AM
}

/**
 * Format delivery windows from fulfillment option endpoints.
 * Matches the time format from the pre-existing fetchWindows
 * redux network request that populates `deliveryWindows` in the cart slice of the redux store.
 * 10:00 AM or 12:30 PM
 */

export function formatWindowTimeLong(windowTime: string | Date, meridiem: string): string {
  const formattedWindowTime = formatWindowTime({ windowTime, meridiem, forceReturnMinutes: true });
  return formattedWindowTime.replace(/(?<! )AM/gi, ' AM').replace(/(?<! )PM/gi, ' PM');
}

const deliveryWindowRegex = new RegExp(/[0-1]?[0-9]:[0-5][0-9] [AP]M$/i);

export function formatWindow(
  deliverAfter: string | Date | null | undefined,
  deliverBefore: string | Date | null | undefined,
  separator = '-',
  meridiem = 'aaa',
  wrapInParentheses = true,
) {
  if (!deliverAfter || !deliverBefore) return '';

  const isDeliverAfterDate = deliverAfter instanceof Date;
  const isDeliverBeforeDate = deliverBefore instanceof Date;

  const parsedAfterDate = isDeliverAfterDate ? deliverAfter : parseDate(deliverAfter);
  const parsedBeforeDate = isDeliverBeforeDate ? deliverBefore : parseDate(deliverBefore);

  if (parsedAfterDate && isValid(parsedAfterDate) && parsedBeforeDate && isValid(parsedBeforeDate)) {
    const formattedWindowTimes = `${formatWindowTime({ windowTime: toUTC(parsedAfterDate), meridiem })} ${separator} ${formatWindowTime(
      {
        windowTime: toUTC(parsedBeforeDate),
        meridiem,
      },
    )}`;
    return wrapInParentheses ? `(${formattedWindowTimes})` : formattedWindowTimes;
  }

  if (!isDeliverAfterDate && !isDeliverBeforeDate) {
    const deliverAfterFormatted = formatWindowTime({ windowTime: deliverAfter, meridiem });
    const deliverBeforeFormatted = formatWindowTime({ windowTime: deliverBefore, meridiem });
    const formattedWindowTimes = `${deliverAfterFormatted} ${separator} ${deliverBeforeFormatted}`;

    return wrapInParentheses ? `(${formattedWindowTimes})` : formattedWindowTimes;
  }

  return '';
}

/**
 *
 * @param deliverAfter
 * @param deliverBefore
 * @param separator
 * @param meridiem
 * @returns string
 *
 * 10:00 AM - 12:00 PM
 */
export function formatWindowLong(
  deliverAfter: string | Date | null | undefined,
  deliverBefore: string | Date | null | undefined,
  separator = '-',
  meridiem = 'aa',
) {
  if (!deliverAfter || !deliverBefore) {
    return '';
  }

  const parsedDeliverAfter = deliverAfter instanceof Date ? deliverAfter : parseDate(deliverAfter);
  const parsedDeliverBefore = deliverBefore instanceof Date ? deliverBefore : new Date(deliverBefore);

  if (parsedDeliverAfter && isValid(parsedDeliverAfter) && parsedDeliverBefore && isValid(parsedDeliverBefore)) {
    const afterString = format(toUTC(parsedDeliverAfter), `h:mm ${meridiem}`);
    const beforeString = format(toUTC(parsedDeliverBefore), `h:mm ${meridiem}`);
    return `${afterString} ${separator} ${beforeString}`;
  }

  return '';
}

// yyyy-MM-dd => true
// MM/dd/yyyy => false
// yyyy-MM => false
export function isValidAPIDateFormat(date: string): boolean {
  return isValid(parse(date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0));
}

export function getAge(date: string): number | null | undefined {
  const parsedDate = parse(date, DATE_FORMATS.DATE_INPUT_FORMAT, 0);
  const now = new Date();

  if (isValid(parsedDate) && isAfter(now, parsedDate)) {
    return differenceInYears(now, parsedDate);
  }

  return null;
}

export const isUnderAgeEighteen = (date: string): boolean => {
  const parsedDate = parseDate(date);
  if (!parsedDate || !isValid(parsedDate)) return false;

  const formattedDate = format(parsedDate, DATE_FORMATS.DATE_INPUT_FORMAT);
  const age = getAge(formattedDate);
  if (!age) return false;
  return age < 18;
};

/**
 * Progressively formats a date string into a proprietary "MM / DD / YYYY" format
 */
export function formatDateInput(date: string): string {
  if (!date) {
    return date;
  }

  date = date.replace(/\D/g, '');

  if (date.slice(3, 6) === ' / ') {
    if (date.slice(8, 11) === ' / ') {
      date = date.slice(0, 8) + date.slice(10);
    }

    date = date.slice(0, 3) + date.slice(6);
  }

  const part1 = date.slice(0, 2);
  const part2 = date.slice(2, 4);
  const part3 = date.slice(4, 8);

  if (part1.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-useless-template-literals
    date = `${part1}`;
  }

  if (part2.length > 0) {
    date = `${date} / ${part2}`;
  }

  if (part3.length > 0) {
    date = `${date} / ${part3}`;
  }

  return date;
}

export const numberOfDaysSince = (date: string) => {
  const today = new Date();
  const olderDate = parseDate(date);
  if (!olderDate || !isValid(olderDate)) return 0;

  return differenceInDays(today, olderDate);
};

export const numberOfDaysUntil = (date: string) => {
  const today = new Date();
  const newerDate = parseDate(date);
  if (!newerDate || !isValid(newerDate)) return 0;

  return differenceInDays(newerDate, today);
};

/**
 *
 * @param date
 * @returns string
 *
 * 1 hour ago
 *
 * 2 hours ago
 *
 * over 1 day ago
 */
export const formatNumberOfHoursAgo = (date: string) => {
  const today = new Date();
  const olderDate = parseDate(date);
  if (!olderDate || !isValid(olderDate)) return '';

  const hoursDiff = differenceInHours(today, olderDate);
  if (hoursDiff > 24) {
    return 'over 1 day ago';
  }
  return hoursDiff <= 1 ? '1 hour ago' : `${hoursDiff} hours ago`;
};

/**
 *
 * @param date
 * @returns string
 *
 * just now
 *
 * 30 mins ago
 *
 * 3 hrs ago
 *
 * June 3, 2020
 */
export const formatTimeAgo = (date: string) => {
  const now = Date.now();
  const olderDate = parseDate(date);
  if (!olderDate || !isValid(olderDate) || isFuture(olderDate)) return '';

  const minutesDiff = differenceInMinutes(now, olderDate);
  if (minutesDiff < 1) {
    return 'Just now';
  } else if (minutesDiff < 60) {
    return `${minutesDiff} min${minutesDiff > 1 ? 's' : ''} ago`;
  }

  const hoursDiff = differenceInHours(now, olderDate);
  if (hoursDiff < 24) {
    return `${hoursDiff} hr${hoursDiff > 1 ? 's' : ''} ago`;
  }

  return format(olderDate, DATE_FORMATS.MONTH_NAME_DAY_YEAR);
};

/**
 *
 * @param datetime
 * @returns number
 *
 */
export const numberOfHoursAgo = (datetime: string) => {
  const now = Date.now();
  const olderDate = parseDate(datetime);
  if (!olderDate || !isValid(olderDate)) return 0;

  return differenceInHours(now, olderDate);
};

/**
 * Determine if the date of birth is greater than or equal to the given age
 * @param dateOfBirth expects MM/DD/YYYY format
 * @param age
 * @returns boolean
 */
export const isOfAge = (dateOfBirth: string, age: number) => {
  // this check is useful in forms so validation does not occur for incomplete dates
  if (dateOfBirth.length !== 10) return false;

  const parsedDateOfBirth = parse(dateOfBirth, DATE_FORMATS.MONTH_DAY_YEAR_SLASHED, 0);
  if (!parsedDateOfBirth || !isValid(parsedDateOfBirth)) return false;

  const today = new Date();
  const maxDateOfBirth = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
  return maxDateOfBirth.getTime() >= parsedDateOfBirth.getTime();
};
