import { SPACING } from '@alto/design-library-tokens';
import { type RefObject } from 'react';
import { type View } from 'react-native';

export const COACHMARK_HEIGHT = 40;
export const COACHMARK_ARROW_WIDTH = 5;

export type Coordinates = {
  x: number;
  y: number;
  width: number;
  height: number;
  pageX: number;
  pageY: number;
};

export type CoachmarkPositions = {
  coachmarkWrapperTop: number;
  coachmarkWrapperLeft: number;
  coachmarkBubbleLeft: number;
};

export const getRefCoordinates = async (target: RefObject<View>): Promise<Coordinates> => {
  return new Promise((resolve, reject) => {
    if (!target?.current) {
      reject();
    }
    target?.current?.measure((x, y, width, height, pageX, pageY) => {
      const coordinates = {
        x,
        y,
        width,
        height,
        pageX,
        pageY,
      };
      resolve(coordinates);
    });
  });
};

export const getCoachmarkPositionsFromCoordinates = (
  parentWrapperCoordinates?: Coordinates | null,
  coachmarkBubbleCoordinates?: Coordinates | null,
): CoachmarkPositions => {
  if (
    parentWrapperCoordinates?.pageX &&
    parentWrapperCoordinates?.width &&
    parentWrapperCoordinates?.height &&
    coachmarkBubbleCoordinates?.width
  ) {
    return {
      // height of the children + the arrow height (COACHMARK_ARROW_WIDTH * 2) + designer padding (SPACING.STATIC.XS = 8px)
      coachmarkWrapperTop: parentWrapperCoordinates?.height
        ? parentWrapperCoordinates.height + COACHMARK_ARROW_WIDTH * 2 + SPACING.STATIC.XS.value
        : 0,
      // distance from the left border of the screen to the left border of the children
      coachmarkWrapperLeft: parentWrapperCoordinates?.pageX || 0,
      /**
       *  Distance from the left border of the screen to the start of the tooltip
       *  To calculate this we use :
       *  distance from the left border to the left border of the children
       *  + half the width of the children (to get to where the tooltip should be centered)
       *  - half the width of the tooltip (to get the start of the tooltip should start in order to be centered with the children)
       *
       * ![image](https://user-images.githubusercontent.com/85632270/223433647-978291d3-7932-40d4-8a1d-beb9241e5d87.png)
       */
      coachmarkBubbleLeft:
        parentWrapperCoordinates.pageX + parentWrapperCoordinates.width / 2 - coachmarkBubbleCoordinates.width / 2,
    };
  }
  return {
    coachmarkWrapperTop: 0,
    coachmarkWrapperLeft: 0,
    coachmarkBubbleLeft: 0,
  };
};
