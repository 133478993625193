// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, LgPadding, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button } from '@alto/pocky';
import { PatientTasksEndpoint } from '@alto/scriptdash/alto/patient_app/patient_tasks/v1/patient_tasks_endpoint';
import React from 'react';
import { push } from 'react-router-redux';
import styledNative from 'styled-components/native';
import { selectUser } from '~shared/actions/ui/users';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useDispatchShared } from '~shared/store';
import Calendar from '~web/images/calendar@2x.png';

const CustomCard = styledNative(Card)`
  margin: 0 0 0;
  z-index: 0;
`;

type Props = {
  readonly userID: number;
  readonly taskID: number;
  readonly onInsuranceDismissal: () => void;
};

export const NewYearInsuranceCard = ({ userID, taskID, onInsuranceDismissal }: Props) => {
  const dispatch = useDispatchShared();

  const title = 'New insurance?';
  const description = 'If your insurance is changing, be sure to update your insurance for the new year.';
  const buttonText = 'Update now';
  const dismissText = 'No insurance changes';

  const handleUpdateInsurance = () => {
    dispatch(selectUser(userID));
    dispatch(push(`me/${userID}`));
  };

  const handleDismissInsurance = async () => {
    const { data } = await PatientTasksEndpoint(apiEndpointHandler).complete({ task_ids: [taskID] });
    if (data) {
      onInsuranceDismissal();
    }
  };

  return (
    <CustomCard
      title={title}
      description={description}
      illustrationSrc={Calendar}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.insuranceNone}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Button
          kind="primary"
          full
          onClick={handleUpdateInsurance}
        >
          {buttonText}
        </Button>
        <XsSpacing />
        <Button
          kind="tertiary"
          full
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleDismissInsurance}
        >
          {dismissText}
        </Button>
      </LgPadding>
    </CustomCard>
  );
};
