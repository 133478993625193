import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { type InputRadio } from './InputRadio';
import { RadioGroupProvider } from './RadioGroupProvider';

export type InputRadioGroupProps = {
  /**
   * Function to get the value the user has chosen.
   */
  readonly onValueChange: (value: string) => void;
  /**
   * Primary place to render input radios.
   */
  readonly children: (React.ReactElement<typeof InputRadio> | null)[] | React.ReactNode;
  /**
   * Use this to preselect a specific value that we'd want to encourage the user to pick.
   */
  readonly defaultValue?: string;
  /**
   * Sets the input to no longer be interactive, and changes the colors to signify this.
   *
   * Example use-case: the user has filled out a form and pressed submit, and you want to prevent editing
   * while the user waits for the submission to complete.
   */
  readonly disabled?: boolean;
  /**
   * This label describes the accessibility label for the radio group.
   */
  readonly label?: string;
  /**
   * Our optional "controlled input" API for InputRadio. Although we prefer allowing InputRadioGroup to manage radio state itself internally.
   * You may use the `value` prop instead if you need complete external control over the currently-selected value of the radio group.
   * This is meant to be used in conjunction with the `onValueChange` prop.
   * Pass null for value to unset the radio options
   */
  readonly value?: string | null;
};

export const InputRadioGroup = ({
  children,
  defaultValue,
  disabled,
  label,
  onValueChange,
  value,
}: InputRadioGroupProps) => {
  const [checkedValue, setCheckedValue] = useState<string | undefined>();
  const onPressRadio = useCallback(
    (radioValue: string) => {
      setCheckedValue(radioValue);
      onValueChange(radioValue);
    },
    [onValueChange],
  );

  const context = useMemo(
    () => ({
      checkedValue: value === undefined ? checkedValue ?? defaultValue : value || undefined,
      onPressRadio,
      groupIsDisabled: disabled,
    }),
    [checkedValue, defaultValue, disabled, onPressRadio, value],
  );

  return (
    <RadioGroupProvider.Provider value={context}>
      <View
        accessibilityRole="radiogroup"
        accessibilityState={{ disabled }}
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        accessibilityLabel={`${label ? `${label} ` : ''}radio group start`}
      >
        {children}
      </View>
    </RadioGroupProvider.Provider>
  );
};
