import { type RouteParams } from './RouteParams';

const UNIMPLEMENTED_ROUTE = '';

export type FeatureRouteMap = {
  [Route in keyof RouteParams]: (params?: RouteParams[Route]) => {
    native: {
      routeName: Route;
      params?: RouteParams[Route];
    };
    web: {
      pathname: string;
    };
  };
};

export const featureRouteMap: FeatureRouteMap = {
  RouteAbout: () => ({
    native: {
      routeName: 'RouteAbout',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteTabAccount: () => ({
    native: {
      routeName: 'RouteTabAccount',
    },
    web: {
      pathname: '/me',
    },
  }),
  RouteAccountFaq: (params) => ({
    native: {
      routeName: 'RouteAccountFaq',
      params,
    },
    web: {
      pathname: '/me/faq',
    },
  }),
  RouteAddFamilyMember: () => ({
    native: {
      routeName: 'RouteAddFamilyMember',
    },
    web: {
      pathname: '/me/family_member',
    },
  }),
  RouteFamilyMemberAdded: (params) => ({
    native: {
      routeName: 'RouteFamilyMemberAdded',
      params,
    },
    web: {
      pathname: '/me/family_member/added',
      state: params,
    },
  }),
  RouteFamilyMemberBenefits: (params) => ({
    native: {
      routeName: 'RouteFamilyMemberBenefits',
      params,
    },
    web: {
      pathname: `/me/${params?.userID}/add-benefits`,
      state: params,
    },
  }),
  RouteFamilyMemberCompleteProfile: (params) => ({
    native: {
      routeName: 'RouteFamilyMemberCompleteProfile',
      params,
    },
    web: {
      pathname: `/me/${params?.userID}/complete-profile`,
    },
  }),
  RouteFamilyMemberMedicalInfo: (params) => ({
    native: {
      routeName: 'RouteFamilyMemberMedicalInfo',
      params,
    },
    web: {
      pathname: `/me/${params?.userID}/medical-info`,
      state: params,
    },
  }),
  RouteFamilyMemberRequest: (params) => ({
    native: {
      routeName: 'RouteFamilyMemberRequest',
      params,
    },
    web: {
      pathname: '/me/family_member/request',
      state: params,
    },
  }),
  RouteAddresses: () => ({
    native: {
      routeName: 'RouteAddresses',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteAppTabNavigator: (params) => ({
    native: {
      routeName: 'RouteAppTabNavigator',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteAssistantMedSelection: (params) => ({
    native: {
      routeName: 'RouteAssistantMedSelection',
      params,
    },
    web: {
      pathname: '/help/medications',
      state: params,
    },
  }),
  RouteAssistantMessage: (params) => ({
    native: {
      routeName: 'RouteAssistantMessage',
      params,
    },
    web: {
      pathname: '/help/message',
      state: params,
    },
  }),
  RouteAssistantSupportCase: (params) => ({
    native: {
      routeName: 'RouteAssistantSupportCase',
      params,
    },
    web: {
      pathname: `/help/support/${params?.supportCaseID}`,
    },
  }),
  RouteAssistantSupportCaseReply: (params) => ({
    native: {
      routeName: 'RouteAssistantSupportCaseReply',
      params,
    },
    web: {
      pathname: `/help/support/${params?.supportCaseID}/reply`,
    },
  }),
  RouteAuthStack: () => ({
    native: {
      routeName: 'RouteAuthStack',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteAutoRefillManagement: (params) => ({
    native: {
      routeName: 'RouteAutoRefillManagement',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteBiometricReauth: (params) => ({
    native: {
      routeName: 'RouteBiometricReauth',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteCancelOrdersForm: (params) => ({
    native: {
      routeName: 'RouteCancelOrdersForm',
      params,
    },
    web: {
      pathname: `/orders/cancel`,
    },
  }),
  RouteCart: (params) => ({
    native: {
      routeName: 'RouteCart',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteChangeQuantity: (params) => ({
    native: {
      routeName: 'RouteChangeQuantity',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteCheckout: (params) => ({
    native: {
      routeName: 'RouteCheckout',
      params,
    },
    web: {
      pathname: '/checkout',
      state: { orderIndex: params?.orderIndex },
    },
  }),
  RouteCheckoutPhotoIdUpload: () => ({
    native: {
      routeName: 'RouteCheckoutPhotoIdUpload',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteCommsVerificationStatusAuthed: (params) => ({
    native: {
      routeName: 'RouteCommsVerificationStatusAuthed',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteCommsVerificationStatusUnauthed: (params) => ({
    native: {
      routeName: 'RouteCommsVerificationStatusUnauthed',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteCommunicationPreferences: (params) => ({
    native: {
      routeName: 'RouteCommunicationPreferences',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteConfirmSplitShipment: () => ({
    native: {
      routeName: 'RouteConfirmSplitShipment',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteContactInfo: () => ({
    native: {
      routeName: 'RouteContactInfo',
    },
    web: {
      pathname: '/me/contact-info',
    },
  }),
  RouteCrossSell: () => ({
    native: {
      routeName: 'RouteCrossSell',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteTabOrders: () => ({
    native: {
      routeName: 'RouteTabOrders',
    },
    web: {
      pathname: '/orders',
    },
  }),
  RouteOrderDetails: (params) => ({
    native: {
      routeName: 'RouteOrderDetails',
      params,
    },
    web: {
      pathname: `/orders/${params?.shipmentID}`,
      state: params?.state,
    },
  }),
  RouteEditOrder: (params) => ({
    native: {
      routeName: 'RouteEditOrder',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteEditPhoneNumber: () => ({
    native: {
      routeName: 'RouteEditPhoneNumber',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteEditTipPage: (params) => ({
    native: {
      routeName: 'RouteEditTipPage',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteEssentials: () => ({
    native: {
      routeName: 'RouteEssentials',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteEssentialsCategoryDetails: (params) => ({
    native: {
      routeName: 'RouteEssentialsCategoryDetails',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteEssentialsDetails: (params) => ({
    native: {
      routeName: 'RouteEssentialsDetails',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteEssentialsFullScreen: (params) => ({
    native: {
      routeName: 'RouteEssentialsFullScreen',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteEssentialsSuggestFeedback: (params) => ({
    native: {
      routeName: 'RouteEssentialsSuggestFeedback',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteExistingPatientPhiAuthorization: () => ({
    native: {
      routeName: 'RouteExistingPatientPhiAuthorization',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteFaq: (params) => ({
    native: {
      routeName: 'RouteFaq',
      params,
    },
    web: {
      pathname: `/me/faq/${params?.faqKey}`,
      state: params,
    },
  }),
  RouteFork: () => ({
    native: {
      routeName: 'RouteFork',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteHealthProfile: (params) => ({
    native: {
      routeName: 'RouteHealthProfile',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteTabHomescreen: (params) => ({
    native: {
      routeName: 'RouteTabHomescreen',
      params,
    },
    web: {
      pathname: '/home',
    },
  }),
  RouteIdentityVerification: (params) => ({
    native: {
      routeName: 'RouteIdentityVerification',
      params,
    },
    web: {
      pathname: '/communications/verify_identity/:commsChannel',
    },
  }),
  RouteInstructionalVideos: (params) => ({
    native: {
      routeName: 'RouteInstructionalVideos',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteInsuranceAdd: (params) => ({
    native: {
      routeName: 'RouteInsuranceAdd',
      params,
    },
    web: {
      pathname: `/me/${params?.userID}/add-benefit`,
      state: params,
    },
  }),
  RouteInsuranceCard: (params) => ({
    native: {
      routeName: 'RouteInsuranceCard',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteInsuranceForm: (params) => ({
    native: {
      routeName: 'RouteInsuranceForm',
      params,
    },
    web: {
      pathname: `/me/${params?.userID}/benefits`,
      state: params,
    },
  }),
  RouteInsurancePhotoForm: (params) => ({
    native: {
      routeName: 'RouteInsurancePhotoForm',
      params,
    },
    web: {
      pathname: `/me/${params?.userID}/benefits/photos`,
      state: params,
    },
  }),
  RouteInsuranceSection: () => ({
    native: {
      routeName: 'RouteInsuranceSection',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteLearnMore: (params) => ({
    native: {
      routeName: 'RouteLearnMore',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteLogin: () => ({
    native: {
      routeName: 'RouteLogin',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteLoginInfoForm: () => ({
    native: {
      routeName: 'RouteLoginInfoForm',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteMainStackPaymentMethodPicker: (params) => ({
    native: {
      routeName: 'RouteMainStackPaymentMethodPicker',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteMedDetails: (params) => ({
    native: {
      routeName: 'RouteMedDetails',
      params,
    },
    web: {
      pathname: `/prescriptions/${params?.primaryPrescriptionId}`,
      state: { origin: params?.origin },
    },
  }),
  RouteMedSyncSelfServiceConfirmed: () => ({
    native: {
      routeName: 'RouteMedSyncSelfServiceConfirmed',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteMedSyncSelfServiceCreatingPlan: (params) => ({
    native: {
      routeName: 'RouteMedSyncSelfServiceCreatingPlan',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteMedSyncSelfServiceInfo: () => ({
    native: {
      routeName: 'RouteMedSyncSelfServiceInfo',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteMedSyncSelfServiceRequestReceived: () => ({
    native: {
      routeName: 'RouteMedSyncSelfServiceRequestReceived',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteMedSyncSelfServiceReview: () => ({
    native: {
      routeName: 'RouteMedSyncSelfServiceReview',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteMedSyncSelfServiceSelectMedications: () => ({
    native: {
      routeName: 'RouteMedSyncSelfServiceSelectMedications',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteMessages: (params) => ({
    native: {
      routeName: 'RouteMessages',
      params,
    },
    web: {
      pathname: '/help',
    },
  }),
  RouteTabMessages: (params) => ({
    native: {
      routeName: 'RouteTabMessages',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteReadOnlyThread: (params) => ({
    native: {
      routeName: 'RouteReadOnlyThread',
      params,
    },
    web: {
      pathname: '/messages',
    },
  }),
  RouteNotificationCenter: (params) => ({
    native: {
      routeName: 'RouteNotificationCenter',
      params,
    },
    web: {
      pathname: '/notifications',
    },
  }),
  RouteMessagesDeliverySelection: (params) => ({
    native: {
      routeName: 'RouteMessagesDeliverySelection',
      params,
    },
    web: {
      pathname: '/help/deliveries',
      state: params,
    },
  }),
  RouteTabMyMeds: (params) => ({
    native: {
      routeName: 'RouteTabMyMeds',
      params,
    },
    web: {
      pathname: '/prescriptions',
      state: { origin: params?.origin },
    },
  }),
  RouteNeedlePrompt: () => ({
    native: {
      routeName: 'RouteNeedlePrompt',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingContactVerification: (params) => ({
    native: {
      routeName: 'RouteOnboardingContactVerification',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingGeoUncovered: () => ({
    native: {
      routeName: 'RouteOnboardingGeoUncovered',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingGeoVerify: () => ({
    native: {
      routeName: 'RouteOnboardingGeoVerify',
    },
    web: {
      pathname: '/signup',
    },
  }),
  RouteOnboardingHealthProfile: (params) => ({
    native: {
      routeName: 'RouteOnboardingHealthProfile',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingInsurance: (params) => ({
    native: {
      routeName: 'RouteOnboardingInsurance',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingLandingPage: () => ({
    native: {
      routeName: 'RouteOnboardingLandingPage',
    },
    web: {
      pathname: '/patient-onboarding',
    },
  }),
  RouteOnboardingPersonal: () => ({
    native: {
      routeName: 'RouteOnboardingPersonal',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingPrescriptions: (params) => ({
    native: {
      routeName: 'RouteOnboardingPrescriptions',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingResume: (params) => ({
    native: {
      routeName: 'RouteOnboardingResume',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingVerify: (params) => ({
    native: {
      routeName: 'RouteOnboardingVerify',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOnboardingTransferOtherScripts: () => ({
    native: {
      routeName: 'RouteOnboardingTransferOtherScripts',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOrderEarlyForm: (params) => ({
    native: {
      routeName: 'RouteOrderEarlyForm',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOrderEarlySubmit: (params) => ({
    native: {
      routeName: 'RouteOrderEarlySubmit',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteOrderSuccess: (params) => ({
    native: {
      routeName: 'RouteOrderSuccess',
      params,
    },
    web: {
      pathname: `/store/order-success/${params?.shipmentIDs[0]}`,
      state: { bundledPrescriptionIDs: params?.bundledPrescriptionIDs },
    },
  }),
  RoutePayments: () => ({
    native: {
      routeName: 'RoutePayments',
    },
    web: {
      pathname: '/me/payments',
    },
  }),
  RoutePhotoId: () => ({
    native: {
      routeName: 'RoutePhotoId',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RoutePreparationInfo: (params) => ({
    native: {
      routeName: 'RoutePreparationInfo',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RoutePrescriptionStatusPage: (params) => ({
    native: {
      routeName: 'RoutePrescriptionStatusPage',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RoutePriceChange: () => ({
    native: {
      routeName: 'RoutePriceChange',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RoutePriceConfirmation: (params) => ({
    native: {
      routeName: 'RoutePriceConfirmation',
      params,
    },
    web: {
      pathname: '/price_confirmation',
      state: params,
    },
  }),
  RouteProgynyWizardCalculating: () => ({
    native: {
      routeName: 'RouteProgynyWizardCalculating',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardDosageCheck: () => ({
    native: {
      routeName: 'RouteProgynyWizardDosageCheck',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardIntro: () => ({
    native: {
      routeName: 'RouteProgynyWizardIntro',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardIntro2: () => ({
    native: {
      routeName: 'RouteProgynyWizardIntro2',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardIntro3: () => ({
    native: {
      routeName: 'RouteProgynyWizardIntro3',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardLeftoverMeds: () => ({
    native: {
      routeName: 'RouteProgynyWizardLeftoverMeds',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardOrderConfirmation: (params) => ({
    native: {
      routeName: 'RouteProgynyWizardOrderConfirmation',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardQuantityReview: () => ({
    native: {
      routeName: 'RouteProgynyWizardQuantityReview',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardStartDate: () => ({
    native: {
      routeName: 'RouteProgynyWizardStartDate',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteProgynyWizardTriggerDate: () => ({
    native: {
      routeName: 'RouteProgynyWizardTriggerDate',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteRateShipment: (params) => ({
    native: {
      routeName: 'RouteRateShipment',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteRefer: () => ({
    native: {
      routeName: 'RouteRefer',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteSelectPaymentMethod: () => ({
    native: {
      routeName: 'RouteSelectPaymentMethod',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteSelectPaymentMethodAddCard: (params) => ({
    native: {
      routeName: 'RouteSelectPaymentMethodAddCard',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteSnoozeShipment: (params) => ({
    native: {
      routeName: 'RouteSnoozeShipment',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteSupport: (params) => ({
    native: {
      routeName: 'RouteSupport',
      params,
    },
    web: {
      pathname: '/me/contact-support',
    },
  }),
  RouteSendPrescriptionOptions: (params) => ({
    native: {
      routeName: 'RouteSendPrescriptionOptions',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RoutePharmacyInfo: (params) => ({
    native: {
      routeName: 'RoutePharmacyInfo',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteTransferPrescription: (params) => ({
    native: {
      routeName: 'RouteTransferPrescription',
      params,
    },
    web: {
      pathname: '/transfer',
    },
  }),
  RouteTransferSuccess: () => ({
    native: {
      routeName: 'RouteTransferSuccess',
    },
    web: {
      pathname: '/transfer/success',
    },
  }),
  RouteUpcomingDeliveryPriceDetails: (params) => ({
    native: {
      routeName: 'RouteUpcomingDeliveryPriceDetails',
      params,
    },
    web: {
      pathname: `/help/deliveries/price-details/${params?.shipmentID}`,
      state: params,
    },
  }),
  RouteUserProfile: (params) => ({
    native: {
      routeName: 'RouteUserProfile',
      params,
    },
    web: {
      pathname: `/me/${params?.userID}`,
    },
  }),
  RouteVacationRequestFailure: (params) => ({
    native: {
      routeName: 'RouteVacationRequestFailure',
      params,
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteWelcome: () => ({
    native: {
      routeName: 'RouteWelcome',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
  RouteWhatMedicationQuestion: () => ({
    native: {
      routeName: 'RouteWhatMedicationQuestion',
    },
    web: {
      pathname: UNIMPLEMENTED_ROUTE,
    },
  }),
};
