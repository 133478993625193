import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Description, Row, XsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Modal, Subway, SubwayStation } from '@alto/pocky';
import { type Prescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import { type SourcePrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/source_prescription';
import { PrescriptionStatusMap } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import {
  COMPLETED,
  IN_PROGRESS,
  NOT_STARTED,
  buildDynamicStations,
  getStationTitleAndDescription,
} from '~shared/features/prescription-tracker/constants';
import { getIsCurrentUserProgyny } from '~shared/features/users/selectors/getIsProgynyUser';
import { isMobileWeb } from '~shared/helpers/helper';
import { useSelectorShared } from '~shared/store';
import SpinningHourglass from './SpinningHourglass';
import TrackerIndicator, { type Status } from './TrackerIndicator';
import { Drawer } from '~web/components/Drawer';

type Tense = 'past' | 'present' | 'future';

const LgPadding = styled.div<{ rightPadding?: string; leftPadding?: string }>`
  padding-top: ${SPACING.STATIC.LG.px};
  padding-right: ${({ rightPadding }) => rightPadding || SPACING.STATIC.LG.px};
  padding-bottom: ${SPACING.STATIC.LG.px};
  padding-left: ${({ leftPadding }) => leftPadding || SPACING.STATIC.LG.px};
`;

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
    h1 {
      color: ${COLORS.TEXT_COLORS.PRIMARY};
    }
  }
  div {
    max-width: 400px;
  }
`;

const StyledText = styledNative(Description)`
  margin-top: -9px;
  line-height: 32px;
`;

const StyledIcon = styled.div`
  margin-top: 0px;
`;

export type Props = {
  readonly prescription?: SourcePrescription | Prescription | null;
  readonly onClose: () => void;
};

type configType = {
  isActiveStation: boolean;
  stationStatus: Status;
  tense: Tense;
  titleColor: string;
};

/**
 * @deprecated use PrescriptionStatusTracker from @alto/features instead.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const PrescriptionTrackerModal = ({ prescription, onClose }: Props) => {
  const isProgyny = useSelectorShared(getIsCurrentUserProgyny);

  const buildSubway = () => {
    let subway;

    if (prescription) {
      const { MULTIPLE_PRICES, READY } = PrescriptionStatusMap;
      const activeStation = prescription?.status === MULTIPLE_PRICES ? READY : prescription?.status;
      const dynamicStations = buildDynamicStations(prescription.status);
      const activeStationIndex = dynamicStations.findIndex((id) => activeStation === id);
      const stations = dynamicStations.map((station, i) => {
        // default station configuration
        let config: configType = {
          isActiveStation: false,
          stationStatus: null,
          tense: '' as Tense,
          titleColor: '',
        };

        // if station is complete
        if (i < activeStationIndex) {
          config = { ...config, stationStatus: COMPLETED, tense: 'past', titleColor: COLORS.PALETTE.TERTIARY.DEFAULT };
        } // if station is in progress
        else if (i === activeStationIndex) {
          config = {
            ...config,
            isActiveStation: true,
            stationStatus: IN_PROGRESS,
            tense: 'present',
            titleColor: COLORS.TEXT_COLORS.PRIMARY,
          };
        } // if station is incomplete
        else {
          config = { ...config, stationStatus: NOT_STARTED, tense: 'future', titleColor: COLORS.TEXT_COLORS.PRIMARY };
        }

        const { isActiveStation, stationStatus, tense, titleColor } = config;

        const stationDetails = getStationTitleAndDescription[station];
        const titleCopy = stationDetails.title(tense, isProgyny);
        const descriptionCopy = stationDetails.description([], isProgyny);

        const title = (
          <Row>
            <StyledText
              fontFamily="semibold"
              color={titleColor}
            >
              {titleCopy}
            </StyledText>
            {isActiveStation && activeStation !== READY ? (
              <>
                <XsSpacing />
                <StyledIcon>
                  <SpinningHourglass />
                </StyledIcon>
              </>
            ) : null}
          </Row>
        );

        const description = isActiveStation && <Body color={COLORS.TEXT_COLORS.GREY}>{descriptionCopy}</Body>;

        return (
          <SubwayStation
            key={station}
            id={station}
            title={title}
            description={description}
            indicator={<TrackerIndicator status={stationStatus} />}
          />
        );
      });

      subway = (
        <Subway
          colors={{
            complete: COLORS.PALETTE.TERTIARY.DEFAULT,
            incomplete: COLORS.PALETTE.GREYSCALE.DEFAULT,
          }}
          verticalEnabled="always"
        >
          {stations}
        </Subway>
      );
    }

    return subway;
  };

  const prescriptionName = prescription ? prescription.name : '';

  return isMobileWeb() ? (
    <Drawer
      dismissable
      title={prescriptionName}
      onClose={onClose}
    >
      <LgPadding
        leftPadding={SPACING.STATIC.XS.px}
        rightPadding={SPACING.STATIC.XS.px}
      >
        {buildSubway()}
      </LgPadding>
    </Drawer>
  ) : (
    <StyledModal
      title={prescriptionName}
      onClose={onClose}
    >
      <LgPadding>{buildSubway()}</LgPadding>
    </StyledModal>
  );
};

export default PrescriptionTrackerModal;
