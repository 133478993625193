// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, DeprecatedModalBody, Modal, Row, spacing } from '@alto/pocky';
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import React, { type ElementRef, useEffect, useState } from 'react';
import { getIsTippableForShipmentID } from '~shared/features/checkout/selectors/getIsTippable';
import { SOURCES } from '~shared/features/courier-tips/AnalyticsProps';
import { DEFAULT_TIP_AMOUNT } from '~shared/features/courier-tips/constants';
import { canSubmitCourierTip } from '~shared/features/courier-tips/helpers';
import { NO_RATING_ERROR_MESSAGE } from '~shared/features/rate-shipment/helpers';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useGetInsurances } from '~shared/queries/query-keys/insurances';
import { useSelectorShared } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import EditOrAddTip from '~web/features/courier-tips/components/EditOrAddTip';
import RateShipment from '~web/features/rate-shipment/RateShipment';

type Props = {
  readonly currentStep: number;
  readonly fetchShipmentTips: () => void;
  readonly onDismissCourierTip: () => void;
  readonly onDismissRating: () => void;
  readonly onSubmitCourierTip: (tipAmount: number, paymentMethodID: number) => void;
  readonly onSubmitRating: (params: { isTippable: boolean; rating: number; freeformReason: string }) => void;
  readonly sendRatingClickedAnalyticsEvent: (rating: number) => void;
  readonly shipmentID: string;
  readonly shipmentTip: ShipmentTip | null | undefined;
  readonly uuid?: string;
};

const PostDeliveryModal = ({
  currentStep,
  fetchShipmentTips,
  onDismissCourierTip,
  onDismissRating,
  onSubmitCourierTip,
  onSubmitRating,
  sendRatingClickedAnalyticsEvent,
  shipmentID,
  shipmentTip,
  uuid,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const [rating, setRating] = useState(0);
  const [freeformReason, setFreeformReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [tipAmount, setTipAmount] = useState(shipmentTip?.amount ?? DEFAULT_TIP_AMOUNT);
  const [tipPaymentMethodID, setTipPaymentMethodID] = useState(shipmentTip?.payment_method_id);

  const { hasGovernmentInsurance } = useGetInsurances();
  const isTippable = useSelectorShared((state) =>
    getIsTippableForShipmentID(state, { shipmentID: Number(shipmentID), hasGovernmentInsurance }),
  );

  useEffect(() => {
    trackEvent({
      event: EVENTS.RATE_SHIPMENT__EXPERIENCE_VIEWED,
      params: {
        view: 'modal',
        uuid,
      },
    });
  }, [trackEvent, uuid]);

  const address = useSelectorShared((state: ReduxStateShared) =>
    getShipmentAddress(state, { shipmentID: Number(shipmentID) }),
  );

  // first star
  const firstFocusableElement: ElementRef<any> = React.createRef();
  useEffect(() => {
    fetchShipmentTips();

    // pretty hacky but need to do it this way for 2 reasons:
    // 1. setting ref on the Star SVG results in an exception
    // 2. focusing the firstFocusableElement (StarWrapper) results in a focus ring visible on mount
    // @ts-expect-error TS(2571): Object is of type 'unknown'.

    firstFocusableElement.current?.children[0]?.focus(); // intentionally leave array empty so that this only executes once on mount

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  /* eslint-enable react-hooks/exhaustive-deps */
  const onRatingChangeWithAnalytics = (rating: number) => {
    sendRatingClickedAnalyticsEvent(rating);
    setErrorMessage('');
    setRating(rating);
  };

  const handleSubmitRating = () => {
    if (rating === 0) {
      setErrorMessage(NO_RATING_ERROR_MESSAGE);
    } else {
      onSubmitRating({ isTippable, rating, freeformReason });
    }
  };

  const handleSubmitCourierTip = () => {
    if (tipPaymentMethodID) {
      onSubmitCourierTip(tipAmount, tipPaymentMethodID);
    }
  };

  const handleDismissTip = () => {
    onDismissCourierTip();
  };

  // TODO: add transition between these pages
  const pageOptions = [
    {
      component: (
        <RateShipment
          rating={rating}
          onRatingChange={onRatingChangeWithAnalytics}
          freeformReason={freeformReason}
          onFreeformReasonChange={setFreeformReason}
          errorMessage={errorMessage}
          firstFocusableElementRef={firstFocusableElement}
          isModal
        />
      ),
      onDismiss: onDismissRating,
      onSubmit: handleSubmitRating,
      disabled: false,
    },
    {
      component: (
        <EditOrAddTip
          editTipAmount={setTipAmount}
          editTipPaymentMethodID={setTipPaymentMethodID}
          tipAmount={tipAmount}
          tipPaymentMethodID={tipPaymentMethodID}
          analyticsProps={{
            shipment_id: shipmentID,
            source: SOURCES.POST_DELIVERY,
            facility_id: address?.dispensing_facility_id,
          }}
        />
      ),
      onDismiss: handleDismissTip,
      onSubmit: handleSubmitCourierTip,
      disabled: !canSubmitCourierTip(tipAmount, tipPaymentMethodID),
    },
  ];

  const { component, disabled, onDismiss, onSubmit } = pageOptions[currentStep];

  return (
    <Modal
      dismissOnOutsideClick={false}
      onClose={onDismiss}
      showDismiss
    >
      <DeprecatedModalBody>
        <Column spacing={spacing.mdSpacing}>
          {component}
          <Row
            rightAlignContent
            spacing={spacing.smSpacing}
          >
            <Button
              onClick={onDismiss}
              kind="secondary"
            >
              Dismiss
            </Button>
            <Button
              disabled={disabled}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Row>
        </Column>
      </DeprecatedModalBody>
    </Modal>
  );
};

export default PostDeliveryModal;
