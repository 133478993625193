// @owners { team: patients-team }

import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React, { useMemo } from 'react';
import { type APIOrderMethod, type APIOrderStatus } from '~shared/features/delivery-info/types';
import {
  formatDateWithDowWithoutYear,
  formatDeliveredAt,
  formatRelativeDateWithDow,
  formatWindow,
  formatWindowTime,
} from '~shared/helpers/date';
import { isMail, isPickup, isUSPS } from '~shared/helpers/order';
import { isPickupPastDue } from '~shared/helpers/pickup';

type Props = {
  readonly date?: string | null;
  readonly method?: APIOrderMethod | null;
  readonly status?: APIOrderStatus | null;
  readonly origin?: string | null; // TODO: should we have an origin type?
  readonly startTime?: string | null;
  readonly endTime?: string | null;
  readonly isAsapDelivery?: boolean;
  readonly isEligibleForMailAutoRefill?: boolean;
  readonly isOnDemand?: boolean;
};

const calendarRowCopy = ({
  date,
  method,
  status,
  origin,
  startTime,
  endTime,
  isAsapDelivery = false,
  isEligibleForMailAutoRefill = false,
  isOnDemand = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  if (!date) return '';

  // delivered logic same for all methods
  // pass delivered_at property for the date value to get copy
  if (status === 'delivered') {
    return formatDeliveredAt(date);
  }

  const dateText = formatRelativeDateWithDow(date);

  // pickup logic
  if (isPickup(method)) {
    // order success copy
    if (origin === 'order_success' && startTime) {
      return `${dateText}, ready by ${formatWindowTime({ windowTime: startTime, meridiem: 'aaa', forceReturnMinutes: true })} (estimated)`;
    }
    if (isPickupPastDue({ date, method, status })) {
      return `Ready on ${formatDateWithDowWithoutYear(date)}`;
    }
    return dateText;
  }

  // mail logic
  if (isMail(method)) {
    if (isUSPS(method)) {
      return `Expected ${dateText}`;
    }
    return dateText;
  }

  // courier logic
  // asap delivery
  if (isAsapDelivery && endTime) {
    return `${dateText}, by ${formatWindowTime({ windowTime: endTime, meridiem: 'aaa' })} (estimated)`;
  }
  //on demand
  if (isOnDemand) {
    return `${dateText}, ASAP`;
  }
  // do not show window
  // eligible for mail auto refills show up as courier until X days before
  if (isEligibleForMailAutoRefill || !startTime || !endTime) {
    return dateText;
  }
  if (startTime && endTime) {
    return `${dateText} ${formatWindow(startTime, endTime)}`;
  }

  return dateText;
};

export const CalendarRowV2 = ({
  date,
  method,
  status,
  origin,
  startTime,
  endTime,
  isAsapDelivery = false,
  isEligibleForMailAutoRefill = false,
  isOnDemand = false,
}: Props) => {
  const copy = useMemo(
    () =>
      calendarRowCopy({
        date,
        method,
        status,
        origin,
        startTime,
        endTime,
        isAsapDelivery,
        isEligibleForMailAutoRefill,
        isOnDemand,
      }),
    [date, endTime, isAsapDelivery, isEligibleForMailAutoRefill, isOnDemand, method, origin, startTime, status],
  );

  if (copy === '') return null;

  return (
    <PresentationListItem
      LeftContent={
        <AltoIcon
          name="calendar"
          type="grey"
        />
      }
      testID="calendar-row"
      text={copy}
      type="grey"
    />
  );
};
