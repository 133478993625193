import { compareDesc, parseISO } from 'date-fns';
import { createSelector } from 'reselect';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';

const getDefaultPaymentMethod = createSelector([getPaymentMethods], (paymentMethods) => {
  // Prioritize primary payment method
  const primaryPaymentMethod = paymentMethods.find(({ is_primary }) => is_primary);
  if (primaryPaymentMethod) {
    return primaryPaymentMethod;
  }

  // Default to most recently used payment method
  const recentPaymentMethods = paymentMethods.sort((a, b) => {
    if (!a.last_used_at) {
      return 1;
    }
    if (!b.last_used_at) {
      return -1;
    }
    return compareDesc(parseISO(a.last_used_at), parseISO(b.last_used_at));
  });
  return recentPaymentMethods.length > 0 ? recentPaymentMethods[0] : undefined;
});

export default getDefaultPaymentMethod;
