// @owners { team: patients-team }
import { openModal } from '~shared/actions/modal';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';
import { connect } from '~shared/store';
import { type PaymentMethod, type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import ChoosePaymentMethod from './ChoosePaymentMethod';

type StateProps = {
  paymentMethods: PaymentMethod[];
};

type DispatchProps = {
  openCreditCardModal: () => void;
};

const mapStateToProps = (state: ReduxStateShared): StateProps => ({
  paymentMethods: getPaymentMethods(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatchShared): DispatchProps => ({
  openCreditCardModal: () => {
    dispatch(openModal('COURIER_TIPS_CREDIT_CARD_MODAL'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePaymentMethod);
