import { type inferQueryKeyStore, mergeQueryKeys } from '@lukemorales/query-key-factory';
import { addToShipment } from './add-to-shipment';
import { cart } from './cart';
import { enterCheckout } from './enter-checkout';
import { facilityAlerts } from './facility-alert';
import { insurances } from './insurances';
import { orderBundling } from './order-bundling';
import { prescriptions } from './prescriptions';
import { pricing } from './pricing';
import { products } from './products';
import { shipmentFees } from './shipment-fees';
import { wundercomMessages } from './wundercom-messages';
import { onboardingConfigs } from '~shared/queries/query-keys/onboarding-configs';

export const queries = mergeQueryKeys(
  addToShipment,
  cart,
  enterCheckout,
  facilityAlerts,
  insurances,
  onboardingConfigs,
  orderBundling,
  prescriptions,
  pricing,
  products,
  shipmentFees,
  wundercomMessages,
);
export type QueryKeys = inferQueryKeyStore<typeof queries>;
