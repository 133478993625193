// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, Button, LgPadding, SmSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext, useEffect } from 'react';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { BUSINESS_HOURS, MESSAGE_RESPONSE_TIME } from '~shared/features/messages/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useNavigateToAssistantLanding } from '../../helpers/useNavigateToAssistantLanding';
import { ContactUs } from '../contact-us/ContactUs';
import { FAQ_KEYS } from '../faq/Constants';
import { HolidayClosureAlert } from '../support-page/HolidayClosureAlert';

export const MessagingActionSheet = () => {
  const { navigate } = useNavigation();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  useEffect(() => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: { name: ASSISTANT_VIEWED_EVENTS.MESSAGING_ACTION_SHEET },
    });
  }, [trackEvent]);

  return (
    <ActionSheetV2
      title="Business hours"
      illustrationSrc={require('./secure-messaging-onboarding.png')}
      buttons={[
        <Button
          label="Got it"
          key="Got it"
          onPress={closeActionSheet}
        />,
      ]}
      analyticsName="messaging business hours"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <HolidayClosureAlert
          onPress={() => {
            closeActionSheet();
            navigate('RouteFaq', {
              faqKey: FAQ_KEYS.HOLIDAY_CLOSURE,
              hideBottomButtons: true,
              goBack: () => {
                navigateToAssistantLanding();
              },
            });
          }}
        />
        <Body>
          We typically respond within {MESSAGE_RESPONSE_TIME} during business hours. If this is an urgent matter, please{' '}
          <ContactUs
            origin={CONTACT_US_ORIGIN.MessagingActionSheet}
            type="link"
            method="phone"
          />
          .
        </Body>
        <SmSpacing />
        <Body>{BUSINESS_HOURS}</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
