import { SPACING } from '@alto/design-library-tokens';
import { Description } from '@alto/design-system';
import { BackorderedWarning, useBackorderedMedications, useEssentialsCartThreshold } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Loader, LoadingButton, Text } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { formattedNextAvailableDate } from '~shared/features/next-available-date/helpers/formattedNextAvailableDate';
import { useQueryNextAvailableDateForOrder } from '~shared/features/next-available-date/queries/useQueryNextAvailableDateForOrder';

const NextAvailableDateContainer = styled.div`
  padding-bottom: ${SPACING.STATIC.XS.px};
  text-align: center;
`;

type Props = {
  readonly handleCheckout: () => void;
  readonly fetchEnterCartLoading: boolean;
  readonly prescriptionIDs: number[];
};

type CartFooterOverlineProps = {
  readonly prescriptionIDs: number[];
};

const CartFooterOverline = ({ prescriptionIDs }: CartFooterOverlineProps) => {
  const { showEssentialsThreshold, orderMinimumRemainder, minimumFooterCopy } = useEssentialsCartThreshold();
  const { nextAvailableDate, isLoading } = useQueryNextAvailableDateForOrder();
  const { deliveryMethods } = useCartDeliveryMethods();

  const { date = '', reason = '' } = nextAvailableDate?.earliest || {};
  const nextDate = date ? formattedNextAvailableDate(date, reason) : null;
  const backorderedMedicationIds = useBackorderedMedications(prescriptionIDs);
  const hasBackorderedMedications = backorderedMedicationIds.length > 0;

  if (deliveryMethods.length > 1) return null;

  if (hasBackorderedMedications) {
    return <BackorderedWarning prescriptionIds={backorderedMedicationIds} />;
  }

  if (showEssentialsThreshold) {
    return (
      <Description center>
        {minimumFooterCopy} <Description fontFamily="semibold">${orderMinimumRemainder}</Description> more to checkout
      </Description>
    );
  }

  if (!isLoading && nextDate) {
    return <Text small>{nextDate}</Text>;
  }

  if (!isLoading && !nextDate) {
    return null;
  }

  return <Loader text="Refreshing..." />;
};

export const CartFooter = ({ handleCheckout, fetchEnterCartLoading, prescriptionIDs }: Props) => {
  const { showEssentialsThreshold } = useEssentialsCartThreshold();
  const backorderedMedicationIds = useBackorderedMedications(prescriptionIDs);
  const hasBackorderedMedications = backorderedMedicationIds.length > 0;

  const { cartSize } = useCartData();
  return !cartSize ? null : (
    <>
      <NextAvailableDateContainer>
        <CartFooterOverline prescriptionIDs={prescriptionIDs} />
      </NextAvailableDateContainer>
      <LoadingButton
        kind="primary"
        disabled={showEssentialsThreshold || hasBackorderedMedications}
        onClick={handleCheckout}
        loading={fetchEnterCartLoading}
      >
        Schedule order
      </LoadingButton>
    </>
  );
};
