// @owners { team: patients-team }
import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
import { FSAIndividualReceiptAlert } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  FlexColumn,
  InfoBox,
  SimpleContainer,
  SimpleContainerHeader,
  SimpleContainerSection,
} from '@alto/pocky';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { type ModalType } from '~shared/actions/modal';
import { type PaymentMethod } from '~shared/types';
import CreditCardPayments from './CreditCardPayments';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { ContentWrapper, FullFlexRow, Main, PageBackground } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';
import { ConfirmLink } from '~web/components/legacy';
import ACHDescription from '~web/features/payments/components/ACHDescription';
import ACHLabel from '~web/features/payments/components/ACHLabel';
import { FSAReceiptSection } from '~web/features/payments/components/FSAReceiptSection';
import { PlaidLinkButton } from '~web/features/payments/components/PlaidLinkButton';

const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormHelper = styled.div`
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  font-size: ${TYPOGRAPHY.TEXT.DETAIL.LG.px};
  color: ${COLORS.PALETTE.PRIMARY.DEFAULT};
  letter-spacing: 0.49px;
  margin-bottom: 0;
  .errored & {
    color: ${COLORS.PALETTE.DANGER.DEFAULT};
  }
`;

type Props = {
  readonly achPaymentMethods: PaymentMethod[];
  readonly creditCardPaymentMethods: PaymentMethod[];
  readonly deletePaymentMethod: (paymentMethod: PaymentMethod) => void;
  readonly loading: boolean;
  readonly openModal: (name: ModalType) => void;
  readonly nativePaymentMethods: PaymentMethod[];
  readonly paymentMethodError: Error | null | undefined;
};

const BREADCRUMBS = [
  {
    text: 'Account',
    to: '/me',
  },
  {
    text: 'Payments and Receipts',
  },
];

export default class ProfilePaymentMethods extends PureComponent<Props> {
  renderACHAccounts() {
    const { achPaymentMethods, deletePaymentMethod } = this.props;

    if (achPaymentMethods.length > 0) {
      return (
        <>
          {achPaymentMethods.map((achPaymentMethod) => {
            const bank = achPaymentMethod.details || {};
            return (
              <SimpleContainerSection key={`ach-${achPaymentMethod.id}`}>
                <FlexColumn evenlySpaceContent>
                  <ACHLabel plaidData={bank} />
                  <ConfirmLink
                    confirmPrompt="Are you sure you want to delete this?"
                    onClick={() => {
                      deletePaymentMethod(achPaymentMethod);
                    }}
                  >
                    Delete
                  </ConfirmLink>
                </FlexColumn>
              </SimpleContainerSection>
            );
          })}
        </>
      );
    }

    return (
      <>
        <SimpleContainerSection>
          <ACHDescription />
        </SimpleContainerSection>
        <SimpleContainerSection>
          <AlignRight>
            <PlaidLinkButton />
          </AlignRight>
        </SimpleContainerSection>
      </>
    );
  }

  render() {
    const {
      creditCardPaymentMethods,
      deletePaymentMethod,
      loading,
      openModal,
      nativePaymentMethods,
      paymentMethodError,
    } = this.props;

    const applePayPaymentMethods = nativePaymentMethods.filter((pm) => pm.details?.native_pay_type === 'apple_pay');
    const googlePayPaymentMethods = nativePaymentMethods.filter((pm) => pm.details?.native_pay_type === 'google_pay');

    return (
      <PageBackground>
        <ContentWrapper>
          <FullFlexRow evenlySpaceContent>
            <Breadcrumbs breadcrumbs={BREADCRUMBS} />
            <PageHeader>Payments and Receipts</PageHeader>
          </FullFlexRow>
          <Main>
            <SimpleContainer>
              <SimpleContainerHeader>Credit Cards</SimpleContainerHeader>
              <CreditCardPayments
                creditCardPaymentMethods={creditCardPaymentMethods}
                deletePaymentMethod={deletePaymentMethod}
              />
              <SimpleContainerSection>
                <AlignRight>
                  <Button
                    kind="secondary"
                    onClick={() => {
                      openModal('CREDIT_CARD_MODAL');
                    }}
                  >
                    Add New Card
                  </Button>
                </AlignRight>
              </SimpleContainerSection>
            </SimpleContainer>
            <SimpleContainer>
              <SimpleContainerHeader>Linked Accounts</SimpleContainerHeader>
              {this.renderACHAccounts()}

              {loading ? <FormHelper>Loading&hellip;</FormHelper> : null}
            </SimpleContainer>
            {applePayPaymentMethods.length > 0 && (
              <SimpleContainer>
                <SimpleContainerHeader>Apple Pay</SimpleContainerHeader>
                <CreditCardPayments
                  creditCardPaymentMethods={applePayPaymentMethods}
                  deletePaymentMethod={deletePaymentMethod}
                />
              </SimpleContainer>
            )}
            {googlePayPaymentMethods.length > 0 && (
              <SimpleContainer>
                <SimpleContainerHeader>Google Pay</SimpleContainerHeader>
                <CreditCardPayments
                  creditCardPaymentMethods={googlePayPaymentMethods}
                  deletePaymentMethod={deletePaymentMethod}
                />
              </SimpleContainer>
            )}
            {paymentMethodError ? <InfoBox warning>{paymentMethodError.message}</InfoBox> : null}
            <SimpleContainer>
              <SimpleContainerHeader>Annual Receipts</SimpleContainerHeader>
              <SimpleContainerSection>
                Download itemized receipts for FSA/HSA claims, taxes, or other reimbursement purposes.
              </SimpleContainerSection>
              <FSAReceiptSection />
              <FSAIndividualReceiptAlert />
            </SimpleContainer>
          </Main>
        </ContentWrapper>
      </PageBackground>
    );
  }
}
