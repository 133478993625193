import { NO_TIP_PAYMENT_METHOD_SELECTED } from '~shared/features/checkout/constants';
import { isValidPaymentMethod } from '~shared/features/checkout/helpers';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { type ValidationError } from '~shared/features/checkout/types';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';
import { getHasAppliedDeliveryFee } from '~shared/features/shipment-fees/selectors/getHasAppliedDeliveryFee';
import { useSelectorShared } from '~shared/store';

export const useCheckoutTipErrors = () => {
  const order = useSelectorShared(getOrder);
  const { tip_amount: tipAmount, tip_payment_method_id: tipPaymentMethodID } = order;
  const paymentMethods = useSelectorShared(getPaymentMethods);
  const isDeliveryFeeApplied = useSelectorShared(getHasAppliedDeliveryFee);

  const errors: ValidationError[] = [];
  const hasTip = (tipAmount ?? 0) > 0;
  const isTipOrFeePaymentMethodRequired = hasTip || isDeliveryFeeApplied;
  const cannotChargeTipPaymentMethod = !isValidPaymentMethod(tipPaymentMethodID, paymentMethods);

  if (isTipOrFeePaymentMethodRequired && cannotChargeTipPaymentMethod) {
    if (hasTip && isDeliveryFeeApplied) {
      errors.push({
        key: NO_TIP_PAYMENT_METHOD_SELECTED,
        message: 'Please select a payment method for your tip & delivery fee.',
      });
    } else if (hasTip) {
      errors.push({
        key: NO_TIP_PAYMENT_METHOD_SELECTED,
        message: 'Please select a payment method for your courier tip.',
      });
    } else if (isDeliveryFeeApplied) {
      errors.push({
        key: NO_TIP_PAYMENT_METHOD_SELECTED,
        message: 'Please select a payment method for your delivery fee.',
      });
    }
  }

  return { tipValidationErrors: errors };
};
