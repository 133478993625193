import { type BACKGROUND_COLORS_VALUES, COLORS, SPACING } from '@alto/design-library-tokens';
import React, { type MutableRefObject, useCallback, useRef, useState } from 'react';
import {
  type NativeSyntheticEvent,
  PixelRatio,
  Platform,
  type TextInput,
  type TextInputFocusEventData,
  type TextInputProps,
  TouchableHighlight,
  View,
} from 'react-native';
import styled from 'styled-components/native';
import { Border } from '../../../borders';
import { Column, Row, XsPadding } from '../../../containers';
import { XsSpacing } from '../../../separators';
import { Caption, Text } from '../../../typography';
import { type ApiResponseRow } from '../InputAutocomplete/types';
import { INPUT_BASE_LINE_HEIGHT, InputBase } from '../shared';
import { InputLabelRow } from './InputLabelRow';
import { LeftIcon, type LeftIconProps, RightIcon, type RightIconProps } from './InputTextIcons';
import { InputTextSelectedTags } from './InputTextSelectedTags';
import { type PlatformSpecificInputTextProps } from './PlatformSpecificInputTextProps';
import { getAccessibilityLabel, getAccessibilityValue } from './helpers';

export type InputTextProps = {
  /**
   * Changes the color of the input field. Only use this if you have a REALLY good reason.
   */
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  /**
   * Small descriptive text that will go just *below* the input box.
   *
   * The caption is best used to give users information on what to input, such as how to find a CVC code, or what password requirements are.
   *
   * Caption use is required when the validation returns an error, so the user understands why they are seeing the error.
   */
  readonly caption?: string;
  /**
   * For internal use only, currently used to support the InputAutocomplete component's multi-select functionality on web.
   */
  readonly selectedValues?: ApiResponseRow<string>[];
  /**
   * For internal use only, currently used to support the InputAutocomplete component's multi-select functionality on web.
   */
  readonly onRemoveValue?: (selectedValue: ApiResponseRow<string>) => () => void;
  /**
   * Sets the input to no longer be interactive, and changes the colors to signify this.
   *
   * Example use-case: the user has filled out a form and pressed submit, and you want to prevent editing
   * while the user waits for the submission to complete.
   */
  readonly disabled?: boolean;
  /**
   * a red-colored caption, usually meant to inform the user of a validation issue with their input.
   */
  readonly error?: string;
  /**
   * prevents the clear-input button from appearing on native after the user types any text into the field.
   */
  readonly hideClearButton?: boolean;
  /**
   * A unique identifier for this input. When used with formik, formik will automatically use the ID to determine
   * what key it should store that input's data under.
   */
  readonly id?: string;
  /**
   * Primary label for this input, appearing directly above the input box. This is almost always required.
   */
  readonly label?: string;
  /**
   * Controls whether an icon appears inside the input box, on the left side.
   */
  readonly leftIconProps?: Pick<LeftIconProps, 'name' | 'type'>;
  /**
   * An alias for id, intended for compatibility with Formik's usage documentation. Since react-native-web doesn't support
   * the name field this is just passed through to the input id field if you don't provide an explicit id prop. Formik
   * treats it the same either way.
   *
   * @alias id
   */
  readonly name?: string;
  /**
   * Pass a ref through via this prop if you want the parent to do things like focus this input after a specific action.
   */
  readonly onRef?: MutableRefObject<TextInput | null> | MutableRefObject<TextInput | null>[];
  /**
   * Makes the word "Optional" appear in small text to the right of the label.
   *
   * You should use this instead of the `required` field for a form that has majority required fields. Just make sure
   * somewhere on the form informs the user that all form fields are required unless otherwise noted.
   */
  readonly optional?: boolean;
  /**
   * Makes a red asterisk appear directly to the right of the label. Use this to indicate whether the user is required to
   * put something in this input.
   *
   * You should use this instead of the `optional` field if you have a form with more optional fields than required fields.
   */
  readonly required?: boolean;
  /**
   * Controls whether an icon appears inside the input box, on the right side. Unlike the left icon, this one can be
   * configured to be pressable.
   */
  readonly rightIconProps?: Pick<
    RightIconProps,
    'name' | 'type' | 'onPress' | 'accessibilityLabel' | 'buttonType' | 'spin' | 'position'
  >;
  /**
   * Text that appears directly to the right of the primary label, in a small size.
   */
  readonly sublabel?: string;
} & PlatformSpecificInputTextProps &
  Pick<
    TextInputProps,
    | 'accessibilityLabel'
    | 'autoCapitalize'
    | 'autoComplete'
    | 'autoCorrect'
    | 'autoFocus'
    | 'blurOnSubmit'
    | 'dataDetectorTypes'
    | 'defaultValue'
    | 'keyboardType'
    | 'maxLength'
    | 'multiline'
    | 'numberOfLines'
    | 'onChangeText'
    | 'passwordRules'
    | 'placeholder'
    | 'returnKeyType'
    | 'secureTextEntry'
    | 'testID'
    | 'textContentType'
    | 'value'
  >;

const MAX_NUMBER_OF_LINES = 6;
const MIN_NUMBER_OF_LINES = 1;

const getTextAreaHeight = (numberOfLines: number) =>
  INPUT_BASE_LINE_HEIGHT.value * PixelRatio.getFontScale() * Math.min(numberOfLines, MAX_NUMBER_OF_LINES);

const StyledInput = styled(InputBase)<InputTextProps & { height: number; hasSelectedValues: boolean }>`
  ${({ hasSelectedValues }) => {
    if (!hasSelectedValues) {
      return 'width: 100%;';
    }
    return `flex-grow: 1; margin: ${SPACING.STATIC.XXS.px} 0;`;
  }}
  height: ${({ height }) => height}px;
  flex-shrink: 1;
  ${({ editable }) => !editable && `color: ${COLORS.TEXT_COLORS.DISABLED}`}
  ${({ disabled }) => Platform.OS === 'web' && disabled && 'cursor: not-allowed;'}
`;

const getBorderColor = (isFocused: boolean, error: string | undefined) => {
  if (error) {
    return COLORS.BORDER_COLORS.DANGER;
  }

  if (isFocused) {
    return COLORS.BORDER_COLORS.SECONDARY;
  }

  return COLORS.BORDER_COLORS.LIGHT;
};

const InputField = styled(Platform.OS === 'web' ? View : TouchableHighlight)<{
  backgroundColor: InputTextProps['backgroundColor'];
  disabled: InputTextProps['disabled'];
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST};
  ${({ disabled }) => Platform.OS === 'web' && disabled && 'cursor: not-allowed;'}
`;

const TextInputAndSelectedValuesWrapper = styled(Row)<{ hasSelectedValues: boolean }>`
  width: 100%;
  ${({ hasSelectedValues }) => hasSelectedValues && `padding: ${SPACING.STATIC.XS.px} 0;`}
`;

export const InputText = ({
  accessibilityLabel,
  accessibilityAutoComplete,
  accessibilityBusy,
  accessibilityDisabled,
  accessibilityInvalid,
  accessibilityLabelledBy,
  accessibilityRequired,
  autoCapitalize,
  autoComplete,
  autoCorrect,
  autoFocus,
  backgroundColor,
  blurOnSubmit,
  caption,
  dataDetectorTypes,
  defaultValue,
  disabled,
  error,
  hideClearButton,
  keyboardType,
  label,
  leftIconProps,
  name,
  maxLength,
  numberOfLines,
  onBlur,
  onChange,
  onChangeText,
  onFocus,
  onKeyPress,
  onLayout,
  onRef,
  onRemoveValue,
  onSubmitEditing,
  id,
  optional,
  passwordRules,
  placeholder,
  required,
  returnKeyType,
  rightIconProps,
  secureTextEntry,
  selectedValues,
  sublabel,
  testID,
  textContentType,
  value,
}: InputTextProps) => {
  const inputRef = useRef<TextInput | null>(null);
  const [hasValue, setHasValue] = useState(!!defaultValue || !!value);
  const [shouldRedactInput, setShouldRedactInput] = useState(secureTextEntry);

  const [isFocused, setFocused] = useState(false);
  const [height, setHeight] = useState(getTextAreaHeight(numberOfLines || MIN_NUMBER_OF_LINES));

  const setCalculatedHeight = useCallback(
    (height: number): void => {
      if (numberOfLines) {
        const heightNotBiggerThanMax = Math.min(getTextAreaHeight(MAX_NUMBER_OF_LINES), height);
        const heightNotSmallerThanMin = Math.max(heightNotBiggerThanMax, getTextAreaHeight(numberOfLines));
        setHeight(heightNotSmallerThanMin || heightNotBiggerThanMax);
      }
    },
    [numberOfLines],
  );

  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (onFocus) {
        onFocus(e);
      }

      setFocused(true);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (onBlur) {
        onBlur(e);
      }

      setFocused(false);
    },
    [onBlur],
  );

  const handleOnChangeText = useCallback(
    (value: string) => {
      if (onChangeText) {
        onChangeText(value);
      }

      setHasValue(!!value);
    },
    [onChangeText],
  );

  const handleClearValue = useCallback(() => {
    inputRef?.current?.clear();
    handleOnChangeText('');
    setHasValue(false);
  }, [handleOnChangeText]);

  const onContentSizeChange = useCallback(
    ({
      nativeEvent: {
        contentSize: { height },
      },
    }: {
      nativeEvent: { contentSize: { height: number } };
    }): void => {
      setCalculatedHeight(height);
    },
    [setCalculatedHeight],
  );

  const handleRef = useCallback(
    (ref: TextInput) => {
      inputRef.current = ref;

      if (onRef && Array.isArray(onRef)) {
        onRef.map((aRef) => {
          aRef.current = ref;
        });
      } else if (onRef) {
        onRef.current = ref;
      }
    },
    [onRef],
  );

  const labelID = label?.replace(/[^a-zA-Z]/g, '_');

  const webOnlyProps = React.useMemo(() => {
    if (Platform.OS !== 'web') {
      return {} as const;
    }
    return {
      accessibilityAutoComplete,
      accessibilityBusy,
      accessibilityDisabled: disabled || accessibilityDisabled,
      accessibilityInvalid: !!error || accessibilityInvalid,
      accessibilityLabelledBy: labelID || accessibilityLabelledBy,
      accessibilityRequired: required || accessibilityRequired,
    } as const;
  }, [
    accessibilityAutoComplete,
    accessibilityBusy,
    accessibilityDisabled,
    accessibilityInvalid,
    accessibilityLabelledBy,
    accessibilityRequired,
    disabled,
    error,
    labelID,
    required,
  ]);

  const computedAccessiblityLabel = getAccessibilityLabel({
    accessibilityLabel,
    disabled,
    error,
    isAndroid: Platform.OS === 'android',
    isFocused,
    label,
    optional,
    required,
    secureTextEntry,
  });

  const computedAccessibilityValue = getAccessibilityValue({
    value,
    defaultValue,
    placeholder,
    shouldRedactInput,
  });

  return (
    <Column>
      <Column
        accessible
        accessibilityLabel={computedAccessiblityLabel}
        accessibilityState={{ disabled }}
        // On both iOS & Android, double tapping activates the text input
        accessibilityHint={!disabled && !isFocused ? 'Double tap to edit' : undefined}
        accessibilityValue={{ text: computedAccessibilityValue }}
      >
        {!!label && (
          <InputLabelRow
            label={label}
            labelID={labelID}
            sublabel={sublabel}
            required={required}
            optional={optional}
            error={error}
            disabled={disabled}
          />
        )}
        <Border
          radius="LG"
          hideTop={disabled}
          hideBottom={disabled}
          hideLeft={disabled}
          hideRight={disabled}
          color={getBorderColor(isFocused, error)}
        >
          <InputField
            activeOpacity={1}
            backgroundColor={!disabled ? backgroundColor : COLORS.BACKGROUND_COLORS.GREY_LIGHTER}
            disabled={disabled}
            underlayColor={COLORS.PALETTE.GREYSCALE.TRANSPARENT}
          >
            <XsPadding
              topPadding={SPACING.STATIC.NONE}
              bottomPadding={SPACING.STATIC.NONE}
            >
              <Row
                centerVertically
                flexGrow={1}
                wrap={false}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  height: selectedValues?.length ? 'auto' : height + 36,
                }}
              >
                <LeftIcon
                  name={leftIconProps?.name}
                  type={leftIconProps?.type}
                  error={error}
                  isFocused={isFocused}
                />
                <TextInputAndSelectedValuesWrapper
                  flexShrink={1}
                  hasSelectedValues={!!selectedValues?.length}
                  wrap={!!selectedValues?.length}
                  centerVertically={!!selectedValues?.length}
                >
                  {!!selectedValues?.length && (
                    <InputTextSelectedTags
                      onRemoveValue={onRemoveValue}
                      selectedValues={selectedValues}
                    />
                  )}
                  <StyledInput
                    accessibilityLabel={accessibilityLabel}
                    autoCapitalize={autoCapitalize}
                    autoComplete={autoComplete}
                    autoCorrect={autoCorrect}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={autoFocus}
                    blurOnSubmit={blurOnSubmit}
                    dataDetectorTypes={dataDetectorTypes}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    editable={!disabled}
                    height={height}
                    hasSelectedValues={!!selectedValues?.length}
                    keyboardType={keyboardType}
                    maxLength={maxLength}
                    multiline={!!numberOfLines}
                    numberOfLines={numberOfLines}
                    onBlur={handleBlur}
                    onChange={onChange}
                    onChangeText={handleOnChangeText}
                    onContentSizeChange={onContentSizeChange}
                    onFocus={handleFocus}
                    onKeyPress={onKeyPress}
                    onLayout={onLayout}
                    onSubmitEditing={onSubmitEditing}
                    passwordRules={passwordRules}
                    placeholder={placeholder}
                    ref={handleRef}
                    returnKeyType={returnKeyType || 'next'}
                    secureTextEntry={shouldRedactInput}
                    testID={testID}
                    nativeID={id || name}
                    textContentType={textContentType}
                    value={value} // textAlignVertical used for Android multiline alignment
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={{
                      textAlignVertical: numberOfLines ? 'top' : 'auto',
                    }}
                    hitSlop={{
                      top: SPACING.STATIC.MD.value,
                      right: SPACING.STATIC.MD.value,
                      bottom: SPACING.STATIC.MD.value,
                      left: SPACING.STATIC.MD.value,
                    }}
                    {...webOnlyProps}
                  />
                </TextInputAndSelectedValuesWrapper>
                <RightIcon
                  name={rightIconProps?.name}
                  type={rightIconProps?.type}
                  onPress={rightIconProps?.onPress}
                  buttonType={rightIconProps?.buttonType}
                  spin={rightIconProps?.spin}
                  accessibilityLabel={rightIconProps?.accessibilityLabel}
                  position={rightIconProps?.position}
                  hideClearButton={hideClearButton || !!numberOfLines || Platform.OS === 'web'}
                  hasValue={hasValue}
                  isFocused={isFocused}
                  setShouldRedactInput={setShouldRedactInput}
                  shouldRedactInput={shouldRedactInput}
                  secureTextEntry={secureTextEntry}
                  handleClearValue={handleClearValue}
                />
              </Row>
            </XsPadding>
          </InputField>
        </Border>
      </Column>
      {!!caption && !error && (
        <>
          <XsSpacing />
          <Caption>{caption}</Caption>
        </>
      )}
      {!!error && (
        <>
          <XsSpacing />
          <Text
            textSize="mini"
            tight
            color={COLORS.TEXT_COLORS.DANGER}
          >
            {error}
          </Text>
        </>
      )}
    </Column>
  );
};
