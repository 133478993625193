// @owners { team: patients-team }
import {
  type OrderPricingParams,
  type PriceOption,
  type PriceType,
} from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { type Order } from '~shared/features/checkout/types';

export const makeFetchOrderPricingOrderParam = ({
  address_id,
  date,
  deliver_after,
  deliver_before,
  delivery_method,
  is_asap: is_asap_delivery,
  items = [],
  on_demand_delivery_price,
  selected_payment_types,
  tip_amount,
}: Order): OrderPricingParams => {
  const param: OrderPricingParams = {
    items: items.map((item) => ({ ...item, selected_price_type: null })),
    selected_payment_types,
    tip_amount,
    scheduling_params: undefined,
    on_demand_delivery_price,
  };
  if (date && address_id) {
    param.scheduling_params = {
      address_id,
      date,
      deliver_after,
      deliver_before,
      is_asap_delivery,
      delivery_method_type: delivery_method || 'courier',
    };
  }
  return param;
};

/**
 * Use the price option corresponding to the FE selectedPaymentType if present. Otherwise, either use the BE-selected
 * price if present or default to the first price.
 */
export const selectedPriceOption = (
  priceOptions: PriceOption[],
  selectedPaymentType?: PriceType | null | undefined,
): PriceOption | null | undefined => {
  if (priceOptions.length === 2 && selectedPaymentType) {
    return priceOptions.find((p) => p.type === selectedPaymentType);
  }

  if (priceOptions.length) {
    return priceOptions.find((p) => p.selected) || priceOptions[0];
  }

  return null;
};
