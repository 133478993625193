// @owners { team: patients-team }
import {
  ActionSheetContext,
  AltoIcon,
  Description,
  InlineAlert,
  LgPadding,
  ListDescription,
  ListItem,
  Tag,
} from '@alto/design-system';
import React, { useCallback, useContext } from 'react';
import {
  type PlaidLinkOnEventMetadata,
  type PlaidLinkOnSuccess,
  type PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import { createBankAccountPaymentMethod } from '~shared/actions/paymentMethods';
import { PLAID_ENV, PLAID_PUBLIC_KEY } from '~shared/config';
import { type PaymentMethodType } from '~shared/constants';
import { useAnalytics } from '~shared/hooks';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getPlaidEvent } from '~shared/lib/analytics/src/getPlaidEvent';
import { useDispatchShared } from '~shared/store';

type Props = {
  readonly paymentMethodType: PaymentMethodType;
};

export const PlaidListNavigation = ({ paymentMethodType }: Props) => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { closeActionSheet } = useContext(ActionSheetContext);

  const sendPlaidAnalytics = useCallback(
    (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      // @ts-expect-error TS(2322): eventName should be PlaidLinkStableEvent which is included in PlaidEventName (Delete me to see the full error)
      const event = getPlaidEvent(eventName, metadata);

      if (event) {
        dispatch(sendAnalyticsEvent(event));
      }
    },
    [dispatch],
  );

  const onSuccess: PlaidLinkOnSuccess = useCallback(
    (publicToken, metadata) => {
      // eslint-disable-next-line promise/catch-or-return
      dispatch(
        createBankAccountPaymentMethod({
          publicToken,
          accountId: metadata.accounts[0].id,
          institutionName: metadata.institution?.name || '',
          paymentMethodType,
        }),
      ).then((success) => {
        if (success) {
          closeActionSheet();
          trackEvent({
            event: EVENTS.PAYMENT_METHOD_CREATED,
            params: {
              newStripeFormEnabled: false,
              type: 'PlaidData',
            },
          });
        } else {
          trackEvent({
            event: EVENTS.CREATE_PAYMENT_METHOD_FAILED,
            params: {
              newStripeFormEnabled: false,
            },
          });
        }
        // eslint-disable-next-line sonarjs/no-redundant-jump
        return;
      });
    },
    [closeActionSheet, dispatch, paymentMethodType, trackEvent],
  );

  const { ready, open, error } = usePlaidLink({
    clientName: 'Alto',
    env: PLAID_ENV,
    onEvent: sendPlaidAnalytics,
    onSuccess,
    product: ['auth'],
    publicKey: PLAID_PUBLIC_KEY,
  });

  const handleItemPress = useCallback(() => open(), [open]);

  if (!ready) return null;

  if (error) {
    return (
      <LgPadding>
        <InlineAlert type="error">
          <Description>
            Whoops! Something went wrong while loading Plaid. Please try again later, or message us if the problem
            doesn't resolve.
          </Description>
        </InlineAlert>
      </LgPadding>
    );
  }

  return (
    <ListItem
      LeftContent={<AltoIcon name="bank" />}
      RightContent={<AltoIcon name="chevronright-small" />}
      title="Bank Account"
      tags={[
        <Tag
          label="Save 1%"
          key="save"
        />,
      ]}
      descriptions={<ListDescription>Save 1% on all orders without insurance</ListDescription>}
      onPress={handleItemPress}
    />
  );
};
