// eslint-disable-next-line @alto/no-pocky-import
import { Row, palette } from '@alto/pocky';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faPrescriptionBottle } from '@fortawesome/pro-solid-svg-icons/faPrescriptionBottle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components';

const imageStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow:
    inset 0 -1px 1px 0 rgba(0, 0, 0, 0.25),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
`;
const PlaceholderImage = styled.div`
  ${imageStyles}
  background-color: ${palette.grey2};
  position: relative;
`;

const MedImage = styled.div<{ src: string }>`
  ${imageStyles}
  background-image: url('${({ src }) => src}');
  background-position: center;
  background-size: cover;
  position: relative;
`;

const VideoIconWrapper = styled(Row)`
  position: absolute;
  top: 7px;
  left: 7px;
  background-color: ${palette.altoColorsS1};
  border-radius: 100%;
  width: 25px;
  height: 25px;
  &:hover {
    cursor: pointer;
  }
`;

type VideoIconProps = {
  readonly show: boolean;
  readonly onClick: () => void;
};

export type MedicationImageLegacyProps = {
  readonly imageUrl?: string;
  readonly className?: string;
  readonly hasVideos?: boolean;
  readonly onClickVideoIcon?: () => void;
};

const VideoIcon = ({ show, onClick }: VideoIconProps) => {
  if (!show) {
    return null;
  }

  return (
    <VideoIconWrapper
      verticallyAlignContent
      horizontallyAlignContent
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faPlay}
        color={palette.white}
        size="xs"
      />
    </VideoIconWrapper>
  );
};

/* NOTE: Prefer the MedicationImage component that is more similar to the
 * MedicationImage component used in native
 */
const noop = () => undefined;

const MedicationImageLegacy = ({
  imageUrl,
  className,
  hasVideos = false,
  onClickVideoIcon = noop,
}: MedicationImageLegacyProps) =>
  imageUrl ? (
    <MedImage
      src={imageUrl}
      className={className}
    >
      <VideoIcon
        show={hasVideos}
        onClick={onClickVideoIcon}
      />
    </MedImage>
  ) : (
    <PlaceholderImage className={className}>
      <VideoIcon
        show={hasVideos}
        onClick={onClickVideoIcon}
      />
      <FontAwesomeIcon
        icon={faPrescriptionBottle}
        color={palette.grey1}
        size="lg"
      />
    </PlaceholderImage>
  );

export default MedicationImageLegacy;
