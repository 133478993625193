// @owners { team: patients-team }
import { Button, type ButtonType, type ButtonWidth, type FontFamily, Link, type TextSize } from '@alto/design-system';
import React from 'react';
import { Linking, Platform } from 'react-native';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { EMAIL } from '~shared/constants';
import { getUserSupportPhone } from '~shared/features/users/selectors/getUserSupportPhone';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { stripNonDigits } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { useNavigateToAssistantLanding } from '../../helpers/useNavigateToAssistantLanding';

export type ContactUsProps = {
  readonly origin: string;
  readonly customLabel?: string;
  readonly customPhoneNumber?: string;
  readonly type: 'button' | 'link';
  readonly method: 'phone' | 'email' | 'message';
  readonly onPressCallback?: () => void;
  readonly fontFamily?: FontFamily;
  readonly small?: boolean;
  readonly buttonType?: ButtonType;
  readonly buttonWidth?: ButtonWidth;
  readonly textSize?: TextSize;
};

export const EMAIL_SUBJECT = `Help with your ${Platform.OS} app`;
export const EMAIL_BODY =
  "To ensure your personal privacy, please don't disclose any personal information about yourself such as your name or contact info over email.";

type ContactUsCopyProps = {
  customLabel?: string;
  phoneNumber: string;
  isInlineText: boolean;
  method: ContactUsProps['method'];
};

// @ts-expect-error not all paths return a value
export const ContactUsCopy = ({ customLabel, method, isInlineText, phoneNumber }: ContactUsCopyProps) => {
  if (customLabel) {
    return customLabel;
  }
  switch (method) {
    case 'phone':
      return `${isInlineText ? 'c' : 'C'}all us at ${phoneNumber}`;
    case 'email':
      // eslint-disable-next-line @typescript-eslint/no-useless-template-literals
      return `${isInlineText ? 'email us' : 'Email Us'}`;
    case 'message':
      return `${isInlineText ? 's' : 'S'}end us a message`;
    default:
      TypescriptUtils.assertNever(method);
  }
};

/**
 * Used to allow patients to contact us either by secure message (default), email, or phone call.
 * On a desktop browser, the tel:// and mailto:// links will open a prompt to the patient
 * which can link into their phone or email client. On mobile, the links will open the relevant apps
 * @param {ContactUsPropsProps} props component props
 * @returns ContactUs
 */
export const ContactUs = ({
  customLabel,
  origin,
  customPhoneNumber,
  type,
  method,
  onPressCallback,
  fontFamily = 'semibold',
  small = false,
  buttonType = 'primary',
  buttonWidth = 'full',
  textSize = 'default',
}: ContactUsProps) => {
  const { trackEvent } = useAnalytics();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const defaultPhoneNumber = useSelectorShared((state) => getUserSupportPhone(state, { userID: currentUserID }));
  const phoneNumber = customPhoneNumber ?? defaultPhoneNumber;
  const componentCopy = ContactUsCopy({ customLabel, phoneNumber, isInlineText: type === 'link', method });
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const onPress = () => {
    trackEvent({
      event: method === 'phone' ? EVENTS.CALL_US_CLICKED : EVENTS.CONTACT_US_CLICKED,
      params: {
        label: componentCopy,
        origin,
        secureMessage: !(method === 'phone' || method === 'email'),
        callUs: method === 'phone',
        emailUs: method === 'email',
        phoneNumber,
      },
    });

    switch (method) {
      case 'phone':
        Linking.openURL(`tel:${stripNonDigits(phoneNumber)}`);
        break;
      case 'email':
        Linking.openURL(`mailto:${EMAIL}?subject=${EMAIL_SUBJECT}&body=${EMAIL_BODY}`);
        break;
      case 'message':
        navigateToAssistantLanding();

        break;
      default:
        TypescriptUtils.assertNever(method);
    }

    if (onPressCallback) {
      onPressCallback();
    }
  };

  switch (type) {
    case 'button':
      return (
        <Button
          accessibilityLabel={componentCopy}
          onPress={onPress}
          label={componentCopy}
          small={small}
          type={buttonType}
          width={buttonWidth}
        />
      );
    case 'link':
      return (
        <Link
          accessibilityLabel={componentCopy}
          onPress={onPress}
          fontFamily={fontFamily}
          textSize={textSize}
        >
          {componentCopy}
        </Link>
      );
    default:
      return null;
  }
};
