// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Label, Link, Row, SimpleContainerSection, Text, palette, spacing } from '@alto/pocky';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { phoneSupport } from '~web/constants';

export default function BusinessHours() {
  return (
    <SimpleContainerSection>
      <Row spacing={spacing.smSpacing}>
        <FontAwesomeIcon
          icon={faPhone}
          color={palette.grey1}
        />
        <Column spacing={spacing.xsSpacing}>
          <Label>Call</Label>
          <Column spacing={spacing.smSpacing}>
            <div>
              <Link href={`tel:${phoneSupport}`}>{phoneSupport}</Link>
              <Text>Monday - Friday</Text>
              <Text>6 am - 9 pm PT</Text>
              <Text>Saturday & Sunday</Text>
              <Text>7 am - 6 pm PT</Text>
            </div>
          </Column>
        </Column>
      </Row>
    </SimpleContainerSection>
  );
}
