// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line import/no-deprecated
import { getScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, ParentWidthColumn, Text } from '@alto/pocky';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  border-radius: 0;
  padding: ${SPACING.STATIC.MD.rem};
`;

const IconContainer = styled.div`
  display: inline-block;
  width: 1rem;
`;

const Label = styled(Text)`
  margin-left: ${SPACING.STATIC.MD.rem};
`;

const Description = styled(Text)<{ isSMScreenOrBigger: boolean }>`
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `display: none;`}
`;

const Chevron = styled(FAIcon).attrs({
  color: COLORS.PALETTE.GREYSCALE.DARKER,
  icon: faChevronRight,
})`
  margin-left: ${SPACING.STATIC.MD.rem};
`;

type Props = {
  readonly description?: string;
  readonly icon: IconProp;
  readonly label: string;
  readonly onClick: () => void;
};

export default class SectionNavigator extends PureComponent<Props> {
  static defaultProps = {
    description: '',
  };

  render() {
    const { description, icon, label, onClick } = this.props;
    // eslint-disable-next-line import/no-deprecated
    const { isSMScreenOrBigger } = getScreenSize();

    return (
      <StyledButton
        full
        kind="tertiary"
        onClick={onClick}
      >
        <ParentWidthColumn evenlySpaceContent>
          <div>
            <IconContainer>
              <FAIcon
                icon={icon}
                color={COLORS.PALETTE.GREYSCALE.DARKER}
              />
            </IconContainer>
            <Label inline>{label}</Label>
          </div>
          <div>
            {!!description && (
              <Description
                inline
                small
                light
                isSMScreenOrBigger={isSMScreenOrBigger}
              >
                {description}
              </Description>
            )}
            <Chevron />
          </div>
        </ParentWidthColumn>
      </StyledButton>
    );
  }
}
