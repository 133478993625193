// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Body, Column, Description, Toast, ToastContext } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React, { useContext } from 'react';
import { saveOrderFailed, updateOrder } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchPaymentMethods } from '~shared/actions/paymentMethods';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing } from '~shared/actions/pricing';
import { DEFAULT_ERROR_ALERT_TITLE } from '~shared/constants';
import { PAYMENT_ERRORS } from '~shared/features/payment-methods/constants';
import { STALE_FEES_ERROR_CODE } from '~shared/features/shipment-fees/constants';
import { useQueryShipmentFeesAvailable } from '~shared/queries';
import { useDispatchShared } from '~shared/store';
import { type APIError } from '~shared/types';
import { useSendCreateOrderAnalyticsEvent } from './useSendCreateOrderAnalyticsEvent';

export const useHandleCreateOrderError = () => {
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatchShared();
  const { sendCreateOrderAnalyticsEvent } = useSendCreateOrderAnalyticsEvent();
  const { invalidateShipmentFeesAvailable } = useQueryShipmentFeesAvailable();
  const { value: showServerErrors } = Experimentation.useFeatureFlag('expose_server_errors_to_client');

  const handleCreateOrderError = (error: APIError) => {
    if (error) {
      const isPaymentPreAuthError = error.details?.code === PAYMENT_ERRORS.PREAUTH_FAILED_ERROR;
      dispatch(saveOrderFailed(error));
      sendCreateOrderAnalyticsEvent({ error });
      // we are fetching payment methods here after a save order failure to get the most up-to-date payment method data
      // the payment method data includes the field most_recent_charge_status which we'll use for error highlighting on the payment methods
      if (isPaymentPreAuthError) {
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchPaymentMethods());
      }

      // refetch order pricing and available fees and clear date/window selection if fees aren't aligned with BE
      if (error.details?.code === STALE_FEES_ERROR_CODE) {
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchOrderPricing());
        invalidateShipmentFeesAvailable();
        dispatch(updateOrder({ date: undefined, deliver_before: undefined, deliver_after: undefined }));
      }
      const errorMessage = showServerErrors ? error.message ?? DEFAULT_ERROR_ALERT_TITLE : DEFAULT_ERROR_ALERT_TITLE;
      const title = isPaymentPreAuthError ? 'Payment failed' : errorMessage;
      const detailsMessage = error.details?.message;
      // don't show the detailed message if it's the same as the error message
      const message = errorMessage === detailsMessage ? undefined : detailsMessage;

      addToast(
        <Toast
          duration={8000}
          variant="error"
          dismissible
        >
          <Column gap="SM">
            <Body color={COLORS.TEXT_COLORS.WHITE}>{title}</Body>
            {message ? <Description color={COLORS.TEXT_COLORS.WHITE}>{message}</Description> : null}
          </Column>
        </Toast>,
      );
    }
  };

  return { handleCreateOrderError };
};
