import { NODE_ENV } from '~shared/config';
import { recordNetworkRequest } from '~shared/helpers/api/requestLogger';
export const inFlightRequests = new Map<string, Promise<any>>();

const isDevelopment = NODE_ENV === 'development';

/**
 * HACK(perf): Deduplicate in-flight requests.
 *
 * We often see multiple requests for the same endpoint being made
 * at roughly the same time, due to component re-renders or
 * reactors or whatever. This seems to impact redux-driven endpoints
 * the most.
 *
 * This function keeps track of all of the requests that have not yet
 * returned. If we see another request for the same URL before the first
 * one is complete, we'll return the existing promise instead of creating
 * a new request.
 *
 * This should only be used with GET requests.
 */
export default async function deduplicator<T>(url: string, requestFn: () => Promise<T>) {
  const inFlightRequest = inFlightRequests.get(url);

  if (isDevelopment) {
    recordNetworkRequest(url, !!inFlightRequest);
  }

  if (inFlightRequest) {
    return inFlightRequest;
  }

  const request = requestFn();
  inFlightRequests.set(url, request);
  try {
    return await request;
  } finally {
    inFlightRequests.delete(url);
  }
}
