/**
 * This file takes care of managing all error state. When an action conforms
 * the convention SNAKE_CASE_ACTION_LOADING or SNAKE_CASE_ACTION_SUCCEEDED, this
 * reducer will set camelCaseActionError to null in the ui.errors state.
 * When an action conforms to the convention SNAKE_CASE_ACTION_FAILED,
 * camelCaseActionError will be set to the action payload.
 *
 * Optionally, for endpoints that operate on a single entity (ie you can hit the
 * same endpoint for different id's), putting an id in the action will add an
 * entry to camelCaseActionErrorByID so that error states can be shown
 * individually.
 *
 * Everything under the ui namespace should be blocked from persisting so
 * users don't get see error states between sessions.
 */
import { type UIAction, type UIActionType, getBaseName, isFailure, isLoading, isSuccess } from './helpers';
import { CLEAR_ADDRESS_ERROR } from '~shared/actions/addresses';
import { CLEAR_AUTH_ERROR, CLEAR_FETCH_SERVICE_AVAILABILITY_ERROR } from '~shared/actions/auth';
import {
  CLEAR_FETCH_ENTER_CART_ERROR,
  CLEAR_FETCH_ENTER_CHECKOUT_ERROR,
  CLEAR_SAVE_ORDER_ERROR,
} from '~shared/actions/cart';
import {
  CLEAR_DELIVERIES_ERROR,
  CLEAR_DELIVERY_NOTIFICATIONS,
  CLEAR_FETCH_DELIVERIES_ERROR,
} from '~shared/actions/deliveries';
import { CLEAR_INSURANCE_ERROR } from '~shared/actions/insurances';
import { CLEAR_PAYMENT_METHOD_ERROR } from '~shared/actions/paymentMethods';
import {
  CLEAR_AUTOBILL_PRESCRIPTION_ERROR,
  CLEAR_PRESCRIPTIONS_ERROR,
  CLEAR_UPDATE_AUTO_REFILL_ERROR,
} from '~shared/actions/prescriptions';
import { CLEAR_CREATE_SURVEY_RESPONSE_ERROR } from '~shared/actions/surveyResponses';
import { CLEAR_USER_ERROR } from '~shared/actions/users';
import { CLEAR_UPDATE_FERTILITY_CYCLE_ERROR } from '~shared/constants';
import { type APIError } from '~shared/types/APIError';

type ObjectValueMapper<Type> = {
  [Property in keyof Type]: Type[Property] extends null ? APIError | null : Record<number, APIError | null>;
};
export type StateUiErrors = ObjectValueMapper<typeof initialState>;

const initialState = {
  archivePrescriptionError: null,
  autobillPrescriptionError: null,
  cancelDeliveriesError: null,
  cancelRenewalError: null,
  completeVerificationError: null,
  createAddressError: null,
  createFeedbackError: null,
  createInsuranceError: null,
  createPaymentMethodError: null,
  createPlaidLinkTokenError: null,
  createPhotoInsuranceError: null,
  createShipmentTipsError: null,
  createSurveyResponseError: null,
  createOrganicTransferError: null,
  declineInsuranceError: null,
  deleteAddressError: null,
  deleteInsuranceError: null,
  deletePaymentMethodError: null,
  deleteTransferError: null,
  fetchAddressesError: null,
  fetchAncillariesError: null,
  fetchAppDownloadLinkError: null,
  fetchAvailableDatesError: null,
  fetchEnterCheckoutError: null,
  fetchEnterCartError: null,
  fetchCopaysError: null,
  fetchDeliveriesError: null,
  fetchDeliveryWindowsError: null,
  fetchFacilitiesError: null,
  fetchFsaReceiptError: null,
  fetchInsurancesError: null,
  fetchMedicationNamesError: null,
  fetchOnboardStateError: null,
  fetchPaymentMethodsError: null,
  fetchPrescriptionsError: null,
  fetchRenewalRequestTimelineError: null,
  fetchServiceAvailabilityError: null,
  fetchS3PresignedPostError: null,
  fetchShipmentsError: null,
  fetchShipmentPaymentMethodsError: null,
  fetchShipmentTipsError: null,
  fetchTransfersError: null,
  fetchUsersError: null,
  fetchUserSubscriptionsError: null,
  fetchPrescriptionPricingError: null,
  fetchOrderPricingError: null,
  forgottenPasswordError: null,
  identityVerificationNeededError: null,
  loginError: null,
  markingWrongUserError: null,
  requestRenewalErrorByID: {} as Record<number, APIError | null>,
  resetPasswordError: null,
  saveOrderError: null,
  sendMessageError: null,
  signupError: null,
  startVerificationError: null,
  unarchivePrescriptionError: null,
  updateAddressError: null,
  updateDeliveriesError: null,
  updateFertilityCycleError: null,
  updateInsuranceError: null,
  updateOnboardingUserError: null,
  updatePaymentMethodError: null,
  updatePrescriptionError: null,
  updateShipmentTipsError: null,
  updateUserError: null,
  updateUserSubscriptionsError: null,
  uploadAvatarError: null,
  uploadFeedbackPhotoError: null,
  uploadImageToS3Error: null,
  uploadPhotoIDsError: null,
  validatingUserError: null,
  fetchSudoError: null,
  setSudoError: null,
  updateAutoRefillError: null,
  verifyCommsError: null,
  syncBiometricsError: null,
};

const getErrorName = (type: UIActionType) => `${getBaseName(type)}Error`;

const getErrorByIDName = (type: UIActionType) => `${getErrorName(type)}ByID`;

const byIDState = (action: UIAction, state: StateUiErrors, value: any) => {
  const { type, id } = action;

  if (!id) {
    return null;
  }

  return {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
    [getErrorByIDName(type)]: { ...state[getErrorByIDName(type)], [id]: value },
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const uiErrors = (state: StateUiErrors = initialState, action: UIAction): StateUiErrors => {
  const { type } = action;

  if (type === CLEAR_PRESCRIPTIONS_ERROR) {
    return { ...state, updatePrescriptionError: null, fetchPrescriptionsError: null };
  }

  if (type === CLEAR_DELIVERIES_ERROR) {
    return { ...state, fetchDeliveriesError: null, updateDeliveriesError: null };
  }

  if (type === CLEAR_DELIVERY_NOTIFICATIONS) {
    return { ...state, fetchDeliveryWindowsError: null };
  }

  if (type === CLEAR_ADDRESS_ERROR) {
    return {
      ...state,
      fetchAddressesError: null,
      createAddressError: null,
      updateAddressError: null,
      deleteAddressError: null,
    };
  }

  if (type === CLEAR_INSURANCE_ERROR) {
    return {
      ...state,
      fetchInsurancesError: null,
      createInsuranceError: null,
      updateInsuranceError: null,
      deleteInsuranceError: null,
      declineInsuranceError: null,
      createPhotoInsuranceError: null,
    };
  }

  if (type === CLEAR_PAYMENT_METHOD_ERROR) {
    return {
      ...state,
      fetchPaymentMethodsError: null,
      createPaymentMethodError: null,
      updatePaymentMethodError: null,
      deletePaymentMethodError: null,
    };
  }

  if (type === CLEAR_USER_ERROR) {
    return {
      ...state,
      fetchUsersError: null,
      updateUserError: null,
      uploadAvatarError: null,
      uploadPhotoIDsError: null,
    };
  }

  if (type === CLEAR_CREATE_SURVEY_RESPONSE_ERROR) {
    return { ...state, createSurveyResponseError: null };
  }

  if (type === CLEAR_AUTH_ERROR) {
    return { ...state, forgottenPasswordError: null, loginError: null, resetPasswordError: null };
  }

  if (type === CLEAR_FETCH_SERVICE_AVAILABILITY_ERROR) {
    return { ...state, fetchServiceAvailabilityError: null };
  }

  if (type === CLEAR_SAVE_ORDER_ERROR) {
    return { ...state, saveOrderError: null };
  }

  if (type === CLEAR_UPDATE_AUTO_REFILL_ERROR) {
    return { ...state, updateAutoRefillError: null, updatePrescriptionError: null };
  }

  if (type === CLEAR_FETCH_ENTER_CHECKOUT_ERROR) {
    return { ...state, fetchEnterCheckoutError: null };
  }

  if (type === CLEAR_FETCH_ENTER_CART_ERROR) {
    return { ...state, fetchEnterCartError: null };
  }

  if (type === CLEAR_UPDATE_FERTILITY_CYCLE_ERROR) {
    return { ...state, updateFertilityCycleError: null };
  }

  if (type === CLEAR_AUTOBILL_PRESCRIPTION_ERROR) {
    return { ...state, autobillPrescriptionError: null };
  }

  if (type === CLEAR_FETCH_DELIVERIES_ERROR) {
    return { ...state, fetchDeliveriesError: null };
  }

  if (isLoading(type)) {
    return { ...state, ...byIDState(action, state, null), [getErrorName(type)]: null };
  }

  if (isSuccess(type)) {
    return { ...state, ...byIDState(action, state, null), [getErrorName(type)]: null };
  }

  if (isFailure(type)) {
    return { ...state, ...byIDState(action, state, action.payload), [getErrorName(type)]: action.payload };
  }

  return { ...state };
};

export default uiErrors;
