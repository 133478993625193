// @owners { team: patients-team }
import { Toast, ToastContext } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useNavigation } from '@alto/navigation';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { createContext, useContext } from 'react';
import { addToCart__DEPRECATED, removeFromCart } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
import { updatePrescriptionSucceeded } from '~shared/actions/prescriptions';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing, fetchShipmentPricing } from '~shared/actions/pricing';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
import { NETWORK_FAILURE_ERROR_MESSAGE } from '~shared/constants';
import { getItemQuantityForAddOnOtc, prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { getExistingOrderPaymentID } from '~shared/features/checkout/selectors/getOrder';
import { getNextDeliveryByPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { updateAddToShipmentError } from '~shared/features/essentials/actions';
import { type OriginValues, SOURCES } from '~shared/features/essentials/add_ons_consts';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';
import { isNative } from '~shared/helpers/isNative';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { queries } from '~shared/queries/query-keys';
import { useQueryClient } from '~shared/react-query';
import { type UseMutateAsyncFunction } from '~shared/react-query';
import { Sentry } from '~shared/sentry';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';

export type AddOnsContextProps = {
  // Which flow are we in? eg. cart, homescreen, post-checkout interstitial, etc.
  origin?: OriginValues;
  // If shipmentID is present, we are updating this upcoming shipment/order. If not present, we are updating cart.
  shipmentID?: number | null | undefined;
  // Callback to update the shipment after there has been a change in OTCs (adding/removing/updating)
  onUpdateShipment?: () => Promise<any>;
  // If we're in the StoreProducts view where multiple products could be mutated, this sets which product is currently active
  setActiveProduct?: (id: number | null) => void;
  // If we're in the StoreProducts view where multiple products could be mutated, this denotes the active product id
  activeProduct?: number | null;
};
export const AddOnsContext = createContext<AddOnsContextProps>({});

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useAddOnsContext() {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const nextDeliveryByPrescriptionID = useSelectorShared(getNextDeliveryByPrescriptionID);
  const context = useContext<AddOnsContextProps>(AddOnsContext);
  const queryClient = useQueryClient();
  const { value: serverSideCartEnabled } = Experimentation.useFeatureFlag('server_side_cart');
  if (context === undefined) {
    throw new Error('`useAddOnsContext` must be used within a AddOnsContextProvider');
  }
  const dispatch = useDispatchShared();
  const { shipmentID, onUpdateShipment, origin } = context;
  const existingOrderPaymentID = useSelectorShared((state) => getExistingOrderPaymentID(state, { shipmentID }));

  const openErrorBanner = (error?: string) => {
    addToast(
      <Toast
        dismissible
        variant="error"
        duration={6000}
      >
        {error || 'Something went wrong. Please try again or message support if the problem persists.'}
      </Toast>,
    );
  };

  const addedOtcBanner = (itemQuantity: number, addOnOtc: AddOnOtc) => {
    addToast(
      <Toast dismissible>
        {itemQuantity} of {addOnOtc.name} added to your {shipmentID ? 'delivery' : 'cart'}!
      </Toast>,
    );
  };

  const onAdd = (
    addOnOtc: AddOnOtc,
    createPrescription: UseMutateAsyncFunction<any, any, any, any>,
    onClose: () => void,
    itemQuantity: number,
    analyticsProps: AnalyticsProps,
    handleRelatedEssentials?: (origin?: OriginValues) => void,
    handleSelectPaymentMethod?: (createAndAdd: (paymentMethodID?: number) => Promise<void>) => void,
  ) => {
    trackEvent({
      event: shipmentID ? EVENTS.ESSENTIALS__OTC_ADD_TO_ORDER_TAPPED : EVENTS.ESSENTIALS__OTC_ADD_TO_CART_TAPPED,
      params: { ...analyticsProps, quantity: itemQuantity },
    });
    const createAndAdd = async (paymentMethodID?: number) =>
      createPrescription(
        {
          shipment_id: shipmentID,
          sku_type: addOnOtc.sku_type,
          sku: addOnOtc.sku,
          product_id: addOnOtc.product_id,
          quantity: (addOnOtc.quantity * itemQuantity).toString(),
          payment_method_id: paymentMethodID,
          with_insurance: false,
        },
        {
          // @ts-expect-error not app paths return a value
          // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/require-await
          onSuccess: async (response) => {
            if (!response.data) {
              const error = getErrorMessageFromResponse(response);
              dispatch(updateAddToShipmentError({ error, quantityAdded: itemQuantity }));
              onClose();
              openErrorBanner(error);

              if (error !== NETWORK_FAILURE_ERROR_MESSAGE) {
                const context = {
                  tags: { featureOwner: 'incubation' },
                  contexts: { 'Add OTC error': { error } },
                };
                Sentry.captureMessage(`Error adding an OTC to ${shipmentID ? 'an order' : 'cart'}`, context);
              }
              return error;
            }

            const { prescription: otcPrescription, delivery_id } = response.data;
            dispatch(updatePrescriptionSucceeded(otcPrescription));
            if (!shipmentID) {
              if (serverSideCartEnabled) {
                // TODO: do we need to invalidate pricing and NAD as well?
                queryClient.invalidateQueries({ queryKey: queries.cart.active({}).queryKey });
              } else {
                dispatch(addToCart__DEPRECATED(prescriptionToItemKey(otcPrescription.id))); // middleware will fetchOrderPricing after this
              }
            } else {
              // eslint-disable-next-line import/no-deprecated
              dispatch(fetchDeliveries());
              if (onUpdateShipment) onUpdateShipment();
            }

            onClose();

            trackEvent({
              event: shipmentID ? EVENTS.ESSENTIALS__OTC_ADDED_TO_ORDER : EVENTS.ESSENTIALS__OTC_ADDED_TO_CART,
              params: {
                ...analyticsProps,
                prescription_id: otcPrescription.id,
                delivery_id,
                quantity: itemQuantity,
                with_insurance: false,
              },
            });

            const fromHomeScreenOrStore =
              analyticsProps.source === SOURCES.HOMESCREEN_CAROUSEL ||
              analyticsProps.source === SOURCES.ALL_OTCS_CAROUSELS ||
              analyticsProps.source === SOURCES.SEARCH_RESULTS ||
              analyticsProps.source === SOURCES.RELATED_OTCS_INTERSTITIAL;

            if (fromHomeScreenOrStore && handleRelatedEssentials && isNative) {
              handleRelatedEssentials(origin);
            } else {
              addedOtcBanner(itemQuantity, addOnOtc);
            }
          },
          onError: (error) => {
            onClose();
            openErrorBanner(getErrorMessageFromResponse(error));
          },
        },
      );

    // If there's no payment method on this shipment, nav to payment method picker
    // a payment method picker isn't available on web, but we should support that when adding to an existing shipment,
    // which doesn't go through the normal checkout flow.
    if (shipmentID && !existingOrderPaymentID) {
      trackEvent({
        event: EVENTS.ESSENTIALS__NO_PAYMENT_METHOD,
        params: analyticsProps,
      });
      // TODO: ideally, we'd use the same action sheet across both platforms, but need to resolve the differences in
      // the contexts in which they are currently being used
      if (isNative) {
        navigate('RouteMainStackPaymentMethodPicker', {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPressDone: createAndAdd,
        });
      } else {
        if (handleSelectPaymentMethod) {
          handleSelectPaymentMethod(createAndAdd);
        }
      }
      return Promise.resolve();
    }

    return createAndAdd();
  };

  const onUpdateQuantity = (
    addOnOtc: AddOnOtc,
    prescription: Prescription,
    updateQuantity: UseMutateAsyncFunction<any, any, any, any>,
    itemQuantity: number,
    analyticsProps: AnalyticsProps,
  ) => {
    return updateQuantity(
      {
        prescription_id: prescription.id,
        prescription_quantity: (addOnOtc.quantity * itemQuantity).toString(),
        add_on_quantity: addOnOtc.quantity,
        shipment_id: shipmentID,
      },
      {
        onSuccess: (response) => {
          if (!response.data) {
            const error = getErrorMessageFromResponse(response);
            openErrorBanner(error);
            return;
          }

          const { prescription: otcPrescription, delivery_id } = response.data;
          const oldQuantity = prescription && getItemQuantityForAddOnOtc(prescription, addOnOtc.quantity);
          dispatch(updatePrescriptionSucceeded(otcPrescription));
          if (shipmentID) {
            // eslint-disable-next-line import/no-deprecated
            dispatch(fetchShipments());
            // eslint-disable-next-line import/no-deprecated
            dispatch(fetchShipmentPricing(shipmentID));
          } else {
            if (serverSideCartEnabled) {
              // TODO: do we need to invalidate pricing and NAD as well?
              queryClient.invalidateQueries({ queryKey: queries.cart.active({}).queryKey });
            } else {
              // eslint-disable-next-line import/no-deprecated
              dispatch(fetchOrderPricing()); // fetch order pricing after updating quantity
            }
          }
          if (itemQuantity > oldQuantity) {
            addToast(
              <Toast dismissible>
                {itemQuantity - oldQuantity} of {addOnOtc.name} added to your {shipmentID ? 'delivery' : 'cart'}!
              </Toast>,
            );
          } else if (itemQuantity < oldQuantity) {
            addToast(
              <Toast dismissible>
                {oldQuantity - itemQuantity} of {addOnOtc.name} removed from your {shipmentID ? 'delivery' : 'cart'}!
              </Toast>,
            );
          }
          trackEvent({
            event: EVENTS.ESSENTIALS__OTC_QUANTITY_CHANGED,
            params: {
              ...analyticsProps,
              prescription_id: prescription?.id,
              delivery_id,
              old_quantity: oldQuantity,
              new_quantity: itemQuantity,
            },
          });
        },
        onError: openErrorBanner,
      },
    );
  };

  const onRemove = async (
    addOnOtc: AddOnOtc,
    prescription: Prescription,
    removePrescription: UseMutateAsyncFunction<any, any, any, any>,
    analyticsProps: AnalyticsProps,
  ) => {
    const oldQuantity = prescription && getItemQuantityForAddOnOtc(prescription, addOnOtc.quantity);

    const response = await removePrescription({ prescription_id: prescription.id });

    if (!response.data) {
      const error = getErrorMessageFromResponse(response);
      openErrorBanner(error);
      return Promise.resolve();
    }

    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchDeliveries());
    if (shipmentID) {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipments());
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipmentPricing(shipmentID));
    }
    if (onUpdateShipment) onUpdateShipment();
    addToast(
      <Toast dismissible>
        {oldQuantity} of {addOnOtc.name} removed from your {shipmentID ? 'delivery' : 'cart'}!
      </Toast>,
    );

    if (!shipmentID) {
      if (serverSideCartEnabled) {
        // TODO: do we need to invalidate pricing and NAD as well?
        queryClient.invalidateQueries({ queryKey: queries.cart.active({}).queryKey });
      } else {
        dispatch(
          removeFromCart(
            {
              prescription,
              ...prescriptionToItemKey(prescription.id),
            },
            analyticsProps,
          ),
        );
      }
    }

    trackEvent({
      event: shipmentID ? EVENTS.ESSENTIALS__OTC_REMOVED_FROM_ORDER : EVENTS.ESSENTIALS__OTC_REMOVED_FROM_CART,
      params: {
        patient_id: prescription.user_id,
        prescription_id: prescription.id,
        delivery_id: nextDeliveryByPrescriptionID[prescription.id]?.id,
        ...analyticsProps,
      },
      additionalFields: {
        deliveryId: nextDeliveryByPrescriptionID[prescription.id]?.id,
        prescriptionId: prescription.id,
        shipmentId: shipmentID,
      },
    });

    return Promise.resolve();
  };

  return { ...context, onAdd, onRemove, onUpdateQuantity };
}
