import { createSelector } from 'reselect';
import getShipments from './getShipments';
import { getAllAddresses } from '~shared/features/addresses/selectors/getAddresses';
import { getDeliveryItemsByShipment } from '~shared/features/delivery-info/selectors/getDeliveryItems';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import { type ShipmentIDParamShort } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getShipmentsWithItems = createSelector(
  [getShipments, getDeliveryItemsByShipment, getAllAddresses],
  (shipments, deliveryItemsByShipmentID, addresses) => {
    const enrichedShipments: ShipmentWithItems[] = [];

    Object.keys(deliveryItemsByShipmentID).forEach((shipmentID) => {
      const shipment = shipments.find((s) => s.id.toString() === shipmentID);

      if (!shipment) return;

      const items = deliveryItemsByShipmentID[Number(shipmentID)] || [];
      const item = items.find((item) => item.delivery);
      const delivery = item?.delivery;
      const address = delivery && addresses.find((address) => address.id === delivery.address_id);
      const safePlace = address?.safe_place;
      const courierTipsAvailable = address?.courier_tips_available;

      // a few properties are renamed for convenience and symmetry with Delivery
      let enrichedShipment: ShipmentWithItems = {
        ...shipment,
        date: shipment.scheduled_date,
        deliver_after: shipment.scheduled_deliver_after,
        deliver_before: shipment.scheduled_deliver_before,
        items,
        method: shipment.shipment_method,
        shipmentID: Number(shipmentID),
        safe_place: safePlace,
        courier_tips_available: courierTipsAvailable,
      };

      /**
       * Processing shipments don't have address information yet so we pull
       * that info from one of the delivery's addresses if a delivery is available.
       *
       * We also set safe place, which is only used to show delivery instructions
       * for upcoming shipments.
       */
      // eslint-disable-next-line sonarjs/no-collapsible-if
      if (enrichedShipment.status === 'processing') {
        if (address) {
          enrichedShipment = {
            ...enrichedShipment,
            street_address_1: address.street_address_1,
            street_address_2: address.street_address_2,
            city: address.city,
            state: address.state,
            zip: address.zip,
          };
        }
      }

      enrichedShipments.push(enrichedShipment);
    });

    return enrichedShipments.sort((a, b) => new Date(b.date || 0).getDate() - new Date(a.date || 0).getDate());
  },
);

export type ShipmentsWithItemsByID = Record<number, ShipmentWithItems>;

const getShipmentsWithItemsByID = createSelector([getShipmentsWithItems], (shipments) => {
  return shipments.reduce<ShipmentsWithItemsByID>((result, shipment) => {
    result[shipment.id] = shipment;

    return result;
  }, {});
});

const getShipmentID = (_: ReduxStateShared, props: ShipmentIDParamShort) => props.id;

const getShipmentWithItemsByID = createSelector(
  [getShipmentsWithItemsByID, getShipmentID],
  (shipmentsByID, shipmentID) => {
    return shipmentsByID[shipmentID] as ShipmentWithItems | undefined;
  },
);

type ShipmentsWithItemsByStatus = {
  upcoming: ShipmentWithItems[];
  past: ShipmentWithItems[];
};

const getAddOnOtcsInShipment = createSelector([getShipmentWithItemsByID], (shipmentWithItems) => {
  if (!shipmentWithItems) {
    return [];
  }
  return shipmentWithItems.items.filter((item) => item.prescription?.is_add_on_otc);
});

const getHasAddOnOtcsInShipment = createSelector([getAddOnOtcsInShipment], (addOnOtcItems) => !!addOnOtcItems.length);

export const getHasNonEssentialsInShipment = createSelector(
  [getShipmentWithItemsByID, getShipmentID],
  (shipmentWithItems) => {
    if (!shipmentWithItems) {
      return [];
    }
    return Boolean(shipmentWithItems.items.filter((item) => !item.prescription?.is_add_on_otc).length);
  },
);

const getShipmentsWithItemsByStatus = createSelector([getShipmentsWithItems], (shipments) => {
  return shipments.reduce<ShipmentsWithItemsByStatus>(
    (result, shipment) => {
      const item = shipment.items.find((item) => item.delivery);
      const delivery = item?.delivery;
      const { status } = delivery || {};

      if (status === 'upcoming') {
        result.upcoming.push(shipment);
      } else if (['failed', 'delivered'].includes(status || '')) {
        result.past.push(shipment);
      }

      return result;
    },
    {
      upcoming: [],
      past: [],
    },
  );
});

const getUpcomingShipmentsWithItems = createSelector(
  [getShipmentsWithItemsByStatus],
  (shipmentsByStatus: ShipmentsWithItemsByStatus): ShipmentWithItems[] => {
    return shipmentsByStatus.upcoming;
  },
);

const getPastShipmentsWithItems = createSelector(
  [getShipmentsWithItemsByStatus],
  (shipmentsByStatus: ShipmentsWithItemsByStatus): ShipmentWithItems[] => {
    return shipmentsByStatus.past;
  },
);

export {
  getShipmentsWithItems,
  getShipmentsWithItemsByID,
  getShipmentWithItemsByID,
  getAddOnOtcsInShipment,
  getHasAddOnOtcsInShipment,
  getUpcomingShipmentsWithItems,
  getPastShipmentsWithItems,
};
