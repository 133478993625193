// @owners { team: patients-team }
import { CashPayInsurance, EmptyInsurance } from '@alto/features';
import { useNavigation } from '@alto/navigation';
// eslint-disable-next-line @alto/no-pocky-import
import { Loader, SimpleContainer, SimpleContainerHeader, SimpleContainerSection } from '@alto/pocky';
import React from 'react';
import { selectUser } from '~shared/actions/ui/users';
import { updateCashPay } from '~shared/actions/users';
import { getUserCashPayOnly } from '~shared/features/users/selectors/getUserCashPayOnly';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useGetInsurancesByUserID } from '~shared/queries/query-keys/insurances';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import InsuranceList from '~web/features/insurances/components/InsuranceList';

export type Props = {
  readonly userID: number;
};

export const ProfileInsurances = ({ userID }: Props) => {
  const { trackEvent } = useAnalytics();
  const { navigate } = useNavigation();
  const dispatch = useDispatchShared();
  const { hasInsurances, insurances, isPending: loading } = useGetInsurancesByUserID({ userID });
  const isCashPayOnly = useSelectorShared((state) =>
    getUserCashPayOnly(state, {
      userID,
    }),
  );
  const isNeedsInsurance = !isCashPayOnly && !hasInsurances;

  const handleAddInsurance = () => {
    dispatch(selectUser(userID));
    navigate('RouteInsuranceAdd', { source: 'profile', userID });
  };

  const handleUpdateCashPay = async () => {
    const { success } = await dispatch(updateCashPay(userID, true));
    if (success) {
      trackEvent({
        event: EVENTS.CASH_PAY_ONLY_SET,
        params: {
          source: 'profile',
          'new value': true,
        },
      });
    }
  };

  if (loading) {
    return (
      <SimpleContainer>
        <SimpleContainerHeader>Benefits</SimpleContainerHeader>
        <Loader text="Loading your benefits..." />
      </SimpleContainer>
    );
  }

  if (isCashPayOnly) {
    return (
      <SimpleContainer>
        <SimpleContainerHeader>Benefits</SimpleContainerHeader>
        <SimpleContainerSection>
          <CashPayInsurance source="profile" />
        </SimpleContainerSection>
      </SimpleContainer>
    );
  }

  if (isNeedsInsurance) {
    return (
      <SimpleContainer>
        <SimpleContainerHeader>Benefits</SimpleContainerHeader>
        <SimpleContainerSection>
          <EmptyInsurance
            onAddInsurance={handleAddInsurance}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSelectCashPay={handleUpdateCashPay}
            inlineButton
          />
        </SimpleContainerSection>
      </SimpleContainer>
    );
  }

  return (
    <SimpleContainer>
      <SimpleContainerHeader>Benefits</SimpleContainerHeader>
      <SimpleContainerSection>
        <InsuranceList
          insurances={insurances}
          onAddInsurance={handleAddInsurance}
          source="profile"
        />
      </SimpleContainerSection>
    </SimpleContainer>
  );
};
