// @owners { team: patients-team }
import { type Cart } from '@alto/orders_api/types/v1/cart';
import { type CartItem, CartItemTypeMap } from '@alto/orders_api/types/v1/cart_item';
import { type PriceType, PriceTypeMap } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { createSelector } from 'reselect';
// eslint-disable-next-line import/no-deprecated
import { getAutoRefills__DEPRECATED } from '~shared/features/checkout/selectors/getCart';
import { getItemsInOrder__DEPRECATED } from '~shared/features/checkout/selectors/getItems';
import getSelectedPaymentTypes from '~shared/features/checkout/selectors/getSelectedPaymentTypes';
import { type PricingResultPaymentType } from '~shared/types';

export const transformPriceType = (priceType?: PricingResultPaymentType | null | undefined): PriceType | null => {
  if (priceType === 'with_insurance') return PriceTypeMap.WITH_INSURANCE;
  if (priceType === 'without_insurance') return PriceTypeMap.WITHOUT_INSURANCE;
  return null;
};

export const transformCartPriceType = (
  cartPriceType: PriceType | null | undefined,
): PricingResultPaymentType | null => {
  if (cartPriceType === PriceTypeMap.WITH_INSURANCE) return 'with_insurance';
  if (cartPriceType === PriceTypeMap.WITHOUT_INSURANCE) return 'without_insurance';
  return null;
};

export const getReduxCartItems = createSelector(
  // eslint-disable-next-line import/no-deprecated
  [getItemsInOrder__DEPRECATED, getAutoRefills__DEPRECATED, getSelectedPaymentTypes],
  (items, autoRefills, selectedPaymentTypes): CartItem[] =>
    items.map((reduxItem) => {
      let autoRefillEnabled = autoRefills?.[reduxItem.prescription.id];
      // in Edit Order case, the auto refill value can be pulled from the prescription itself
      if (autoRefillEnabled === undefined) {
        autoRefillEnabled = reduxItem.prescription.auto_refill || false;
      }
      return {
        id: -1,
        patient_id: reduxItem.userID,
        cart_id: -1,
        cart_item_type: reduxItem.prescription.is_add_on_otc ? CartItemTypeMap.ESSENTIAL : CartItemTypeMap.PRESCRIPTION,
        resource_type: 'Prescription',
        resource_id: reduxItem.prescription.id,
        name: reduxItem.displayName(),
        // Unfortunately, there's no clean way to infer the Essential quantity from the Item, only from the Essential itself.
        // Components that need the quantity will just continue to compute it from the Essential, rather than the
        // CartItem until we fully transition to the Server-Side Cart (which will have the proper quantity set).
        quantity: 1,
        auto_refill_enabled: autoRefillEnabled,
        selected_price_type: transformPriceType(selectedPaymentTypes?.[reduxItem.prescription.id]),
        created_at: '',
        updated_at: '',
      };
    }),
);

export const getReduxCart = createSelector(
  [getReduxCartItems],
  (cartItems): Cart => ({
    id: -1,
    patient_id: -1,
    items: cartItems,
    deleted_at: null,
    ordered_at: null,
    created_at: '',
    updated_at: new Date().toISOString(),
  }),
);
