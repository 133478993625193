// @owners { team: patients-team }
import { formatDollars } from '~shared/helpers/currency';

export const COURIER_TIP_COPY = {
  CARD_BODY: 'You can leave them a tip. Tipping is optional and 100% of your tip goes to your courier.',
  CARD_HEADER: 'Want to thank your courier?',
  CUSTOM_AMOUNT_LINK: 'Enter Custom Amount',
  INFO_ACTION_SHEET_BODY: [
    `Leaving a tip for your courier is entirely optional and delivery is
    always free. The full amount of the tip is passed on to your courier
    as a weekly total - Alto does not retain any portion of the tip and
    covers all processing fees. Tips are entirely anonymous and are not
    tied to any specific deliveries, routes or customers.`.replace(/\s+/g, ' '),
    `Please note: if you choose to leave a tip, you will see two separate
    charges on your selected method of payment - once for any copays or
    medication costs, and once for the amount of the tip.`.replace(/\s+/g, ' '),
  ],
  INFO_BULLETS: [
    '100% of your tip goes to your courier',
    'Tips are always optional',
    'If you tip during checkout, you can change your tip up to 2 hours after delivery',
    'Otherwise, you can tip after you receive your delivery, when you next log into the Alto app or website',
  ],
  INFO_ACTION_SHEET_BUTTON_LABEL: 'Done',
  INFO_ACTION_SHEET_TITLE: 'Tipping at Alto',
  TOAST_SUCCESSFUL_UPDATE: 'Thank you! Your tip was successfully updated',
};
export const COURIER_NEW_TIP_COPY = {
  CARD_BODY: 'Tipping is optional. 100% of your tip goes to your courier.',
  CUSTOM_AMOUNT_LINK: 'Enter Custom Amount',
  INFO_ACTION_SHEET_BODY: [
    `Leaving a tip for your courier is entirely optional and delivery is
    always free. The full amount of the tip is passed on to your courier
    as a weekly total - Alto does not retain any portion of the tip and
    covers all processing fees. Tips are entirely anonymous and are not
    tied to any specific deliveries, routes or customers.`.replace(/\s+/g, ' '),
    `Please note: if you choose to leave a tip, you will see two separate
    charges on your selected method of payment - once for any copays or
    medication costs, and once for the amount of the tip.`.replace(/\s+/g, ' '),
  ],
  INFO_BULLETS: [
    '100% of your tip goes to your courier',
    'Tips are always optional',
    'If you tip during checkout, you can change your tip up to 2 hours after delivery',
    'Otherwise, you can tip after you receive your delivery, when you next log into the Alto app or website',
  ],
  INFO_ACTION_SHEET_BUTTON_LABEL: 'Done',
  INFO_ACTION_SHEET_TITLE: 'Tipping at Alto',
  TOAST_SUCCESSFUL_UPDATE: 'Thank you! Your tip was successfully updated',
};
// Default tip amount is set on all checkout instances, even ones that are ineligible for tips
export const DEFAULT_TIP_AMOUNT = 0;
// Initial tip amount is set when the order is eligible for tips and doesn't have a tip amount manually selected
export const INITIAL_TIP_AMOUNT = 5;
export const CUSTOM_TIP_PLACEHOLDER = '0.00';
export const MAX_TIP_AMOUNT = 100.0;
export const MAX_TIP_AMOUNT_LENGTH = MAX_TIP_AMOUNT.toFixed(2).toString().length;
export const MIN_TIP_AMOUNT = 0.5;
export const TIP_AMOUNT_ERRORS = {
  OVER_MAX: 'Please enter a smaller tip amount',
  UNDER_MIN: `We cannot accept tips less than ${formatDollars(MIN_TIP_AMOUNT)}`,
};

export const TIP_AMOUNT_OPTIONS = [
  {
    value: 0,
    label: 'No tip',
  },
  {
    value: 3,
    label: '$3',
  },
  {
    value: 5,
    label: '$5',
  },
  {
    value: 10,
    label: '$10',
  },
];
export const TIP_AMOUNT_OPTION_VALUES = TIP_AMOUNT_OPTIONS.map((option) => option.value);

export const HOURS_TO_CREATE_OR_UPDATE_TIPS_AFTER_DELIVERY = 23;
export const MIN_RATING_TO_TIP = 4;
