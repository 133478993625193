// @owners { team: patients-team }
import { type DeliveryMethodType } from '@alto/deliver_api/types/v1/delivery_methods/delivery_method_type';
import { type CartItemsByDeliveryMethod } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { type Item } from '~shared/features/checkout/types';
import { getItemKey } from './getOrderParams';

/**
 * Collect redux items by delivery method type (i.e. fedex_priority_overnight, courier)
 * using the resource key (type and id)
 * @returns a map of delivery method type to redux items
 */
export const getItemsByDeliveryMethod = ({
  cartItemsByDeliveryMethod,
  itemsByKey,
}: {
  cartItemsByDeliveryMethod: CartItemsByDeliveryMethod;
  itemsByKey: Record<string, Item>;
}) => {
  return Object.entries(cartItemsByDeliveryMethod).reduce<Partial<Record<DeliveryMethodType, Item[]>>>(
    (itemsByDeliveryMethodMap, [deliveryMethodType, cartItems]) => {
      const itemsForDeliveryMethod = cartItems.map((cartItem) => {
        // get items from redux using the cartItem's resource type and id
        const key = getItemKey(cartItem);
        return itemsByKey[key];
      });
      itemsByDeliveryMethodMap[deliveryMethodType as DeliveryMethodType] = itemsForDeliveryMethod;
      return itemsByDeliveryMethodMap;
    },
    {},
  );
};
