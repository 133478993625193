// @owners { team: patients-team }
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { type ModalType, openModal } from '~shared/actions/modal';
import { selectUser } from '~shared/actions/ui/users';
// eslint-disable-next-line import/no-deprecated
import { uploadImageToS3 } from '~shared/actions/upload';
// eslint-disable-next-line import/no-deprecated
import { fetchUsers, updateUser } from '~shared/actions/users';
import { getUser } from '~shared/features/users/selectors/getUser';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared, type S3Source, type User } from '~shared/types';
import ProfileUserProfile from './ProfileUserProfile';

type Props = {
  readonly fetchUsers: () => void;
  readonly openModal: (name: ModalType) => void;
  readonly updateAvatar: (image: S3Source) => void;
  readonly user: User;
  readonly isPatientsOtherScriptsOn: boolean | null | undefined;
};

class ProfileUserProfileContainer extends PureComponent<Props> {
  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  render() {
    const { openModal, updateAvatar, user, isPatientsOtherScriptsOn } = this.props;

    return (
      <ProfileUserProfile
        openModal={openModal}
        updateAvatar={updateAvatar}
        user={user}
        isPatientsOtherScriptsOn={isPatientsOtherScriptsOn}
      />
    );
  }
}

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state: ReduxStateShared, ownProps) => {
  const userID = (ownProps.params?.id && parseInt(ownProps.params.id, 10)) || null;
  const user = getUser(state, {
    userID,
  });

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    ...bindActionCreators(
      {
        openModal,
        selectUser,
        // eslint-disable-next-line import/no-deprecated
        updateUser,
        // eslint-disable-next-line import/no-deprecated
        uploadImageToS3,
      },
      dispatch,
    ),
    fetchUsers: () => {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchUsers());
    },
  };
};

// @ts-expect-error TS(7006): Parameter 'stateProps' implicitly has an 'any' type.
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { user } = stateProps;
  const { openModal, selectUser, updateUser, uploadImageToS3, ...newDispatchProps } = dispatchProps;

  return {
    ...stateProps,
    ...newDispatchProps,
    ...ownProps,
    // @ts-expect-error TS(7006): Parameter 'modalType' implicitly has an 'any' type.
    openModal: (modalType) => {
      if (user) selectUser(user.id);
      openModal(modalType);
    },
    // @ts-expect-error TS(7006): Parameter 'image' implicitly has an 'any' type.
    updateAvatar: (image) => {
      // @ts-expect-error TS(7006): Parameter 'url' implicitly has an 'any' type.
      // eslint-disable-next-line promise/catch-or-return
      uploadImageToS3(image, 'users').then((url) => {
        // eslint-disable-next-line promise/always-return
        if (url && user) {
          updateUser({
            id: user.id,
            avatar_url: url,
          });
        }
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProfileUserProfileContainer);
