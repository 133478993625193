// @owners { team: patients-team }
import React, { PureComponent } from 'react';
import { type ModalType, openModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { clearPaymentMethodError, deletePaymentMethod, fetchPaymentMethods } from '~shared/actions/paymentMethods';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getPaymentBreakdowns } from '~shared/reducers/paymentMethods';
import { connect } from '~shared/store';
import { type PaymentMethod, type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import ProfilePaymentMethods from './ProfilePaymentMethods';

type Props = {
  readonly achPaymentMethods: PaymentMethod[];
  readonly creditCardPaymentMethods: PaymentMethod[];
  readonly deletePaymentMethod: (paymentMethod: PaymentMethod) => void;
  readonly fetchPaymentMethods: () => void;
  readonly loading: boolean;
  readonly nativePaymentMethods: PaymentMethod[];
  readonly openModal: (name: ModalType) => void;
  readonly paymentMethodError: Error | null | undefined;
};

class ProfilePaymentMethodsContainer extends PureComponent<Props> {
  componentDidMount() {
    const { fetchPaymentMethods } = this.props;
    fetchPaymentMethods();
  }

  render() {
    const {
      achPaymentMethods,
      creditCardPaymentMethods,
      deletePaymentMethod,
      loading,
      openModal,
      nativePaymentMethods,
      paymentMethodError,
    } = this.props;

    return (
      <ProfilePaymentMethods
        achPaymentMethods={achPaymentMethods}
        creditCardPaymentMethods={creditCardPaymentMethods}
        deletePaymentMethod={deletePaymentMethod}
        loading={loading}
        openModal={openModal}
        nativePaymentMethods={nativePaymentMethods}
        paymentMethodError={paymentMethodError}
      />
    );
  }
}

const mapStateToProps = (state: ReduxStateShared) => {
  const { paymentMethods } = state;
  const errors = getErrors(state);
  const loading = getLoading(state);
  const paymentMethodError =
    errors.fetchPaymentMethodsError ||
    errors.createPaymentMethodError ||
    errors.updatePaymentMethodError ||
    errors.deletePaymentMethodError;
  return {
    ...getPaymentBreakdowns(paymentMethods),
    loading: loading.createPaymentMethodLoading || loading.deletePaymentMethodLoading,
    paymentMethodError,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    // @ts-expect-error TS(7006): Parameter 'paymentMethod' implicitly has an 'any' type.
    deletePaymentMethod: (paymentMethod) => {
      dispatch(deletePaymentMethod(paymentMethod));
    },
    fetchPaymentMethods: () => {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchPaymentMethods());
    },
    openModal: (modalType: ModalType) => {
      dispatch(clearPaymentMethodError());
      dispatch(openModal(modalType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePaymentMethodsContainer);
