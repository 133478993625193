// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/v1/delivery_methods/delivery_method';
import { useMemo } from 'react';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import {
  getIsOrderPickupMethod,
  getOrderAddress,
  getOrderDate,
  getOrderDeliverBefore,
} from '~shared/features/checkout/selectors/getOrder';
import { useGetInsurances } from '~shared/queries/query-keys/insurances';
import { useSelectorShared } from '~shared/store';

/**
 * When we add items to the cart, we can't check their delivery method to see if
 * they are tippable since all the items will be prescriptions.
 * During checkout, a user may change something like the address that will change
 * what delivery method we use, so we can't rely on the delivery's delivery method.
 * Instead, we'll use useCartDeliveryMethods which takes into account
 * addresses and other variables to compute a map of delivery method to item (prescription).
 */
export const useCheckoutCourierTipping = ({ deliveryMethod }: { deliveryMethod?: DeliveryMethod } = {}) => {
  const { deliveryMethods } = useCartDeliveryMethods();
  const address = useSelectorShared(getOrderAddress);
  const { hasGovernmentInsurance } = useGetInsurances();
  const deliveryMethodMode = deliveryMethod?.mode;
  const dateSelected = useSelectorShared(getOrderDate);
  const orderDeliverBefore = useSelectorShared(getOrderDeliverBefore);

  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);

  const hasEligibleDeliveryMethod = useMemo(() => {
    if (isOrderPickupMethod) return false;
    if (deliveryMethodMode) return deliveryMethodMode === 'COURIER';

    return deliveryMethods.some((method) => method.mode === 'COURIER');
  }, [deliveryMethodMode, deliveryMethods, isOrderPickupMethod]);

  const isEligibleForTipping =
    !hasGovernmentInsurance &&
    !!address?.courier_tips_available &&
    hasEligibleDeliveryMethod &&
    !!dateSelected &&
    !!orderDeliverBefore;
  return { isEligibleForTipping };
};
