// @owners { team: patients-team }
import { Card, Description } from '@alto/design-system';
import React from 'react';
import { ADDRESS_MESSAGES } from '~shared/features/checkout/constants';
import { getOrderDate } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { useSelectorShared } from '~shared/store';
import { useCheckoutAddressInfoKeys } from '../../hooks/useCheckoutAddressInfoKeys';
import { useSignatureConfirmation } from '../../hooks/useSignatureConfirmation';
import { AddressInfoAlert } from '../delivery-address-card/address-info/AddressInfoAlert';
import { HomeToSignConfirmation } from '../delivery-address-card/address-info/HomeToSignConfirmation';

export const SignatureConfirmationCard = () => {
  const orderIndex = useSelectorShared(getOrderIndex);
  const orderDate = useSelectorShared(getOrderDate);
  const { homeToSignConfirmationRequired, signatureRequired } = useSignatureConfirmation();
  const { keys: addressInfoKeys } = useCheckoutAddressInfoKeys();

  if (!homeToSignConfirmationRequired || !orderDate) return null;

  return (
    <Card title={signatureRequired ? 'Signature required' : 'Delivery instructions'}>
      {addressInfoKeys.expensive && (
        <AddressInfoAlert type="warning">
          <Description>{ADDRESS_MESSAGES.expensive}</Description>
        </AddressInfoAlert>
      )}
      <HomeToSignConfirmation
        isSignatureRequired={signatureRequired}
        orderIndex={orderIndex}
      />
    </Card>
  );
};
