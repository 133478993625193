// @owners { team: patients-team }
import { Toast, ToastContext } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import {
  CartEndpoint,
  type CartEndpointRemoveItemRequest,
} from '@alto/scriptdash/alto/patient_app/carts/v1/cart_endpoint';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useState } from 'react';
import {
  type RemoveFromCartPayload,
  clearDeliveryWindows,
  fetchWindows,
  removeFromCart,
  resetOrdersByIndex,
  updateOrder,
} from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing } from '~shared/actions/pricing';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getIsOrderBundling } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getNextDeliveryByPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { deleteAddOnPrescription } from '~shared/features/essentials/actions';
import { getPrescriptionsHash } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { apiEndpointHandler } from '~shared/helpers/api';
import { getErrorMessageFromResponse, isControl, isExpensive } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useCheckoutAddressInfoKeys } from '../../checkout';

const cartEndpoint = CartEndpoint(apiEndpointHandler);

type RemoveFromCartParams = {
  item: RemoveFromCartPayload;
  // this is used for analytic events. see example in cross sell container
  // removeFromCart: (prescription: Prescription, index: number)
  // dispatch(sendAnalyticsEvent(createEvent(EVENTS.REFILL_CROSS_SELL_ITEM_REMOVED, { prescription, index }),
  properties?: Record<string, any> | null | undefined;
};

type RemoveFromCartWithCreatePayload = RemoveFromCartParams & {
  payload: CartEndpointRemoveItemRequest;
};

type RemoveFromCartResult = {
  success: boolean;
};

type UseRemoveFromCart = {
  isPending: boolean;
  isSuccess: boolean;
  handleRemoveFromCart: (params: RemoveFromCartParams) => Promise<RemoveFromCartResult>;
};

/**
 * Hook used to remove an item from the patient's cart.
 *
 * // export the errorMessage to render in your component
 * const { handleRemoveFromCart } = useRemoveFromCart();
 *
 * const result = await handleRemoveFromCart({ item, onSuccess: onRemoveFromCartSuccess });
 *
 * // perform logic after the item is successfully removed from cart
 * if (result.success) {
 *   ...
 * }
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useRemoveFromCart = (): UseRemoveFromCart => {
  const { value: serverSideCartEnabled } = Experimentation.useFeatureFlag('server_side_cart');
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatchShared();
  const queryClient = useQueryClient();
  const { cartItems } = useCartData();
  const { injectables } = usePrescriptionsInCart();
  const prescriptions = useSelectorShared(getPrescriptionsHash);
  const { prescriptions: cartPrescriptions } = usePrescriptionsInCart();
  const { keys: addressInfoKeys, homeToSignConfirmationRequired } = useCheckoutAddressInfoKeys();
  const { include_sharps_container } = useSelectorShared(getOrder);
  const nextDeliveryByPrescriptionID = useSelectorShared(getNextDeliveryByPrescriptionID);
  const [isSuccess, setIsSuccess] = useState(false);
  const { addToast } = useContext(ToastContext);
  const isOrderBundling = useSelectorShared(getIsOrderBundling);
  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');

  const resetHomeToSignConfirmation = (prescriptionID: number) => {
    // Don't reset home_to_sign_confirmation because the bundled order likely requires it
    if (!isOrderBundling) {
      const remainingCartPrescriptions = cartPrescriptions.filter((rx) => rx.id !== prescriptionID);
      const hasControlInCart = remainingCartPrescriptions.some(isControl);
      const isExpensiveCart = isExpensive(remainingCartPrescriptions, useNewThreshold);

      // reset home to sign confirmation if the order no longer contains expensive meds and there are no controlled meds
      if (addressInfoKeys.expensive && !isExpensiveCart && !hasControlInCart) {
        dispatch(updateOrder({ home_to_sign_confirmation: undefined }));
      }

      // reset home to sign confirmation when no longer required
      if (homeToSignConfirmationRequired && !isExpensiveCart && !hasControlInCart) {
        dispatch(updateOrder({ home_to_sign_confirmation: undefined }));
      }
    }
  };

  const clearIncludeSharpsContainer = useCallback(
    (prescriptionID: number) => {
      const include_sharps_container_set = include_sharps_container !== undefined;
      const remainingInjectables = injectables.filter((injectable) => injectable.resource_id !== prescriptionID);

      if (remainingInjectables.length === 0 && include_sharps_container_set) {
        dispatch(updateOrder({ include_sharps_container: undefined }));
      }
    },
    [dispatch, include_sharps_container, injectables],
  );

  const removeAddOnPrescriptionAndRefreshData = useCallback(
    (item: RemoveFromCartPayload) => {
      const prescription = prescriptions[item.resource_id];

      resetHomeToSignConfirmation(item.resource_id);

      if (item.resource_type === 'Prescription' && prescription?.is_add_on_otc) {
        try {
          deleteAddOnPrescription(prescription?.id);
          trackEvent({
            event: EVENTS.ESSENTIALS__OTC_REMOVED_FROM_CART,
            params: {
              patient_id: prescription.user_id,
              sku: prescription.sku,
              sku_type: prescription.sku_type,
              prescription_id: prescription.id,
              delivery_id: nextDeliveryByPrescriptionID[prescription.id]?.id,
            },
            additionalFields: {
              deliveryId: nextDeliveryByPrescriptionID[prescription.id]?.id,
              prescriptionId: prescription.id,
            },
          });
        } catch (e) {
          // no op:
          // Deleting the Add On prescription is for garbage collecting purposes on the backend. The client doesn't care about
          // the result of this endpoint, nor does any part of the UI rely on its loading/success/error.
        }
      }

      clearIncludeSharpsContainer(item.resource_id);

      if (cartItems.length === 0 || (cartItems.length === 1 && cartItems[0].resource_id === item.resource_id)) {
        dispatch(clearDeliveryWindows());
        dispatch(
          updateOrder({
            tip_amount: undefined,
            deliver_before: undefined,
            deliver_after: undefined,
            date: undefined,
            delivery_method: undefined,
            pay_at_pickup: undefined,
            pickup_address: undefined,
            pickup_ready_time: undefined,
            is_asap: undefined,
          }),
        );
      } else {
        dispatch(updateOrder({ date: undefined }));
      }

      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchOrderPricing());
      dispatch(fetchWindows());
      // reset ordersByIndex and orderIndex for split shipments when removing items from cart
      // because the compound medication could be removed
      dispatch(resetOrdersByIndex());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, cartItems, prescriptions, trackEvent, clearIncludeSharpsContainer],
  );

  const removeFromCartRedux = useCallback(
    ({ item, properties }: RemoveFromCartParams) => {
      setIsSuccess(false);
      dispatch(removeFromCart(item, properties, true));
      removeAddOnPrescriptionAndRefreshData(item);
      setIsSuccess(true);
      return Promise.resolve({ success: true });
    },
    [dispatch, removeAddOnPrescriptionAndRefreshData],
  );

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async (params: RemoveFromCartWithCreatePayload) => {
      setIsSuccess(false);
      const response = await cartEndpoint.removeItem(params.payload);
      return { response, params };
    },
  });

  const removeFromCartApi = useCallback(
    async ({ item, properties }: RemoveFromCartParams) => {
      const prescription = prescriptions[item.resource_id];
      if (!prescription?.user_id || !prescription?.medication_name) {
        throw new Error('missing user id or medication name for remove from cart');
      }

      const payload: CartEndpointRemoveItemRequest = { id: prescription.id };
      try {
        const { response } = await mutateAsync({ item, payload, properties });
        if (response.errors?.length) {
          const errorMessage = getErrorMessageFromResponse({ errors: response.errors });
          addToast(<Toast variant="error">{errorMessage}</Toast>);
          return { success: false };
        }

        queryClient.setQueryData(['cart', {}], () => response.data);
        // trigger the cart middleware
        removeAddOnPrescriptionAndRefreshData(item);
        return { success: true };
      } catch (e) {
        const errorMessage = getErrorMessageFromResponse({});
        addToast(<Toast variant="error">{errorMessage}</Toast>);
        return { success: false };
      }
    },
    [prescriptions, mutateAsync, queryClient, removeAddOnPrescriptionAndRefreshData, addToast],
  );

  if (serverSideCartEnabled) {
    return {
      isSuccess,
      isPending,
      handleRemoveFromCart: removeFromCartApi,
    };
  }

  return {
    isSuccess,
    isPending: serverSideCartEnabled === undefined,
    handleRemoveFromCart: removeFromCartRedux,
  };
};
