// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, LgPadding, ListButton, MdSpacing } from '@alto/design-system';
import React, { useContext } from 'react';
import { type Video } from '~shared/features/instructional-videos/types';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { YoutubePlayer } from '../../video';
import { PreparationInfoActionSheet } from './PreparationInfoActionSheet';

type InjectionVideoActionSheetProps = {
  readonly medicationName: string;
  readonly videosForNDC: Video[];
};

export const InjectionVideoActionSheet = ({ medicationName, videosForNDC }: InjectionVideoActionSheetProps) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  return (
    <ActionSheetV2
      title={`How to inject ${medicationName}`}
      analyticsName="InjectionVideoActionSheet Viewed"
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        {videosForNDC.map(({ videoID, title, prepItems }) => (
          <React.Fragment key={`${videoID}-${title}`}>
            <YoutubePlayer
              title={title}
              videoID={videoID}
              height={220}
              origin={ORIGIN_NAMES.NEW_MED_DETAILS}
            />
            <MdSpacing />
            <ListButton
              label="View preparation info"
              onPress={() => {
                setActiveActionSheet(
                  <PreparationInfoActionSheet
                    title={title}
                    prepItems={prepItems}
                  />,
                );
              }}
              rightIcon="chevronright"
            />
            <MdSpacing />
          </React.Fragment>
        ))}
      </LgPadding>
    </ActionSheetV2>
  );
};
