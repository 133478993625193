// @owners { team: patients-team }
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React from 'react';
import { getDeliveryMethod } from '~shared/features/delivery-info/helpers/getShipmentWithItemsRowProps';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { type Shipment } from '~shared/types';

type Props = {
  shipment: Shipment;
  readonly status?: APIOrderStatus;
};

export const DeliveryMethodRow = ({ shipment, status }: Props) => {
  const { text, iconName } = getDeliveryMethod(shipment.shipment_method, true, status || '');

  return (
    <PresentationListItem
      LeftContent={
        <AltoIcon
          name={iconName}
          type="grey"
          testID={`delivery-method-icon-${iconName}`}
        />
      }
      text={text}
    />
  );
};
