import { type ReduxStateShared } from '~shared/types';

/**
 * When we send state to third party services, we want to strip it of PHI.
 * This function only allows specific state keys, which protects against later adding new
 * keys that contain PHI.
 */
export const getPHIFilteredState = (state: ReduxStateShared) => ({
  auth: { loggedInAt: state.auth.loggedInAt, authorized: state.auth?.authorized },
  addresses: { addresses: state.addresses.addresses.map(({ id }) => ({ id })) },
  analytics: {
    events: state.analytics.events.map(({ id, key, occurredAt, properties }) => ({
      id,
      key,
      occurredAt,
      properties,
    })),
    flushing: state.analytics.flushing,
    lastFlushAttemptedAt: state.analytics.lastFlushAttemptedAt,
  },
  appTime: state.appTime,
  cart: state.cart,
  deliveries: {
    deliveries: state.deliveries.deliveries.map(({ created_at, id, status }) => ({ created_at, id, status })),
    // @ts-expect-error Property  does not exist on type 'ReduxStateShared'
    windows: state.deliveries?.windows,
  },
  insurances: {
    insurances: state.insurances.insurances.map(({ id }) => {
      id;
    }),
  },
  modal: state.modal,
  nextAvailableDates: {
    // @ts-expect-error Property  does not exist on type 'ReduxStateShared'
    nextAvailableDates: state.nextAvailableDates.nextAvailableDates?.map((nextAvailableDate) => ({
      address: { id: nextAvailableDate.address?.id },
      computed_at: nextAvailableDate.computed_at,
      excluded_dates: nextAvailableDate.excluded_dates,
      facility_id: nextAvailableDate.facility_id,
      id: nextAvailableDate.id,
      next_auto_refill_date: nextAvailableDate.next_auto_refill_date,
      next_available_date: nextAvailableDate.next_available_date,
      next_available_dates: nextAvailableDate.next_available_dates,
      prescription_ids: nextAvailableDate.prescription_ids,
    })),
  },
  notices: state.notices,
  paymentMethods: { paymentMethods: state.paymentMethods.paymentMethods.map(({ id }) => ({ id })) },
  prescriptions: {
    prescriptions: state.prescriptions.prescriptions.map(({ id }) => ({ id })),
    transfers: state.prescriptions.transfers?.map(({ id }) => ({ id })) ?? [],
  },
  presence: state.presence,
  routing: state.routing,
  shipments: { shipments: state.shipments.shipments.map(({ id }) => ({ id })) },
  ui: {
    actionSheet: { key: state.ui.actionSheet?.key },
    cart: state.ui.cart,
    loading: state.ui.loading,
    errors: state.ui.errors,
    users: state.ui.users,
    medications: state.ui.medications,
  },
  uiOnboarding: {
    isSignupLoading: state.uiOnboarding?.isSignupLoading,
    isCurrentUserLoading: state.uiOnboarding?.isCurrentUserLoading,
    isOnboarding: state.uiOnboarding?.isOnboarding,
  },
  users: {
    currentUserID: state.users.currentUserID,
    users: state.users.users.map(({ id, created_at, updated_at }) => ({ id, created_at, updated_at })),
  },
});
