// eslint-disable-next-line @alto/no-pocky-import
import { Button, DeprecatedModalBody, DeprecatedModalFooter, InfoBox, LoadingButton, Text } from '@alto/pocky';
import { useQueryClient } from '@tanstack/react-query';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TypescriptUtils } from '~shared/TypescriptUtils';
// eslint-disable-next-line import/no-deprecated
import { clearInsuranceError, createPhotoInsurance } from '~shared/actions/insurances';
import { updateCashPay } from '~shared/actions/users';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getInsuranceSource } from '~shared/lib/analytics/src/getInsuranceSource';
import { queries } from '~shared/queries/query-keys';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import InsurancePhotoFormElements, {
  type Values,
  validate,
} from '~web/features/insurances/components/InsurancePhotoFormElements';

const HeaderText = styled(Text)`
  margin-bottom: 1.5rem;
`;

const initialValues: Values = {
  image_url: undefined,
  image_url_2: undefined,
};

type Props = {
  readonly customHeader?: React.ReactNode | null | undefined;
  readonly onCancel: () => void;
};

export const InsurancePhotoForm = ({ customHeader = null, onCancel }: Props) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const selectedUserID = useSelectorShared(getSelectedUserID);
  const userID = selectedUserID || currentUserID;
  const insuranceError = useSelectorShared(
    (state) =>
      state.ui.errors.fetchInsurancesError ||
      state.ui.errors.createInsuranceError ||
      state.ui.errors.updateInsuranceError ||
      state.ui.errors.createPhotoInsuranceError,
  );
  const loading = useSelectorShared((state) => state.ui.loading.createPhotoInsuranceLoading);
  const source = useSelectorShared(getInsuranceSource);
  const [isDirty, setIsDirty] = useState(false);

  const setDirty = () => {
    setIsDirty(true);
  };

  const onSubmit = async (insurance: Values) => {
    dispatch(clearInsuranceError());

    const images = [insurance.image_url?.[0], insurance.image_url_2?.[0]];
    const filteredImages = images.filter(TypescriptUtils.isPresent);
    if (filteredImages.length > 0) {
      // @ts-expect-error createPhotoInsurance is deprecated; works with type Dropzone.ImageFile[]
      // eslint-disable-next-line import/no-deprecated
      const insurance = await dispatch(createPhotoInsurance(filteredImages, userID));
      if (typeof insurance !== 'boolean') {
        trackEvent({
          event: EVENTS.INSURANCE_ADDED,
          params: {
            type: 'photo',
            source,
            entity_name: insurance.insurance_plan_name ?? '',
          },
        });
        queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });

        if (!userID) return;
        const { success } = await dispatch(updateCashPay(userID, false));
        if (!success) return;
        trackEvent({
          event: EVENTS.CASH_PAY_ONLY_SET,
          params: {
            source,
            'new value': false,
          },
        });
      }
    }
  };

  const defaultHeader = (
    <HeaderText>Upload a photo of the front and back of your prescription benefit card.</HeaderText>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      validateOnChange={isDirty}
      validateOnBlur={isDirty}
      enableReinitialize
    >
      {(formikProps) => (
        <Form
          {...formikProps}
          noValidate
        >
          <DeprecatedModalBody>
            {customHeader || defaultHeader}
            <InsurancePhotoFormElements />
            {insuranceError ? (
              <InfoBox
                danger
                title="Something went wrong"
              >
                <Text>There was an error uploading your benefit card. Please try again or contact us.</Text>
              </InfoBox>
            ) : null}
          </DeprecatedModalBody>
          <DeprecatedModalFooter>
            <Button
              kind="tertiary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={loading}
              onClick={setDirty}
            >
              Add benefits
            </LoadingButton>
          </DeprecatedModalFooter>
        </Form>
      )}
    </Formik>
  );
};
