// @owners { team: patients-team }
import { ShipmentFeeCohortMap } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_cohort';
import { ShipmentFeeTypeMap } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_type';
import {
  type FulfillmentOption,
  type FulfillmentWindowOption,
} from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import { formatDollars } from '~shared/helpers/currency';
import { formatDate, formatDateStringForAPI } from '~shared/helpers/date';

export const buildTestDeliveryWindows = () => {
  return [
    {
      date: '2021-09-01',
      label: 'Express',
      description: 'by 11:45am (estimated)',
      delivery_window: {
        start_time: '2021-09-01T11:45:00Z',
        end_time: '2021-09-01T11:45:00Z',
        cutoff_time: '2021-09-01T11:45:00Z',
      },
      estimated_eta: '2021-09-01T11:45:00Z',
      available_fee: {
        fee_type: ShipmentFeeTypeMap.ASAP_DELIVERY,
        amount: 12.99,
        original_amount: 12.99,
        fee_cohort: ShipmentFeeCohortMap.ALTO,
        date: '2021-09-01',
      },
    },
    {
      date: '2021-09-01',
      label: 'Afternoon',
      description: '12:00 - 3:00pm',
      delivery_window: {
        start_time: '2021-09-01T12:00:00Z',
        end_time: '2021-09-01T15:00:00Z',
        cutoff_time: '2021-09-01T10:00:00Z',
      },
      estimated_eta: null,
      available_fee: {
        fee_type: ShipmentFeeTypeMap.SAME_DAY_DELIVERY,
        amount: 9.99,
        original_amount: 9.99,
        fee_cohort: ShipmentFeeCohortMap.ALTO,
        date: '2021-09-01',
      },
    },
    {
      date: '2021-09-01',
      label: 'Evening',
      description: '6:00 - 9:00pm',
      delivery_window: {
        start_time: '2021-09-01T18:00:00Z',
        end_time: '2021-09-01T21:00:00Z',
        cutoff_time: '2021-09-01T15:00:00Z',
      },
      estimated_eta: null,
      available_fee: {
        fee_type: ShipmentFeeTypeMap.SAME_DAY_DELIVERY,
        amount: 0,
        original_amount: 9.99,
        fee_cohort: ShipmentFeeCohortMap.ALTO,
        date: '2021-09-01',
      },
    },
  ];
};

export const createCustomFulfillmentOption = (deliveryDate: string, deliveryWindow?: FulfillmentWindowOption) => {
  const availableFee = deliveryWindow?.available_fee;
  const { amount: feeAmount, original_amount: feeOriginalAmount } = availableFee ?? { amount: 0, original_amount: 0 };

  return {
    label: formatDate(deliveryDate),
    date: formatDateStringForAPI(deliveryDate),
    fee_amount_label: feeAmount > 0 ? formatDollars(feeAmount || 0) : 'Free',
    fee_original_amount_label: feeAmount > 0 ? formatDollars(feeOriginalAmount || 0) : 'Free',
    tag: null,
    available_fees: availableFee ? [availableFee] : [],
    pickup_data: null,
    // We set fulfillment_window_option here to null because when selecting a custom date, we dont know at time
    // of date selection if there is only a single window. Because of this we set this as nil as it's only used
    // if the date has a single delivery window available.
    fulfillment_window_option: null,
  } as FulfillmentOption;
};

export const getTimeOfDay = (time: string) => {
  // Split the time string into hours and the rest
  const [hours, period] = time.split(':');

  // Convert the hours to a number
  const numericHours = Number(hours);

  // Determine the time of day based on the hours and period
  if (period.includes('AM') && numericHours < 12) {
    return 'Morning';
  } else if (period.includes('PM') && (numericHours === 12 || (numericHours >= 1 && numericHours < 5))) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
};

export const initSelectedFulfillmentWindowOption = ({
  orderDate,
  orderDeliverAfter,
  orderDeliverBefore,
  isAsap = false,
}: {
  orderDate: string | undefined;
  orderDeliverAfter: string | undefined;
  orderDeliverBefore: string | undefined;
  isAsap?: boolean;
}): FulfillmentWindowOption | undefined => {
  if (orderDate && orderDeliverAfter && orderDeliverBefore) {
    return {
      available_fee: undefined,
      date: orderDate,
      estimated_eta: undefined,
      label: isAsap ? 'Express' : getTimeOfDay(orderDeliverAfter),
      description: isAsap ? `by ${orderDeliverBefore} (estimated)` : `${orderDeliverAfter} - ${orderDeliverBefore}`,
      delivery_window: {
        start_time: orderDeliverAfter,
        end_time: orderDeliverBefore,
        cutoff_time: '',
      },
    };
  }
  return undefined;
};

// Returns a sorted by date (ascending) list of radio date options. If a customFulfillmentOption is provided,
// it will be added to the list. If we are in the second of a split shipment order, we will
// only show the selected date by the user as they are not able to change the date at this point.
export const getRadioDateOptions = ({
  courierDateFulfillmentOptions,
  customFulfillmentOption,
  isSecondSplitShipment,
  selectedDate,
}: {
  courierDateFulfillmentOptions: FulfillmentOption[];
  customFulfillmentOption?: FulfillmentOption | null;
  isSecondSplitShipment?: boolean;
  selectedDate?: string | null;
}) => {
  const radioDateOptions = customFulfillmentOption
    ? [...courierDateFulfillmentOptions, customFulfillmentOption]
    : courierDateFulfillmentOptions;

  // For case when in second of split shipment, we only want to show the selected date
  // since date cannot be changed during this step by the patient.
  if (isSecondSplitShipment && selectedDate) {
    return radioDateOptions.filter((option) => option.date === selectedDate);
  }

  return radioDateOptions.sort((a, b) => {
    if (a.date && b.date) {
      return a.date.localeCompare(b.date);
    }
    return 0;
  });
};

export const formatStoreTime = (time: string) => {
  const originalHour = new Date(time).getUTCHours();
  const originalMinutes = new Date(time).getUTCMinutes();

  const hour = originalHour > 12 ? originalHour % 12 : originalHour;
  const minutes = originalMinutes < 10 ? `0${originalMinutes}` : originalMinutes;
  const amPm = originalHour >= 12 ? 'pm' : 'am';

  if (minutes === '00') {
    return `${hour}${amPm}`;
  }

  return `${hour}:${minutes}${amPm}`;
};

export const getDateKey = ({
  date,
  isPickup,
  tag,
}: {
  date: string | null | undefined;
  isPickup: boolean;
  tag: 'BUNDLED' | null | undefined;
}) => {
  if (isPickup) return `PICKUP-${date}`;

  return `${date}${tag ? '-' + tag : ''}`;
};

export const getFeeLabels = ({
  fulfillmentOption,
  selectedWindowOption,
  isDateOptionSelected,
}: {
  fulfillmentOption: FulfillmentOption;
  selectedWindowOption?: FulfillmentWindowOption;
  isDateOptionSelected: boolean;
}) => {
  const feeAmountLabel = fulfillmentOption.fee_amount_label ?? undefined;
  const feeOriginalAmountLabel = fulfillmentOption.fee_original_amount_label ?? undefined;
  if (!isDateOptionSelected || !selectedWindowOption) {
    return {
      feeAmountLabel,
      feeOriginalAmountLabel,
    };
  }

  const selectedAmount = selectedWindowOption.available_fee?.amount;
  const selectedOriginalAmount = selectedWindowOption.available_fee?.original_amount;

  let selectedFeeAmountLabel: string | undefined;
  if (selectedAmount === 0 && selectedOriginalAmount) {
    selectedFeeAmountLabel = 'Free';
  } else if (!!selectedAmount && selectedAmount > 0) {
    selectedFeeAmountLabel = formatDollars(selectedAmount);
  } else {
    selectedFeeAmountLabel = feeAmountLabel;
  }

  const selectedFeeOriginalAmountLabel = selectedOriginalAmount
    ? formatDollars(selectedOriginalAmount)
    : feeOriginalAmountLabel;

  return { feeAmountLabel: selectedFeeAmountLabel, feeOriginalAmountLabel: selectedFeeOriginalAmountLabel };
};
