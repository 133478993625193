// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import { Row, Text, spacing } from '@alto/pocky';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Icon = styled(FontAwesomeIcon)`
  margin-top: 3px;
  margin-right: ${spacing.xsSpacing} !important;
`;

export default function TipQualifiedExpenseWarning() {
  return (
    <Row>
      <Icon
        icon={['far', 'info-circle']}
        color={COLORS.TEXT_COLORS.SUCCESS}
      />
      <Text success>Tips are not qualified expenses for FSA or HSA cards.</Text>
    </Row>
  );
}
