// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  DeprecatedModal,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  FlexRow,
  InfoText,
  Input,
  Label,
  Span,
  Text,
  spacing,
} from '@alto/pocky';
import { faPaste } from '@fortawesome/pro-solid-svg-icons/faPaste';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import styled from 'styled-components';
import { modal } from '~shared/actions';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalType from '~shared/selectors/modal/getModalType';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import Image from '~web/components/Image';
import referral from '~web/images/inviteFriends@2x.png';

const StyledSpan = styled(FlexRow)`
  flex-direction: row;
`;

const StyledSpanAddon = styled(Span)`
  border-left-style: none;
  padding: ${SPACING.STATIC.XS.px} ${TYPOGRAPHY.TEXT.DETAIL.LG.px};
  margin-left: -1px;
  line-height: 1;
  border: ${BORDERS.SIZE}px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  background-color: ${COLORS.PALETTE.GREYSCALE.LIGHT};
`;

const StyledInput = styled(Input)`
  border: ${BORDERS.SIZE}px solid ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  border-right-color: ${COLORS.PALETTE.GREYSCALE.LIGHT};
  border-radius: ${SPACING.STATIC.NONE.value};
  z-index: 2;
  height: 34px;
  padding: ${SPACING.STATIC.XXS.px} ${TYPOGRAPHY.TEXT.DETAIL.LG.px};
  font-size: ${TYPOGRAPHY.TEXT.BODY.SM.px};
`;

const StyledInfoText = styled(InfoText)<{ copied: boolean }>`
  text-align: left;
  visibility: ${({ copied }) => (copied ? 'visible' : 'hidden')};
`;

const StyledLabel = styled(Label)<{ for: string }>``;

const emailSubject = 'Have you tried Alto Pharmacy?';

const emailBody = (referral: string) => `
Hi!
%0D%0A%0D%0A
I've been using Alto Pharmacy to get my medications delivered to my door for free. They have everything I need in stock and can help with insurance and copays. It's saved me so much valuable time.
%0D%0A%0D%0A
Switch to a better pharmacy: ${referral}
`;

export const ReferralModal = () => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const [copied, setCopied] = useState(false);
  const modalType = useSelectorShared(getModalType);
  const show = useSelectorShared(getIsModalShowing) ?? false;
  const currentUser = useSelectorShared(getCurrentUser);
  const referralLink = currentUser?.referral_link ?? 'https://alto.com';

  const closeModal = () => {
    dispatch(modal.closeModal('REFERRAL_MODAL'));
  };

  const onEmailPrompt = () => {
    trackEvent({
      event: EVENTS.REFERRAL_LINK_SHARED,
      params: {
        shareMethod: 'email',
      },
    });
  };

  const copyLink = () => {
    copy(referralLink);
    setCopied(true);
    trackEvent({
      event: EVENTS.REFERRAL_LINK_SHARED,
      params: {
        shareMethod: 'copy',
      },
    });

    // FIXME: this is sloppy
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const shown = show && modalType === 'REFERRAL_MODAL';
  const emailPrompt = `mailto:?subject=${emailSubject}&body=${emailBody(referralLink)}`;

  return shown ? (
    <DeprecatedModal
      showing={shown}
      onClose={closeModal}
      title="Invite a Friend to Alto"
    >
      <DeprecatedModalBody>
        <FlexRow
          horizontallyAlignContent
          padding={spacing.smSpacing}
          spacing={spacing.smSpacing}
        >
          <Image
            src={referral}
            alt="Illustration of friends"
          />
          <Text>Share this unique code to invite your friends and family to Alto:</Text>
          <div>
            <StyledLabel for="copyLink">Copy link</StyledLabel>
            <StyledSpan>
              <StyledInput
                type="text"
                value={referralLink}
              />
              <StyledSpanAddon id="copyLink">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
                <a
                  onClick={copyLink}
                  tabIndex={0}
                  role="button"
                >
                  <FontAwesomeIcon
                    icon={faPaste}
                    size="sm"
                  />
                </a>
              </StyledSpanAddon>
            </StyledSpan>
            <StyledInfoText copied={copied}>Copied!</StyledInfoText>
          </div>
        </FlexRow>
      </DeprecatedModalBody>
      <DeprecatedModalFooter>
        <Button
          kind="tertiary"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <a
          href={emailPrompt}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button onClick={onEmailPrompt}>Send Email</Button>
        </a>
      </DeprecatedModalFooter>
    </DeprecatedModal>
  ) : null;
};
