// @owners { team: patients-team }
import { Description, ExternalLink, InlineAlert, SmSpacing } from '@alto/design-system';
import React from 'react';
import { deliveryMethodString } from '~shared/features/delivery-info/helpers/getShipmentWithItemsRowProps';
import { isFedEx, isUSPS } from '~shared/helpers/order';
import { type ShipmentMethod } from '~shared/types';
import { FEDEX_ACCOUNT_LINK, USPS_ACCOUNT_LINK } from './constants';

type Props = {
  readonly method?: ShipmentMethod;
};
export const TrackMailDeliveryAlert = ({ method }: Props) => {
  const mailMethod = method ? deliveryMethodString[method] : 'FedEx or USPS';
  const isSentViaFedEx = isFedEx(method);

  return (
    <InlineAlert>
      <Description fontFamily="semibold">Manage and track your delivery</Description>
      <SmSpacing />
      {isUSPS(method) ? (
        <Description>{`Create a ${mailMethod} account to manage signature requirements and delivery notifications.`}</Description>
      ) : (
        <Description>{`Create an account to manage this delivery directly with ${mailMethod}.`}</Description>
      )}
      <SmSpacing />
      {method ? (
        <ExternalLink
          href={isSentViaFedEx ? FEDEX_ACCOUNT_LINK : USPS_ACCOUNT_LINK}
          textSize="mini"
        >
          {isSentViaFedEx ? 'Go to FedEx Delivery Manager' : 'Go to USPS.com'}
        </ExternalLink>
      ) : (
        <>
          <ExternalLink
            href={FEDEX_ACCOUNT_LINK}
            textSize="mini"
          >
            Sign up for FedEx Delivery Manager
          </ExternalLink>
          <SmSpacing />
          <ExternalLink
            href={USPS_ACCOUNT_LINK}
            textSize="mini"
          >
            Sign up for USPS
          </ExternalLink>
        </>
      )}
    </InlineAlert>
  );
};
