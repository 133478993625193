// @owners { team: patients-team }
import { ActionSheetContext, Column, ListDescription } from '@alto/design-system';
import { type MedDetailsPrescription } from '@alto/scriptdash/alto/medications/patients/v1/types';
import { isBefore, parse } from 'date-fns';
import pluralize from 'pluralize';
import React, { useContext } from 'react';
import { getIsPrescriptionScheduleable } from '~shared/features/prescriptions/selectors/getScheduleableForPrescriptionID';
import { getCurrentUserIsEligibleForQuantityChange } from '~shared/features/users/selectors/getUserIsEligibleForQuantityChange';
import { useSelectorShared } from '~shared/store';
import { AncillaryItemsActionSheet } from '../../../ancillaries';
import { QuantityChangeLink } from '../../../quantity-change';

type Props = {
  readonly medicationName: string;
  readonly prescription: MedDetailsPrescription;
};

export const PrescriptionDescriptions = ({ medicationName, prescription }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const {
    expiration_date: expirationDateString,
    id: prescriptionID,
    includes_ancillaries,
    last_filled,
    prescriber,
    quantity,
    remaining_refills,
    rx_number,
    signature,
  } = prescription;
  const expirationDate = parse(expirationDateString, 'MMMM dd, yyyy', new Date());
  const outOfRefillsOrExpired = remaining_refills === 0 || isBefore(expirationDate, new Date());
  const scheduleable = useSelectorShared((state) => getIsPrescriptionScheduleable(state, { prescriptionID }));
  const isEligibleForQuantityChange = useSelectorShared(getCurrentUserIsEligibleForQuantityChange);
  const showQuantityChangeLink = isEligibleForQuantityChange && scheduleable;

  const topHalfAboveAncillaries = [
    <ListDescription
      key={`${rx_number}-${quantity}`}
      iconName="pillbottle-small"
    >
      <>
        {quantity}
        {showQuantityChangeLink ? (
          <>
            &nbsp;•&nbsp;
            <QuantityChangeLink
              prescriptionID={prescriptionID}
              origin="new med details"
            />
          </>
        ) : null}
      </>
    </ListDescription>,
    <ListDescription
      key={`${rx_number}-${remaining_refills}}`}
      iconName="sync-small"
      type={outOfRefillsOrExpired ? 'danger' : 'grey'}
    >
      {`${remaining_refills} ${pluralize('refill', remaining_refills)} before ${expirationDateString}`}
    </ListDescription>,
  ];

  const possiblyAncillariesDescription = includes_ancillaries
    ? [
        <ListDescription
          type="secondary"
          key={`${rx_number}-with-ancillaries`}
          iconName="syringe-small"
          onPress={() => {
            setActiveActionSheet(
              <AncillaryItemsActionSheet
                prescriptionID={prescriptionID}
                medicationName={medicationName}
              />,
            );
          }}
        >
          Ancillary supplies included
        </ListDescription>,
      ]
    : [];

  return (
    <Column
      gap="XXS"
      flexShrink={1}
    >
      {[
        ...topHalfAboveAncillaries,
        ...possiblyAncillariesDescription,
        <ListDescription
          key={`${rx_number}-${last_filled}`}
          iconName="calendar-small"
        >
          {last_filled}
        </ListDescription>,
        <ListDescription
          key={`${rx_number}-${prescriber}`}
          iconName="doctor-small"
        >
          {`Prescribed by ${prescriber}`}
        </ListDescription>,
        <ListDescription
          key={`${rx_number}-${signature}`}
          iconName="signpaper-small"
        >
          {signature}
        </ListDescription>,
        <ListDescription
          key={rx_number}
          iconName="rxchart-small"
        >
          {rx_number}
        </ListDescription>,
      ]}
    </Column>
  );
};
