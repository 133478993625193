// @owners { team: patients-team }
import {
  type BACKGROUND_COLORS_VALUES,
  type BaseIconName,
  COLORS,
  type ColorVariant,
} from '@alto/design-library-tokens';
import { isAfter, parseISO } from 'date-fns';
import { type HomeToSignConfirmation, type Item } from '~shared/features/checkout/types';
import { type APIOrderMethod } from '~shared/features/delivery-info/types';
import { formatFullAddress, getAddressOneLiner } from '~shared/helpers/address';
import { formatDeliveredAt, formatRelativeDateWithDow, formatWindow, formatWindowTime } from '~shared/helpers/date';
import { getIcon } from '~shared/helpers/delivery';
import { isExpensive } from '~shared/helpers/helper';
import { isCourier, isMail, isPickup, isUSPS } from '~shared/helpers/order';
import { type Address, type ShipmentMethod, type ShipmentStatus } from '~shared/types';

type IconRowProps = {
  bold?: boolean;
  danger?: boolean;
  icon?: string;
  success?: boolean;
  text?: string;
  warning?: boolean;
};

export type ShipmentWithItemsRowProps = {
  deliveryMethod: IconRowProps | null | undefined;
  deliveryDate: IconRowProps | null | undefined;
  address: IconRowProps | null | undefined;
  refrigerated: IconRowProps | null | undefined;
  ancillaryItems: IconRowProps | null | undefined;
  tracking: IconRowProps | null | undefined;
};

export const deliveryMethodString: Record<string, string> = {
  courier: 'courier',
  onfleet: 'courier',
  pickup: 'pickup',
  fedex_priority_overnight: 'FedEx',
  fedex_standard_overnight: 'FedEx',
  fedex_ground: 'FedEx',
  usps_priority: 'USPS',
  usps_first: 'USPS',
  usps_express: 'USPS',
  usps_ground_advantage: 'USPS',
  uber: 'Uber', // not in use
  manual: '',
};

export const getDeliveryMethod = (
  method: APIOrderMethod | null | undefined,
  showMethod: boolean,
  status: string,
  isOrderPickupMethod?: boolean,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): { iconName: BaseIconName; text: string } => {
  if (!method || !showMethod) {
    return { iconName: 'box', text: '' };
  }

  const isPickupMethod = isPickup(method) || !!isOrderPickupMethod;
  const isCourierMethod = isCourier(method) && !isOrderPickupMethod;
  const isMailMethod = isMail(method) && !isOrderPickupMethod;
  const methodString = isOrderPickupMethod ? 'pickup' : deliveryMethodString[method] ?? '';

  if (!methodString) {
    return { iconName: 'box', text: '' };
  }

  switch (status) {
    case 'delivered':
      return {
        iconName: getIcon(method),
        text: isPickupMethod ? 'Picked up' : `Delivered by ${methodString}`,
      };

    case 'failed':
      return {
        iconName: 'alert',
        text: `Failed delivery (${methodString})`,
      };

    default:
      if (isPickupMethod) {
        return {
          iconName: 'handbox',
          text: 'Pick up order',
        };
      }
      return {
        iconName: isCourierMethod ? 'car' : 'box',
        text: isMailMethod ? `Ships via ${methodString}` : `Arrives via ${methodString}`,
      };
  }
};

export function getDeliveryMethod_deprecated({
  method,
  status,
}: {
  method?: ShipmentMethod | null;
  status?: ShipmentStatus;
}): IconRowProps | null | undefined {
  if (!method) {
    return null;
  }

  const methodString = deliveryMethodString[method];

  if (!methodString) {
    return null;
  }

  let icon = '';
  let text = '';

  const mailMethodString = isMail(method) ? `mail (${methodString})` : methodString;

  switch (status) {
    case 'delivered':
      if (isPickup(method)) {
        text = 'Picked up';
      } else {
        text = `Delivered via ${mailMethodString}`;
      }

      icon = 'check-circle';
      break;

    case 'failed':
      icon = 'times-circle';
      text = `Failed delivery (${methodString})`;
      break;

    default:
      if (isPickup(method)) {
        icon = 'hand-holding-box';
        text = 'Pickup';
      } else if (isCourier(method)) {
        icon = 'car';
        text = 'Arrives via courier';
      } else if (isMail(method)) {
        icon = 'box';
        text = `Ships via ${methodString}`;
      }

      break;
  }

  return {
    icon,
    text,
    success: status === 'delivered',
    danger: status === 'failed',
    bold: ['delivered', 'failed'].includes(status ?? ''),
  };
}

type GetDeliveryDateTextProps = {
  deliverAfter: string | null | undefined;
  deliverBefore: string | null | undefined;
  date: string | null | undefined;
  method: APIOrderMethod | null | undefined;
  isOnDemand?: boolean;
  isAsapDelivery?: boolean;
};

export const getDeliveryDateText = ({
  deliverAfter,
  deliverBefore,
  date,
  method,
  isOnDemand,
  isAsapDelivery,
}: GetDeliveryDateTextProps) => {
  if (!date) return '';

  const dateText = formatRelativeDateWithDow(date);
  const today = new Date();

  if (isPickup(method) && isAfter(today, parseISO(date))) {
    return `Ready on ${dateText}`;
  }

  if (isAsapDelivery && deliverBefore) {
    return `${dateText}, by ${formatWindowTime({ windowTime: deliverBefore, meridiem: 'aaa' })} (estimated)`;
  }

  if ((!deliverBefore || !deliverAfter) && date) {
    const formattedDate = formatRelativeDateWithDow(date);
    return isUSPS(method) ? `Expected ${formattedDate}` : formattedDate;
  }

  if (isCourier(method)) {
    return isOnDemand ? `${dateText}, ASAP` : `${dateText} ${formatWindow(deliverAfter, deliverBefore)}`;
  }

  return dateText;
};

export const getDeliveredAtText = (date: string | null | undefined) => {
  if (!date) {
    return null;
  }
  return formatDeliveredAt(date);
};

export function getFormattedAddress_deprecated(address: Address | undefined): IconRowProps | null | undefined {
  if (!address?.street_address_1) {
    return null;
  }

  return {
    icon: 'map-marker-alt',
    text: getAddressOneLiner(address),
  };
}

export const getFormattedAddress = (address: Address | null | undefined) => {
  if (!address) return 'Address deleted';
  return formatFullAddress(address);
};

type GetInstructionsProps = {
  address: Address | undefined;
  homeToSignConfirmation: HomeToSignConfirmation | null | undefined;
  items: Item[];
  method: APIOrderMethod | null | undefined;
  status: string;
  use3000Threshold: boolean;
};
export const getInstructions = ({
  address,
  homeToSignConfirmation,
  items,
  method,
  status,
  use3000Threshold,
}: GetInstructionsProps): {
  iconName: BaseIconName;
  text: string;
  type?: ColorVariant;
  backgroundColor?: BACKGROUND_COLORS_VALUES;
} | null => {
  if (!address || ['delivered', 'failed'].includes(status)) {
    return null;
  }

  if (method === 'pickup') {
    return {
      iconName: 'box',
      text: "You're picking up this package",
      type: 'success',
    };
  }

  const prescriptions = items.map((i) => i.prescription);
  if (
    items.some((i) => i.isSignatureRequired) ||
    (isExpensive(prescriptions, use3000Threshold) && homeToSignConfirmation === 'CONFIRM')
  ) {
    return {
      iconName: 'signpaper',
      text: 'Someone needs to be present to sign for this delivery',
      type: 'grey',
      backgroundColor: COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
    };
  }

  if (isMail(method)) {
    return null;
  }

  if (!address.safe_place) {
    return {
      iconName: 'box',
      text: 'Someone needs to be present to receive this delivery',
      type: 'grey',
      backgroundColor: COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
    };
  }

  return {
    iconName: 'homelock',
    text: "We'll leave this package in your designated safe place",
    type: 'grey',
  };
};
