// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, ListBase, MdPadding, Tag } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { type UpcomingShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/upcoming_shipment';
import React, { useMemo } from 'react';
import {
  getAsapShipmentFee,
  getOnDemandShipmentFee,
} from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import {
  getShipmentStatusInfoText,
  getShipmentStatusString,
} from '~shared/features/shipments/helpers/getShipmentStatusConfig';
import { useEligibleForMailAutoRefill } from '~shared/features/shipments/hooks/useEligibleForMailAutoRefill';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { formatDateWithDowWithoutYear, formatRelativeDateWithDow } from '~shared/helpers/date';
import { isExpensive } from '~shared/helpers/helper';
import { isMail, isUSPS } from '~shared/helpers/order';
import { isPickupPastDue } from '~shared/helpers/pickup';
import { type ShipmentMethod, type ShipmentStatus } from '~shared/types';
import { FulfillmentMethodRow, HomeToSignRow, LocationRow, MedRow, TimeWindowRow } from '../../../info-rows';
import { DeliveryCardCTA } from './DeliveryCardCTA';

type Props = {
  readonly shipment: ShipmentWithItemsAndAdditionalFields;
  readonly isEditable?: boolean;
  readonly isPastDelivery?: boolean;
};

export const getDeliveryCardTitle = ({
  date,
  delivered_at,
  method,
  status,
}: {
  date?: string;
  delivered_at?: string | null;
  method?: ShipmentMethod;
  status?: ShipmentStatus;
}) => {
  if (delivered_at) {
    return formatRelativeDateWithDow(delivered_at);
  } else if (date) {
    if (isUSPS(method)) {
      return `Expected ${formatRelativeDateWithDow(date)}`;
    } else if (isPickupPastDue({ date, method, status })) {
      return `Ready on ${formatDateWithDowWithoutYear(date)}`;
    } else {
      return formatRelativeDateWithDow(date);
    }
  }
  return getShipmentStatusInfoText({ status, method });
};

export const DeliveryCard = ({ shipment, isEditable, isPastDelivery }: Props) => {
  const {
    address,
    date,
    delivered_at,
    deliver_after,
    deliver_before,
    is_pickup,
    items,
    method,
    home_to_sign_confirmation,
    safe_place,
    status,
    shipmentID,
  } = shipment;
  const medicationNames = items.map((item) => (item.medication ? item.medication.medicationName : ''));
  const isOnDemandDelivery = !!getOnDemandShipmentFee(shipment);
  const isAsapDelivery = !!getAsapShipmentFee(shipment);
  const prescriptions = items.map((item) => item.prescription);
  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');
  const isSignatureRequired =
    items.some((item) => item.isSignatureRequired) || isExpensive(prescriptions, useNewThreshold);
  const shipmentAsUpcoming = {
    id: shipmentID,
    date: shipment.date,
    start_time: null,
    end_time: null,
    address,
    medication_names: medicationNames,
    editable: isEditable,
    family_member_name: null,
    price: null,
    ordered_on_demand: isOnDemandDelivery,
    status,
  } as UpcomingShipment;
  const eligibleForMailAutoRefill = useEligibleForMailAutoRefill({ shipment: shipmentAsUpcoming });

  const rows = [
    {
      key: 'calendar',
      component: (
        <TimeWindowRow
          startTime={deliver_after}
          endTime={deliver_before}
          isOnDemandDelivery={isOnDemandDelivery}
          isAsapDelivery={isAsapDelivery}
        />
      ),
      hide: status === 'delivered' || method === 'pickup' || isMail(method) || eligibleForMailAutoRefill,
    },
    {
      key: 'location',
      component: (
        <LocationRow
          address={address}
          isPickup={is_pickup}
          showMap={status !== 'delivered'}
        />
      ),
      hide: !address, //if no address provided, do not show
    },
    {
      key: 'method',
      component: (
        <FulfillmentMethodRow
          method={method}
          status={status}
        />
      ),
      hide: eligibleForMailAutoRefill,
    },
    {
      key: 'meds',
      component: <MedRow medicationNames={medicationNames} />,
    },
    {
      key: 'signature',
      component: isPastDelivery ? null : (
        <HomeToSignRow
          homeToSignConfirmation={home_to_sign_confirmation}
          safePlace={!!safe_place}
          method={method}
          isSignatureRequired={isSignatureRequired}
        />
      ),
      hide: method === 'pickup' || eligibleForMailAutoRefill,
    },
    {
      key: 'view-delivery',
      component: (
        <MdPadding
          topPadding={SPACING.STATIC.LG}
          bottomPadding={SPACING.STATIC.LG}
        >
          <DeliveryCardCTA
            isEditable={isEditable}
            shipmentID={shipmentID}
          />
        </MdPadding>
      ),
    },
  ];

  const lines = rows.filter((row) => !row.hide);

  const deliveryTitle = useMemo(() => {
    return getDeliveryCardTitle({ date, delivered_at, method, status });
  }, [date, delivered_at, method, status]);

  return (
    <Card
      title={deliveryTitle}
      tag={
        status !== 'delivered' ? (
          <Tag
            label={getShipmentStatusString(status, is_pickup)}
            type="recommended"
          />
        ) : undefined
      }
    >
      <ListBase items={lines} />
    </Card>
  );
};
