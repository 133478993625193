/**
 * This is just a stub on web, since it's much more easy to track requests there via devtools
 */

export const setUpNetworkLogDevTool = () => {
  // noop
};

export const recordNetworkRequest = async (url: string, hasInflightRequest: boolean) => {
  // noop
  return Promise.resolve({ url, hasInflightRequest });
};
