import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetV2,
  Button,
  Description,
  FlatCard,
  LgPadding,
  LgSpacing,
  ListDescription,
  ListItem,
  MdPadding,
  Tag,
  XsSpacing,
} from '@alto/design-system';
import { type DoseChangePrescription } from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import React from 'react';
import { formatList } from '~shared/helpers/list';

export type ConfirmPrescriptionChangeActionSheetProps = {
  readonly onBack: () => void;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;

  /**
   * The old prescription
   */
  readonly otherPrescription: DoseChangePrescription;

  /**
   * The new prescription
   */
  readonly prescription: DoseChangePrescription;
};

export const ConfirmPrescriptionChangeActionSheet = ({
  onBack,
  onCancel,
  onConfirm,
  otherPrescription,
  prescription,
}: ConfirmPrescriptionChangeActionSheetProps) => {
  const didStrengthChange = prescription.strength !== otherPrescription.strength;
  const didQuantityChange = prescription.quantity !== otherPrescription.quantity;
  const didDaysSupplyChange = prescription.days_supply !== otherPrescription.days_supply;
  const changedFields = [
    didStrengthChange && 'strength',
    didQuantityChange && 'quantity',
    didDaysSupplyChange && 'day supply',
  ].filter(Boolean) as string[]; // ! i need to add in ts-reset
  const changedFieldsContent = formatList(changedFields);

  return (
    <ActionSheetV2
      title="We noticed your prescription changed"
      buttons={[
        <Button
          key="Add to cart"
          label="Add to cart"
          testID="confirm-prescription-change-action-sheet-add-to-cart"
          onPress={onConfirm}
        />,
        <Button
          key="Cancel"
          label="Cancel"
          onPress={onCancel}
          type="tertiary"
        />,
      ]}
      handleClose={onCancel}
      onBack={onBack}
      analyticsName="cart confirm prescription change"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Description>
          There was a change in <Description fontFamily="semibold">{changedFieldsContent}</Description> from your last
          fill of this medication. This usually means your provider adjusted your prescription, or that different doses
          of the same medication are meant to be taken together.
        </Description>
        <LgSpacing />
        <Tag
          label="PREVIOUS RX"
          type="warning"
        />
        <XsSpacing />
        <ListItem
          descriptions={[
            <ListDescription
              iconName="pillbottle-small"
              key="previous-rx-quanity"
            >
              {otherPrescription.product_quantity}
            </ListDescription>,
            <ListDescription
              iconName="calendarcheck-small"
              key="previous-rx-days-supply"
            >
              {`${otherPrescription.days_supply} day supply`}
            </ListDescription>,
          ]}
          fullBleed
          title={otherPrescription.medication_name}
        />
        <LgSpacing />
        <Tag
          label="NEW RX"
          type="success"
        />
        <XsSpacing />
        <ListItem
          descriptions={[
            <ListDescription
              iconName="pillbottle-small"
              key="new-rx-quanity"
            >
              {prescription.product_quantity}
            </ListDescription>,
            <ListDescription
              iconName="calendarcheck-small"
              key="new-rx-days-supply"
            >
              {`${prescription.days_supply} day supply`}
            </ListDescription>,
          ]}
          fullBleed
          title={prescription.medication_name}
        />
        <LgSpacing />
        <FlatCard>
          <MdPadding>
            <Description>If you have any questions or were not expecting this change, send us a message.</Description>
          </MdPadding>
        </FlatCard>
      </LgPadding>
    </ActionSheetV2>
  );
};
