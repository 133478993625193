// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, ExternalLink, LgPadding } from '@alto/design-system';
import React from 'react';
import { PRIVACY_POLICY_URL } from '~shared/constants';

export const AltoAssistantActionSheet = () => {
  return (
    <ActionSheetV2
      title="Alto Assistant"
      illustrationSrc={require('./chatbot.png')}
      analyticsName="alto assistant info"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          The Alto virtual assistant is an automated chatbot that helps you with basic prescription management tasks and
          helps connect you with the right Alto team member when needed. As a reminder, just like other messages you
          send to Alto, information you submit is subject to Alto’s{' '}
          <ExternalLink href={PRIVACY_POLICY_URL}>Privacy Policy</ExternalLink>.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
