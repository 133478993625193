// @owners { team: patients-team }

// eslint-disable-next-line @alto/no-pocky-import
import { Column, H1, Text, palette, spacing } from '@alto/pocky';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { type AnalyticsProps, SOURCES } from '~shared/features/courier-tips/AnalyticsProps';
import sendTipAnalyticsEvent from '~shared/features/courier-tips/actions/sendTipAnalyticsEvent';
import { COURIER_NEW_TIP_COPY, COURIER_TIP_COPY, INITIAL_TIP_AMOUNT } from '~shared/features/courier-tips/constants';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import ChoosePaymentMethod from './ChoosePaymentMethodContainer';
import CourierTipInput from './CourierTipInput';
import TipQualifiedExpenseWarning from './TipQualifiedExpenseWarning';
import Image from '~web/components/Image';
import bikeCourier from '~web/images/bikeCourierGreenBackground.png';

const StyledColumn = styled(Column)`
  text-align: center;
  padding: ${spacing.lgSpacing} ${spacing.lgSpacing} 0 ${spacing.lgSpacing};

  > *:last-child {
    margin-top: ${spacing.xlSpacing};
  }
`;

const PaymentAndWarningColumn = styled(Column)`
  text-align: left;
`;

const CourierTipCardHeader = styled(H1)`
  color: ${palette.altoColorsP2};
`;

type Props = {
  readonly analyticsProps: AnalyticsProps;
  readonly editTipAmount: (tip: number) => void;
  readonly editTipPaymentMethodID: (arg0: number) => void;
  readonly tipAmount: number;
  readonly tipPaymentMethodID: number | null | undefined;
};

const EditOrAddTip = ({
  analyticsProps,
  editTipAmount,
  editTipPaymentMethodID,
  tipAmount,
  tipPaymentMethodID,
}: Props) => {
  const dispatch = useDispatchShared();
  const { source } = analyticsProps;
  const loaded = useRef(false);

  useEffect(() => {
    if (loaded.current) return;
    // leave tip as is if one is already present for the shipment
    if (!tipAmount && source !== SOURCES.EDIT_DELIVERY) {
      loaded.current = true;
      editTipAmount(INITIAL_TIP_AMOUNT);
    }
  }, [tipAmount, source, editTipAmount]);

  useEffect(() => {
    const eventKey =
      source === SOURCES.POST_DELIVERY
        ? EVENTS.COURIER_TIPS__POST_DELIVERY_TIP_VIEWED
        : EVENTS.COURIER_TIPS__EDIT_TIP_VIEWED;
    dispatch(
      sendTipAnalyticsEvent({
        eventKey,
        analyticsProps,
        additionalProps: { tip_amount: tipAmount },
      }),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledColumn spacing={spacing.mdSpacing}>
      <Image
        src={bikeCourier}
        alt={IMG_ALT_TEXTS.bikeCourier}
      />
      <CourierTipCardHeader>{COURIER_TIP_COPY.CARD_HEADER}</CourierTipCardHeader>
      <Text center>{COURIER_NEW_TIP_COPY.CARD_BODY}</Text>
      <CourierTipInput
        alignCenter
        editTipAmount={editTipAmount}
        initialTipAmount={tipAmount}
        verticalSpacing={spacing.mdSpacing}
        analyticsProps={analyticsProps}
      />
      <PaymentAndWarningColumn spacing={spacing.smSpacing}>
        <ChoosePaymentMethod
          paymentMethodID={tipPaymentMethodID}
          selectPaymentMethodID={editTipPaymentMethodID}
        />
        <TipQualifiedExpenseWarning />
      </PaymentAndWarningColumn>
    </StyledColumn>
  );
};

export default EditOrAddTip;
