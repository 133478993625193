// @owners { team: patients-team }
import { getPrescriptionsInOrder__DEPRECATED } from '~shared/features/checkout/selectors/getItems';
import { getScheduleablePrescriptions } from '~shared/features/my-meds/selectors/getScheduleablePrescriptions';
import { getNextAvailableDateAddressID } from '~shared/features/next-available-date/selectors/getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from '~shared/features/next-available-date/selectors/getNextAvailableDateFacilityId';
import getShipmentID from '~shared/features/next-available-date/selectors/getShipmentId';
import { get } from '~shared/helpers/apiHelper';
import { type ReduxDispatch, type ReduxGetStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import { type NextAvailableDateResponse } from '~shared/types/clients';

export const FETCH_NEXT_AVAILABLE_DATES_LOADING = 'FETCH_NEXT_AVAILABLE_DATES_LOADING';

type ActionFetchNextAvailableDatesLoading = {
  type: typeof FETCH_NEXT_AVAILABLE_DATES_LOADING;
};

const fetchingNextAvailableDates = (): ActionFetchNextAvailableDatesLoading => {
  return {
    type: FETCH_NEXT_AVAILABLE_DATES_LOADING,
  };
};

export const FETCH_NEXT_AVAILABLE_DATES_FAILED = 'FETCH_NEXT_AVAILABLE_DATES_FAILED';

export type ActionFetchNextAvailableDatesFailed = {
  type: typeof FETCH_NEXT_AVAILABLE_DATES_FAILED;
  payload: APIError;
  error: true;
};

function fetchNextAvailableDatesFailed(error: APIError): ActionFetchNextAvailableDatesFailed {
  return {
    type: FETCH_NEXT_AVAILABLE_DATES_FAILED,
    payload: error,
    error: true,
  };
}

export const FETCH_NEXT_AVAILABLE_DATES_SUCCEEDED = 'FETCH_NEXT_AVAILABLE_DATES_SUCCEEDED';

export type ActionFetchNextAvailableDatesSucceeded = {
  type: typeof FETCH_NEXT_AVAILABLE_DATES_SUCCEEDED;
  payload: NextAvailableDateResponse[];
};

function fetchNextAvailableDatesSucceeded(
  nextAvailableDates: NextAvailableDateResponse[],
): ActionFetchNextAvailableDatesSucceeded {
  return {
    type: FETCH_NEXT_AVAILABLE_DATES_SUCCEEDED,
    payload: nextAvailableDates,
  };
}

export type NextAvailableDateActions =
  | ActionFetchNextAvailableDatesFailed
  | ActionFetchNextAvailableDatesSucceeded
  | ActionFetchNextAvailableDatesLoading;

type FetchNextAvailableDatesRequest = {
  address_id?: number;
  facility_id?: number;
  shipment_id?: number;
  unbundled?: string;
  bundled?: string;
};

export type FetchNextAvailableDatesOptions = {
  addressID?: number;
  facilityID?: number;
  unbundledPrescriptionIDs?: number[];
  bundledPrescriptionIDs?: number[];
  shipmentID?: number;
};

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export const fetchNextAvailableDates = (options: FetchNextAvailableDatesOptions = {}) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    options = options || {};

    const addressID = options.addressID || getNextAvailableDateAddressID(state);
    const facilityID = options.facilityID || getNextAvailableDateFacilityID(state);
    const unbundled = options.unbundledPrescriptionIDs || getScheduleablePrescriptions(state).map((rx) => rx.id);
    const bundled = options.bundledPrescriptionIDs || getPrescriptionsInOrder__DEPRECATED(state).map((rx) => rx.id);
    const shipmentID = options.shipmentID || getShipmentID(state);

    // Fail early if neither address or facility is available.
    // One of either is required for calculating the next available date
    if (!addressID && !facilityID) {
      dispatch(
        fetchNextAvailableDatesFailed({
          details: {
            message: 'One of address or facility is required.',
            statusCode: 400,
            statusType: 'error',
          },
          message: 'One of address or facility is required.',
          name: 'error',
        }),
      );

      return Promise.resolve(false);
    }

    // 2023-10-20 alau: this is a temporary fix. we're seeing tons of attempts for empty NAD requests getting through
    // here, but don't know exactly where they are coming from.
    if (unbundled.length === 0 && bundled.length === 0 && !shipmentID) {
      dispatch(fetchNextAvailableDatesSucceeded([]));

      return Promise.resolve(true);
    }

    dispatch(fetchingNextAvailableDates());

    const params: FetchNextAvailableDatesRequest = {};

    if (addressID) {
      params.address_id = addressID;
    } else {
      params.facility_id = facilityID;
    }

    if (unbundled.length > 0) {
      params.unbundled = unbundled.join(',');
    }

    if (bundled.length > 0) {
      params.bundled = bundled.join(',');
    }

    if (shipmentID) {
      params.shipment_id = shipmentID;
    }

    return get('/next_available_dates', params, { version: 'v3' }).then((json) => {
      if (json.error) {
        dispatch(fetchNextAvailableDatesFailed(json.error));

        return false;
      }

      dispatch(fetchNextAvailableDatesSucceeded(json));

      return true;
    });
  };
};
