// @owners { team: patients-team }
import { NavigationService } from '@alto/navigation';
import { unauthorizedRouteNames } from '@alto/navigation/src/unauthorizedRouteNames';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { addHours, isBefore } from 'date-fns';
import { getBrand } from 'react-native-device-info';
import { logout } from '~shared/actions/auth';
import { BIOMETRIC_SESSION_EXPIRY } from '~shared/constants';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { type ApiOptions } from '~shared/helpers/api';
import { post } from '~shared/helpers/apiHelper';
import getDeviceID from '~shared/helpers/getDeviceID';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import {
  type AvailableBiometricsResponse,
  BIOMETRIC_SIGNATURE_PAYLOAD,
  availableBiometrics,
  configureBiometricSession,
  createSignature,
  promptBiometrics,
} from '~shared/lib/biometrics';
import { getBiometricsForCurrentUser } from '~shared/selectors/biometrics/getBiometricsForCurrentUser';
import { Sentry } from '~shared/sentry';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';

const V3_API_VERSION = 'v3';
const v3Options: ApiOptions = {
  version: V3_API_VERSION,
};

export function getAvailableBiometrics() {
  try {
    return availableBiometrics();
  } catch (e) {
    return Promise.resolve({
      available: false,
      biometryType: undefined,
    });
  }
}

export const SAMSUNG = 'samsung';
export const isBrandSupported = () => {
  const brand = getBrand();
  return brand !== SAMSUNG;
};

async function downgradeSession(userID: number) {
  const deviceID = getDeviceID();
  return post(`/users/${userID}/biometric/${deviceID}/downgrade_session`, {}, v3Options);
}

export type SyncBiometricsPayload = {
  available: boolean;
  loginRegistered: boolean;
  supportedType: string | undefined;
};

export type UpdateBiometricsRequestPayload = {
  userID: number;
  enabled: boolean;
  prompted: boolean;
};

export type UpdateBiometricsResponsePayload = {
  enabled: boolean;
  error?: boolean;
};

export type SetBiometricsPromptedPayload = {
  userID: number | null;
  prompted: boolean;
};

export const SYNC_BIOMETRICS_LOADING = 'SYNC_BIOMETRICS_LOADING';
export const SYNC_BIOMETRICS_SUCCEEDED = 'SYNC_BIOMETRICS_SUCCEEDED';
export type ActionSyncBiometricsLoading = {
  type: typeof SYNC_BIOMETRICS_LOADING;
};

export type ActionSyncBiometricsSucceeded = {
  type: typeof SYNC_BIOMETRICS_SUCCEEDED;
  payload: SyncBiometricsPayload;
};

const syncBiometricsSucceeded = ({
  available,
  loginRegistered,
  supportedType,
}: SyncBiometricsPayload): ActionSyncBiometricsSucceeded => ({
  type: SYNC_BIOMETRICS_SUCCEEDED,
  payload: {
    available,
    loginRegistered,
    supportedType,
  },
});

const getSupportedType = (biometryType: string | undefined): string => {
  switch (biometryType) {
    case 'FaceID':
      return 'Face ID';

    case 'TouchID':
      return 'Touch ID';

    default:
      return 'face or fingerprint ID';
  }
};

export function syncBiometrics() {
  return async (dispatch: ReduxDispatchShared) => {
    dispatch({
      type: SYNC_BIOMETRICS_LOADING,
    } as ActionSyncBiometricsLoading);

    const { available, biometryType }: AvailableBiometricsResponse = await getAvailableBiometrics();

    if (available && biometryType) {
      dispatch(
        syncBiometricsSucceeded({
          available: true,
          loginRegistered: false,
          supportedType: getSupportedType(biometryType),
        }),
      );
    } else {
      dispatch(
        syncBiometricsSucceeded({
          available: false,
          loginRegistered: false,
          supportedType: undefined,
        }),
      );
    }

    return Promise.resolve();
  };
}

export const ENABLE_BIOMETRICS_LOADING = 'ENABLE_BIOMETRICS_LOADING';
export const ENABLE_BIOMETRICS_SUCCEEDED = 'ENABLE_BIOMETRICS_SUCCEEDED';
export const ENABLE_BIOMETRICS_FAILED = 'ENABLE_BIOMETRICS_FAILED';
export type ActionEnableBiometricsLoading = {
  type: typeof ENABLE_BIOMETRICS_LOADING;
};

export type ActionEnableBiometricsSucceeded = {
  type: typeof ENABLE_BIOMETRICS_SUCCEEDED;
  payload: UpdateBiometricsRequestPayload;
};

export type ActionEnableBiometricsFailed = {
  type: typeof ENABLE_BIOMETRICS_FAILED;
  payload: UpdateBiometricsRequestPayload;
};

export const enableBiometricsSucceeded = (
  payload: UpdateBiometricsRequestPayload,
): ActionEnableBiometricsSucceeded => ({
  type: ENABLE_BIOMETRICS_SUCCEEDED,
  payload,
});

export const enableBiometricsFailed = (payload: UpdateBiometricsRequestPayload): ActionEnableBiometricsFailed => ({
  type: ENABLE_BIOMETRICS_FAILED,
  payload,
});

export function enableBiometrics() {
  return async (
    dispatch: ReduxDispatchShared,
    getState: () => ReduxStateShared,
  ): Promise<UpdateBiometricsResponsePayload> => {
    dispatch({
      type: ENABLE_BIOMETRICS_LOADING,
    } as ActionEnableBiometricsLoading);

    try {
      const state = getState();
      const currentUserID = getCurrentUserID(state);

      if (currentUserID) {
        const promptResponse = await promptBiometrics();

        if (promptResponse.success) {
          const success = await configureBiometricSession(currentUserID);

          if (success) {
            dispatch(
              enableBiometricsSucceeded({
                userID: currentUserID,
                enabled: true,
                prompted: true,
              }),
            );
            return Promise.resolve({
              enabled: true,
            });
          }

          dispatch(
            enableBiometricsFailed({
              userID: currentUserID,
              enabled: false,
              prompted: true,
            }),
          );
          return Promise.resolve({
            enabled: false,
            error: true,
          });
        }

        // when the biometric prompt fails, the device may disable biometrics on the OS-level.
        // re-sync redux state with device biometrics to ensure we have the most up-to-date state
        dispatch(syncBiometrics());
        return Promise.resolve({
          enabled: false,
        });
      }
    } catch (e) {
      // same here - on Android when the biometric prompt fails after too many attempts, it will throw an error.
      // re-sync redux state with OS-level device biometrics to ensure we have the most up-to-date state
      dispatch(syncBiometrics());
    }

    return Promise.resolve({
      enabled: false,
      error: true,
    });
  };
}

export const DISABLE_BIOMETRICS_LOADING = 'DISABLE_BIOMETRICS_LOADING';
export const DISABLE_BIOMETRICS_SUCCEEDED = 'DISABLE_BIOMETRICS_SUCCEEDED';
export const DISABLE_BIOMETRICS_FAILED = 'DISABLE_BIOMETRICS_FAILED';
export type ActionDisableBiometricsLoading = {
  type: typeof DISABLE_BIOMETRICS_LOADING;
};

export type ActionDisableBiometricsSucceeded = {
  type: typeof DISABLE_BIOMETRICS_SUCCEEDED;
  payload: UpdateBiometricsRequestPayload;
};

export type ActionDisableBiometricsFailed = {
  type: typeof DISABLE_BIOMETRICS_FAILED;
  payload: UpdateBiometricsRequestPayload;
};

export const disableBiometricsSucceeded = (
  payload: UpdateBiometricsRequestPayload,
): ActionDisableBiometricsSucceeded => ({
  type: DISABLE_BIOMETRICS_SUCCEEDED,
  payload,
});

export const disableBiometricsFailed = (payload: UpdateBiometricsRequestPayload): ActionDisableBiometricsFailed => ({
  type: DISABLE_BIOMETRICS_FAILED,
  payload,
});

export function disableBiometrics() {
  return async (
    dispatch: ReduxDispatchShared,
    getState: () => ReduxStateShared,
  ): Promise<UpdateBiometricsResponsePayload> => {
    dispatch({
      type: DISABLE_BIOMETRICS_LOADING,
    } as ActionDisableBiometricsLoading);

    const state = getState();
    const currentUserID = getCurrentUserID(state);

    if (currentUserID) {
      const downgradeResponse = await downgradeSession(currentUserID);

      if (downgradeResponse.error) {
        dispatch(
          disableBiometricsFailed({
            userID: currentUserID,
            enabled: true,
            prompted: true,
          }),
        );
        return Promise.resolve({
          enabled: true,
          error: true,
        });
      }

      await AsyncStorage.removeItem(BIOMETRIC_SESSION_EXPIRY);
      dispatch(
        disableBiometricsSucceeded({
          userID: currentUserID,
          enabled: false,
          prompted: true,
        }),
      );
      return Promise.resolve({
        enabled: false,
      });
    }

    return Promise.resolve({
      enabled: true,
      error: true,
    });
  };
}

export const SET_BIOMETRICS_PROMPTED = 'SET_BIOMETRICS_PROMPTED';
export type ActionSetBiometricsPrompted = {
  type: typeof SET_BIOMETRICS_PROMPTED;
  payload: SetBiometricsPromptedPayload;
};
export const setBiometricsPrompted = (payload: SetBiometricsPromptedPayload): ActionSetBiometricsPrompted => ({
  type: SET_BIOMETRICS_PROMPTED,
  payload,
});

export function recordBiometricsPrompted() {
  // eslint-disable-next-line @typescript-eslint/require-await
  return async (dispatch: ReduxDispatchShared, getState: () => ReduxStateShared) => {
    const state = getState();
    const userID = getCurrentUserID(state);
    dispatch(
      setBiometricsPrompted({
        userID,
        prompted: true,
      }),
    );
  };
}

export function verifySignature() {
  // @ts-expect-error not all code paths return a value
  return async (dispatch: ReduxDispatchShared, getState: () => ReduxStateShared) => {
    const state = getState();

    try {
      const signatureResponse = await createSignature();

      if (signatureResponse.success) {
        const deviceInfoID = getDeviceID();
        const userID = getCurrentUserID(state);

        const res = await post(
          `/users/${userID}/biometric/${deviceInfoID}/validate`,
          {
            device_info_id: deviceInfoID,
            signature: signatureResponse.signature,
            payload: BIOMETRIC_SIGNATURE_PAYLOAD,
          },
          v3Options,
        );

        if (res.error) {
          return dispatch(logout());
        }

        dispatch(sendAnalyticsEvent(createEvent(EVENTS.BIOMETRICS_REAUTH_SUCCESS)));

        await AsyncStorage.setItem(BIOMETRIC_SESSION_EXPIRY, res.expires_at);

        dispatch(biometricAuthenticationSuccess());

        NavigationService.goBack();
        return;
      }

      // when the biometric signature fails after too many attempts, the device may disable biometrics on the OS-level.
      // re-sync redux state with device biometrics to ensure we have the most up-to-date state
      dispatch(syncBiometrics());
    } catch (e) {
      const context = {
        contexts: {
          'Biometric error': {
            e,
          },
        },
      };
      Sentry.captureMessage('Error raised while verifying biometric signature', context);
      return dispatch(logout());
    }
  };
}

async function biometricSessionIsExpired() {
  const expiresAt = await AsyncStorage.getItem(BIOMETRIC_SESSION_EXPIRY);

  if (!expiresAt) {
    return false;
  }

  const now = new Date();
  const inOneHour = addHours(now, 1);
  const expiresAtDate = new Date(expiresAt);
  return isBefore(expiresAtDate, inOneHour);
}

// Redirects to Biometric Reauth screen if biometric session cookie has expired
export function handleBiometricReauthenticationRedirect() {
  return async (dispatch: ReduxDispatchShared, getState: () => ReduxStateShared) => {
    await dispatch(syncBiometrics());
    const state = getState();
    const routeName = NavigationService.getCurrentRouteName();
    const biometrics = getBiometricsForCurrentUser(state);

    if (!biometrics.enabled) {
      dispatch(biometricAuthenticationSuccess());
      return Promise.resolve(false);
    }

    const isSessionExpired = await biometricSessionIsExpired();

    if (isSessionExpired) {
      const { available } = getBiometricsForCurrentUser(state);

      if (available && isBrandSupported()) {
        if (routeName && unauthorizedRouteNames[routeName]) {
          // Do not navigate to Reauth if the current route does not require authorization
          return Promise.resolve(false);
        }

        NavigationService.navigate({
          name: 'RouteBiometricReauth',
          params: {
            biometricReauth: true,
          },
        });
      } else {
        // If the biometric session has expired but biometrics are no longer available on the device
        // log them out.
        dispatch(logout());
      }

      return Promise.resolve(true);
    }

    dispatch(biometricAuthenticationSuccess());
    return Promise.resolve(false);
  };
}

export const BIOMETRICS_AUTHENTICATED = 'BIOMETRICS_AUTHENTICATED';

export type ActionBiometricsAuthenticated = {
  type: typeof BIOMETRICS_AUTHENTICATED;
};

export function biometricAuthenticationSuccess(): ActionBiometricsAuthenticated {
  return {
    type: BIOMETRICS_AUTHENTICATED,
  };
}
