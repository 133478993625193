import { SPACING } from '@alto/design-library-tokens';
import { Tag, Toast, ToastContext } from '@alto/design-system';
import { useUpdateCartItem } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, Modal, ModalContent, ModalFooter, RadioGroup, RadioOption, Row, Text } from '@alto/pocky';
import { type PriceOption, type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import wallet from '~shared/assets/images/wallet@3x.png';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { SELECT_PRICE_OPTION } from '~shared/features/checkout/constants';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { getPriceOptionsForPrescriptionInOrder } from '~shared/features/price-options/selectors/getPriceOptionsForPrescriptionInOrder';
import { PRICE_OPTION_LABELS } from '~shared/features/pricing/constants';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { getInsuranceName } from '~shared/helpers/insuranceName';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { ButtonWrapper } from '~web/components/ButtonWrapper';
import HeaderImage, { MODAL_SIZE } from '~web/components/HeaderImage';

export const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

type PropsProvidedByRadioGroupParent = {
  readonly checked?: boolean;
  readonly onChange?: React.ChangeEventHandler<HTMLElement>;
};
type OptionProps = PropsProvidedByRadioGroupParent & {
  readonly value: PriceType | undefined;
  readonly option: PriceOption;
  readonly isLowestPrice: boolean;
};
const Option = ({ value, option, isLowestPrice, checked, onChange }: OptionProps) => {
  const insurance = useSelectorShared((state) => getInsuranceByID(state, option?.insurance_id || undefined));
  const { primaryName: insuranceName } = insurance ? getInsuranceName(insurance) : { primaryName: '' };
  let optionTypeText = PRICE_OPTION_LABELS[option.type ?? 'without_insurance'];
  if (option.type === 'with_insurance') {
    if (insuranceName) {
      optionTypeText = insuranceName;
    }
    optionTypeText += SELECT_PRICE_OPTION.with_insurance_subtitle;
  } else {
    optionTypeText += SELECT_PRICE_OPTION.without_insurance_subtitle;
  }

  return (
    <RadioOption
      name={value}
      value={value}
      checked={checked}
      onChange={onChange}
    >
      <Column>
        <Row spacing={SPACING.STATIC.XS.rem}>
          <Text bold>{formatDollarsWithPlaceholder(option.price)}</Text>
          {isLowestPrice ? (
            <Tag
              type="recommended"
              label={SELECT_PRICE_OPTION.lowest_price_tag}
            />
          ) : null}
        </Row>
        <Text
          small
          light
        >
          {optionTypeText}
        </Text>
      </Column>
    </RadioOption>
  );
};

export type Props = {
  readonly onClose: () => void;
  readonly prescriptionID: number;
};
const SelectPriceOptionModal = ({ onClose, prescriptionID }: Props) => {
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const priceOptions = useSelectorShared((state) => getPriceOptionsForPrescriptionInOrder(state, { prescriptionID }));

  const { cartItems } = useCartData();
  const selectedCartPaymentType = cartItems.find((item) => item.resource_id === prescriptionID)?.selected_price_type;
  const { handleUpdateCartItem } = useUpdateCartItem();
  const defaultOption = selectedCartPaymentType || priceOptions.find((o) => o?.selected)?.type || 'without_insurance';
  const [selectedPaymentType, setSelectedPaymentType] = useState(defaultOption);

  const option1 = priceOptions[0];
  const option2 = priceOptions[1];
  let lowestPricePaymentType: PriceType | null | undefined = option1?.type || 'without_insurance';
  if ((option2?.price || 0) < (option1?.price || 0)) {
    lowestPricePaymentType = option2?.type;
  }

  const onChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'without_insurance' || event.target.value === 'with_insurance') {
      const value = event.target.value as PriceType;
      const priceOption = priceOptions.find((option) => option.type === value);
      setSelectedPaymentType(value);
      trackEvent({
        event: EVENTS.PRICE_SELECTED,
        params: {
          price: priceOption?.price,
          price_type: priceOption?.type,
          payment_type: value,
          source: 'checkout',
        },
        additionalFields: { prescriptionId: prescriptionID },
      });
    }
  };

  const onDismiss = () => {
    onClose();
  };

  const onSave = async () => {
    const res = await handleUpdateCartItem({
      resource_type: 'Prescription',
      resource_id: prescriptionID,
      selected_price_type: selectedPaymentType,
    });
    if (res.success) {
      onDismiss();
    } else {
      addToast(
        <Toast variant="error">
          Unable to select payment type selection for prescription. If the problem persists please contact support.
        </Toast>,
      );
    }
  };

  return (
    <StyledModal onClose={onDismiss}>
      <ModalContent textAlignment="left">
        <Column spacing={SPACING.STATIC.SM.rem}>
          <HeaderImage
            src={wallet}
            alt={IMG_ALT_TEXTS.wallet}
            title={SELECT_PRICE_OPTION.title}
            imgSize={MODAL_SIZE}
          />
          <Text>{SELECT_PRICE_OPTION.subtitle}</Text>
          <RadioGroup
            input={{
              name: 'priceOption',
              value: selectedPaymentType,
              onChange: onChangeOption,
            }}
          >
            {priceOptions.map((o) => (
              <Option
                key={o.type}
                value={o.type ?? undefined}
                option={o}
                isLowestPrice={o.type === lowestPricePaymentType}
              />
            ))}
          </RadioGroup>
        </Column>
      </ModalContent>
      <ModalFooter>
        <ButtonWrapper horizontallyAlignContent>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button onClick={onSave}>Save</Button>
        </ButtonWrapper>
      </ModalFooter>
    </StyledModal>
  );
};

export default SelectPriceOptionModal;
