import { SIZES, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { type ImageSourcePropType, View } from 'react-native';
import styled from 'styled-components/native';
import { useScreenSize } from '../../../utils';
import { PressableAltoIcon } from '../../buttons';
import { Column, LgPadding, Row } from '../../containers';
import { Illustration, type NavBarLogo } from '../../images';
import { LgSpacing, XsSpacing } from '../../separators';
import { type Body, H1 } from '../../typography';
import { BasePage, type BasePageProps } from './BasePage';
import { NavBar } from './NavBar';

const MaxWidthContent = styled(LgPadding)<{ isMDScreenOrBigger: boolean }>`
  align-self: center;
  width: ${SIZES.PAGE.CONTENT_WIDTH.SM};

  ${({ isMDScreenOrBigger }) =>
    isMDScreenOrBigger &&
    `
        width: ${SIZES.PAGE.CONTENT_WIDTH.LG};
        max-width: ${SIZES.PAGE.CONTENT_WIDTH.LG};
  `}
`;

const IllustrationPlaceholder = styled(View)`
  height: ${SIZES.ILLUSTRATION.XXL.px};
  width: ${SIZES.ILLUSTRATION.XXL.px};
`;

const VariableTopOfPageSpacing = styled(View)<{ isMDScreenOrBigger: boolean }>`
  height: ${SPACING.STATIC.XXL.px};
  width: ${SPACING.STATIC.XXL.px};

  ${({ isMDScreenOrBigger }) =>
    isMDScreenOrBigger &&
    `
      height: ${SPACING.STATIC.XXXL.px};
      width: ${SPACING.STATIC.XXXL.px};
  `}
`;

type Props = Omit<BasePageProps, 'NavBar' | 'children'> & {
  readonly illustrationSrc?: ImageSourcePropType;
  readonly illustrationSize?: 'xl' | 'xxl';
  readonly title: string;
  readonly onDismiss?: () => void;
  readonly dismissIcon?: 'close' | 'chevronleft';
  readonly HeaderBodyCopy?: React.ReactElement<typeof Body>;
  readonly children?: React.ReactNode;
  readonly NavBarCenterContent?: string | React.ReactElement<typeof NavBarLogo>;
};

export const LandingPage = ({
  children,
  backgroundColor,
  illustrationSrc,
  illustrationSize = 'xxl',
  title,
  onDismiss,
  dismissIcon = 'close',
  HeaderBodyCopy,
  buttons,
  Footnote,
  NavBarCenterContent,
  withoutWebFramingElementHeights,
  footerPlacementContext,
}: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();
  const hasNavBar = !!onDismiss || !!NavBarCenterContent;
  return (
    <BasePage
      backgroundColor={backgroundColor}
      buttons={buttons}
      Footnote={Footnote}
      withoutWebFramingElementHeights={withoutWebFramingElementHeights}
      footerPlacementContext={footerPlacementContext}
      NavBar={
        hasNavBar ? (
          <NavBar
            CenterContent={NavBarCenterContent}
            LeftPressableAltoIcon={
              onDismiss ? (
                <PressableAltoIcon
                  name={dismissIcon}
                  onPress={onDismiss}
                  accessibilityLabel={dismissIcon === 'close' ? 'Dismiss this screen' : 'Go back to previous screen'}
                />
              ) : undefined
            }
          />
        ) : undefined
      }
    >
      <MaxWidthContent
        flexGrow={1}
        isMDScreenOrBigger={isMDScreenOrBigger}
        topPadding={SPACING.STATIC.NONE}
      >
        <Column center>
          {!onDismiss || isMDScreenOrBigger ? (
            <VariableTopOfPageSpacing isMDScreenOrBigger={isMDScreenOrBigger} />
          ) : null}
          {illustrationSrc ? (
            <Illustration
              size={illustrationSize}
              source={illustrationSrc}
            />
          ) : null}
          {!illustrationSrc && isMDScreenOrBigger ? <IllustrationPlaceholder /> : null}
          <LgSpacing />
          <H1 center>{title}</H1>
          {!!HeaderBodyCopy && (
            <>
              <XsSpacing />
              {}
              <Row flexShrink={1}>{React.cloneElement<any>(HeaderBodyCopy, { center: true })}</Row>
            </>
          )}
        </Column>
        {!!children && (
          <>
            <LgSpacing />
            {children}
          </>
        )}
      </MaxWidthContent>
    </BasePage>
  );
};
