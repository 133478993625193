import { type FlattenSimpleInterpolation } from 'styled-components';
import { type VerticalEnabled } from './types';

type GetCssFromOrientationParams = {
  isMDScreenOrBigger: boolean;
  verticalEnabled: VerticalEnabled;
  verticalCss?: FlattenSimpleInterpolation | string;
  horizontalCss?: FlattenSimpleInterpolation | string;
};
export const getCssFromOrientation = ({
  isMDScreenOrBigger,
  verticalEnabled,
  verticalCss,
  horizontalCss,
}: GetCssFromOrientationParams) => {
  switch (verticalEnabled) {
    case 'always':
      return verticalCss ?? '';
    case 'never':
      return horizontalCss ?? '';
    case 'mobile':
      if (isMDScreenOrBigger) {
        return horizontalCss ?? '';
      }
      return verticalCss ?? '';
    default:
      return '';
  }
};
