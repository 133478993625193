import { ANIMATION } from '@alto/design-library-tokens';
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { Constants } from '../../utils';
import type { ActionSheetProps } from './DEPRECATED_ActionSheet';
// eslint-disable-next-line import/no-deprecated
import { DEPRECATED_ActionSheet } from './DEPRECATED_ActionSheet';

export type ActionSheetFlowContentProps = {
  transitionContent?: (content?: ActionSheetFlowContentProvider) => void;
  onModalClose?: () => void;
};

export type ActionSheetFlowContentProvider = (
  flowContentProps: ActionSheetFlowContentProps,
) => Partial<ActionSheetProps>;

type ActionSheetFlowProps = {
  readonly initialContent?: ActionSheetFlowContentProvider;
  readonly handleModalClose: () => void;
};

/**
 *
 * @deprecated please use ActionSheetV2 and setActiveActionSheet instead. See more details in the
 * readme here https://github.com/scriptdash/clients/tree/master/libs/design-system/src/components/action-sheet/README.md
 *
 */
export const DEPRECATED_ActionSheetFlow = ({ initialContent, handleModalClose }: ActionSheetFlowProps) => {
  const [contentProviderStack, setContentProviderStack] = useState<ActionSheetFlowContentProvider[]>([]);
  const fadeAnimationValue = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    if (initialContent && contentProviderStack.length === 0) {
      setContentProviderStack([...contentProviderStack, initialContent]);
    }
  }, [initialContent, contentProviderStack]);

  const fadeIn = () => {
    Animated.timing(fadeAnimationValue, {
      toValue: 1,
      duration: ANIMATION.SPEEDS.FAST,
      useNativeDriver: Constants.useNativeDriver,
    }).start();
  };

  const fadeOutCallback = (content?: ActionSheetFlowContentProvider) => {
    if (content) {
      setContentProviderStack([...contentProviderStack, content]);
    } else {
      setContentProviderStack(contentProviderStack.slice(0, contentProviderStack.length - 1));
    }
    fadeIn();
  };

  const transitionContent = (content?: ActionSheetFlowContentProvider) => {
    Animated.timing(fadeAnimationValue, {
      toValue: 0,
      duration: ANIMATION.SPEEDS.FAST,
      useNativeDriver: Constants.useNativeDriver,
    }).start(() => {
      fadeOutCallback(content);
    });
  };

  const onModalClose = () => {
    setContentProviderStack([]);
    handleModalClose();
  };

  const currentContentProvider =
    contentProviderStack.length > 0 && contentProviderStack[contentProviderStack.length - 1];
  const currentContent = currentContentProvider
    ? currentContentProvider({ transitionContent, onModalClose })
    : undefined;

  const actionSheetProps = {
    title: '',
    ...currentContent,
    isVisible: !!currentContent,
    fadeAnimationValue,
    handleModalClose: onModalClose,
  };

  if (currentContent && contentProviderStack.length > 1) {
    actionSheetProps.handleBackClick = () => {
      if (currentContent.handleBackClick) {
        currentContent.handleBackClick();
      }
      transitionContent();
    };
  }

  return <DEPRECATED_ActionSheet {...actionSheetProps} />;
};
