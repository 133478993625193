// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/v1/delivery_methods/delivery_method';
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  AltoSpinningLoader,
  Card,
  type DateData,
  Description,
  Divider,
  H2,
  InlineAlert,
  InputRadioGroup,
  LgPadding,
  ListItem,
  MdPadding,
  Row,
  SmSpacing,
  XsSpacing,
} from '@alto/design-system';
import { type ShipmentFeeType } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_type';
import {
  type FulfillmentOption,
  FulfillmentOptionTagMap,
  type FulfillmentWindowOption,
} from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import { useIsMutating } from '@tanstack/react-query';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { updateOrder } from '~shared/actions/cart';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { useCheckoutCourierTipping } from '~shared/features/checkout/hooks/useCheckoutCourierTipping';
import { useCheckoutDateErrors } from '~shared/features/checkout/hooks/useCheckoutDateErrors';
import { getEditShipmentID, getShowingValidationErrors } from '~shared/features/checkout/selectors/getCart';
import {
  getIsOrderPickupMethod,
  getOrder,
  getOrderAddressId,
  getOrderDate,
  getOrderDeliverAfter,
  getOrderDeliverBefore,
} from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import getSelectedPaymentTypes from '~shared/features/checkout/selectors/getSelectedPaymentTypes';
import { INITIAL_TIP_AMOUNT } from '~shared/features/courier-tips/constants';
import { getTipAmountWithDefault } from '~shared/features/courier-tips/helpers';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { formatDateStringForAPI, formatWindowTime } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { exitOrderBundling, initializeOrderBundling } from '~shared/reducers/ui/checkoutFlow';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { CREATE_ORDER_MUTATION_KEY, SelectDeliveryWindowActionSheet, useQueryFulfillmentOptions } from '../../../..';
import { getSelectedPriceTypesForPrescriptions } from '../../helpers/getSelectedPriceTypesForPrescriptions';
import { AddressInfo } from '../delivery-address-card/address-info/AddressInfo';
import { FeeRadioRightContent } from '../select-date-card/FeeRadioRightContent';
import { FeeDetails } from './FeeDetails';
import { FulfillmentOptionRadioOption } from './FulfillmentOptionRadioOption';
import { PickupInfo } from './PickupInfo';
import { SelectCustomDateActionSheet } from './SelectCustomDateActionSheet';
import { createCustomFulfillmentOption, getRadioDateOptions, initSelectedFulfillmentWindowOption } from './utils';

type Props = {
  readonly openGetItSoonerFlow: () => void;
};

export const SchedulingCard = ({ openGetItSoonerFlow }: Props) => {
  const dispatch = useDispatchShared();
  const { closeActionSheet, setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();

  // order selectors
  const orderDeliverAfter = useSelectorShared(getOrderDeliverAfter);
  const orderDate = useSelectorShared(getOrderDate);
  const order = useSelectorShared(getOrder);
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const orderDeliverBefore = useSelectorShared(getOrderDeliverBefore);
  const addressID = useSelectorShared(getOrderAddressId);

  // State for the selected date radio option
  const initialCheckedValue = isOrderPickupMethod && orderDate ? 'PICKUP-' + orderDate : orderDate;
  const [checkedValue, setCheckedValue] = useState<string | null>(initialCheckedValue || null);
  // State for any custom date chosen from "Select other date". Constructed only on the FE.
  const [customFulfillmentOption, setCustomFulfillmentOption] = useState<FulfillmentOption | undefined>(undefined);
  // State for the selected fulfillment window option.
  const [selectedFulfillmentWindowOption, setSelectedFulfillmentWindowOption] = useState<
    FulfillmentWindowOption | undefined
  >(initSelectedFulfillmentWindowOption({ orderDate, orderDeliverAfter, orderDeliverBefore, isAsap: order.is_asap }));

  // other selectors
  const saveOrderLoading = useSelectorShared((state) => state.ui.loading.saveOrderLoading);
  const isCreateOrderPending = !!useIsMutating({ mutationKey: CREATE_ORDER_MUTATION_KEY });
  const showingValidationErrors = useSelectorShared(getShowingValidationErrors);
  const { dateValidationErrors } = useCheckoutDateErrors();
  const { prescriptionIDs: cartPrescriptionIDs } = usePrescriptionsInCart();
  const cartSelectedPaymentTypes = useSelectorShared(getSelectedPaymentTypes);
  const selectedPrices = getSelectedPriceTypesForPrescriptions({
    prescriptionIDs: cartPrescriptionIDs,
    cartSelectedPaymentTypes,
  });
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const editShipmentId = useSelectorShared(getEditShipmentID);

  const { deliveryMethods } = useCartDeliveryMethods();
  const orderIndex = useSelectorShared(getOrderIndex);
  const deliveryMethod: DeliveryMethod | undefined = deliveryMethods[orderIndex];
  const isFirstShipment = orderIndex === 0;
  const { isEligibleForTipping: isTippable } = useCheckoutCourierTipping({ deliveryMethod });
  const tipAmount = isTippable ? getTipAmountWithDefault(order.tip_amount, INITIAL_TIP_AMOUNT) : undefined;

  const {
    courierDateFulfillmentOptions,
    courierAnyDateFulfillmentOption,
    pickupFulfillmentOptions,
    isPending: isFulfillmentOptionsLoading,
    isSuccess: isFulfillmentOptionsSuccess,
  } = useQueryFulfillmentOptions({
    addressID,
    cartPrescriptionIDs,
    selectedPrices,
    shipmentID: editShipmentId,
  });
  const ready_by_time = pickupFulfillmentOptions[0]?.pickup_data?.ready_by_time;
  const orderBundlingDates = courierDateFulfillmentOptions
    .filter((option) => option.tag === FulfillmentOptionTagMap.BUNDLED)
    .map((option) => option.date)
    .filter(TypescriptUtils.isPresent);

  const hasSchedulableDeliveryFeesInCart = [...courierDateFulfillmentOptions, courierAnyDateFulfillmentOption].some(
    (fulfillmentOption) => fulfillmentOption?.available_fees.some((fee) => fee.amount > 0),
  );

  const disableSelector = saveOrderLoading || isCreateOrderPending || isFulfillmentOptionsLoading;
  const showErrors = showingValidationErrors && dateValidationErrors?.length;
  const errorMessage = dateValidationErrors.map((e) => e.message).join('. ');

  useEffect(() => {
    if (!isFulfillmentOptionsSuccess) return;

    const asapFulfillmentOption = courierDateFulfillmentOptions.find((fulfillmentOption) => {
      return fulfillmentOption.available_fees.some((fee) => fee.fee_type === 'asap_delivery');
    });

    trackEvent({
      event: EVENTS.CHECKOUT_VIEWED_WITH_FULFILLMENT_OPTIONS,
      params: {
        origin: isEditingOrder ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT,
        shipment_id: isEditingOrder ? editShipmentId : null,
        asap_eligible: !!asapFulfillmentOption,
        // only populated when one courier window option for asap delivery
        asap_eta: asapFulfillmentOption?.fulfillment_window_option?.estimated_eta,
        pickup_eligible: pickupFulfillmentOptions.length > 0,
        pickup_eta: pickupFulfillmentOptions[0]?.pickup_data?.ready_by_time,
      },
    });
  }, [
    trackEvent,
    isFulfillmentOptionsSuccess,
    courierDateFulfillmentOptions,
    isEditingOrder,
    editShipmentId,
    pickupFulfillmentOptions,
  ]);

  const onMountRef = useRef(true);
  useEffect(() => {
    // this effect should only run when the address changes, not on mount
    // i.e: in edit order flow, without this, we would not preselect the order's date in InputRadioGroup
    if (onMountRef.current) {
      onMountRef.current = false;
      return;
    }

    // Whenever address changes, clear out any existing state selections
    // as they may not be applicable.
    setCustomFulfillmentOption(undefined);
    setSelectedFulfillmentWindowOption(undefined);
    setCheckedValue(null);
  }, [addressID]);

  // Whenever the checkedValue is a pickup value and the pickup_ready_time changes, update the pickup_ready_time in redux
  useEffect(() => {
    if (!checkedValue?.startsWith('PICKUP')) return;

    dispatch(updateOrder({ pickup_ready_time: ready_by_time }));
  }, [checkedValue, dispatch, ready_by_time]);

  // When entering the second of a split shipment order, set the selected window
  // based on the order date
  useEffect(() => {
    if (orderIndex > 0 && orderDate && orderDeliverAfter && orderDeliverBefore && !selectedFulfillmentWindowOption) {
      const window = initSelectedFulfillmentWindowOption({ orderDate, orderDeliverAfter, orderDeliverBefore });
      setSelectedFulfillmentWindowOption(window);
    }
  }, [orderDate, orderDeliverAfter, orderDeliverBefore, orderIndex, selectedFulfillmentWindowOption]);

  // On checkout initialization, if we have an existing order date and time,
  // and it's not in list of available options, create a custom option for it
  // to show the current order date as selected.
  useEffect(() => {
    if (isFulfillmentOptionsLoading || !isFulfillmentOptionsSuccess || !selectedFulfillmentWindowOption) return;
    if (!orderDate || (!orderDeliverBefore && !orderDeliverAfter)) return;

    const selectedOption = courierDateFulfillmentOptions.find((option) => option?.date === orderDate);
    if (selectedOption) return;

    // Get any applicable fees for any day case if the selected date is not in the list of available date options.
    const { available_fees, fee_amount_label, fee_original_amount_label } = courierAnyDateFulfillmentOption || {};

    const customFulfillmentOption = {
      ...createCustomFulfillmentOption(orderDate || '', selectedFulfillmentWindowOption),
      available_fees,
      fee_amount_label,
      fee_original_amount_label,
    } as FulfillmentOption;
    setCustomFulfillmentOption(customFulfillmentOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFulfillmentOptionsLoading, isFulfillmentOptionsSuccess, selectedFulfillmentWindowOption]);

  // if date options do not have fees, no order bundling option, and one window available,
  // auto-select the earliest date option when entering checkout and set the order date and window
  useEffect(() => {
    // If a date is already set, do not pre-select a date.
    if (checkedValue) return;

    const hasFees = courierDateFulfillmentOptions.some((option) => option.available_fees.length > 0);
    const hasOrderBundling = courierDateFulfillmentOptions.some((option) => option.tag === 'BUNDLED');
    if (hasFees || hasOrderBundling) return;

    const earliestOption = courierDateFulfillmentOptions[0];
    if (!earliestOption) return;
    // when there's only one window it will be populated in the fulfillment_window_option
    const { date, fulfillment_window_option: option } = earliestOption;
    if (!date) return;
    const formattedDate = formatDateStringForAPI(date);

    // If the delivery method is mail, default to the first date available.
    if (deliveryMethod?.mode === 'MAIL') {
      dispatch(updateOrder({ date: formattedDate, delivery_method: undefined }));
      setCheckedValue(formattedDate);
      return;
    }

    // Only pre-select if a window option exists for that date.
    if (!option?.delivery_window) return;

    const { start_time, end_time } = option.delivery_window;
    dispatch(updateOrder({ date: formattedDate }));
    dispatch(updateOrder({ deliver_after: start_time, deliver_before: end_time, delivery_method: undefined }));
    setCheckedValue(formattedDate);
  }, [courierDateFulfillmentOptions, dispatch, checkedValue, deliveryMethod?.mode]);

  const trackWindowSelected = useCallback(
    ({
      autoSelected,
      deliver_after,
      deliver_before,
      fee_type,
    }: {
      autoSelected?: boolean;
      deliver_after?: string;
      deliver_before?: string;
      fee_type?: ShipmentFeeType | null;
    }) => {
      trackEvent({
        event: EVENTS.CHECKOUT__WINDOW_SELECTED,
        params: {
          'auto-selected': autoSelected ?? false,
          origin: isEditingOrder ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT,
          deliver_after,
          deliver_before,
          fee_type,
        },
      });
    },
    [isEditingOrder, trackEvent],
  );

  const handleCustomDateDeliveryWindowSelection = useCallback(
    (deliveryDate: string, deliveryWindow: FulfillmentWindowOption) => {
      const customFulfillmentOption = createCustomFulfillmentOption(deliveryDate, deliveryWindow);
      setCustomFulfillmentOption(customFulfillmentOption);
      setCheckedValue(formatDateStringForAPI(deliveryDate));
      setSelectedFulfillmentWindowOption(deliveryWindow);

      const deliver_after = deliveryWindow.delivery_window?.start_time;
      const deliver_before = deliveryWindow.delivery_window?.end_time;
      const isAsap = deliveryWindow.available_fee?.fee_type === 'asap_delivery';

      trackWindowSelected({ deliver_after, deliver_before, fee_type: deliveryWindow.available_fee?.fee_type });

      // The getShouldUpdateCart reactor clears out deliver_after and deliver_before if the window isn't recognized, which it won't
      // be because windows aren't loaded into the store until after the date has been set. We can avoid this by setting the date first.
      dispatch(
        updateOrder({
          deliver_after,
          deliver_before,
          delivery_method: deliveryMethod?.name || null,
          is_asap: isAsap,
        }),
      );
      dispatch(updateOrder({ date: deliveryDate, tip_amount: tipAmount }));
      // If the user selects any other date, then it cannot be an order bundling
      // option, so we clear any previous order bundling state
      dispatch(exitOrderBundling());
      closeActionSheet();
    },
    [closeActionSheet, deliveryMethod?.name, dispatch, trackWindowSelected, tipAmount],
  );

  const handleCustomDateSelection = useCallback(
    (date: DateData) => {
      const deliveryDate = formatDateStringForAPI(new Date(date.timestamp).toISOString());

      // If delivery method is not courier, then delivery windows are not
      // applicable. Set order date and do not show window selection modal.
      if (deliveryMethod?.mode !== 'COURIER') {
        closeActionSheet();
        const customFulfillmentOption = createCustomFulfillmentOption(deliveryDate);
        setCustomFulfillmentOption(customFulfillmentOption);
        setCheckedValue(deliveryDate);
        dispatch(updateOrder({ date: deliveryDate, delivery_method: undefined }));
        return;
      }

      setActiveActionSheet(
        <SelectDeliveryWindowActionSheet
          deliveryDate={deliveryDate}
          selectedWindowOption={selectedFulfillmentWindowOption}
          handleCustomDateDeliveryWindowSelection={handleCustomDateDeliveryWindowSelection}
        />,
      );
    },
    [
      closeActionSheet,
      deliveryMethod,
      dispatch,
      handleCustomDateDeliveryWindowSelection,
      setActiveActionSheet,
      selectedFulfillmentWindowOption,
    ],
  );

  const handleStandardDeliveryWindowSelection = useCallback(
    (deliveryWindow: FulfillmentWindowOption) => {
      const isAsap = deliveryWindow?.available_fee?.fee_type === 'asap_delivery';
      let deliver_after: string | undefined;
      let deliver_before: string | undefined;
      if (isAsap) {
        deliver_after = formatWindowTime({
          windowTime: new Date(),
          meridiem: 'a',
          forceReturnMinutes: true,
          useUTC: false,
          separator: ' ',
        });
        deliver_before = formatWindowTime({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          windowTime: deliveryWindow.estimated_eta!,
          meridiem: 'a',
          forceReturnMinutes: true,
          useUTC: false,
          separator: ' ',
        });
      } else {
        deliver_after = deliveryWindow.delivery_window?.start_time;
        deliver_before = deliveryWindow.delivery_window?.end_time;
      }

      trackWindowSelected({ deliver_after, deliver_before, fee_type: deliveryWindow.available_fee?.fee_type });

      // TODO: consolidate this logic with handleCustomDateDeliveryWindowSelection
      setCustomFulfillmentOption(undefined);
      setSelectedFulfillmentWindowOption(deliveryWindow);
      setCheckedValue(formatDateStringForAPI(deliveryWindow.date));
      dispatch(updateOrder({ date: formatDateStringForAPI(deliveryWindow.date), is_asap: isAsap }));
      dispatch(
        updateOrder({
          deliver_before,
          deliver_after,
          delivery_method: deliveryMethod?.name || null,
        }),
      );
      dispatch(updateOrder({ tip_amount: tipAmount }));
      closeActionSheet();
    },
    [closeActionSheet, deliveryMethod?.name, dispatch, tipAmount, trackWindowSelected],
  );

  const setSelectedDateWithoutWindow = (dateKey: string) => {
    setSelectedFulfillmentWindowOption(undefined);
    setCustomFulfillmentOption(undefined);
    setCheckedValue(dateKey);
  };

  const selectPickUpOption = useCallback(
    (dateKey: string) => {
      const selectedDate = dateKey.replace('PICKUP-', '');
      const selectedOption = pickupFulfillmentOptions[0];

      trackEvent({
        event: EVENTS.CHECKOUT__PICKUP_SELECTED,
        params: {
          origin: isEditingOrder ? ORIGIN_NAMES.EDIT_DELIVERY : ORIGIN_NAMES.CHECKOUT,
          ready_by_time: selectedOption?.pickup_data?.ready_by_time,
        },
      });
      setSelectedDateWithoutWindow(dateKey);
      dispatch(
        updateOrder({
          date: selectedDate,
          tip_amount: undefined,
          delivery_method: 'pickup',
          deliver_after: undefined,
          deliver_before: undefined,
          pickup_address: pickupFulfillmentOptions[0]?.pickup_data?.facility_address,
          pickup_ready_time: pickupFulfillmentOptions[0]?.pickup_data?.ready_by_time,
        }),
      );
    },
    [dispatch, isEditingOrder, pickupFulfillmentOptions, trackEvent],
  );

  const selectBundledOption = useCallback(
    (dateKey: string, selectedOption: FulfillmentOption) => {
      const selectedDate = dateKey.replace(`-${FulfillmentOptionTagMap.BUNDLED}`, '');
      setSelectedDateWithoutWindow(dateKey);
      dispatch(initializeOrderBundling());

      const { start_time, end_time } = selectedOption?.fulfillment_window_option?.delivery_window ?? {};
      dispatch(
        updateOrder({
          date: selectedDate,
          deliver_after: start_time,
          deliver_before: end_time,
          tip_amount: undefined,
          delivery_method: deliveryMethod?.name || null,
        }),
      );
    },
    [deliveryMethod?.name, dispatch],
  );

  const handleRadioPress = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (dateKey: string) => {
      // It is possible to have multiple options with the same date if one of them is
      // bundled and the other is not. To account for this we structure the date key
      // to be MM-DD-YYYY and append -${tag} if a tag (like bundled) is applicable.
      const selectedDate = dateKey;

      if (order.deliver_after && order.deliver_before) {
        // Clear delivery method if user selects a non-pickup option.
        // BE will determine the default delivery method for non-pickups
        dispatch(updateOrder({ delivery_method: undefined }));
      }

      const selectedOption = [...courierDateFulfillmentOptions, customFulfillmentOption].find((option) => {
        const tagSuffix = option?.tag ? `-${option.tag}` : '';
        return `${option?.date}${tagSuffix}` === dateKey;
      });

      const isBundledOption = dateKey.includes(FulfillmentOptionTagMap.BUNDLED);
      if (isBundledOption && selectedOption) {
        selectBundledOption(dateKey, selectedOption);
        return;
      } else {
        // Exit order bundling state if user selected a non bundled option.
        dispatch(exitOrderBundling());
      }

      const isPickupOption = dateKey.startsWith('PICKUP');
      if (isPickupOption) {
        selectPickUpOption(dateKey);
        return;
      }

      // If delivery method is not courier, then delivery windows are not
      // applicable. Set order date and do not show window selection modal.
      if (deliveryMethod?.mode !== 'COURIER') {
        if (selectedDate !== customFulfillmentOption?.date) {
          setCustomFulfillmentOption(undefined);
        }
        setSelectedFulfillmentWindowOption(undefined);
        setCheckedValue(dateKey);
        dispatch(updateOrder({ date: selectedDate, delivery_method: undefined }));

        return;
      }

      // If there is only a single fulfillment option for the date auto-select it
      if (selectedOption?.fulfillment_window_option?.delivery_window) {
        setSelectedFulfillmentWindowOption(undefined);
        const { delivery_window: window, available_fee } = selectedOption.fulfillment_window_option;
        const isAsap = available_fee?.fee_type === 'asap_delivery';
        setCheckedValue(dateKey);

        if (selectedDate !== customFulfillmentOption?.date) {
          setCustomFulfillmentOption(undefined);
        }

        trackWindowSelected({
          autoSelected: true,
          deliver_after: window.start_time,
          deliver_before: window.end_time,
          fee_type: available_fee?.fee_type,
        });

        dispatch(updateOrder({ date: selectedDate, is_asap: isAsap }));
        dispatch(
          updateOrder({
            deliver_after: window.start_time,
            deliver_before: window.end_time,
            delivery_method: deliveryMethod?.name || null,
          }),
        );

        // Only clear bundling once a date has been selected
        if (selectedOption.tag !== 'BUNDLED') {
          dispatch(exitOrderBundling());
        }
        return;
      }

      // If not bundled and not pickup, show the action sheet to select a delivery window from the selected date.
      if (!isBundledOption && !isPickupOption) {
        setActiveActionSheet(
          <SelectDeliveryWindowActionSheet
            deliveryDate={selectedDate}
            selectedWindowOption={selectedFulfillmentWindowOption}
            handleDeliveryWindowSelection={handleStandardDeliveryWindowSelection}
          />,
        );
      }
    },
    [
      courierDateFulfillmentOptions,
      customFulfillmentOption,
      deliveryMethod?.mode,
      deliveryMethod?.name,
      dispatch,
      handleStandardDeliveryWindowSelection,
      order.deliver_after,
      order.deliver_before,
      selectBundledOption,
      selectPickUpOption,
      selectedFulfillmentWindowOption,
      setActiveActionSheet,
      trackWindowSelected,
    ],
  );

  if (isFulfillmentOptionsLoading) {
    return (
      <Card
        title="Schedule delivery"
        overflow="hidden"
      >
        <Row centerHorizontally>
          <LgPadding topPadding={SPACING.STATIC.NONE}>
            <AltoSpinningLoader />
          </LgPadding>
        </Row>
      </Card>
    );
  }

  const radioDateOptions = getRadioDateOptions({
    courierDateFulfillmentOptions,
    customFulfillmentOption,
    isSecondSplitShipment: orderIndex > 0,
    selectedDate: orderDate,
  });

  return (
    <Card
      title="Schedule delivery"
      overflow="hidden"
      RightContent={hasSchedulableDeliveryFeesInCart ? <FeeDetails /> : undefined}
    >
      <InputRadioGroup
        value={checkedValue}
        onValueChange={handleRadioPress}
        disabled={disableSelector}
      >
        {radioDateOptions.map((fulfillmentOption) => {
          return (
            <FulfillmentOptionRadioOption
              key={`radio-${fulfillmentOption.date}-${fulfillmentOption.tag}-${fulfillmentOption.fee_amount_label}`}
              fulfillmentOption={fulfillmentOption}
              disableSelector={disableSelector}
              loading={isFulfillmentOptionsLoading}
              selectedWindowOption={selectedFulfillmentWindowOption}
              deliveryMethod={deliveryMethod}
              checkedValue={checkedValue}
            />
          );
        })}
      </InputRadioGroup>
      {/* We only show the Select other date option when it is a single shipment
        or the first shipment in a split shipment order. If it is the second shipment
        then the user can only set the delivery window of the selected date. */}
      {courierAnyDateFulfillmentOption && isFirstShipment ? (
        <React.Fragment key={'any-day-option'}>
          <ListItem
            LeftContent={<AltoIcon name="calendar" />}
            onPress={() => {
              setActiveActionSheet(
                <SelectCustomDateActionSheet
                  minDate={courierAnyDateFulfillmentOption.date || ''}
                  orderBundlingDates={orderBundlingDates}
                  handleDateSelection={handleCustomDateSelection}
                  openGetItSoonerFlow={openGetItSoonerFlow}
                />,
              );
            }}
            RightContent={
              <FeeRadioRightContent
                feeAmountLabel={courierAnyDateFulfillmentOption.fee_amount_label || undefined}
                feeOriginalAmountLabel={courierAnyDateFulfillmentOption.fee_original_amount_label || undefined}
                loading={isFulfillmentOptionsLoading}
                showIconOpenCalendar
              />
            }
            title={courierAnyDateFulfillmentOption.label}
            testID={'any-day-delivery'}
          />
        </React.Fragment>
      ) : null}
      <AddressInfo topPadding={SPACING.STATIC.MD} />
      {showErrors ? (
        <MdPadding>
          <XsSpacing />
          <InlineAlert type="error">
            <Description>{errorMessage}</Description>
          </InlineAlert>
        </MdPadding>
      ) : null}
      {pickupFulfillmentOptions.length > 0 ? (
        <>
          <Divider />
          <LgPadding
            topPadding={SPACING.STATIC.MD}
            bottomPadding={SPACING.STATIC.MD}
          >
            <H2>Pick up at Alto</H2>
          </LgPadding>
          <InputRadioGroup
            value={checkedValue}
            onValueChange={handleRadioPress}
            disabled={disableSelector}
          >
            {pickupFulfillmentOptions.map((fulfillmentOption) => {
              return (
                <FulfillmentOptionRadioOption
                  key={`radio-${fulfillmentOption.date}-${fulfillmentOption.tag}-${fulfillmentOption.fee_amount_label}`}
                  fulfillmentOption={fulfillmentOption}
                  disableSelector={disableSelector}
                  loading={isFulfillmentOptionsLoading}
                />
              );
            })}
            {checkedValue?.startsWith('PICKUP') ? (
              <PickupInfo
                pickupData={
                  pickupFulfillmentOptions.find((pickupOption) => checkedValue === `PICKUP-${pickupOption.date}`)
                    ?.pickup_data
                }
              />
            ) : null}
          </InputRadioGroup>
          <SmSpacing />
        </>
      ) : null}
    </Card>
  );
};
