// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ListDescription, ListItem, MdSpacing, PresentationListItem } from '@alto/design-system';
import { MedicationImage } from '@alto/features';
import { type ProposedShipment } from '@alto/scriptdash/alto/med_sync/types/patients/v1/proposed_shipment';
import React from 'react';
import { formatRelativeDateWithDow, normalizeDateString } from '~shared/helpers/date';

type Props = {
  readonly proposedShipment: ProposedShipment;
};

export const MedSyncSelfServiceFirstSyncedShipment = ({ proposedShipment }: Props) => {
  return (
    <>
      <PresentationListItem
        iconName="calendar"
        // eslint-disable-next-line @typescript-eslint/no-useless-template-literals
        text={`${formatRelativeDateWithDow(normalizeDateString(proposedShipment.date) || '')}`}
        backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}
      />
      <PresentationListItem
        iconName="sync"
        text="Auto-refill (recurring)"
        backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}
      />
      {proposedShipment.proposed_deliveries?.map((proposedDelivery) => (
        <ListItem
          key={proposedDelivery.medication_name}
          title={proposedDelivery.medication_name}
          descriptions={[
            <ListDescription
              iconName="pills-small"
              key={proposedDelivery.days_supply}
            >{`${proposedDelivery.days_supply} day supply`}</ListDescription>,
          ]}
          LeftContent={<MedicationImage imageUrl={proposedDelivery?.product_image_url} />}
        />
      ))}
      <MdSpacing />
    </>
  );
};
