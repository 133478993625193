// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Button, InputRadio, InputRadioGroup } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Modal, ModalContent, ModalFooter, Text } from '@alto/pocky';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { createPatientDiagnosis } from '~shared/helpers/patientDiagnoses';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared } from '~shared/store';

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
  }
`;

export type Props = {
  readonly onClose: () => void;
  readonly onSave: () => void;
};

export const MounjaroModal = ({ onClose, onSave }: Props) => {
  const dispatch = useDispatchShared();
  const [hasType2Diabetes, setHasType2Diabetes] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      sendAnalyticsEvent(
        createEvent(EVENTS.MEDICAL_CONDITIONS_PROMPTED, {
          condition: 'type 2 diabetes',
          origin: 'add to cart',
        }),
      ),
    );
  }, [dispatch]);

  const handleDismiss = () => {
    dispatch(
      sendAnalyticsEvent(
        createEvent(EVENTS.MEDICAL_CONDITIONS_DISMISSED, {
          condition: 'type 2 diabetes',
          origin: 'add to cart',
        }),
      ),
    );
    onClose();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    dispatch(
      sendAnalyticsEvent(
        createEvent(EVENTS.MEDICAL_CONDITIONS_SAVED, {
          added: hasType2Diabetes,
          condition: 'type 2 diabetes',
          origin: 'add to cart',
        }),
      ),
    );
    if (hasType2Diabetes) {
      await createPatientDiagnosis({ params: { code: 'E11', notes: 'Type 2 diabetes mellitus' } });
    }
    onSave(); // running all the add to cart stuff
    setIsLoading(false);
    onClose();
  };

  return (
    <StyledModal
      dismissOnOutsideClick
      showDismiss
      onClose={handleDismiss}
      title="Do you have type 2 diabetes?"
    >
      <ModalContent textAlignment="left">
        <Column spacing={SPACING.STATIC.MD.rem}>
          <Text>
            The manufacturer is updating their discount program for Mounjaro. Tell us your diabetes diagnosis below to
            qualify for the best price.
          </Text>
          <InputRadioGroup
            onValueChange={(value) => {
              if (value === 'yes') {
                setHasType2Diabetes(true);
              } else {
                setHasType2Diabetes(false);
              }
            }}
            label="Do you have type 2 diabetes?"
          >
            <InputRadio
              key="yes"
              label="Yes"
              value="yes"
            />
            <InputRadio
              key="no"
              label="No"
              value="no"
            />
          </InputRadioGroup>
        </Column>
      </ModalContent>
      <ModalFooter>
        <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleSubmit}
          label="Save"
          disabled={hasType2Diabetes === null}
          loading={isLoading}
        />
      </ModalFooter>
    </StyledModal>
  );
};
