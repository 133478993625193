// @owners { team: patients-team }
import { ActionSheetContext, Toast, ToastContext } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { SentryTransactionHub } from '@alto/performance';
import { CartEndpoint, type CartEndpointAddItemParams } from '@alto/scriptdash/alto/patient_app/carts/v1/cart_endpoint';
import { type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useState } from 'react';
import {
  addToCart__DEPRECATED,
  fetchWindows,
  // eslint-disable-next-line import/no-deprecated
  selectPaymentType__DEPRECATED,
  // eslint-disable-next-line import/no-deprecated
  updateCartAutoRefill__DEPRECATED,
  updateOrder,
} from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
// eslint-disable-next-line import/no-deprecated
import { type BillingOverridePayload, autobillPrescription } from '~shared/actions/prescriptions';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing } from '~shared/actions/pricing';
import { transformPriceType } from '~shared/features/cart/selectors/getReduxCart';
import { type ItemKey } from '~shared/features/checkout/types';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { getPrescriptionIDsWithCashLessThanInsurancePricingScenarios } from '~shared/features/pricing/selectors/getPrescriptionIDsWithCashLessThanInsurancePricingScenarios';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useAnalytics } from '~shared/hooks';
import { eventsGenerated } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getOrigin from '~shared/lib/analytics/src/getOrigin';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useInvalidatePrescriptions, useQueryShipmentFeesAvailable } from '~shared/queries';
import { queries } from '~shared/queries/query-keys';
import { useDispatchShared, useSelectorShared } from '~shared/store';

const genericErrorMessage = 'There was an error adding to your cart. Please try again later.';
const cartEndpoint = CartEndpoint(apiEndpointHandler);

export type AddToCartParams = {
  resource_id: number;
  resource_type: string;
  quantity?: number | null | undefined;
  auto_refill_enabled?: boolean | null | undefined;
  resetOrderDate?: boolean;
  billing_overrides?: BillingOverridePayload;
};

type AddToCartResult = {
  success: boolean;
};

type UseAddToCart = {
  isDisabled: boolean;
  isPending: boolean;
  isSuccess: boolean;
  handleAddToCart: (params: AddToCartParams) => Promise<AddToCartResult>;
};

type RxProperties = {
  availableForNextDeliveryWindow: boolean | null | undefined;
  delayedAvailabilityReason: string | null | undefined;
  id: number | null | undefined;
  nextAvailableDateFromNow: string | null | undefined;
};

/**
 * Hook used to add an item to the patient's cart. The cart will be created if
 * it doesn't yet exist.
 *
 * // export the errorMessage to render in your component
 * const { handleAddToCart, errorMessage } = useAddToCart();
 *
 * const result = await handleAddToCart({ item, onSuccess: onAddToCartSuccess });
 *
 * // perform logic after the item is successfully added to cart
 * if (result.success) {
 *   ...
 * }
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useAddToCart = (): UseAddToCart => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const cashIsLessThanInsurancePrescriptionIDs = useSelectorShared(
    getPrescriptionIDsWithCashLessThanInsurancePricingScenarios,
  );
  const { addToast } = useContext(ToastContext);
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { value: serverSideCartEnabled } = Experimentation.useFeatureFlag('server_side_cart');
  const { invalidatePrescriptionsQuery } = useInvalidatePrescriptions();
  const { nextAvailableDatesByPrescriptionID, isLoading: isLoadingNextAvailableDates } =
    useNextAvailableDatesForPrescriptions();
  const origin = getOrigin();
  const [isSuccess, setIsSuccess] = useState(false);
  useQueryShipmentFeesAvailable();

  // disable add to cart buttons while other requests are in flight so delivery status settles
  // a scheduleable prescription can be removed from the cart if the delivery status is processing
  const isFetching = useSelectorShared((state) => {
    const {
      autobillPrescriptionLoadingByID,
      fetchActiveDeliveriesLoading,
      fetchDeliveriesLoading,
      fetchActivePrescriptionsLoading,
      fetchPrescriptionsLoading,
      fetchNextAvailableDatesLoading,
      fetchOrderPricingLoading,
    } = state.ui.loading;
    return (
      fetchOrderPricingLoading ||
      fetchNextAvailableDatesLoading ||
      fetchActiveDeliveriesLoading ||
      fetchDeliveriesLoading ||
      fetchActivePrescriptionsLoading ||
      fetchPrescriptionsLoading ||
      Object.values(autobillPrescriptionLoadingByID).some(Boolean)
    );
  });

  const fireCartItemAddedEvent = useCallback(
    ({
      resource_id,
      auto_refill_enabled,
      selected_price_type,
    }: {
      resource_id: AddToCartParams['resource_id'];
      auto_refill_enabled: AddToCartParams['auto_refill_enabled'];
      selected_price_type: PriceType | null | undefined;
    }) => {
      const rxProperties: RxProperties = {
        availableForNextDeliveryWindow: null,
        delayedAvailabilityReason: null,
        id: null,
        nextAvailableDateFromNow: null,
      };
      const nextAvailableDate = isLoadingNextAvailableDates ? null : nextAvailableDatesByPrescriptionID[resource_id];
      if (nextAvailableDate) {
        rxProperties.nextAvailableDateFromNow = nextAvailableDate.earliest.date;
        rxProperties.availableForNextDeliveryWindow = nextAvailableDate.earliest.reason === 'available';
        rxProperties.delayedAvailabilityReason = nextAvailableDate.earliest.reason;
      }

      const orderResult = auto_refill_enabled ? 'auto refill' : 'one-time fill';
      const event = createEvent(EVENTS.CART__ITEM_ADDED, {
        origin,
        'next available date from now': rxProperties.nextAvailableDateFromNow,
        'available for next delivery window': rxProperties.availableForNextDeliveryWindow,
        'delayed availability reason': rxProperties.delayedAvailabilityReason,
        'order type': orderResult,
        'price type': selected_price_type ? selected_price_type : 'not selected',
      });
      event.prescriptionId = resource_id;
      dispatch(eventsGenerated([event]));
    },
    [dispatch, nextAvailableDatesByPrescriptionID, origin, isLoadingNextAvailableDates],
  );

  const autobillAndRefreshData = useCallback(
    async ({ resource_id, resource_type, resetOrderDate, billing_overrides }: AddToCartParams) => {
      if (resource_type === 'Prescription') {
        // eslint-disable-next-line import/no-deprecated
        await dispatch(autobillPrescription(resource_id, billing_overrides));
        // eslint-disable-next-line import/no-deprecated
        await dispatch(fetchOrderPricing());

        // The correct order is to refresh deliveries after autobill, before refreshing prescriptions.
        // Why? Because refreshing prescriptions includes logic to remove any prescriptions from cart that aren't
        // ready to schedule. (see removeUnschedulablePrescriptionsFromCart).
        // A delivery will appear not ready to schedule if it's refreshed during autobill and not after.
        // eslint-disable-next-line import/no-deprecated
        await dispatch(fetchDeliveries());
        invalidatePrescriptionsQuery();

        // eslint-disable-next-line import/no-deprecated
        await dispatch(fetchNextAvailableDates({ unbundledPrescriptionIDs: [resource_id] }));
        if (resetOrderDate) {
          // resetOrderDate is passed in for flows that are likely to update the next available date to an
          // earlier date. This is currently used for 'Get it sooner' flows, where patients can choose
          // different payment options as a way to get their medication sooner.
          // In these scenarios, we should reset the order date and have select date component
          // calculate the order date based on the new next available date
          dispatch(updateOrder({ date: undefined }));
        }
      }

      dispatch(fetchWindows());
    },
    [dispatch, invalidatePrescriptionsQuery],
  );

  const addToCartRedux = useCallback(
    async (params: AddToCartParams) => {
      setIsSuccess(false);
      const { resource_id, auto_refill_enabled, resetOrderDate, billing_overrides } = params;
      const item: ItemKey = { resource_id, resource_type: 'Prescription' };
      dispatch(addToCart__DEPRECATED(item, resetOrderDate, billing_overrides, true));
      if (auto_refill_enabled !== null && auto_refill_enabled !== undefined) {
        // eslint-disable-next-line import/no-deprecated
        dispatch(updateCartAutoRefill__DEPRECATED(resource_id, auto_refill_enabled));
        trackEvent({
          event: EVENTS.AUTO_REFILL_UI_STATUS_CHANGED,
          params: {
            origin,
            auto_refill_status: auto_refill_enabled ? 'auto refill' : 'one-time fill',
          },
          additionalFields: {
            prescriptionId: resource_id,
          },
        });
      }

      // If we're adding to cart and we're in the c < i scenario, this means that there is no pricing option selected
      // and we should default to the cash option which is 'without_insurance'
      let selected_price_type: PriceType | undefined;
      if (cashIsLessThanInsurancePrescriptionIDs.includes(resource_id)) {
        // eslint-disable-next-line import/no-deprecated
        dispatch(selectPaymentType__DEPRECATED(resource_id, 'without_insurance'));
        selected_price_type = 'without_insurance';
      }

      // close the action sheet before doing data invalidation
      closeActionSheet();

      fireCartItemAddedEvent({
        resource_id,
        auto_refill_enabled,
        selected_price_type,
      });
      try {
        await autobillAndRefreshData(params);
        setIsSuccess(true);
        return { success: true };
      } catch (e) {
        addToast(<Toast variant="error">{genericErrorMessage}</Toast>);
        return { success: false };
      } finally {
        // finish any add-to-cart transactions if they're active
        SentryTransactionHub.finishTransaction(SentryTransactionHub.operations.addToCartFromHomescreen);
      }
    },
    [
      addToast,
      autobillAndRefreshData,
      closeActionSheet,
      dispatch,
      fireCartItemAddedEvent,
      origin,
      trackEvent,
      cashIsLessThanInsurancePrescriptionIDs,
    ],
  );

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async (params: CartEndpointAddItemParams) => {
      setIsSuccess(false);
      const response = await cartEndpoint.addItem({ params });
      return { response };
    },
  });

  const addToCartApi = useCallback(
    async (params: AddToCartParams) => {
      try {
        const quantity = params.quantity || 1;
        const auto_refill_enabled = params.auto_refill_enabled || false;
        // If we're adding to cart and we're in the c < i scenario, this means that there is no pricing option selected
        // and we should default to the cash option which is 'without_insurance'
        let selected_price_type: PriceType | undefined;
        if (cashIsLessThanInsurancePrescriptionIDs.includes(params.resource_id)) {
          selected_price_type = 'without_insurance';
        }
        const requestParams: CartEndpointAddItemParams = {
          resource_id: params.resource_id,
          resource_type: params.resource_type,
          quantity,
          auto_refill_enabled,
          selected_price_type: transformPriceType(selected_price_type),
        };
        const { response } = await mutateAsync(requestParams);
        if (response.errors?.length) {
          addToast(<Toast variant="error">{genericErrorMessage}</Toast>);
          return { success: false };
        }

        // close the action sheet before doing data invalidation
        closeActionSheet();

        queryClient.invalidateQueries({ queryKey: queries.cart.active({}).queryKey });

        // trigger the cart middleware
        fireCartItemAddedEvent({
          resource_id: params.resource_id,
          auto_refill_enabled,
          selected_price_type,
        });
        await autobillAndRefreshData(params);
        setIsSuccess(true);
        return { success: true };
      } catch (e) {
        addToast(<Toast variant="error">{genericErrorMessage}</Toast>);
        return { success: false };
      } finally {
        // finish any add-to-cart transactions if they're active
        SentryTransactionHub.finishTransaction(SentryTransactionHub.operations.addToCartFromHomescreen);
      }
    },
    [
      addToast,
      autobillAndRefreshData,
      closeActionSheet,
      fireCartItemAddedEvent,
      mutateAsync,
      queryClient,
      cashIsLessThanInsurancePrescriptionIDs,
    ],
  );

  if (serverSideCartEnabled) {
    return {
      isSuccess,
      isPending,
      isDisabled: isPending || isFetching,
      handleAddToCart: addToCartApi,
    };
  }

  return {
    isSuccess,
    isPending: serverSideCartEnabled === undefined,
    isDisabled: isFetching,
    handleAddToCart: addToCartRedux,
  };
};
