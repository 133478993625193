// @owners { team: patients-team }
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React from 'react';
import { HOME_TO_SIGN_CONFIRMATION_MAP } from '~shared/features/checkout/constants';
import { getInstructions } from '~shared/features/delivery-info/helpers/getShipmentWithItemsRowProps';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import { type Address } from '~shared/types';

type Props = {
  readonly shipment: ShipmentWithItems;
  readonly address?: Address;
  readonly status?: APIOrderStatus;
};

export const DeliveryInstructionsRow = ({ shipment, address, status }: Props) => {
  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');

  const homeToSignConfirmation = shipment?.home_to_sign_confirmation
    ? HOME_TO_SIGN_CONFIRMATION_MAP[shipment.home_to_sign_confirmation]
    : null;

  const instructions = getInstructions({
    address: address || shipment.address,
    homeToSignConfirmation,
    items: shipment.items,
    method: shipment.method,
    status: status || '',
    use3000Threshold: useNewThreshold,
  });

  if (!instructions || shipment.is_pickup) return null;

  return (
    <PresentationListItem
      LeftContent={
        <AltoIcon
          name={instructions.iconName}
          type="grey"
        />
      }
      text={instructions.text}
      type={instructions.type}
      backgroundColor={instructions.backgroundColor}
    />
  );
};
