// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoSpinningLoader, Body, Column, Row, SmSpacing } from '@alto/design-system';
import { ORDER_TYPE_CONTEXT, OrderTypeSelectionSection, useFetchAncillariesByPrescriptionIds } from '@alto/features';
import { faSnowflake } from '@fortawesome/pro-light-svg-icons/faSnowflake';
import { faSyringe } from '@fortawesome/pro-light-svg-icons/faSyringe';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { type Item } from '~shared/features/checkout/types';
import { UPCOMING_STATUSES } from '~shared/features/shipments/constants';
import { formatQuantity } from '~shared/helpers/helper';
import { type LightDelivery, type Prescription, type Shipment } from '~shared/types';
import { RemoveItemButton } from './RemoveItemButton';
import EditOtcRow from '~web/features/deliveries/components/EditOtcRow';
import UserTag from '~web/features/family-accounts/components/UserTagContainer';
import { Ancillaries } from '~web/features/my-meds/components/med-list/ancillaries/Ancillaries';

type Props = {
  readonly items: Item[];
  readonly shipmentIsEditable: boolean;
  readonly priceLoading: boolean;
  readonly shipment: Shipment;
};

const ItemsContainer = styled(Column)`
  border-top: 1px solid ${COLORS.BORDER_COLORS.LIGHT};
`;

const ItemContainer = styled(Column)`
  border-bottom: 1px solid ${COLORS.BORDER_COLORS.LIGHT};
  padding: ${SPACING.STATIC.XS} 0px;
`;
styled(Row).attrs({
  spaceEvenly: true,
})`
  padding: ${SPACING.STATIC.XS} 0px;
  border-bottom: 1px solid ${COLORS.BORDER_COLORS.LIGHT};
`;

const PaddedIcon = styled(Icon).attrs({
  color: COLORS.BORDER_COLORS.DARK,
  size: 'sm',
})`
  margin-left: ${SPACING.STATIC.XS};
`;

const DeliveryItems = ({ items, shipmentIsEditable, priceLoading, shipment }: Props) => {
  const { ancillaries } = useFetchAncillariesByPrescriptionIds({
    prescriptionIds: items.map((i) => i.prescription?.id),
    hasAncillaryItems: items.some((i) => i.hasAncillaryItems),
  });
  const getPrescriptionInfo = (prescription: Prescription | undefined, delivery: LightDelivery | undefined) => {
    if (!prescription) {
      return '';
    }

    const quantity = formatQuantity(prescription, delivery);
    const rxNumber = `Rx${prescription.rx_number}`;

    if (quantity === '') {
      return rxNumber;
    }

    return `${quantity} • ${rxNumber}`;
  };

  const isOnlyOneMedication = items.length === 1;

  const canChangeAutoRefillStatus = (prescription: Prescription | undefined, delivery: LightDelivery | undefined) => {
    return (
      [...UPCOMING_STATUSES, 'upcoming'].includes(status) &&
      !!prescription?.auto_refill_eligible &&
      !delivery?.med_sync_status
    );
  };

  return (
    <ItemsContainer>
      {items.map((item, index) => (
        <ItemContainer key={`${item.resource_type}-${item.resource_id}`}>
          <Row
            spaceBetween
            key={item.prescription?.id}
          >
            <Column>
              <Row>
                <Body>
                  {item.displayName()}
                  {item.isRefrigerated ? (
                    <PaddedIcon
                      icon={faSnowflake}
                      aria-label="Medication requires refrigeration"
                    />
                  ) : null}
                  {item.hasAncillaryItems ? (
                    <PaddedIcon
                      icon={faSyringe}
                      aria-label="Medication comes with additional items"
                    />
                  ) : null}
                </Body>
              </Row>
              <>
                <Row>
                  <Body>{getPrescriptionInfo(item.prescription, item.delivery)}</Body>
                </Row>
                {!canChangeAutoRefillStatus(item.prescription, item.delivery) &&
                item.delivery?.med_sync_status === 'short_fill' ? (
                  <Body>Partial fill ({item.delivery.days_supply} day supply)</Body>
                ) : null}
              </>
              <UserTag
                small
                userIDs={[item.userID]}
              />
            </Column>
            <Column>
              <Row right>{priceLoading ? <AltoSpinningLoader /> : <Body>{item.paymentString()}</Body>}</Row>
            </Column>
          </Row>
          <Row centerVertically>
            {item.prescription?.auto_refill_eligible ? (
              <OrderTypeSelectionSection
                orderTypeContext={ORDER_TYPE_CONTEXT.POST_CHECKOUT}
                prescriptionID={item.prescription.id}
                isEditable={shipmentIsEditable}
                initialAutoRefillEnabledValue={!!item.prescription.auto_refill}
              />
            ) : null}
            {item.prescription?.is_add_on_otc && item.prescription?.product_id ? (
              <EditOtcRow
                productID={item.prescription.product_id}
                shipment={shipment}
              />
            ) : null}
          </Row>
          {item.hasAncillaryItems ? <Ancillaries ancillaries={ancillaries} /> : null}
          {!isOnlyOneMedication ? (
            <RemoveItemButton
              userID={shipment.user_id}
              shipmentIsEditable={shipmentIsEditable}
              shipmentID={shipment.id}
              prescriptionID={item.prescription.id}
              deliveryID={item.delivery?.id}
            />
          ) : null}
          {index !== items.length - 1 && <SmSpacing />}
        </ItemContainer>
      ))}
    </ItemsContainer>
  );
};

export default DeliveryItems;
