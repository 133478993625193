// @owners { team: patients-team }
import { type Doctor } from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import { addDays, isAfter, isSameDay, isValid, isWithinInterval, parse, parseISO, startOfDay } from 'date-fns';
import pluralize from 'pluralize';
import { type Medication } from './types';
import { PRESCRIPTION_STATES } from '~shared/constants';
import { getVideosFromNdc } from '~shared/features/instructional-videos/constants';
import { type Video } from '~shared/features/instructional-videos/types';
import { isActiveRx } from '~shared/features/prescriptions/helper';
import { DATE_FORMATS, formatShortDate } from '~shared/helpers/date';
import { type LightDelivery, type LightDoctor, type Prescription } from '~shared/types';

export function inactiveText(prescription: Prescription): string {
  const { active_state, archived_at, discontinued_at, expiration_date, renewed_at, transferred_out_at } = prescription;

  switch (active_state) {
    case PRESCRIPTION_STATES.TRANSFERRED:
      if (transferred_out_at) {
        return `You transferred this prescription to another pharmacy on ${formatShortDate(transferred_out_at)}`;
      }

      return 'You transferred this prescription to another pharmacy';

    case PRESCRIPTION_STATES.EXPIRED:
      if (expiration_date) {
        return `This prescription expired on ${formatShortDate(expiration_date)}`;
      }

      return 'This prescription has expired';

    case PRESCRIPTION_STATES.DISCONTINUED:
      if (discontinued_at) {
        return `This prescription was discontinued on ${formatShortDate(discontinued_at)}`;
      }

      return 'This prescription has been discontinued';

    case PRESCRIPTION_STATES.ARCHIVED:
      if (archived_at) {
        return `You archived this prescription on ${formatShortDate(archived_at)}`;
      }

      return 'You archived this prescription';

    case PRESCRIPTION_STATES.RENEWED:
      if (renewed_at) {
        return `This prescription was renewed on ${formatShortDate(renewed_at)}`;
      }

      return 'This prescription was renewed';

    default:
      return 'Unavailable';
  }
}

export type PartitionedUpcomingDeliveries = {
  potentialCanceledDeliveries: LightDelivery[];
  upcomingDeliveries: LightDelivery[];
};

export function partitionUpcomingDeliveries(deliveries: LightDelivery[]): PartitionedUpcomingDeliveries {
  const result: PartitionedUpcomingDeliveries = {
    potentialCanceledDeliveries: [],
    upcomingDeliveries: [],
  };

  deliveries.forEach((delivery) => {
    if (delivery.status === 'upcoming') {
      const startOfToday = startOfDay(new Date());
      const shipByDate = delivery.ship_by_date
        ? parse(delivery.ship_by_date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0)
        : startOfToday;

      if (delivery.is_pickup || isAfter(shipByDate, startOfToday)) {
        result.potentialCanceledDeliveries.push(delivery);
      } else {
        result.upcomingDeliveries.push(delivery);
      }
    }
  });

  return result;
}

export function prescriptionNeedsRenewal(prescription: Prescription): boolean {
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  return [PRESCRIPTION_STATES.NO_REFILLS, PRESCRIPTION_STATES.EXPIRED].includes(prescription.active_state);
}

export function prescriptionIsEligibleForRenewal(prescription: Prescription): boolean {
  return (
    prescriptionNeedsRenewal(prescription) &&
    !prescription.renewal_request_blocked_reason &&
    !prescription.last_renewal_request_canceled_at &&
    !prescription.last_renewal_request_denial
  );
}

export function prescriptionIsActive(rx: Prescription): boolean {
  // Any prescription with a renewal request should be treated as active, even if the prescription is expired or out of refills
  if (prescriptionIsEligibleForRenewal(rx)) {
    return !!rx.active_renewal_request_start_at;
  }

  return isActiveRx(rx);
}

export function sortByActiveState(prescription: Prescription): 1 | 2 | 3 | 4 | 5 | 6 | 7 {
  switch (prescription.active_state) {
    case PRESCRIPTION_STATES.ACTIVE:
      return 1;

    case PRESCRIPTION_STATES.ACTIVE_RENEWED:
      return 2;

    case PRESCRIPTION_STATES.TOO_EARLY:
      return 3;

    case PRESCRIPTION_STATES.RENEWED:
      return 4;

    case PRESCRIPTION_STATES.NO_REFILLS:
      return 5;

    case PRESCRIPTION_STATES.ARCHIVED:
      return 6;

    case PRESCRIPTION_STATES.DISCONTINUED:
    case PRESCRIPTION_STATES.EXPIRED:
    case PRESCRIPTION_STATES.TRANSFERRED:
    default:
      return 7;
  }
}

// "active" means that we plan to try renewing, but it may not have started yet
export function prescriptionHasActiveRenewal(prescription: Prescription): boolean {
  return !!prescription.active_renewal_request_start_at;
}

// "started" means that we have sent the first contact to the provider
export function prescriptionRenewalHasStarted(prescription: Prescription): boolean {
  if (prescriptionHasActiveRenewal(prescription) && prescription.active_renewal_request_start_at) {
    return new Date() >= new Date(prescription.active_renewal_request_start_at);
  }

  return false;
}

export function formatNameWithCommas(name?: string, isEndOfSentence?: boolean): string {
  if (name) {
    return isEndOfSentence ? `, ${name}.` : `, ${name}, `;
  } else {
    return isEndOfSentence ? '.' : ' ';
  }
}

export function getDoctorName(doctor?: Doctor | LightDoctor | null | undefined): string {
  if (doctor?.first_name && doctor?.last_name) {
    return `${doctor.first_name} ${doctor.last_name}`;
  }

  return "your doctor's office";
}

export function getDoctorNameOrEmptyString(doctor?: LightDoctor): string {
  return doctor?.first_name && doctor?.last_name ? `${doctor.first_name} ${doctor.last_name}` : '';
}

export function ineligibleRenewalText(prescription: Prescription): string {
  const reachOutToDoctor = 'If you still need this medication, please reach out to your doctor.';

  if (prescription.last_renewal_request_canceled_at) {
    return `The refill request was canceled on ${formatShortDate(
      prescription.last_renewal_request_canceled_at,
    )}. ${reachOutToDoctor}`;
  }

  if (prescription.last_renewal_request_denial) {
    switch (prescription.last_renewal_request_denial.reason) {
      case 'no_longer_with_md':
        return `We requested more refills, but ${getDoctorName(
          prescription.doctor,
        )} said that you no longer see them, so they cannot authorize it. If you still need this medication, please reach out to your current doctor for a new prescription.`;

      case 'needs_appointment':
        return 'We requested more refills, but your doctor asked for you to go in for an appointment first.';

      case 'too_soon':
        return `We requested more refills, but your doctor said it is too soon to prescribe more refills. ${reachOutToDoctor}`;

      case 'discontinue':
      default:
        return `We requested more refills, but your doctor declined to authorize any more refills. ${reachOutToDoctor}`;
    }
  }

  return reachOutToDoctor;
}

export function getRefillText(medication: Medication) {
  const { prescriptions } = medication;

  const activePrescriptions = prescriptions.filter(isActiveRx);

  if (activePrescriptions.length === 1) {
    const refills = activePrescriptions[0].refills_remaining || 0;
    return `${refills} ${pluralize('Refill', refills)} left`;
  }

  return '';
}

export function isPrescriptionReadyForRefill(nextRefillDate: string | null | undefined): boolean {
  if (!nextRefillDate) return false;
  const today = new Date();
  const parsedNextRefillDate = parseISO(nextRefillDate);
  return (
    isValid(parsedNextRefillDate) && (isSameDay(today, parsedNextRefillDate) || isAfter(today, parsedNextRefillDate))
  );
}

export function shouldShowReadyToRefillIndicator(
  nextRefillDate: string | null | undefined,
  restrictMaxDate?: boolean,
): boolean {
  if (nextRefillDate) {
    const today = new Date();
    const parsedNextRefillDate = parseISO(nextRefillDate);
    const maxDateToShowIndicator = restrictMaxDate ? addDays(parsedNextRefillDate, 10) : today;

    if (isAfter(parsedNextRefillDate, maxDateToShowIndicator)) {
      return false;
    }

    return isWithinInterval(today, { start: parsedNextRefillDate, end: maxDateToShowIndicator });
  }

  return false;
}

export function isAlternativeMed(medication: Medication | null | undefined): boolean {
  const refillsUsed = medication?.representativePrescription?.refills_used || 0;
  return !!getOriginalMedNameOrEmptyString(medication?.representativePrescription) && refillsUsed === 0;
}

export function getOriginalMedNameOrEmptyString(prescription?: Prescription): string {
  // In the case where an alternative med is prescribed, the original name can be retrieved from the prescription.
  // In the backend, alternative med is called TIA.
  return prescription?.tia_swap_original_product_name || '';
}

export function getInstructionalVideosForMedication(medication: Medication | undefined): Video[] {
  return medication?.ndc ? getVideosFromNdc(medication.ndc) : [];
}

export type MedicationsByUserID = Record<number, Medication[]>;
export const medicationsByUserID = (medications: Medication[]): MedicationsByUserID => {
  return medications.reduce<Record<number, Medication[]>>((medsByUserID, medication) => {
    const { userID } = medication;

    if (userID) {
      if (medsByUserID[userID]?.length) {
        medsByUserID[userID].push(medication);
      } else {
        medsByUserID[userID] = [medication];
      }
    }

    return medsByUserID;
  }, {});
};
