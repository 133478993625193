import { BORDERS, COLORS, SHADOWS, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { type LayoutChangeEvent, ScrollView, StatusBar, View, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { useKeyboardHeight } from '../../hooks/useKeyboardHeight';
import { useScrollPositionExtremes } from '../../hooks/useScrollPositionExtremes';
import { useScreenSize } from '../../utils';
import { PressableAltoIcon } from '../buttons';
import { type ButtonsGroupProps } from '../buttons/buttonUtils';
import { Column, LgPadding, MdPadding } from '../containers';
import { LgSpacing, MdSpacing, XsSpacing } from '../separators';
import { type Tag } from '../tags';
import { Description, H2, type Text } from '../typography';

const BUTTON_KEYS = ['primary', 'secondary', 'tertiary'];

const StyledSheetTop = styled(View)<{ shouldShowTopBorder: boolean }>`
  border-bottom-width: ${BORDERS.SIZE}px;
  border-color: ${({ shouldShowTopBorder }) => (shouldShowTopBorder ? COLORS.BORDER_COLORS.LIGHT : 'transparent')};
`;

const StyledSheetBottom = styled(View)<{ shouldShowBottomShadow: boolean; isMDScreenOrBigger?: boolean }>`
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  border-top-color: ${({ shouldShowBottomShadow }) =>
    shouldShowBottomShadow ? COLORS.BORDER_COLORS.LIGHT : 'transparent'};
  border-top-width: ${BORDERS.SIZE}px;
  ${({ shouldShowBottomShadow }) => (shouldShowBottomShadow ? SHADOWS.TOP : '')}
  elevation: ${({ shouldShowBottomShadow }) => `${shouldShowBottomShadow ? 10 : 0}`};
  ${({ isMDScreenOrBigger }) => {
    if (isMDScreenOrBigger) {
      return `
        border-bottom-left-radius: ${BORDERS.RADIUS.XL.px}; 
        border-bottom-right-radius: ${BORDERS.RADIUS.XL.px}; 
      `;
    }
    return ``;
  }}
`;

const BottomSafeArea = styled(View)<{ height?: number }>`
  ${({ height }) => `height: ${height}px;`}
`;

const BottomSheetContent = ({ children }: { readonly children: React.ReactNode }) => {
  return (
    <Column
      flexGrow={1}
      flexShrink={1}
    >
      {children}
    </Column>
  );
};

export type BottomSheetProps = {
  /**
   * internal use only
   */
  readonly alwaysScrollable?: boolean;
  /**
   * An array of Button components that should appear at the bottom of the action sheet.
   */
  readonly buttons?: ButtonsGroupProps;
  /**
   * Arbitrary components rendered between the title and the action sheet buttons (if present)
   */
  readonly children?: React.ReactNode;
  /**
   * Small text that will appear below the action sheet title.
   */
  readonly description?: string;
  /**
   * Controls whether this action sheet will have an x button at the top left to close it.
   * If this is false you must supply your own close functionality on some other user interaction.
   */
  readonly dismissible?: boolean;
  /**
   * Content that appears below your buttons. You should generally never use this unless you have a good reason to.
   */
  readonly footnote?: React.ReactElement<typeof Text>;
  /**
   * Optional additional callback to call when the back button on the action sheet is pressed
   */
  readonly handleBack?: () => void;
  /**
   * Optional additional callback to call when the action sheet is closed
   */
  readonly handleClose: () => void;
  /**
   * Internal use only. Here for legacy purposes. If we ever delete DEPRECATED_ActionSheet.tsx we can remove this.
   */
  readonly hasScrollableContentsOverride?: boolean;
  /**
   * A Tag component that will appear above the action sheet title. Only use this if it's in your designs.
   */
  readonly Tag?: React.ReactElement<typeof Tag>;
  /**
   * Text in a large serif font appearing at the top of the action sheet. eg "How can we help?"
   */
  readonly title?: string;
};

export const BottomSheet = ({
  alwaysScrollable = false,
  buttons,
  children,
  description,
  dismissible = true,
  footnote,
  handleBack,
  handleClose,
  hasScrollableContentsOverride,
  Tag,
  title,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: BottomSheetProps) => {
  const [containerHeight, setContainerHeight] = React.useState(0);
  const [bottomHeight, setBottomHeight] = React.useState(0);
  const { handleScroll, isAtBottom, isAtTop, scrollEventThrottle } = useScrollPositionExtremes();

  // We are using `useKeyboardHeight` here to help determine the viewable area of the screen.
  // This area is compared with the height of the `<View />` component containing the content
  // to determine whether we should display a scrollable container.
  // @see https://alto.slack.com/archives/C026Z3YLMFC/p1665505742451949
  const keyboardHeight = useKeyboardHeight();
  const { height: windowHeight } = useWindowDimensions();
  const { top, bottom } = useSafeAreaInsets();

  // Calculate the height of the action sheet content
  const handleLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setContainerHeight(height);
  };

  // Calculate the height of the fixed bottom area
  const handleBottomLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setBottomHeight(height);
  };

  // Determine if we need to scrolling to display the action sheet content
  const statusBarHeight = StatusBar.currentHeight || top;
  const viewableHeight = windowHeight - statusBarHeight - keyboardHeight - bottom - bottomHeight;
  const maxHeight = (viewableHeight * 80) / 100;
  const hasScrollableContents = containerHeight > maxHeight - 1;

  // here only for legacy purposes. If we ever delete DEPRECATED_ActionSheet.tsx we can remove
  const overriddenHasScrollableContents = hasScrollableContentsOverride ?? hasScrollableContents;

  const { isMDScreenOrBigger } = useScreenSize();
  const shouldShowTopBorder = overriddenHasScrollableContents && !isAtTop;
  const shouldShowBottomShadow = overriddenHasScrollableContents && !isAtBottom;
  const hasTopSection = !!title || !!description;
  const hasButtons = !!buttons?.length;
  const hasFootnote = !!footnote;
  const shouldUseScrollView = alwaysScrollable || overriddenHasScrollableContents;
  const ContentsView = shouldUseScrollView ? ScrollView : View;

  return (
    <>
      {dismissible ? (
        <StyledSheetTop shouldShowTopBorder={shouldShowTopBorder}>
          <MdPadding topPadding={SPACING.STATIC.LG}>
            <PressableAltoIcon
              name={handleBack ? 'chevronleft' : 'close'}
              onPress={handleBack || handleClose}
              accessibilityLabel={handleBack ? 'Press to go back' : 'Press to close'}
            />
          </MdPadding>
        </StyledSheetTop>
      ) : (
        <LgSpacing />
      )}
      <ContentsView
        keyboardShouldPersistTaps="handled"
        onScroll={handleScroll}
        scrollEventThrottle={scrollEventThrottle}
        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={{ flexGrow: 1 }}
      >
        {/*
          We need to wrap the action sheet content that we want scrollable in a <View> with `onStartShouldSetResponder`
          to allow it to become the touch responder. This enables scrolling within the action sheet modal.
          https://github.com/react-native-modal/react-native-modal/issues/236#issuecomment-597534293
          https://reactnative.dev/docs/gesture-responder-system#capture-shouldset-handlers
        */}
        <View
          onStartShouldSetResponder={() => true}
          onLayout={handleLayout}
        >
          {hasTopSection ? (
            <LgPadding
              topPadding={SPACING.STATIC.NONE}
              bottomPadding={SPACING.STATIC.MD}
            >
              {!!Tag && (
                <>
                  {Tag}
                  <MdSpacing />
                </>
              )}
              {!!title && <H2 autofocus>{title}</H2>}

              {!!description && (
                <>
                  <XsSpacing />
                  <Description color={COLORS.TEXT_COLORS.GREY}>{description}</Description>
                </>
              )}
            </LgPadding>
          ) : null}
          <BottomSheetContent>{children}</BottomSheetContent>
        </View>
      </ContentsView>
      {hasButtons || hasFootnote ? (
        <StyledSheetBottom
          shouldShowBottomShadow={shouldShowBottomShadow}
          isMDScreenOrBigger={isMDScreenOrBigger}
          onLayout={handleBottomLayout}
        >
          {hasFootnote ? (
            <LgPadding
              topPadding={SPACING.STATIC.MD}
              bottomPadding={SPACING.STATIC.XS}
            >
              {footnote}
            </LgPadding>
          ) : null}
          {hasButtons ? (
            <LgPadding
              topPadding={hasFootnote ? SPACING.STATIC.NONE : SPACING.STATIC.MD}
              bottomPadding={bottom ? SPACING.STATIC.XS : undefined}
            >
              {buttons.map((button, i) => (
                <React.Fragment key={`${BUTTON_KEYS[i]}_button`}>
                  {button}
                  {i < buttons.length - 1 && <XsSpacing />}
                </React.Fragment>
              ))}
            </LgPadding>
          ) : null}
        </StyledSheetBottom>
      ) : null}
      <BottomSafeArea height={bottom} />
    </>
  );
};
