// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { toaster } from '@alto/pocky';
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import {
  type CreateShipmentTipRequest,
  type UpdateShipmentTipRequest,
} from '@alto/scriptdash/alto/patients/v3/courier_tips/wunderbar/v1/shipment_tips_endpoint';
import { nextModalStep } from '~shared/actions/modal';
import { viewNotice } from '~shared/actions/notices';
import { rateShipmentDismissed } from '~shared/actions/rateShipment';
// eslint-disable-next-line import/no-deprecated
import { type SurveyResponsePayload, submitSurveyResponse } from '~shared/actions/surveyResponses';
import { DEFAULT_ERROR_ALERT_MESSAGE, DEFAULT_ERROR_ALERT_TITLE } from '~shared/constants';
import { type AnalyticsProps, SOURCES } from '~shared/features/courier-tips/AnalyticsProps';
// eslint-disable-next-line import/no-deprecated
import { createShipmentTip, fetchShipmentTipsForUser, updateShipmentTip } from '~shared/features/courier-tips/actions';
import sendTipAnalyticsEvent from '~shared/features/courier-tips/actions/sendTipAnalyticsEvent';
import { COURIER_TIP_COPY } from '~shared/features/courier-tips/constants';
import { canTipAfterRating } from '~shared/features/courier-tips/helpers';
import { getShipmentTipForShipmentID } from '~shared/features/courier-tips/selectors';
import { POST_DELIVERY_MODAL_NOTICE } from '~shared/features/notices/selectors/getNotices';
import { createSurveyResponseDataPayload } from '~shared/features/rate-shipment/helpers';
import getPendingRating from '~shared/features/rate-shipment/selectors/getPendingRating';
import { getShipmentAddress, getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { formatDollars } from '~shared/helpers/currency';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { type ANALYTICS_EVENT, EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import getModalCurrentStep from '~shared/selectors/modal/getModalCurrentStep';
import { connect } from '~shared/store';
import { type ReduxDispatchShared, type ReduxStateShared } from '~shared/types';
import PostDeliveryModal from './PostDeliveryModal';

const mapStateToProps = (state: ReduxStateShared) => {
  const pendingRating = getPendingRating(state);
  const shipmentID = pendingRating?.shipment_id;
  const address = getShipmentAddress(state, { shipmentID });
  const tipAnalyticsProps = {
    shipment_id: shipmentID,
    source: SOURCES.POST_DELIVERY,
    facility_id: address?.dispensing_facility_id,
  };

  return {
    currentStep: getModalCurrentStep(state),
    currentUserID: getCurrentUserID(state),
    shipment: getShipmentWithID(state, {
      shipmentID,
    }),
    shipmentID,
    shipmentTip: getShipmentTipForShipmentID(state, {
      shipmentID,
    }),
    tipAnalyticsProps,
    uuid: pendingRating?.uuid,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatchShared) => {
  return {
    // eslint-disable-next-line import/no-deprecated
    fetchShipmentTipsForUser: (userID: number) => dispatch(fetchShipmentTipsForUser(userID)),
    sendAnalyticsEvent: (key: ANALYTICS_EVENT, properties: Record<string, unknown> | null | undefined) => {
      dispatch(
        sendAnalyticsEvent(
          createEvent(key, {
            view: 'modal',
            ...properties,
          }),
        ),
      );
    },
    sendTipAnalyticsEvent: (
      key: ANALYTICS_EVENT,
      analyticsProps: AnalyticsProps,
      additionalProps?: Record<string, unknown>,
    ) => {
      dispatch(sendTipAnalyticsEvent({ eventKey: key, analyticsProps, additionalProps }));
    },
    onSubmitRating: (uuid: string, rating: number, freeformReason: string) => {
      const surveyResponse: SurveyResponsePayload = {
        name: 'rate_shipment',
        data: createSurveyResponseDataPayload(rating, freeformReason),
        survey_uuid: uuid,
        version: 2,
      };
      // eslint-disable-next-line import/no-deprecated
      dispatch(submitSurveyResponse(surveyResponse));
      dispatch(viewNotice(POST_DELIVERY_MODAL_NOTICE));
      toaster.toast({
        kind: 'success',
        title: 'Thank you for your rating!',
      });
    },
    onDismissRating: () => {
      dispatch(rateShipmentDismissed());
      dispatch(viewNotice(POST_DELIVERY_MODAL_NOTICE));
    },
    // eslint-disable-next-line import/no-deprecated
    createShipmentTip: (tip: CreateShipmentTipRequest) => dispatch(createShipmentTip(tip)),
    // eslint-disable-next-line import/no-deprecated
    updateShipmentTip: (tip: UpdateShipmentTipRequest) => dispatch(updateShipmentTip(tip)),
    nextModalStep: () => dispatch(nextModalStep('POST_DELIVERY_MODAL')),
  };
};

const mergeProps = (
  {
    currentStep,
    currentUserID,
    shipment,
    shipmentID,
    shipmentTip,
    uuid,
    tipAnalyticsProps,
  }: ReturnType<typeof mapStateToProps>,
  {
    createShipmentTip: dispatchCreateShipmentTip,
    fetchShipmentTipsForUser: dispatchFetchShipmentTipsForUser,
    nextModalStep: dispatchNextModalStep,
    onDismissRating: dispatchOnDismissRating,
    onSubmitRating: dispatchOnSubmitRating,
    sendAnalyticsEvent,
    updateShipmentTip: dispatchUpdateShipmentTip,
    sendTipAnalyticsEvent: dispatchSendTipAnalyticsEvent,
  }: ReturnType<typeof mapDispatchToProps>,
  { onClose }: { onClose: () => void },
) => {
  const sendRatingClickedAnalyticsEvent = (rating: number) => {
    sendAnalyticsEvent(EVENTS.RATE_SHIPMENT__RATING_CLICKED, {
      rating,
      uuid,
    });
  };

  const onSubmitRating = ({
    isTippable,
    rating,
    freeformReason,
  }: {
    isTippable: boolean;
    rating: number;
    freeformReason: string;
  }) => {
    const isEligibleToTip = canTipAfterRating({
      isTippable,
      rating,
      shipment,
      shipmentTip,
    });

    if (isEligibleToTip) {
      dispatchNextModalStep();
    } else {
      onClose();
    }

    dispatchOnSubmitRating(uuid, rating, freeformReason);
    sendAnalyticsEvent(EVENTS.RATE_SHIPMENT__SUBMIT_CLICKED, {
      uuid,
    });
  };

  const onDismissRating = () => {
    onClose();
    dispatchOnDismissRating();
    sendAnalyticsEvent(EVENTS.RATE_SHIPMENT__DISMISS_CLICKED, {
      uuid,
    });
  };

  const onDismissCourierTip = () => {
    dispatchSendTipAnalyticsEvent(EVENTS.COURIER_TIPS__POST_DELIVERY_TIP_DISMISSED, tipAnalyticsProps, {
      view: undefined,
    });
    onClose();
  };

  const handleCourierTipSuccess = (tipAmount: number) => {
    if (tipAmount > 0) {
      toaster.toast({
        kind: 'success',
        title: COURIER_TIP_COPY.TOAST_SUCCESSFUL_UPDATE,
      });
    }

    onClose();
  };

  // @ts-expect-error TS(7006): Parameter 'e' implicitly has an 'any' type.
  const handleCourierTipError = (e) => {
    toaster.toast({
      kind: 'danger',
      title: DEFAULT_ERROR_ALERT_TITLE,
      description: e?.message || DEFAULT_ERROR_ALERT_MESSAGE,
    });

    onClose();
  };

  const handleUpdateTip = (tipAmount: number, tipPaymentMethodID: number, validatedShipmentTip: ShipmentTip) => {
    const updatedShipmentTip: ShipmentTip = {
      ...validatedShipmentTip,
      payment_method_id: tipPaymentMethodID,
      amount: tipAmount,
    };
    const request: UpdateShipmentTipRequest = {
      id: validatedShipmentTip.id,
      shipment_tip: updatedShipmentTip,
    };

    // eslint-disable-next-line promise/catch-or-return, promise/always-return
    dispatchUpdateShipmentTip(request).then(() => {
      handleCourierTipSuccess(tipAmount);
    }, handleCourierTipError);
  };

  const handleCreateTip = (tipAmount: number, tipPaymentMethodID: number, validatedCurrentUserID: number) => {
    const request: CreateShipmentTipRequest = {
      user_id: validatedCurrentUserID,
      shipment_id: shipmentID,
      payment_method_id: tipPaymentMethodID,
      amount: tipAmount,
    };

    // eslint-disable-next-line promise/catch-or-return, promise/always-return
    dispatchCreateShipmentTip(request).then(() => {
      handleCourierTipSuccess(tipAmount);
    }, handleCourierTipError);
  };

  return {
    currentStep,
    fetchShipmentTips: () => {
      if (!currentUserID) return;
      dispatchFetchShipmentTipsForUser(currentUserID);
    },
    shipmentTip,
    shipmentID,
    sendRatingClickedAnalyticsEvent,
    onSubmitRating,
    onDismissRating,
    onSubmitCourierTip: (tipAmount: number, tipPaymentMethodID: number) => {
      dispatchSendTipAnalyticsEvent(EVENTS.COURIER_TIPS__POST_DELIVERY_TIP_SUBMITTED, tipAnalyticsProps, {
        payment_method_id: tipPaymentMethodID,
        tip_value: formatDollars(tipAmount, false),
        view: undefined,
      });

      if (shipmentTip) {
        handleUpdateTip(tipAmount, tipPaymentMethodID, shipmentTip);
      } else if (currentUserID) {
        handleCreateTip(tipAmount, tipPaymentMethodID, currentUserID);
      }
    },
    onDismissCourierTip,
    uuid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PostDeliveryModal);
