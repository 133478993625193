import { createSelector } from 'reselect';
import { updateOrder } from '~shared/actions/cart';
import { getAddresses } from '~shared/features/addresses/selectors/getAddresses';
import { getWindows } from '~shared/features/checkout/selectors/getCart';
import { getDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import getDefaultDeliveryWindow from '~shared/features/checkout/selectors/getDefaultDeliveryWindow';
import getDefaultPaymentMethod from '~shared/features/checkout/selectors/getDefaultPaymentMethod';
import { getIsOrderPickupMethod, getOrder } from '~shared/features/checkout/selectors/getOrder';
import { type LightDeliveryWindow, type Order, type Window } from '~shared/features/checkout/types';
import { findMatchingPaymentMethod } from '~shared/features/payment-methods/helpers';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { type ReduxStateShared } from '~shared/store';
import { type Address, type PaymentMethod, type ReduxAction } from '~shared/types';
// Reactor that handles the concerns of updating the cart with defaults when
// they are missing and cleaning up references to objects that no longer exist

const getFetchDeliveryWindowsLoading = (state: ReduxStateShared) => state.ui.loading.fetchDeliveryWindowsLoading;

export default createSelector(
  [
    getAddresses,
    getDefaultAddress,
    getPaymentMethods,
    getDefaultPaymentMethod,
    getOrder,
    getWindows,
    getDefaultDeliveryWindow,
    getFetchDeliveryWindowsLoading,
    getIsEditingExistingOrder,
    getIsOrderPickupMethod,
  ],
  (
    addresses: Address[],
    defaultAddress: Address | null | undefined,
    paymentMethods: PaymentMethod[],
    defaultPaymentMethod: PaymentMethod | null | undefined,
    order: Order,
    windows: Window[],
    defaultDeliveryWindow: LightDeliveryWindow | null | undefined,
    fetchDeliveryWindowsLoading: boolean,
    isEditingExistingOrder,
    isOrderPickupMethod,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): ReduxAction | null | undefined => {
    const {
      address_id,
      deliver_after,
      deliver_before,
      is_asap: isAsapOrder,
      items,
      payment_method_id,
      tip_payment_method_id,
      pay_at_pickup,
    } = order;

    const orderParams: Partial<Order> = {};

    // items in your cart is a proxy for the fact that you are within the
    // checkout flow or have started one
    if (!items?.length) {
      return undefined;
    }

    if (!isAsapOrder && !isEditingExistingOrder && !fetchDeliveryWindowsLoading && deliver_after && deliver_before) {
      // Clear window if we can't find that window
      const window = windows.find((w) => w.deliver_after === deliver_after && w.deliver_before === deliver_before);

      if (!window) {
        // @ts-expect-error TS(2339): Property 'deliver_after' does not exist on type '{}'.
        orderParams.deliver_after = null;
        // @ts-expect-error TS(2339): Property 'deliver_before' does not exist on type '{}'.
        orderParams.deliver_before = null;
      }
    }

    // set a default delivery window if one hasn't been set yet
    if (!isOrderPickupMethod && !deliver_after && !deliver_before && defaultDeliveryWindow) {
      orderParams.deliver_after = defaultDeliveryWindow.deliver_after;
      orderParams.deliver_before = defaultDeliveryWindow.deliver_before;
    }

    // Clear address_id if we can't find that address
    const address = addresses.find((a) => a.id === address_id);

    if (address_id && !address) {
      // @ts-expect-error TS(2339): Property 'address_id' does not exist on type '{}'.
      orderParams.address_id = null;
    }

    // Auto select an address
    if (!address_id && addresses.length > 0 && defaultAddress) {
      orderParams.address_id = defaultAddress.id;
    }

    // Clear payment_method_id if we can't find that payment method
    const paymentMethod = findMatchingPaymentMethod(paymentMethods, payment_method_id);

    if (payment_method_id && !paymentMethod) {
      // @ts-expect-error TS(2339): Property 'payment_method_id' does not exist on type '{}'.
      orderParams.payment_method_id = null;
    }

    // Auto select a payment method
    if (!pay_at_pickup && !payment_method_id && paymentMethods.length > 0 && defaultPaymentMethod) {
      orderParams.payment_method_id = defaultPaymentMethod.id;
    }

    // Clear tip_payment_method_id if we can't find that payment method
    const tipPaymentMethod = findMatchingPaymentMethod(paymentMethods, tip_payment_method_id);

    if (tip_payment_method_id && !tipPaymentMethod) {
      // @ts-expect-error TS(2339): Property 'tip_payment_method_id' does not exist on type '{}'.
      orderParams.tip_payment_method_id = null;
    }

    // Auto select a payment method
    if (!tip_payment_method_id && paymentMethods.length > 0 && defaultPaymentMethod) {
      orderParams.tip_payment_method_id = defaultPaymentMethod.id;
    }

    if (Object.keys(orderParams).length > 0) {
      return updateOrder(orderParams);
    }

    return undefined;
  },
);
