// @owners { team: patients-team }

// eslint-disable-next-line @alto/no-pocky-import
import { Button, Row, spacing } from '@alto/pocky';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  justify-content: space-between;
`;

export type Props = {
  readonly openCreditCardModal: () => void;
};

export default function AddPaymentMethodButton({ openCreditCardModal }: Props) {
  return (
    <StyledButton
      kind="secondary"
      onClick={openCreditCardModal}
    >
      <Row
        spacing={spacing.smSpacing}
        verticallyAlignContent
      >
        <FontAwesomeIcon icon={faPlus} />
        <div>Add Payment Method</div>
      </Row>
    </StyledButton>
  );
}
