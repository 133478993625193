// @owners { team: patients-team }
import { ActionSheetContext, Body, Button } from '@alto/design-system';
import { usePrevious } from '@alto/design-system/src/hooks/usePrevious';
import { CardFinderActionSheet, CashPayInsurance, EmptyInsurance } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import {
  DeprecatedModalBody,
  DeprecatedModalFooter,
  FlexColumn,
  FlexRow,
  Link,
  Loader,
  SectionContainer,
  SectionContainerSection,
  spacing,
} from '@alto/pocky';
import React, { useContext, useEffect, useState } from 'react';
import { nextModalStep } from '~shared/actions/modal';
import { updateCashPay } from '~shared/actions/users';
import { getUserCashPayOnly } from '~shared/features/users/selectors/getUserCashPayOnly';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getResolution } from '~shared/lib/analytics/src/events/onboarding/getInsuranceCompleted';
import {
  useGetCardFinderInsurances,
  useGetInsurances,
  useGetInsurancesByUserID,
} from '~shared/queries/query-keys/insurances';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { Insurance } from '~web/features/insurances/components/Insurance';
import { InsuranceFormContainer } from '~web/features/insurances/components/InsuranceFormContainer';
import InsuranceList from '~web/features/insurances/components/InsuranceList';
import { InsurancePhotoForm } from '~web/features/insurances/components/InsurancePhotoForm';
import cardFinder from '~web/images/insuranceCard@1x.png';

export const OnboardingInsuranceForm = () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatchShared();
  const userID = useSelectorShared(getCurrentUserID);
  const { hasInsurances } = useGetInsurancesByUserID({ userID });
  const isCashPayOnly = useSelectorShared((state) =>
    getUserCashPayOnly(state, {
      userID,
    }),
  );
  const isNeedsInsurance = !isCashPayOnly && !hasInsurances;
  const resolution = useSelectorShared(getResolution);
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  const [isAddingInsurance, setIsAddingInsurance] = useState(false);
  const [isUploadingInsurance, setIsUploadingInsurance] = useState(false);

  const success = useSelectorShared(
    (state) =>
      state.insurances.createInsuranceSuccess ||
      state.insurances.createPhotoInsuranceSuccess ||
      state.insurances.updateInsuranceSuccess,
  );
  const prevSuccess = usePrevious(success);
  const { insurances, isPending: loading } = useGetInsurances();
  const { insurances: cardFinderInsurances, hasCardFinderInsurance } = useGetCardFinderInsurances();

  const handleCloseAll = () => {
    setIsAddingInsurance(false);
    setIsUploadingInsurance(false);
  };

  useEffect(() => {
    if (!prevSuccess && success) {
      handleCloseAll();
    }
  }, [prevSuccess, success]);

  const handleAddInsurance = () => {
    setIsAddingInsurance(true);
    setIsUploadingInsurance(false);
  };

  const handleUploadInsurance = () => {
    setIsAddingInsurance(false);
    setIsUploadingInsurance(true);
  };

  const handleNext = () => {
    trackEvent({
      event: EVENTS.ONBOARDING__INSURANCE_STEP_COMPLETED,
      params: {
        'cardfinder insurance present': hasCardFinderInsurance,
        resolution,
      },
    });
    dispatch(nextModalStep('ONBOARDING_MODAL'));
  };

  const handleSelectCashPay = async () => {
    if (!userID) return;
    const { success } = await dispatch(updateCashPay(userID, true));
    if (success) {
      trackEvent({
        event: EVENTS.CASH_PAY_ONLY_SET,
        params: {
          source: 'onboarding',
          'new value': true,
        },
      });
    }
    dispatch(nextModalStep('ONBOARDING_MODAL'));
  };

  const renderBody = () => {
    if (loading) {
      return <Loader text="Loading your insurances..." />;
    }

    if (isNeedsInsurance) {
      return (
        <EmptyInsurance
          onSkip={handleNext}
          onAddInsurance={handleAddInsurance}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSelectCashPay={handleSelectCashPay}
        />
      );
    }

    if (isCashPayOnly) {
      return (
        <CashPayInsurance
          onAddInsurance={handleAddInsurance}
          source="onboarding"
        />
      );
    }

    let insuranceContent = (
      <InsuranceList
        insurances={insurances}
        source="onboarding"
      />
    );

    const firstCardFinderInsurance = cardFinderInsurances[0];

    if (firstCardFinderInsurance && insurances.length === 1) {
      insuranceContent = (
        <FlexRow spacing={spacing.smSpacing}>
          <SectionContainer>
            <SectionContainerSection>
              <FlexColumn
                verticallyAlignContent
                evenlySpaceContent
              >
                <FlexRow>
                  <Body>We found your insurance!</Body>
                  <Link
                    onClick={() => {
                      setActiveActionSheet(<CardFinderActionSheet />);
                    }}
                  >
                    How does it work?
                  </Link>
                </FlexRow>
                <img
                  width="44px"
                  height="44px"
                  alt="cardfinder"
                  src={cardFinder}
                />
              </FlexColumn>
            </SectionContainerSection>
          </SectionContainer>
          <SectionContainer>
            <SectionContainerSection>
              <Insurance
                hideActions
                insurance={firstCardFinderInsurance}
                source="onboarding"
              />
            </SectionContainerSection>
          </SectionContainer>
        </FlexRow>
      );
    }

    return (
      <FlexRow spacing={spacing.mdSpacing}>
        <Body>
          Have additional insurance or a prescription savings card? Add them all, so we can get you the best pricing.
        </Body>
        {insuranceContent}
      </FlexRow>
    );
  };

  if (isAddingInsurance) {
    return (
      <InsuranceFormContainer
        onCancel={handleCloseAll}
        showPhotoForm={handleUploadInsurance}
      />
    );
  }

  if (isUploadingInsurance) {
    return <InsurancePhotoForm onCancel={handleCloseAll} />;
  }

  return (
    <>
      <DeprecatedModalBody>{renderBody()}</DeprecatedModalBody>
      {hasInsurances || isCashPayOnly ? (
        <DeprecatedModalFooter>
          <Button
            type={isCashPayOnly ? 'primary' : 'tertiary'}
            small
            onPress={handleNext}
          >
            Next
          </Button>
          <Button
            type={isCashPayOnly ? 'secondary' : 'primary'}
            small
            onPress={handleAddInsurance}
          >
            {isCashPayOnly ? 'Add benefits' : 'Add another benefit'}
          </Button>
        </DeprecatedModalFooter>
      ) : null}
    </>
  );
};
