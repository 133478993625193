// @owners { team: patients-team }
import { faSnowflake } from '@fortawesome/pro-light-svg-icons/faSnowflake';
import React, { PureComponent } from 'react';
import InfoRow from '~web/components/InfoRow';

type Props = {
  readonly medicationName: string;
};

export default class RefrigerationRequiredTag extends PureComponent<Props> {
  render() {
    const { medicationName } = this.props;

    return (
      <InfoRow
        icon={faSnowflake}
        infoText={`Store ${medicationName} in the refrigerator to ensure it remains stable and potent.`}
      >
        Requires refrigeration
      </InfoRow>
    );
  }
}
