// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { getEditShipmentID } from './getCart';
import { type Item } from '~shared/features/checkout/types';
import { getShipmentsWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import { isCourier } from '~shared/helpers/order';
import { type ReduxStateShared } from '~shared/types';

export const isAnyDeliveryItemEligibleForTips = (items: Item[] = []) =>
  items.some((item) => isCourier(item.delivery?.delivery_method ?? undefined));

type GetIsTippableForShipmentIDParams = {
  shipmentID?: number | null;
  hasGovernmentInsurance: boolean;
};

const getShipmentID = (_: ReduxStateShared, params: GetIsTippableForShipmentIDParams) => params.shipmentID;
const getHasGovernmentInsurance = (_: ReduxStateShared, params: GetIsTippableForShipmentIDParams) =>
  params.hasGovernmentInsurance;

// Determines whether or not a specific shipment is tippable or not.
// Takes a provided shipmentID, if one does not exist it will try to get the items
// for the editShipmentID (only works during checkout flow).
// A shipment is tippable if the courier_tips GK is on and one of the delivery items
// has a courier delivery method.
export const getIsTippableForShipmentID = createSelector(
  [getShipmentID, getHasGovernmentInsurance, getEditShipmentID, getShipmentsWithItemsByID],
  (shipmentID, hasGovernmentInsurance, editShipmentID, shipmentsWithItemsByID) => {
    let shipment: ShipmentWithItems | null = null;

    if (shipmentID) {
      shipment = shipmentsWithItemsByID[shipmentID];
    } else if (editShipmentID) {
      shipment = shipmentsWithItemsByID[Number(editShipmentID)];
    }

    return (
      !hasGovernmentInsurance && !!shipment?.courier_tips_available && isAnyDeliveryItemEligibleForTips(shipment?.items)
    );
  },
);
