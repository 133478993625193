// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Button, LgPadding, ListButton } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { type InsuranceSource } from '~shared/constants';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useSelectorShared } from '~shared/store';

type Props = {
  readonly onAddInsurance?: () => void; // web callback only used in onboarding flow
  readonly source: InsuranceSource;
};

const isWeb = getPlatformOS() === 'web';

export const InsuranceButton = ({ onAddInsurance, source }: Props) => {
  const { navigate } = useNavigation();
  const currentUserID = useSelectorShared(getCurrentUserID);
  const selectedUserID = useSelectorShared(getSelectedUserID);
  const userID = selectedUserID || currentUserID;

  const handleAddBenefit = () => {
    if (!userID) return;
    navigate('RouteInsuranceAdd', { source, userID });
  };

  return (
    <LgPadding
      topPadding={SPACING.STATIC.MD}
      bottomPadding={SPACING.STATIC.NONE}
      rightPadding={isWeb ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
      leftPadding={isWeb ? SPACING.STATIC.NONE : SPACING.STATIC.LG}
    >
      {isWeb ? (
        <Button
          label="Add benefits"
          width="inline"
          small
          onPress={source === 'onboarding' && !!onAddInsurance ? onAddInsurance : handleAddBenefit}
        />
      ) : (
        <ListButton
          label="Add benefits"
          onPress={handleAddBenefit}
        />
      )}
    </LgPadding>
  );
};
