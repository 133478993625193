// @owners { team: patients-team }
import React from 'react';
import { type PaymentMethod } from '~shared/types';
import AddPaymentMethodButton from './AddPaymentMethodButton';
import PaymentMethodDropdown from './PaymentMethodDropdown';

export type Props = {
  openCreditCardModal: () => void;
  paymentMethodID: number | null | undefined;
  paymentMethods: PaymentMethod[];
  selectPaymentMethodID: (paymentMethodID: number) => void;
};

export default function ChoosePaymentMethod({
  openCreditCardModal,
  paymentMethodID,
  paymentMethods,
  selectPaymentMethodID,
}: Props) {
  let content;

  if (paymentMethods?.length) {
    content = (
      <PaymentMethodDropdown
        openCreditCardModal={openCreditCardModal}
        paymentMethodID={paymentMethodID}
        paymentMethods={paymentMethods}
        selectPaymentMethodID={selectPaymentMethodID}
      />
    );
  } else {
    content = <AddPaymentMethodButton openCreditCardModal={openCreditCardModal} />;
  }

  return content;
}
