// @owners { team: patients-team }
import { AltoIcon, Description, InlineAlert, Link, MdSpacing, Pressable, Row, XsSpacing } from '@alto/design-system';
import { KeyDateTypeMap } from '@alto/operations_api/v1/types';
import { type FacilityAlertEndpointFetchOneRequest } from '@alto/scriptdash/alto/patient_app/facility_alert/v2/facility_alert_endpoint';
import React, { useEffect } from 'react';
import { ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { queries } from '~shared/queries/query-keys';
import { useQuery } from '~shared/react-query';

const params: FacilityAlertEndpointFetchOneRequest = {
  options: {
    date_type: KeyDateTypeMap.HOLIDAY,
  },
};

type Props = {
  readonly onPress?: () => void;
};
export const HolidayClosureAlert = ({ onPress }: Props) => {
  const { trackEvent } = useAnalytics();
  const { data, isPending, error } = useQuery({
    ...queries.facilityAlerts.fetchOne({ params }),
  });
  const [dismiss, setDismiss] = React.useState(false);

  const title = data?.data?.alert_title ?? '';
  const description = data?.data?.alert_description ?? '';
  const alertType = data?.data?.key_date_type ?? '';

  useEffect(() => {
    if (!isPending && title) {
      trackEvent({
        event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
        params: {
          name: ASSISTANT_VIEWED_EVENTS.ALTO_CLOSURE_INLINE_ALERT,
          alert_title: title,
          alert_type: alertType,
        },
      });
    }
  }, [alertType, title, data, trackEvent, isPending]);

  if (!title && !description) {
    return null;
  }

  const learnMoreNavigate = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: {
        name: ASSISTANT_VIEWED_EVENTS.HOLIDAY_CLOSURE_FAQ,
      },
    });
    onPress && onPress();
  };

  const handleDismiss = () => {
    setDismiss(true);
  };

  if (dismiss) return null;

  return (
    <>
      <MdSpacing />
      <InlineAlert
        type="warning"
        data-testid="holidayClosureAlert"
      >
        {error ? (
          <Description>{`There was an error getting holiday closures. ${error.message}.`}</Description>
        ) : (
          <>
            <Row spaceBetween>
              <Description fontFamily="bold">{title}</Description>
              <Pressable
                accessibilityLabel="dismiss holiday closure alert"
                onPress={handleDismiss}
              >
                <AltoIcon name="close" />
              </Pressable>
            </Row>
            <XsSpacing />
            <Description>{description}</Description>
            <XsSpacing />
            {onPress ? (
              <Link
                onPress={learnMoreNavigate}
                textSize="mini"
              >
                Learn more
              </Link>
            ) : null}
          </>
        )}
      </InlineAlert>
      <MdSpacing />
    </>
  );
};
