// @owners { team: patients-team }
import {
  type ActionAuthSigningUp,
  type ActionAuthSignupFailed,
  type ActionAuthSignupSucceeded,
  type ActionAuthValidatingUserFailed,
  type ActionAuthValidatingUserLoading,
  type ActionFetchServiceAvailabilityFailed,
  type ActionFetchServiceAvailabilitySucceeded,
  type ActionValidatingUserSucceeded,
  FETCH_SERVICE_AVAILABILITY_SUCCEEDED,
  SIGNUP_FAILED,
  SIGNUP_LOADING,
  SIGNUP_SUCCEEDED,
  VALIDATING_USER_FAILED,
  VALIDATING_USER_LOADING,
  VALIDATING_USER_SUCCEEDED,
} from '~shared/actions/auth';
import {
  type ActionUiOnboardingEnter,
  type ActionUiOnboardingExit,
  type ActionUiOnboardingSetZip,
  type ActionUiOnboardingUpdateUser,
  type NewUserPayload,
  UI_ONBOARDING_ENTER,
  UI_ONBOARDING_EXIT,
  UI_ONBOARDING_SET_ZIP,
  UI_ONBOARDING_UPDATE_USER,
} from '~shared/actions/uiOnboarding';
import {
  UPDATE_USER_FAILED,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCEEDED,
  type UpdateUserFailed,
  type UpdateUserLoading,
  type UpdateUserSucceeded,
} from '~shared/actions/users';
import { isSupportEmail } from '~shared/helpers/helper';
import { withRehydration } from '~shared/reducers/withRehydration';
import { type APIError } from '~shared/types/APIError';

export type StateUiOnboarding = {
  user: NewUserPayload;
  zip: string;
  transferPharmacy: {
    name: string;
    street_address: string;
    phone: string;
  };
  isTransferAll: boolean;
  medications: string;
  errorSignup: APIError | null | undefined;
  isSignupLoading: boolean;
  isGeoCovered: boolean | null | undefined;
  isSignedUp: boolean;
  isUserValidated?: boolean;
  isUserValidationLoading?: boolean;
  hasInitialTransfer: boolean;
  isOnboarding: boolean;
  isCurrentUserLoading: boolean;
  errorCurrentUser: APIError | null | undefined;
  isCurrentUser: boolean | null | undefined;
};

export const initialState: StateUiOnboarding = {
  user: {},
  zip: '',
  isSignupLoading: false,
  isCurrentUserLoading: false,
  isGeoCovered: null,
  errorSignup: null,
  errorCurrentUser: null,
  transferPharmacy: {
    name: '',
    street_address: '',
    phone: '',
  },
  medications: '',
  isTransferAll: true,
  isSignedUp: false,
  hasInitialTransfer: false,
  isOnboarding: false,
  isCurrentUser: null,
};

type Action =
  | ActionUiOnboardingUpdateUser
  | ActionUiOnboardingSetZip
  | ActionFetchServiceAvailabilitySucceeded
  | ActionFetchServiceAvailabilityFailed
  | ActionUiOnboardingEnter
  | ActionUiOnboardingExit
  | ActionAuthValidatingUserFailed
  | ActionAuthValidatingUserLoading
  | ActionValidatingUserSucceeded
  | UpdateUserLoading
  | UpdateUserFailed
  | UpdateUserSucceeded
  | ActionAuthSignupSucceeded
  | ActionAuthSigningUp
  | ActionAuthSignupFailed;

const uiOnboarding = (state: StateUiOnboarding = initialState, action: Action): StateUiOnboarding => {
  switch (action.type) {
    case UPDATE_USER_LOADING:
      // update user is used in place of signup only if we're onboarding and the
      // user already has an account created.
      // update user is used in place of user verification for the same reason.
      // So, we set both sets of properties.
      if (!state.isOnboarding || !state.isCurrentUser) return state;
      return {
        ...state,
        isUserValidationLoading: true,
        isUserValidated: false,
        isSignupLoading: true,
        errorSignup: null,
        isSignedUp: false,
      };

    case UPDATE_USER_FAILED:
      // update user is used in place of signup only if we're onboarding and the
      // user already has an account created.
      // update user is used in place of user verification for the same reason.
      // So, we set both sets of properties.
      if (!state.isOnboarding || !state.isCurrentUser) return state;
      return {
        ...state,
        // @ts-expect-error TS(2322): Type '{ errorUserValidation: any; isUserValidationLoading: boolean; isUserValidated: boolean; errorS... (Delete me to see the full error)
        errorUserValidation: action.payload,
        isUserValidationLoading: false,
        isUserValidated: false,
        errorSignup: action.payload,
        isSignupLoading: false,
        isSignedUp: false,
      };

    case UPDATE_USER_SUCCEEDED:
      // update user is used in place of signup only if we're onboarding and the
      // user already has an account created.
      if (!state.isOnboarding || !state.isCurrentUser) return state;
      // NOTE: technically, we get back the user object, but we don't have a
      // need to track it because we're exiting onboarding. We also won't get
      // back the password, which is the hack we're using to see if this was a
      // "verification" or a "signup"
      return {
        ...state,
        // if we have a password, we're actually beyond the verification step
        // @ts-expect-error TS(2322): Type '{ isUserValidated: boolean; isUserValidationLoading: boolean; errorUserValidation: null; isSig... (Delete me to see the full error)
        isUserValidated: !state.user.password,
        isUserValidationLoading: false,
        isSignupLoading: false,
        errorSignup: null,
        // we're actually only signedup if the user has a password set
        // @ts-expect-error TS(2339): Property 'password' does not exist on type 'NewUserPayload'.
        isSignedUp: state.user.password,
      };

    case FETCH_SERVICE_AVAILABILITY_SUCCEEDED:
      return {
        ...state,
        isGeoCovered: action.payload.available,
      };

    case UI_ONBOARDING_UPDATE_USER:
      return { ...state, isUserValidated: undefined, user: { ...state.user, ...action.payload } };

    case UI_ONBOARDING_SET_ZIP:
      return {
        ...state,
        isGeoCovered: null,
        zip: action.payload,
      };

    case SIGNUP_LOADING:
      return { ...state, errorSignup: null, isSignupLoading: true, isSignedUp: false };

    case SIGNUP_SUCCEEDED:
      return { ...state, errorSignup: null, isSignupLoading: false, isSignedUp: true };

    case SIGNUP_FAILED:
      return {
        ...state,
        errorSignup: action.payload,
        isSignupLoading: false,
        isSignedUp: false,
      };

    case UI_ONBOARDING_ENTER:
      return { ...state, isOnboarding: true };

    case UI_ONBOARDING_EXIT:
      return { ...initialState };

    case VALIDATING_USER_LOADING:
      return { ...state, isCurrentUserLoading: true, errorCurrentUser: null, isCurrentUser: null };

    case VALIDATING_USER_FAILED:
      return {
        ...state,
        isCurrentUserLoading: false,
        errorCurrentUser: action.payload,
        isCurrentUser: false,
      };

    case VALIDATING_USER_SUCCEEDED: {
      const { email, ...user } = action.payload;

      if (!isSupportEmail(email)) {
        // @ts-expect-error TS(2461)  Property 'email' does not exist on type '{ id: number; created_at?: number | undefine...
        user.email = email;
      }

      return { ...state, isCurrentUserLoading: false, errorCurrentUser: null, isCurrentUser: true, user };
    }

    default:
      return state;
  }
};

export default withRehydration(uiOnboarding, initialState);
