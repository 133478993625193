// @owners { team: patients-team }
import { COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { Body, Column, XlPadding, XsSpacing } from '@alto/design-system';
import { ContactUs } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { ALIGNMENTS, InfoBox, LoaderImage, Modal, ModalContent, ModalFooter, spinnerStyles } from '@alto/pocky';
import React, { useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
// eslint-disable-next-line import/no-deprecated
import { autobillPrescription, fetchPrescriptions } from '~shared/actions/prescriptions';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing, fetchPrescriptionPricing } from '~shared/actions/pricing';
import { updateQuantity } from '~shared/actions/quantityChange';
import { CONTACT_US_ORIGIN } from '~shared/constants';
import { nextAvailableDatesQueryKeys } from '~shared/features/next-available-date/queries/nextAvailableDatesQueryKeys';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { formatQuantityToFriendlyDosageConversion, formatWrittenQuantity } from '~shared/helpers/helper';
import { useQueryClient } from '~shared/react-query';
import getFetchQuantityOptionsLoading from '~shared/selectors/quantityChange/getFetchQuantityOptionsLoading';
import getQuantityOptionsByPrescriptionID from '~shared/selectors/quantityChange/getQuantityOptionsByPrescriptionID';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { ChangeQuantityFooter } from './ChangeQuantityFooter';
import { ChangeQuantitySelect } from './ChangeQuantitySelect';

const StyledModal = styled(Modal)`
  > div {
    overflow: visible;
  }

  .modal-header {
    padding-bottom: ${SPACING.STATIC.LG.px};
    display: flex;

    h1 {
      color: ${COLORS.TEXT_COLORS.PRIMARY};
      font-family: ${TYPOGRAPHY.FONT.BODY.SEMIBOLD};
    }
  }
`;

const StyledModalContent = styled(ModalContent)`
  overflow: visible;
`;

const SyledModalFooter = styled(ModalFooter)`
  padding: ${SPACING.STATIC.MD.px} ${SPACING.STATIC.LG.px};
`;

type Props = {
  readonly onClose: () => void;
  readonly prescriptionID: number;
};

const ChangeQuantityModalContent = ({ onClose, prescriptionID }: Props) => {
  const queryClient = useQueryClient();
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const currentQuantity = prescription?.quantity;
  const [selectedQuantityOption, setSelectedQuantityOption] = useState(currentQuantity);
  const [updateQuantityError, setUpdateQuantityError] = useState(false);
  const quantityOptions = useSelectorShared((state) => getQuantityOptionsByPrescriptionID(state, prescriptionID));
  const formattedQuantity = prescription ? formatWrittenQuantity(prescription) : '';
  const conversion = prescription ? formatQuantityToFriendlyDosageConversion(prescription) : '';
  const quantityChanged = !!selectedQuantityOption && selectedQuantityOption !== currentQuantity;
  const isOptionsEmpty = quantityOptions.length === 0;

  const dispatch = useDispatchShared();

  const emptyState = (
    <Body color={COLORS.TEXT_COLORS.GREY}>
      Quantity change for this medication is not available online. Please{' '}
      <ContactUs
        type="link"
        method="message"
        origin={CONTACT_US_ORIGIN.ChangeQuantityList}
      />{' '}
      to change the quantity of this prescription.
    </Body>
  );

  const onConfirm = () => {
    if (selectedQuantityOption) {
      setUpdateQuantityError(false);
      // eslint-disable-next-line promise/catch-or-return
      dispatch(updateQuantity(prescriptionID, selectedQuantityOption)).then((wasSuccessful: boolean) => {
        // eslint-disable-next-line promise/always-return
        if (wasSuccessful) {
          // eslint-disable-next-line import/no-deprecated, promise/catch-or-return, promise/no-nesting, promise/always-return
          dispatch(autobillPrescription(prescriptionID)).then(() => {
            // eslint-disable-next-line import/no-deprecated
            dispatch(fetchOrderPricing());
            // eslint-disable-next-line import/no-deprecated
            dispatch(fetchPrescriptionPricing());
            // eslint-disable-next-line import/no-deprecated
            dispatch(fetchPrescriptions());
            // eslint-disable-next-line import/no-deprecated
            dispatch(fetchDeliveries());

            // TODO: remove this once we're fully using react query
            // eslint-disable-next-line import/no-deprecated
            dispatch(fetchNextAvailableDates());
            queryClient.invalidateQueries({ queryKey: nextAvailableDatesQueryKeys.all });
          });

          onClose();
        } else {
          setUpdateQuantityError(true);
        }
      });
    }
  };

  return (
    <>
      <StyledModalContent
        textAlignment={ALIGNMENTS.LEFT}
        padding={SPACING.STATIC.LG.px}
      >
        <Column>
          <Body color={COLORS.TEXT_COLORS.GREY}>Your doctor prescribed: {formattedQuantity}</Body>
          {/* eslint-disable-next-line @typescript-eslint/no-useless-template-literals */}
          {!!conversion.length && <Body color={COLORS.TEXT_COLORS.GREY}>{`${conversion}`}</Body>}
        </Column>
        <XsSpacing />
        {isOptionsEmpty ? (
          emptyState
        ) : (
          <ChangeQuantitySelect
            quantityOptions={quantityOptions}
            showWarning={quantityChanged}
            onChange={(value: number) => {
              setSelectedQuantityOption(value);
            }}
            value={selectedQuantityOption}
          />
        )}
        {updateQuantityError ? (
          <>
            <XsSpacing />
            <InfoBox danger>Something went wrong. Please try again or message support if the problem persists.</InfoBox>
          </>
        ) : null}
      </StyledModalContent>
      <SyledModalFooter>
        <ChangeQuantityFooter
          onClose={onClose}
          disabled={!quantityChanged}
          isOptionsEmpty={isOptionsEmpty}
          onConfirm={onConfirm}
        />
      </SyledModalFooter>
    </>
  );
};

export const ChangeQuantityModal = (props: Props) => {
  const { onClose } = props;
  const isLoading = useSelectorShared(getFetchQuantityOptionsLoading);

  return (
    <StyledModal
      dismissOnOutsideClick
      showDismiss
      onClose={onClose}
      title="Change Quantity"
    >
      {isLoading ? (
        <XlPadding>
          <LoaderImage style={spinnerStyles} />
        </XlPadding>
      ) : (
        <ChangeQuantityModalContent {...props} />
      )}
    </StyledModal>
  );
};
