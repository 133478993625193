import { EventTypes } from 'redux-segment';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from './deliveries';
// eslint-disable-next-line import/no-deprecated
import { uploadImageToS3 } from './upload';
import { getNeedsInsuranceDeliveries } from '~shared/features/delivery/selectors/getNeedsInsuranceDeliveries';
import {
  type CreateManualInsurancePayload,
  type CreatePhotoInsurancePayload,
  type UpdateManualInsurancePayload,
} from '~shared/features/insurances/types';
import { destroy, post, put } from '~shared/helpers/apiHelper';
import {
  type APIError,
  type Insurance,
  type ReduxDispatch,
  type ReduxDispatchShared,
  type ReduxGetStateShared,
  type S3Source,
} from '~shared/types';

const S3_BUCKET = 'insurances';

export type FetchInsurancesSucceeded = {
  type: typeof FETCH_INSURANCES_SUCCEEDED;
  payload: Insurance[];
};

export type FetchInsurancesFailed = {
  type: typeof FETCH_INSURANCES_FAILED;
  payload: APIError;
  error: true;
};

export const FETCH_INSURANCES_SUCCEEDED = 'FETCH_INSURANCES_SUCCEEDED';

export function fetchInsurancesSucceeded(insurances: Insurance[]): FetchInsurancesSucceeded {
  return {
    type: FETCH_INSURANCES_SUCCEEDED,
    payload: insurances,
  };
}

export const FETCH_INSURANCES_FAILED = 'FETCH_INSURANCES_FAILED';

export function fetchInsurancesFailed(error: APIError): FetchInsurancesFailed {
  return {
    type: FETCH_INSURANCES_FAILED,
    payload: error,
    error: true,
  };
}

export type SelectInsurance = {
  type: typeof SELECT_INSURANCE;
  payload: Insurance;
};

export const SELECT_INSURANCE = 'SELECT_INSURANCE';

export function selectInsurance(insurance: Insurance): SelectInsurance {
  return {
    type: SELECT_INSURANCE,
    payload: insurance,
  };
}

export type DeselectInsurance = {
  type: typeof DESELECT_INSURANCE;
};

export const DESELECT_INSURANCE = 'DESELECT_INSURANCE';

export function deselectInsurance(): DeselectInsurance {
  return {
    type: DESELECT_INSURANCE,
  };
}

export type CreateInsuranceLoading = {
  type: typeof CREATE_INSURANCE_LOADING;
};

export type CreateInsuranceSucceeded = {
  type: typeof CREATE_INSURANCE_SUCCEEDED;
  payload: Insurance;
  meta: any;
};

export type CreateInsuranceFailed = {
  type: typeof CREATE_INSURANCE_FAILED;
  payload: APIError;
  error: true;
  meta: any;
};

export const CREATE_INSURANCE_LOADING = 'CREATE_INSURANCE_LOADING';

export function creatingInsurance(): CreateInsuranceLoading {
  return {
    type: CREATE_INSURANCE_LOADING,
  };
}

export const CREATE_INSURANCE_SUCCEEDED = 'CREATE_INSURANCE_SUCCEEDED';

export function createInsuranceSucceeded(insurance: Insurance): CreateInsuranceSucceeded {
  return {
    type: CREATE_INSURANCE_SUCCEEDED,
    payload: insurance,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: CREATE_INSURANCE_SUCCEEDED,
          properties: {
            resource_type: 'Insurance',
            resource_id: insurance.id,
            entity_name: insurance.insurance_plan_name,
          },
        },
      },
    },
  };
}

export const CREATE_INSURANCE_FAILED = 'CREATE_INSURANCE_FAILED';

export function createInsuranceFailed(error: APIError): CreateInsuranceFailed {
  return {
    type: CREATE_INSURANCE_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export type CreatePhotoInsuranceLoading = {
  type: typeof CREATE_PHOTO_INSURANCE_LOADING;
};

export type CreatePhotoInsuranceSucceeded = {
  type: typeof CREATE_PHOTO_INSURANCE_SUCCEEDED;
  payload: Insurance;
  meta: any;
};

export type CreatePhotoInsuranceFailed = {
  type: typeof CREATE_PHOTO_INSURANCE_FAILED;
  payload: Error;
  error: true;
  meta: any;
};

export const CREATE_PHOTO_INSURANCE_LOADING = 'CREATE_PHOTO_INSURANCE_LOADING';

export function creatingPhotoInsurance(): CreatePhotoInsuranceLoading {
  return {
    type: CREATE_PHOTO_INSURANCE_LOADING,
  };
}

export const CREATE_PHOTO_INSURANCE_SUCCEEDED = 'CREATE_PHOTO_INSURANCE_SUCCEEDED';

export function createPhotoInsuranceSucceeded(insurance: Insurance): CreatePhotoInsuranceSucceeded {
  return {
    type: CREATE_PHOTO_INSURANCE_SUCCEEDED,
    payload: insurance,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: CREATE_PHOTO_INSURANCE_SUCCEEDED,
          properties: {
            resource_type: 'Insurance',
            resource_id: insurance.id,
          },
        },
      },
    },
  };
}

export const CREATE_PHOTO_INSURANCE_FAILED = 'CREATE_PHOTO_INSURANCE_FAILED';

export function createPhotoInsuranceFailed(error: Error): CreatePhotoInsuranceFailed {
  return {
    type: CREATE_PHOTO_INSURANCE_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export type UpdateInsuranceLoading = {
  type: typeof UPDATE_INSURANCE_LOADING;
};

export type UpdateInsuranceSucceeded = {
  type: typeof UPDATE_INSURANCE_SUCCEEDED;
  payload: Insurance;
  meta: any;
};

export type UpdateInsuranceFailed = {
  type: typeof UPDATE_INSURANCE_FAILED;
  payload: APIError;
  error: true;
  meta: any;
};

export const UPDATE_INSURANCE_LOADING = 'UPDATE_INSURANCE_LOADING';

export function updatingInsurance(): UpdateInsuranceLoading {
  return {
    type: UPDATE_INSURANCE_LOADING,
  };
}

export const UPDATE_INSURANCE_SUCCEEDED = 'UPDATE_INSURANCE_SUCCEEDED';

export function updateInsuranceSucceeded(insurance: Insurance): UpdateInsuranceSucceeded {
  return {
    type: UPDATE_INSURANCE_SUCCEEDED,
    payload: insurance,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: UPDATE_INSURANCE_SUCCEEDED,
          properties: {
            resource_type: 'Insurance',
            resource_id: insurance.id,
          },
        },
      },
    },
  };
}

export const UPDATE_INSURANCE_FAILED = 'UPDATE_INSURANCE_FAILED';

export function updateInsuranceFailed(error: APIError): UpdateInsuranceFailed {
  return {
    type: UPDATE_INSURANCE_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export type DeleteInsuranceLoading = {
  type: typeof DELETE_INSURANCE_LOADING;
};

export type DeleteInsuranceSucceeded = {
  type: typeof DELETE_INSURANCE_SUCCEEDED;
  payload: Insurance;
  meta: any;
};

export type DeleteInsuranceFailed = {
  type: typeof DELETE_INSURANCE_FAILED;
  payload: APIError;
  error: true;
  meta: any;
};

export const DELETE_INSURANCE_LOADING = 'DELETE_INSURANCE_LOADING';

export function deletingInsurance(): DeleteInsuranceLoading {
  return {
    type: DELETE_INSURANCE_LOADING,
  };
}

export const DELETE_INSURANCE_SUCCEEDED = 'DELETE_INSURANCE_SUCCEEDED';

export function deleteInsuranceSucceeded(insurance: Insurance): DeleteInsuranceSucceeded {
  return {
    type: DELETE_INSURANCE_SUCCEEDED,
    payload: insurance,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export const DELETE_INSURANCE_FAILED = 'DELETE_INSURANCE_FAILED';

export function deleteInsuranceFailed(error: APIError): DeleteInsuranceFailed {
  return {
    type: DELETE_INSURANCE_FAILED,
    payload: error,
    error: true,
    meta: {
      analytics: {
        eventType: EventTypes.track,
      },
    },
  };
}

export type ClearInsuranceError = {
  type: typeof CLEAR_INSURANCE_ERROR;
};
export const CLEAR_INSURANCE_ERROR = 'CLEAR_INSURANCE_ERROR';

export function clearInsuranceError(): ClearInsuranceError {
  return {
    type: CLEAR_INSURANCE_ERROR,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function createInsurance(insurance: CreateManualInsurancePayload | CreatePhotoInsurancePayload) {
  return (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    dispatch(creatingInsurance());
    return post('/insurances', {
      insurance,
    }).then((json) => {
      if (json.error) {
        dispatch(createInsuranceFailed(json.error));
        return false;
      }

      dispatch(createInsuranceSucceeded(json));

      // The status of needs or processing insurance is per delivery, based off of
      // delivery.comms_status. If we were successfully creating an insurance
      // when at least one of the deliveries was in needs insurance state,
      // we can assert that we would be going from the needs insurance state to the
      // processing insurance state for a delivery. Therefore, we should fetch the
      // updated delivery with the right billing status.
      const state = getState();
      const needsInsuranceDeliveries = getNeedsInsuranceDeliveries(state);
      // eslint-disable-next-line import/no-deprecated
      if (needsInsuranceDeliveries.length > 0) dispatch(fetchDeliveries());

      // TODO:
      // return json here because upload requires insurance object
      // for analytics. should make all create actions return object
      // instead of boolean.
      return json;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function createPhotoInsurance(sources: S3Source[], userID: number | null | undefined = null) {
  return (dispatch: ReduxDispatch) => {
    dispatch(creatingPhotoInsurance());

    // eslint-disable-next-line import/no-deprecated
    const requests = sources.map((source) => dispatch(uploadImageToS3(source, S3_BUCKET)));

    return Promise.all(requests)
      .then((urls) => {
        const failedIndex = urls.findIndex((url) => !url);
        const failed = failedIndex !== -1;

        if (failed) {
          dispatch(createPhotoInsuranceFailed(new Error('Create photo insurance failed')));
          return false;
        } else {
          const params: CreatePhotoInsurancePayload = {
            image_url: urls[0] || null,
            image_url_2: urls[1] || null,
          };
          if (userID) params.user_id = userID;

          // eslint-disable-next-line promise/no-nesting
          return dispatch(createInsurance(params)).then((insurance) => {
            if (insurance) {
              dispatch(createPhotoInsuranceSucceeded(insurance));
              return insurance as Insurance;
            }

            dispatch(createPhotoInsuranceFailed(new Error('Create photo insurance failed')));
            return false;
          });
        }
      })
      .catch((err) => {
        dispatch(createPhotoInsuranceFailed(err));
        return false;
      });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function updateInsurance(insurance: UpdateManualInsurancePayload) {
  return (dispatch: ReduxDispatch) => {
    dispatch(updatingInsurance());
    return put(`/insurances/${insurance.id}`, insurance).then((json) => {
      if (json.error) {
        dispatch(updateInsuranceFailed(json.error));
        return false;
      }

      dispatch(updateInsuranceSucceeded(json));
      dispatch(deselectInsurance());
      return json;
    });
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function deleteInsurance(insurance: Insurance) {
  return (dispatch: ReduxDispatchShared) => {
    dispatch(deletingInsurance());
    return destroy(`/insurances/${insurance.id}`).then((json) => {
      if (json.error) {
        dispatch(deleteInsuranceFailed(json.error));
        return false;
      }

      dispatch(deleteInsuranceSucceeded(json));
      return true;
    });
  };
}

export type DeclineInsuranceLoading = {
  type: typeof DECLINE_INSURANCE_LOADING;
};

export type DeclineInsuranceSucceeded = {
  type: typeof DECLINE_INSURANCE_SUCCEEDED;
};

export type DeclineInsuranceFailed = {
  type: typeof DECLINE_INSURANCE_FAILED;
  payload: APIError;
  error: true;
};

// this is part of onboarding
export const DECLINE_INSURANCE_LOADING = 'DECLINE_INSURANCE_LOADING';

export function decliningInsurance(): DeclineInsuranceLoading {
  return {
    type: DECLINE_INSURANCE_LOADING,
  };
}

export const DECLINE_INSURANCE_FAILED = 'DECLINE_INSURANCE_FAILED';

export function declineInsuranceFailed(error: APIError): DeclineInsuranceFailed {
  return {
    type: DECLINE_INSURANCE_FAILED,
    payload: error,
    error: true,
  };
}

export const DECLINE_INSURANCE_SUCCEEDED = 'DECLINE_INSURANCE_SUCCEEDED';

export function declineInsuranceSucceeded(): DeclineInsuranceSucceeded {
  return {
    type: DECLINE_INSURANCE_SUCCEEDED,
  };
}

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function declineInsurance() {
  return (dispatch: ReduxDispatchShared) => {
    const user = {
      cash_pay_only: true,
    };
    dispatch(decliningInsurance());
    return post('/users/onboarding_update', {
      user,
    }).then((json) => {
      if (json.error) {
        dispatch(declineInsuranceFailed(json));
        return false;
      }

      dispatch(declineInsuranceSucceeded());
      return true;
    });
  };
}

export type InsurancesActions =
  | FetchInsurancesFailed
  | FetchInsurancesSucceeded
  | SelectInsurance
  | DeselectInsurance
  | CreateInsuranceLoading
  | CreateInsuranceSucceeded
  | CreateInsuranceFailed
  | CreatePhotoInsuranceLoading
  | CreatePhotoInsuranceSucceeded
  | CreatePhotoInsuranceFailed
  | DeclineInsuranceLoading
  | DeclineInsuranceSucceeded
  | DeclineInsuranceFailed
  | DeleteInsuranceLoading
  | DeleteInsuranceSucceeded
  | DeleteInsuranceFailed
  | UpdateInsuranceLoading
  | UpdateInsuranceSucceeded
  | UpdateInsuranceFailed
  | ClearInsuranceError;
