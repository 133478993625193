import { validatePresent } from './validatePresent';

export const validatePresentWithMessage = <T>(label: string) => {
  return (value: T) => {
    if (validatePresent(value)) {
      return label ? `${label} cannot be blank` : validatePresent(value);
    }

    return null;
  };
};
