import React from 'react';
import { Column } from '../../../containers';
import { type BulletedListItem } from './BulletedListItem';

type Props = {
  readonly children: React.ReactElement<typeof BulletedListItem> | React.ReactElement<typeof BulletedListItem>[];
};

export const BulletedList = ({ children }: Props) => (
  <Column
    gap="XXS"
    accessibilityRole="list"
  >
    {children}
  </Column>
);
