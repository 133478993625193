// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { type ShipmentFeeType } from '@alto/scriptdash/alto/orders/types/v1/shipment_fee_type';
import { format, parse } from 'date-fns';
import { DATE_FORMATS } from '~shared/helpers/date';

export const getPreSelectRadioValueForOrder = ({
  hasSchedulableDeliveryFeesInCart,
  orderDate,
  dateToFeeHash,
  hasFutureOrderBundlingOption,
  earliestAvailableRadioVisible,
  earliestAvailableDate,
  radioValue,
}: {
  hasSchedulableDeliveryFeesInCart: boolean;
  orderDate: string | undefined | null;
  dateToFeeHash: Record<string, ShipmentFeeType | null>;
  hasFutureOrderBundlingOption?: boolean;
  earliestAvailableRadioVisible?: boolean;
  earliestAvailableDate?: string;
  radioValue?: string;
}) => {
  const feeForDate = dateToFeeHash[orderDate || ''];
  // if an order bundling option is already selected, persist the selection
  if (radioValue === 'same-day-order-bundling') return 'same-day-order-bundling';
  if (radioValue === 'order-bundling') return 'order-bundling';

  // if the order date matches a same-day or next-day option, select it
  if (feeForDate === 'same_day_delivery') return 'same-day-delivery';
  if (feeForDate === 'next_day_delivery') return 'next-day-delivery';

  // always let the patient select which option when delivery fees are offered
  if (!orderDate && hasSchedulableDeliveryFeesInCart) return 'no-selection';

  // if there's a separate radio button for the earliest available date and the order date matches it, select it
  if (earliestAvailableRadioVisible && earliestAvailableDate === orderDate) return 'earliest-available-date';

  // if there's a bundled option and any-day-delivery is not already selected, default to order bundling
  if (hasFutureOrderBundlingOption && radioValue !== 'any-day-delivery') return 'order-bundling';

  // If the selected date exists and is not one of same or next day delivery, it is assumed to be standard delivery.
  if (orderDate) return 'any-day-delivery';
  return 'no-selection';
};

export const feeTypeToFormValue: Record<ShipmentFeeType, string> = {
  asap_delivery: 'asap_delivery',
  same_day_delivery: 'same-day-delivery',
  next_day_delivery: 'next-day-delivery',
  standard_delivery: 'any-day-delivery',
  on_demand_delivery: 'on-demand-delivery',
  essentials_only_delivery: 'essentials-only-delivery',
};

export const getFormattedDate = (date: string | undefined): string =>
  date ? format(parse(date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0), DATE_FORMATS.WEEKDAY_MONTH_ORDINAL_DATE) : '';

export const getDisabledColor = (loading: boolean) =>
  loading ? COLORS.TEXT_COLORS.DISABLED : COLORS.TEXT_COLORS.PRIMARY;
