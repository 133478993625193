// @owners { team: patients-team }
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { NO_ORDER_ITEMS } from '~shared/features/checkout/constants';
import { getPaymentErrors } from '~shared/features/checkout/helpers';
import { useCheckoutDateErrors } from '~shared/features/checkout/hooks/useCheckoutDateErrors';
import { useCheckoutSharpsContainerErrors } from '~shared/features/checkout/hooks/useCheckoutSharpsContainerErrors';
import { useCheckoutTipErrors } from '~shared/features/checkout/hooks/useCheckoutTipErrors';
import { getOrderIndex, getOrdersByIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { getIsPaymentMethodRequired } from '~shared/features/checkout/selectors/getPaymentBreakdown';
import { type CheckoutValidationErrors, type Order, type ValidationError } from '~shared/features/checkout/types';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';
import { getItemsSubtotal } from '~shared/features/pricing/selectors/getOrderTotal';
import { getIsSplitPaymentEnabled } from '~shared/features/ui/selectors/getCart';
import { useSelectorShared } from '~shared/store';
import { useCheckoutAddressErrors } from './useCheckoutAddressErrors';

export const useCheckoutValidationErrors = () => {
  const orderIndex = useSelectorShared(getOrderIndex);
  const ordersByIndex = useSelectorShared(getOrdersByIndex);
  const order: Order | undefined = ordersByIndex[orderIndex];
  const { deliveryMethods } = useCartDeliveryMethods();
  const isLastShipment = orderIndex === deliveryMethods.length - 1;
  const items = order?.items ?? [];
  const paymentMethods = useSelectorShared(getPaymentMethods);
  const isPaymentMethodRequired = useSelectorShared(getIsPaymentMethodRequired);
  const isSplitPaymentEnabled = useSelectorShared(getIsSplitPaymentEnabled);
  const splitPaymentsTotal = useSelectorShared(getItemsSubtotal);

  const orderValidationErrors: ValidationError[] = items?.length
    ? []
    : [{ key: NO_ORDER_ITEMS, message: 'Please select one or more prescriptions.' }];
  const { addressValidationErrors } = useCheckoutAddressErrors();
  const { dateValidationErrors } = useCheckoutDateErrors(false);
  const { tipValidationErrors } = useCheckoutTipErrors();
  const paymentErrors = getPaymentErrors({
    order,
    paymentMethods,
    isPaymentMethodRequired,
    isSplitPaymentEnabled,
    splitPaymentsTotal,
  });

  const { sharpsContainerValidationErrors } = useCheckoutSharpsContainerErrors();
  // sharps container is only shown on the last checkout screen of an order
  const contextualSharpContainerErrors: ValidationError[] = isLastShipment ? sharpsContainerValidationErrors : [];

  const validationErrors: CheckoutValidationErrors = {
    address: addressValidationErrors,
    date: dateValidationErrors,
    order: orderValidationErrors,
    payment: paymentErrors,
    sharpsContainer: contextualSharpContainerErrors,
    tip: tipValidationErrors,
  };

  return {
    addressValidationErrors,
    dateValidationErrors,
    hasValidationErrors:
      addressValidationErrors.length > 0 ||
      dateValidationErrors.length > 0 ||
      orderValidationErrors.length > 0 ||
      paymentErrors.length > 0 ||
      contextualSharpContainerErrors.length > 0 ||
      tipValidationErrors.length > 0,
    paymentErrors,
    sharpsContainerValidationErrors: contextualSharpContainerErrors,
    tipValidationErrors,
    validationErrors,
  };
};
