// @owners { team: patients-team }
import { BORDERS, COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Button, Illustration, ListDescription, ListItem } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { type MedListMedication, PrescriptionStatusMap } from '@alto/scriptdash/alto/medications/patients/v1/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import getPrescriptionPricingByID from '~shared/features/pricing/selectors/getPrescriptionPricingByID';
import { getItemPricingTotal } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';

export const MedListItem = ({
  medication,
  useAddToCartInterstitialSheets,
}: {
  readonly medication: MedListMedication;
  readonly useAddToCartInterstitialSheets: any;
}) => {
  const [pressed, setPressed] = useState(false);
  const {
    title,
    date,
    primary_prescription_id,
    prescription_status,
    stock_status,
    therapeutic_alternative,
    upcoming_order,
    patient_name,
    image_url,
  } = medication;

  const isWeb = getPlatformOS() === 'web';
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { isPrescriptionInCart } = usePrescriptionsInCart();
  const inCart = isPrescriptionInCart(primary_prescription_id);
  const prescriptionPricing = useSelectorShared((state) => getPrescriptionPricingByID(state, primary_prescription_id));
  const isProgyny = getItemPricingTotal(prescriptionPricing).reason === PRESCRIPTION_PRICING_LABELS.PROGYNY;
  const isProgynyWeb = isWeb && isProgyny;
  const { initializeActionSheetsToShow, isDisabled } = useAddToCartInterstitialSheets({
    origin: ORIGIN_NAMES.MED_LIST,
    prescription: { ...medication, id: primary_prescription_id },
  });

  useEffect(() => {
    if (!isDisabled) {
      setPressed(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    trackEvent({
      event: EVENTS.MED_LIST__ROW_VIEWED,
      additionalFields: { prescriptionId: primary_prescription_id },
      params: {
        medicationStatus: prescription_status,
        addToCartEligible:
          prescription_status === PrescriptionStatusMap.ELIGIBLE_FOR_CART && stock_status !== 'unavailable',
        isAlternative: !!therapeutic_alternative,
        hasImage: !!image_url,
        multipleActiveRxs: medication.active_prescription_ids?.length > 1,
        quantityChangeLinkShown: false,
        priceShown: false,
        noAvailableDates: stock_status === 'unavailable',
      },
    });
  }, [
    image_url,
    medication.active_prescription_ids?.length,
    prescription_status,
    primary_prescription_id,
    stock_status,
    therapeutic_alternative,
    trackEvent,
  ]);

  const showAddToCartActionSheet = useCallback(() => {
    setPressed(true);
    trackEvent({
      event: EVENTS.ADD_TO_CART_TAPPED,
      additionalFields: { prescriptionId: primary_prescription_id },
      params: { origin: ORIGIN_NAMES.MED_LIST },
    });
    initializeActionSheetsToShow();
  }, [initializeActionSheetsToShow, primary_prescription_id, trackEvent]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const listDescriptions: React.ReactElement<typeof ListDescription>[] = useMemo(() => {
    // Order of these descriptions matters and determine order they are shown from top to bottom
    const descriptions: React.ReactElement<typeof ListDescription>[] = [];
    if (patient_name) {
      descriptions.push(<ListDescription iconName="user-small">{patient_name}</ListDescription>);
    }

    if (upcoming_order) {
      descriptions.push(
        <ListDescription
          iconName={
            upcoming_order === 'Awaiting pickup' || upcoming_order === 'Upcoming pickup' ? 'handbox-small' : 'car-small'
          }
        >
          {upcoming_order}
        </ListDescription>,
      );
    } else if (!inCart) {
      if (stock_status === 'unavailable') {
        descriptions.push(<ListDescription iconName="closecircle-small">Unavailable</ListDescription>);
      } else if (prescription_status === PrescriptionStatusMap.ELIGIBLE_FOR_CART && stock_status !== 'unavailable') {
        // Don't add any ListDescription for prescription_status if eligible for cart
        // only show "Add to cart" button
      } else if (prescription_status === PrescriptionStatusMap.PROCESSING) {
        descriptions.push(<ListDescription iconName="sync-small">Processing prescription</ListDescription>);
      } else if (prescription_status === PrescriptionStatusMap.REFILLS_REQUESTED) {
        descriptions.push(<ListDescription iconName="hourglass-small">Refills requested</ListDescription>);
      } else if (prescription_status === PrescriptionStatusMap.INACTIVE) {
        descriptions.push(<ListDescription iconName="closecircle-small">No active prescriptions</ListDescription>);
      }
    }

    if (therapeutic_alternative) {
      descriptions.push(<ListDescription iconName="swap-small">{therapeutic_alternative}</ListDescription>);
    }

    if (date) {
      descriptions.push(<ListDescription iconName="calendar-small">{date}</ListDescription>);
    }

    if (inCart) {
      descriptions.push(<ListDescription iconName="checkcircle-small">Added to cart</ListDescription>);
    }

    return descriptions;
  }, [date, inCart, patient_name, prescription_status, stock_status, therapeutic_alternative, upcoming_order]);

  const canAddToCart = prescription_status === 'ELIGIBLE_FOR_CART' && !inCart && stock_status !== 'unavailable';

  return (
    <ListItem
      backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}
      LeftContent={
        <Illustration
          borderRadius={BORDERS.RADIUS.MD.value}
          source={image_url || require('./med_image_placeholder.png')}
        />
      }
      title={title}
      RightContent={<AltoIcon name="chevronright-small" />}
      descriptions={listDescriptions}
      onPress={() => {
        navigate('RouteMedDetails', { origin: ORIGIN_NAMES.MED_LIST, primaryPrescriptionId: primary_prescription_id });
      }}
      BottomButton={
        canAddToCart ? (
          <Button
            small
            width="inline"
            loading={isDisabled}
            disabled={(!pressed && isDisabled) || isProgynyWeb}
            label={isProgynyWeb ? 'Order on the Alto app' : 'Add to cart'}
            onPress={showAddToCartActionSheet}
          />
        ) : undefined
      }
    />
  );
};
