import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { applyMiddleware, compose, createStore } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'redux-segment'. '/Users/Boyle/go/src/github.com/script... (Delete me to see the full error)
import { EventTypes, createTracker } from 'redux-segment';
import thunk from 'redux-thunk';
import { analyticsMiddleware } from '~shared/lib/analytics/src/middleware';
import sentryReduxEnhancerConfig from '~shared/lib/sentryReduxEnhancerConfig';
import cart from '~shared/middlewares/cart';
import reactors from '~shared/reactors';
import { Sentry } from '~shared/sentry';
import onboardingResume from './middlewares/onboarding-resume';
import reducersWithLogout from './reducer';

export const configureStore = () => {
  const middleware = routerMiddleware(browserHistory);

  // Segment
  const customMapper = {
    mapper: {
      '@@router/CALL_HISTORY_METHOD': EventTypes.page,
      '@@router/LOCATION_CHANGE': EventTypes.page,
    },
  };
  const tracker = createTracker(customMapper);

  // @ts-expect-error TS(2339): Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' does not exist on type 'Window & typeof globalThis'.
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // eslint-disable-next-line import/no-deprecated
  const store = createStore(
    // @ts-expect-error: Type 'State | undefined' is not assignable to type 'State' - because of custom redux logout wrapper
    reducersWithLogout,
    composeEnhancers(
      applyMiddleware(thunk, middleware, tracker, cart, analyticsMiddleware, onboardingResume),
      autoRehydrate(),
      Sentry.createReduxEnhancer(sentryReduxEnhancerConfig),
    ),
  );

  const persistor = persistStore(store, {
    blacklist: ['form', 'routing', 'modal', 'analytics', 'appTime', 'ui', 'presence'],
  });

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const nextReducer = require('./reducer').default;

      store.replaceReducer(nextReducer);
    });
  }

  reactors(store);

  return { store, persistor };
};
