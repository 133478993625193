// @owners { team: patients-team }
import { useAddOnsContext } from '@alto/features';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { useCallback } from 'react';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { useEssentialPrescriptionByProductID } from '~shared/features/essentials/hooks';
import { formatDollars } from '~shared/helpers/currency';
import { useAddOnsDeletePrescription } from '~shared/queries/useAddOnsDeletePrescription';
import { useAddOnsUpdatePrescription } from '~shared/queries/useAddOnsUpdatePrescription';
import { Button, ButtonSideText } from '~web/features/essentials/primitives';

type ButtonProps = {
  readonly itemQuantity: number;
  readonly essential: AddOnOtc;
  readonly analyticsProps: AnalyticsProps;
  readonly showPrice: boolean;
  readonly onClose?: () => void;
  readonly disabled?: boolean;
  readonly onDelete?: () => void;
};

export const ProductUpdateButton = ({
  onClose,
  itemQuantity,
  essential,
  analyticsProps,
  showPrice,
  disabled,
  onDelete,
}: ButtonProps) => {
  const { shipmentID, onRemove, onUpdateQuantity, setActiveProduct, activeProduct } = useAddOnsContext();
  const { mutateAsync: updateQuantity, isPending: updatePrescriptionLoading } = useAddOnsUpdatePrescription();
  const { mutateAsync: deletePrescription, isPending: deletePrescriptionLoading } = useAddOnsDeletePrescription();
  const active = !activeProduct || activeProduct === essential.product_id;
  const { essential: prescription } = useEssentialPrescriptionByProductID({
    productID: essential.product_id,
    shipmentID,
  });

  const targetObject = shipmentID ? 'order' : 'cart';

  const handleUpdate = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (setActiveProduct) {
        setActiveProduct(essential.product_id);
      }

      if (!prescription) return;
      if (itemQuantity > 0) {
        await onUpdateQuantity(essential, prescription, updateQuantity, itemQuantity, analyticsProps);
        if (onClose) onClose();
      } else {
        await onRemove(essential, prescription, deletePrescription, analyticsProps);
        if (onDelete) {
          onDelete();
        }
        if (onClose) {
          onClose();
        }
      }
      if (setActiveProduct) {
        setActiveProduct(null);
      }
    },
    [
      analyticsProps,
      onClose,
      prescription,
      essential,
      itemQuantity,
      onDelete,
      deletePrescription,
      onUpdateQuantity,
      updateQuantity,
      onRemove,
      setActiveProduct,
    ],
  );

  if (prescription) {
    return (
      <Button
        title={itemQuantity > 0 ? `Update ${targetObject}` : `Remove from ${targetObject}`}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleUpdate}
        disabled={disabled || updatePrescriptionLoading || deletePrescriptionLoading}
        loading={((active && updatePrescriptionLoading) || deletePrescriptionLoading) ?? false}
        Right={showPrice && !disabled ? <ButtonSideText text={formatDollars(itemQuantity * essential.price)} /> : null}
      />
    );
  }

  return null;
};
