// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, AltoIcon, LgSpacing, ListItem, ThirdPartyIcon } from '@alto/design-system';
import React, { useCallback, useContext } from 'react';
import { Platform } from 'react-native';
import { type PaymentMethodType } from '~shared/constants';
import { AddPaymentCardActionSheet } from './AddPaymentCardActionSheet';
import { PlaidListNavigation } from './PlaidListNavigation';

type Props = {
  readonly handleNativePay?: (paymentMethodType: PaymentMethodType) => void;
  readonly paymentMethodType: PaymentMethodType;
};

export const AddPaymentMethodActionSheet = ({ handleNativePay, paymentMethodType }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const showApplePay = Platform.OS === 'ios';

  const handlePaymentCardPress = useCallback(() => {
    setActiveActionSheet(<AddPaymentCardActionSheet paymentMethodType={paymentMethodType} />);
  }, [paymentMethodType, setActiveActionSheet]);

  const handleNativePayPress = useCallback(() => {
    if (handleNativePay) {
      handleNativePay(paymentMethodType);
    }
  }, [handleNativePay, paymentMethodType]);

  return (
    <ActionSheetV2
      title="Add new method"
      analyticsName="add payment method"
    >
      <ListItem
        LeftContent={<AltoIcon name="creditcard" />}
        title="Credit or Debit Card"
        RightContent={<AltoIcon name="chevronright-small" />}
        onPress={handlePaymentCardPress}
      />
      {showApplePay ? (
        <ListItem
          LeftContent={<ThirdPartyIcon name="applepay" />}
          RightContent={<AltoIcon name="chevronright-small" />}
          title="Apple Pay"
          onPress={handleNativePayPress}
        />
      ) : null}
      <PlaidListNavigation paymentMethodType={paymentMethodType} />
      <LgSpacing />
    </ActionSheetV2>
  );
};
