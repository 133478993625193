import { SHIPMENT_STATUS_MAP } from '~shared/features/shipments/constants';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import { type ShipmentStatus } from '~shared/types';

export const getShipmentStatusString = (status: ShipmentStatus | undefined, isPickup = false): string | undefined => {
  if (!status) {
    return '';
  }
  if (isPickup) {
    return SHIPMENT_STATUS_MAP[status]?.pickupStatusString;
  }
  return SHIPMENT_STATUS_MAP[status]?.statusString;
};

export const getShipmentStatusInfoText = ({ status, method }: Pick<ShipmentWithItems, 'status' | 'method'>) => {
  if (!status || !SHIPMENT_STATUS_MAP[status]) {
    return '';
  }

  const config = SHIPMENT_STATUS_MAP[status];

  if (!method) {
    return config?.infoText;
  }

  if (method.includes('fedex') || method.includes('usps')) {
    return config?.mailInfoText;
  }

  if (method.includes('pickup')) {
    return config?.pickupInfoText;
  }

  return config?.infoText;
};
