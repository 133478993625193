// @owners { team: patients-team }
import { Description } from '@alto/design-system';
import React from 'react';
import { getWhatToExpectCopy } from '~shared/features/shipments/helpers/getWhatToExpectCopy';
import { type ShipmentMethod, type ShipmentStatus } from '~shared/types';

type WhatToExpectOnDeliveryDayRowProps = {
  readonly method: ShipmentMethod | undefined;
  readonly shipmentStatus?: ShipmentStatus;
};
export const WhatToExpectOnDeliveryDayRow = ({ method, shipmentStatus }: WhatToExpectOnDeliveryDayRowProps) => {
  const copy = getWhatToExpectCopy(method, shipmentStatus);

  return copy ? <Description>{copy}</Description> : null;
};
