// @owners { team: patients-team }
import { isPickup } from './order';
import { type APIOrderMethod, type APIOrderStatus } from '~shared/features/delivery-info/types';
import { numberOfDaysSince } from '~shared/helpers/date';

type Props = {
  date: string;
  method?: APIOrderMethod | null;
  status?: APIOrderStatus | null;
};

export function isPickupPastDue({ date, method, status }: Props) {
  if (!isPickup(method)) return false;
  if (status !== 'shipped') return false;
  return numberOfDaysSince(date) > 0;
}
