// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  FlexColumn,
  FlexRow,
  H3,
  SimpleContainer,
  SimpleContainerHeader,
  SimpleContainerSection,
  Text,
  palette,
  spacing,
} from '@alto/pocky';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { openModal } from '~shared/actions/modal';
import { selectPrescription } from '~shared/actions/prescriptions';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { AUTO_REFILL_ACTION_CARD, AUTO_REFILL_EMPTY_LIST } from '~shared/features/auto-refill/constants';
import {
  getFormattedDeliveryDateForAutoRefillPrescriptions,
  getMedsWithAutoRefill,
} from '~shared/features/auto-refill/selectors/getMedsWithAutoRefill';
import { prescriptionIsActive } from '~shared/features/my-meds/helper';
import { formatQuantity } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { ContentWrapper, FullFlexRow, Main, PageBackground, Sidebar } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';
import SidebarItem from '~web/features/my-meds/components/SidebarItem';
import autoRefill from '~web/images/autoRefill@3x.png';

const BREADCRUMBS = [
  {
    text: 'Account',
    to: '/me',
  },
  {
    text: 'Auto Refill',
  },
];

const StyledContainerSection = styled(SimpleContainerSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
`;

// helps give more consistent wrapping in mobile web
const Spacer = styled.div`
  height: ${spacing.smSpacing};
  width: ${spacing.smSpacing};
`;

type PrescriptionProps = {
  readonly autoRefillDate: string;
  readonly medicationName: string;
  readonly prescription: Prescription;
};

const AutoRefillPrescription = ({ medicationName, autoRefillDate, prescription }: PrescriptionProps) => {
  const dispatch = useDispatchShared();
  const { id: prescriptionID, refills_remaining } = prescription;

  const openAutoRefillOffModal = () => {
    dispatch(selectPrescription(prescriptionID));
    dispatch(openModal('AUTO_REFILL'));
  };

  return (
    <StyledContainerSection key={`rx_ar_${prescriptionID}`}>
      <FlexRow
        verticallyAlignContent
        wrap
        spacing={spacing.xxsSpacing}
      >
        <H3>{medicationName}</H3>
        <FlexColumn
          verticallyAlignContent
          spacing={spacing.xsSpacing}
        >
          <FontAwesomeIcon
            icon={faSync}
            color={palette.grey1}
            size="sm"
          />
          <Text
            inline
            light
            small
          >
            {`${refills_remaining || 'No'} ${pluralize('Refill', refills_remaining)} Left (QTY: ${formatQuantity(
              prescription,
            )})`}
          </Text>
        </FlexColumn>
        <FlexColumn
          verticallyAlignContent
          spacing={spacing.xsSpacing}
        >
          <FontAwesomeIcon
            icon={faCalendarAlt}
            color={palette.grey1}
            size="sm"
          />
          <Text
            light
            small
          >
            {`Auto refills on ${autoRefillDate}`}
          </Text>
        </FlexColumn>
      </FlexRow>
      <Spacer />
      <ButtonContainer>
        <Button
          kind="secondary"
          onClick={openAutoRefillOffModal}
        >
          Turn auto refill off
        </Button>
      </ButtonContainer>
    </StyledContainerSection>
  );
};

export const ProfileAutoRefills = () => {
  const { trackPageView } = useAnalytics();
  const dispatch = useDispatchShared();
  const medicationsWithAutoRefills = useSelectorShared(getMedsWithAutoRefill);
  const deliveryDatesByPrescriptionID = useSelectorShared(getFormattedDeliveryDateForAutoRefillPrescriptions);

  useEffect(() => {
    trackPageView({
      event: EVENTS.AUTO_REFILL_MANAGEMENT_CENTER_VIEWED,
    });
  }, [trackPageView]);

  const navigateToMedList = () => {
    dispatch(push('/prescriptions'));
  };

  return (
    <PageBackground>
      <ContentWrapper>
        <FullFlexRow evenlySpaceContent>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
          <PageHeader>Auto refill</PageHeader>
        </FullFlexRow>
        <Main>
          <SimpleContainer>
            <SimpleContainerHeader>Medications on auto refill</SimpleContainerHeader>
            {isEmpty(medicationsWithAutoRefills) ? (
              <SimpleContainerSection>
                <Text>{AUTO_REFILL_EMPTY_LIST.body}</Text>
              </SimpleContainerSection>
            ) : (
              medicationsWithAutoRefills.reduce<React.ReactNode[]>((rxRows, { medicationName, prescriptions }) => {
                const rxWithAutoRefillRows = prescriptions.reduce<React.ReactNode[]>(
                  (rxsWithAutoRefills, prescription) => {
                    const { auto_refill, id } = prescription;

                    if (prescriptionIsActive(prescription) && auto_refill) {
                      rxsWithAutoRefills.push(
                        <AutoRefillPrescription
                          autoRefillDate={deliveryDatesByPrescriptionID[id]}
                          medicationName={medicationName}
                          prescription={prescription}
                        />,
                      );
                    }

                    return rxsWithAutoRefills;
                  },
                  [],
                );

                return [...rxRows, ...rxWithAutoRefillRows];
              }, [])
            )}
          </SimpleContainer>
        </Main>
        <Sidebar>
          <SidebarItem
            buttonText={AUTO_REFILL_ACTION_CARD.buttonText}
            img={autoRefill}
            imgAlt={IMG_ALT_TEXTS.autoRefill}
            onClick={navigateToMedList}
            text={AUTO_REFILL_ACTION_CARD.body}
          />
        </Sidebar>
      </ContentWrapper>
    </PageBackground>
  );
};
