// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { H1, Text, palette, spacing } from '@alto/pocky';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link as RRLink } from 'react-router';
import styled from 'styled-components';
import { PageBackground, SingleColumnContentWrapper } from '~web/components/Page';
import UnauthorizedFormWrapper from '~web/components/UnauthorizedFormWrapper';

const HugeEmoji = styled(H1)`
  text-align: center;
  font-size: 6rem;
  line-height: 1.2px;
  margin-bottom: ${spacing.mdSpacing};
`;

const Link = styled(RRLink)`
  color: ${palette.royalBlue};
  font-size: unset;
`;

export default class DeliveryConfirmedPage extends React.Component {
  render() {
    return (
      <PageBackground>
        <SingleColumnContentWrapper>
          <UnauthorizedFormWrapper header="Delivery Confirmed!">
            {/* @ts-expect-error: Type 'Element' is not assignable to type 'string' */}
            <HugeEmoji>
              <FontAwesomeIcon
                color={palette.grey1}
                icon={faThumbsUp}
              />
            </HugeEmoji>
            <Text>
              <Link to="/login">Login to your dashboard</Link> to edit this delivery.
            </Text>
          </UnauthorizedFormWrapper>
        </SingleColumnContentWrapper>
      </PageBackground>
    );
  }
}
