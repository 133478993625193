// @owners { team: patients-team }
import {
  type AllergensEndpointSearchRequest,
  type AllergensEndpointSearchResponse,
} from '@alto/scriptdash/alto/patient_app/allergens/v1/allergens_endpoint';
import { type DataSource, type DataSourceResponseRow } from '~shared/data-sources/types';
import { get } from '~shared/helpers/apiHelper';

export const allergensAutocompleteDataSource: DataSource<string> = async (text) => {
  const params: AllergensEndpointSearchRequest = { query: text };
  const config = { version: 'v1' };
  const response: AllergensEndpointSearchResponse = await get('/allergens/search', params, config);
  if (response.errors?.length) return Promise.reject(response);
  const dataSourceRows: DataSourceResponseRow<string>[] = response.data
    ? response.data.map((allergen) => ({
        title: allergen.description,
        label: allergen.description,
        key: allergen.description,
        value: JSON.stringify(allergen),
      }))
    : [];

  return dataSourceRows;
};
