import { Button, Description, InlineAlert, MdPadding } from '@alto/design-system';
import React, { useCallback } from 'react';
import { type PlaidLinkStableEvent, usePlaidLink } from 'react-plaid-link';
import { createBankAccountPaymentMethod } from '~shared/actions/paymentMethods';
import { PLAID_ENV, PLAID_PUBLIC_KEY } from '~shared/config';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type PlaidEventMetadata, getPlaidEvent } from '~shared/lib/analytics/src/getPlaidEvent';
import { useDispatchShared } from '~shared/store';

type PlaidMetadata = {
  account_id: string;
  institution: {
    name: string;
  };
};

export const PlaidLinkButton = () => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();

  const onSuccess = useCallback(
    async (publicToken: string, metadata: PlaidMetadata) => {
      const success = await dispatch(
        createBankAccountPaymentMethod({
          publicToken,
          accountId: metadata.account_id,
          institutionName: metadata.institution.name,
        }),
      );

      if (success) {
        trackEvent({
          event: EVENTS.PAYMENT_METHOD_CREATED,
          params: {
            newStripeFormEnabled: false,
            type: 'PlaidData',
          },
        });
      } else {
        trackEvent({
          event: EVENTS.CREATE_PAYMENT_METHOD_FAILED,
          params: {
            newStripeFormEnabled: false,
          },
        });
      }
    },
    [dispatch, trackEvent],
  );

  const sendPlaidAnalytics = (eventName: PlaidLinkStableEvent, metadata: PlaidEventMetadata) => {
    const event = getPlaidEvent(eventName, metadata);
    if (event) {
      dispatch(sendAnalyticsEvent(event));
    }
  };

  const { open, ready, error } = usePlaidLink({
    clientName: 'Alto',
    env: PLAID_ENV,
    // @ts-expect-error eventName types not compatible
    onEvent: sendPlaidAnalytics,
    // @ts-expect-error TS(2322): Type '(publicToken: string, metadata: PlaidMetadata) => void' is not assignable to type 'PlaidLinkOn... (Delete me to see the full error)
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onSuccess,
    product: ['auth'],
    publicKey: PLAID_PUBLIC_KEY,
  });

  if (error) {
    return (
      <InlineAlert type="warning">
        <MdPadding>
          <Description>
            Whoops! Something went wrong while loading Plaid. Please try again later, or message us if the problem
            doesn't resolve.
          </Description>
        </MdPadding>
      </InlineAlert>
    );
  }

  return (
    <Button
      loadingLabel="Loading Bank Data"
      type="secondary"
      onPress={open as () => void}
      loading={!ready}
    >
      Link Bank Account
    </Button>
  );
};
