// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  Card,
  Column,
  H1,
  LgPadding,
  LgSpacing,
  SecondaryPage,
  Separator,
  SmSpacing,
  XsSpacing,
  XxxlPadding,
} from '@alto/design-system';
import { AddOnsContext, OrderDetailsCard, PaymentSummary, SplitPaymentCard } from '@alto/features';
import { type ShipmentTip } from '@alto/scriptdash/alto/orders/types/v1/shipment_tip';
import React, { useCallback, useMemo } from 'react';
import styledNative from 'styled-components/native';
import { SHIPMENTS_API_VERSION } from '~shared/actions/shipments';
import { API_URL } from '~shared/config';
import { getIsTippableForShipmentID } from '~shared/features/checkout/selectors/getIsTippable';
import { canEditCourierTip } from '~shared/features/courier-tips/helpers';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder } from '~shared/features/payment-methods/helpers/checkIfPaymentMethodFailed';
import { type PaymentMethodWithAmount } from '~shared/features/payments/types';
import { type OrderPricing } from '~shared/features/pricing/types';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import { useHasCompoundPrescriptionsForShipment } from '~shared/features/shipments/hooks/useHasCompoundPrescriptionsForShipment';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import { useDeliveryDetailsViewed } from '~shared/lib/analytics/src/hooks/useDeliveryDetailsViewed';
import { useGetInsurances } from '~shared/queries/query-keys/insurances';
import { useSelectorShared } from '~shared/store';
import DeliveryItems from './DeliveryItems';
import { ShipmentSingleCarousel } from '~web/features/homescreen/components/ShipmentSingleCarousel';

const ParentWidthLgPadding = styledNative(LgPadding)`
  width: 100%
`;

type OrderDetailsProps = {
  readonly editShipment: () => void;
  readonly openCancelDeliveryPage: () => void;
  readonly openEditOrAddTipModal: () => void;
  readonly shipment: ShipmentWithItems;
  readonly shipmentPaymentMethods: PaymentMethodWithAmount[];
  readonly shipmentTip: ShipmentTip | null | undefined;
  readonly shipmentPricing: OrderPricing;
  readonly priceLoading: boolean;
};

export const OrderDetails = ({
  editShipment,
  openCancelDeliveryPage,
  openEditOrAddTipModal,
  shipment,
  shipmentPaymentMethods,
  shipmentTip,
  shipmentPricing,
  priceLoading,
}: OrderDetailsProps) => {
  useDeliveryDetailsViewed();
  const { hasGovernmentInsurance } = useGetInsurances();
  const shipmentID = shipment.id;
  const isTippable = useSelectorShared((state) =>
    getIsTippableForShipmentID(state, { shipmentID, hasGovernmentInsurance }),
  );
  const address = useSelectorShared((state) => getShipmentAddress(state, { shipmentID }));
  shipment.address = address;
  const canEditTip = canEditCourierTip(shipment, shipmentTip);
  const tipPaymentMethod = useMemo(() => {
    return shipmentPaymentMethods.find(({ payment_method_id }) => payment_method_id === shipmentTip?.payment_method_id)
      ?.paymentMethod;
  }, [shipmentPaymentMethods, shipmentTip]);
  const anyPaymentMethodFailed = shipmentPaymentMethods?.some((spm) =>
    checkIfPaymentMethodFailedBeforeSaveOrderAttemptOnExistingOrder(spm),
  );
  const hasFailedSplitPayments = shipmentPaymentMethods?.length > 1 && anyPaymentMethodFailed;
  const hasCompound = useHasCompoundPrescriptionsForShipment({ shipmentID });
  const shipmentIsEditable = !!shipment && isShipmentEditable(shipment);
  const isEssentialsAutoRefillOptOutAllowed = shipmentIsEditable && !hasCompound;

  const essentialsContext = useMemo(
    () =>
      ({
        shipmentID,
        origin: ORIGINS.EDIT_DELIVERY_DETAILS,
      }) as const,
    [shipmentID],
  );

  const handleDownloadReceipt = useCallback(() => {
    const url = `${API_URL}/${SHIPMENTS_API_VERSION}/shipments/${shipmentID}/receipt`;
    window.open(url, '_blank');
  }, [shipmentID]);

  return (
    <AddOnsContext.Provider value={essentialsContext}>
      <SecondaryPage
        HeaderContent={
          <XxxlPadding
            leftPadding={SPACING.STATIC.NONE}
            rightPadding={SPACING.STATIC.NONE}
          >
            <Column centerHorizontally>
              <H1>Order details</H1>
            </Column>
          </XxxlPadding>
        }
      >
        <OrderDetailsCard
          handleCancelDelivery={openCancelDeliveryPage}
          handleDownloadReceipt={handleDownloadReceipt}
          handleEditDelivery={editShipment}
          shipment={shipment}
        >
          {isEssentialsAutoRefillOptOutAllowed ? (
            <>
              <Separator />
              <LgSpacing />
              <ParentWidthLgPadding topPadding={SPACING.STATIC.NONE}>
                <ShipmentSingleCarousel />
              </ParentWidthLgPadding>
            </>
          ) : null}
        </OrderDetailsCard>
        <SmSpacing />
        <Card>
          <LgPadding>
            <DeliveryItems
              items={shipment.items}
              shipmentIsEditable={isShipmentEditable(shipment)}
              priceLoading={priceLoading}
              shipment={shipment}
            />
          </LgPadding>
        </Card>
        <SmSpacing />
        <Card>
          <PaymentSummary
            origin="DeliveryDetails"
            pricing={shipmentPricing}
            openEditTip={canEditTip ? openEditOrAddTipModal : undefined}
            showZeroTipLineItem={isTippable}
            showEditTipOption={canEditTip}
            shipmentPaymentMethods={shipmentPaymentMethods}
            tipPaymentMethod={tipPaymentMethod}
            shipmentID={shipment.id}
            shipment={shipment}
          />
        </Card>
        <XsSpacing />
        <SplitPaymentCard hasFailedSplitPayments={hasFailedSplitPayments} />
        <XsSpacing />
      </SecondaryPage>
    </AddOnsContext.Provider>
  );
};
