// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  InputRadioGroup,
  LgPadding,
  LgSpacing,
  ListButton,
  Row,
} from '@alto/design-system';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import PaymentMethodRadioOption from '~web/features/checkout/components/PaymentMethodRadioOption';
import { Button } from '~web/features/essentials/primitives';
import { CreditCardActionSheet } from '~web/features/payments/components/CreditCardActionSheet';

type Props = {
  readonly onSubmit: (paymentMethodID?: number) => void;
};
export const SelectPaymentMethodActionSheet = ({ onSubmit }: Props) => {
  const dispatch = useDispatchShared();
  const { closeActionSheet, setActiveActionSheet, goToPreviousActionSheet } = useContext(ActionSheetContext);
  const [paymentMethodID, setPaymentMethodID] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const paymentMethods = useSelectorShared(getPaymentMethods);
  const selectedMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === paymentMethodID);

  const handleSelectPaymentMethod = useCallback(
    (paymentMethodID: string) => {
      setPaymentMethodID(parseInt(paymentMethodID, 10));
    },
    [setPaymentMethodID],
  );

  const handleAddPaymentMethodClick = useCallback(() => {
    dispatch(sendAnalyticsEvent(createEvent(EVENTS.ADD_NEW_METHOD_TAPPED)));
    setActiveActionSheet(<CreditCardActionSheet onClose={goToPreviousActionSheet} />);
  }, [dispatch, setActiveActionSheet, goToPreviousActionSheet]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/await-thenable, @typescript-eslint/no-confusing-void-expression
    await onSubmit(paymentMethodID);
    setLoading(false);
    closeActionSheet();
  }, [onSubmit, paymentMethodID, setLoading, closeActionSheet]);

  const cardText = useMemo(
    () => [selectedMethod?.details?.card_type, selectedMethod?.details?.card_last_4].filter((text) => !!text).join('-'),
    [selectedMethod?.details?.card_last_4, selectedMethod?.details?.card_type],
  );

  return (
    <ActionSheetV2 title="Select payment method">
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <InputRadioGroup onValueChange={handleSelectPaymentMethod}>
          {paymentMethods.map((paymentMethod) => (
            <PaymentMethodRadioOption
              key={paymentMethod.id}
              deletable={false}
              paymentMethod={paymentMethod}
              value={paymentMethod.id.toString()}
            />
          ))}
        </InputRadioGroup>
        <LgSpacing />
        <ListButton
          label="Add credit card"
          rightIcon="creditcard"
          onPress={handleAddPaymentMethodClick}
        />
        <LgSpacing />
        <Row center>
          <Button
            title={`Select ${cardText || 'card'}`}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit}
            disabled={!selectedMethod || loading}
            loading={loading}
          />
        </Row>
      </LgPadding>
    </ActionSheetV2>
  );
};
