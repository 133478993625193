// eslint-disable-next-line @alto/no-pocky-import
import { Row, Text, palette, spacing } from '@alto/pocky';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { type ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled(Row)`
  align-items: baseline;
`;

const ChecklistInfoRow = ({ infoText, children }: { readonly infoText?: string; readonly children?: ReactElement }) => (
  <Container spacing={spacing.xsSpacing}>
    <FontAwesomeIcon
      icon={faCheckCircle}
      color={palette.navy}
      size="sm"
    />
    {infoText ? <Text>{infoText}</Text> : children}
  </Container>
);

export default ChecklistInfoRow;
