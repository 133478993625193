// @owners { team: patients-team }
import { Toast, ToastContext } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { CartEndpoint } from '@alto/scriptdash/alto/patient_app/carts/v1/cart_endpoint';
import { type PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { selectPaymentType__DEPRECATED, updateCartAutoRefill__DEPRECATED } from '~shared/actions/cart';
import { transformCartPriceType } from '~shared/features/cart/selectors/getReduxCart';
import { apiEndpointHandler } from '~shared/helpers/api';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getOrigin from '~shared/lib/analytics/src/getOrigin';
import { queries } from '~shared/queries/query-keys';
import { useDispatchShared } from '~shared/store';

const cartEndpoint = CartEndpoint(apiEndpointHandler);

type UpdateCartItemParams = {
  resource_id: number;
  resource_type: string;
  quantity?: number;
  auto_refill_enabled?: boolean | null | undefined;
  selected_price_type?: PriceType | null | undefined;
};

type UpdateCartItemRequestParams = {
  quantity?: number;
  auto_refill_enabled?: boolean | null | undefined;
  selected_price_type?: PriceType | null | undefined;
};

type UpdateCartItemResult = {
  success: boolean;
};

type UseUpdateCartItem = {
  isPending: boolean;
  isSuccess: boolean;
  handleUpdateCartItem: (params: UpdateCartItemParams) => Promise<UpdateCartItemResult>;
};

/**
 * Hook used to update item from the patient's cart.
 *
 *
 * const result = await handleUpdateCartItem({ resource_id: rx.id, resource_type: 'Prescription' });
 *
 * // perform logic after the item is successfully updated in the cart
 * if (result.success) {
 *   ...
 * }
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useUpdateCartItem = (): UseUpdateCartItem => {
  const { value: serverSideCartEnabled } = Experimentation.useFeatureFlag('server_side_cart');
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();

  const clearState = useCallback(() => {
    setIsSuccess(false);
  }, []);

  const updateCartItemRedux = useCallback(
    ({ resource_id, auto_refill_enabled, selected_price_type }: UpdateCartItemParams) => {
      clearState();
      if (auto_refill_enabled !== null && auto_refill_enabled !== undefined) {
        // eslint-disable-next-line import/no-deprecated
        dispatch(updateCartAutoRefill__DEPRECATED(resource_id, auto_refill_enabled));
        trackEvent({
          event: EVENTS.AUTO_REFILL_UI_STATUS_CHANGED,
          params: {
            origin: getOrigin(),
            auto_refill_status: auto_refill_enabled ? 'auto refill' : 'one-time fill',
          },
          additionalFields: {
            prescriptionId: resource_id,
          },
        });
      }
      if (selected_price_type !== null && selected_price_type !== undefined) {
        const priceType = transformCartPriceType(selected_price_type);
        if (priceType) {
          // eslint-disable-next-line import/no-deprecated
          dispatch(selectPaymentType__DEPRECATED(resource_id, priceType));
        }
      }
      setIsSuccess(true);
      return Promise.resolve({ success: true });
    },
    [clearState, dispatch, trackEvent],
  );

  const { isPending, mutateAsync } = useMutation({
    // this should be of type CartEndpointUpdateItemRequest once we update cart proto
    mutationFn: async (params: any) => {
      setIsSuccess(false);
      const response = await cartEndpoint.updateItem(params);
      return { response };
    },
  });

  const updateCartItemApi = useCallback(
    async ({ resource_id, quantity, auto_refill_enabled, selected_price_type }: UpdateCartItemParams) => {
      // this should be of type CartEndpointUpdateParams once we update cart proto
      const requestParams: UpdateCartItemRequestParams = {
        quantity: quantity || 1,
        auto_refill_enabled,
        selected_price_type,
      };
      try {
        const { response } = await mutateAsync({ id: resource_id, params: requestParams });
        if (response.errors) {
          const errorMessage = getErrorMessageFromResponse({ errors: response.errors });
          addToast(<Toast variant="error">{errorMessage}</Toast>);
          return { success: false };
        }

        queryClient.invalidateQueries({ queryKey: queries.cart.active({}).queryKey });
        return { success: true };
      } catch (e) {
        const errorMessage = getErrorMessageFromResponse({});
        addToast(<Toast variant="error">{errorMessage}</Toast>);
        return { success: false };
      }
    },
    [addToast, mutateAsync, queryClient],
  );

  if (serverSideCartEnabled) {
    return {
      isSuccess,
      isPending,
      handleUpdateCartItem: updateCartItemApi,
    };
  }

  return {
    isSuccess,
    isPending: serverSideCartEnabled === undefined,
    handleUpdateCartItem: updateCartItemRedux,
  };
};
