import { Column } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, SectionContainerSection } from '@alto/pocky';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { type InsuranceSource } from '~shared/constants';
import { type Insurance as InsuranceType } from '~shared/types/clients';
import { Insurance } from './Insurance';

type Props = {
  readonly insurances: InsuranceType[];
  readonly onAddInsurance?: () => void;
  readonly source: InsuranceSource;
};

const StyledSection = styled(SectionContainerSection)`
  display: flex;
  justify-content: flex-end;
`;

export default class InsuranceList extends PureComponent<Props> {
  render() {
    const { insurances, onAddInsurance, source } = this.props;
    return (
      <>
        <Column gap="LG">
          {insurances.map((insurance) => (
            <Insurance
              key={insurance.id}
              insurance={insurance}
              source={source}
            />
          ))}
        </Column>
        {onAddInsurance ? (
          <StyledSection>
            <Button
              kind="secondary"
              onClick={onAddInsurance}
            >
              <span>Add another benefit</span>
              <span>&nbsp;+</span>
            </Button>
          </StyledSection>
        ) : null}
      </>
    );
  }
}
