import { type AnyAction } from 'redux';
import { LOGOUT_SUCCEEDED } from '~shared/actions/auth';
import { type ReduxStateShared, getSharedReducers } from '~shared/store';

export const reducers = getSharedReducers();

// Clears state on log out
const reducersWithLogout = (state: ReduxStateShared, action: AnyAction): ReduxStateShared => {
  const { type } = action;

  if (state && type === LOGOUT_SUCCEEDED) {
    const { notices } = state;

    // @ts-expect-error - cannot override readonly state
    state = {
      notices: { ...notices },
    };
  }

  return reducers(state, action) as ReduxStateShared;
};

export default reducersWithLogout;
