// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CHAT_ORIGINS,
  type ActionSheetProviderStackCacheKey,
  type AssistantPastDeliveryIssue,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { DELIVERY_METHOD_PICKUP } from '~shared/features/checkout/constants';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { formatDateWithDowWithoutYear, formatDateWithoutDowWithoutYear } from '~shared/helpers/date';
import { formatList } from '~shared/helpers/list';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type AssistantMessageParams } from '../../../constants';
import { MenuOption } from '../MenuOption';
import { AddressConfirmationActionSheet } from './AddressConfirmationActionSheet';

type Props = {
  readonly shipment: ShipmentWithItemsAndAdditionalFields;
};

export const ReportIssueActionSheet = ({ shipment }: Props) => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();

  const { saveStack, setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const { address, date, items: shipmentItems, shipment_method } = shipment;
  const isPickupDelivery = shipment_method === DELIVERY_METHOD_PICKUP;
  const dateForSubjectLine = formatDateWithoutDowWithoutYear(date);
  const dateForTitle = formatDateWithDowWithoutYear(date);
  const messageDescriptionText = formatList(shipmentItems.map((i) => i.displayName()));

  const trackActionChosen = (action: AssistantPastDeliveryIssue) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.PAST_DELIVERY_REPORT_ISSUE_ACTION_CHOSEN,
        action,
        editable: false,
        shipmentID: shipment.id,
      },
    });
  };

  const navigateToMessageForm = (params: Pick<AssistantMessageParams, 'messagePrompt' | 'subject' | 'category'>) => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteAssistantMessage', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_PAST_DELIVERY_REPORT_ISSUE_ACTION_MENU,
      description: messageDescriptionText,
      ...params,
    });
  };

  const handlePressNeverArrived = () => {
    // when there is an address, have the patient confirm if the right address and safe place
    if (address) {
      setActiveActionSheet(
        <AddressConfirmationActionSheet
          shipment={shipment}
          address={address}
        />,
      );
      return;
    }

    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    // otherwise, navigate to RouteAssistantMessage
    navigateToMessageForm({
      messagePrompt: MESSAGE_PROMPTS.DELIVERY_NEVER_ARRIVED,
      subject: `Delivery from ${dateForSubjectLine} - Never arrived`,
      category: 'deliveries.never_arrived',
    });
  };

  const items = [
    {
      key: 'delivery-never-arrived',
      component: (
        <MenuOption
          iconName="box-duo"
          hide={isPickupDelivery}
          onPress={() => {
            trackActionChosen('never_arrived');
            handlePressNeverArrived();
          }}
          rightIconName="chevronright"
          text="Delivery never arrived"
        />
      ),
    },
    {
      key: 'missing-items',
      component: (
        <MenuOption
          iconName="pillbottle-duo"
          onPress={() => {
            trackActionChosen('missing_items');
            navigateToMessageForm({
              messagePrompt: MESSAGE_PROMPTS.DELIVERY_MISSING_ITEMS,
              subject: `Delivery from ${dateForSubjectLine} - Missing items`,
              category: 'deliveries.missing_items',
            });
          }}
          rightIconName="chevronright"
          text="Missing items"
        />
      ),
    },
    {
      key: 'incorrect-items',
      component: (
        <MenuOption
          iconName="cancel-duo"
          onPress={() => {
            trackActionChosen('incorrect_items');
            navigateToMessageForm({
              messagePrompt: MESSAGE_PROMPTS.DELIVERY_INCORRECT_ITEMS,
              subject: `Delivery from ${dateForSubjectLine} - Incorrect items`,
              category: 'deliveries.incorrect_items',
            });
          }}
          rightIconName="chevronright"
          text="Incorrect items"
        />
      ),
    },
    {
      key: 'damaged-items',
      component: (
        <MenuOption
          iconName="alert-duo"
          onPress={() => {
            trackActionChosen('damaged_items');
            navigateToMessageForm({
              messagePrompt: MESSAGE_PROMPTS.DELIVERY_DAMAGED_ITEMS,
              subject: `Delivery from ${dateForSubjectLine} - Damaged items`,
              category: 'deliveries.damaged_items',
            });
          }}
          rightIconName="chevronright"
          text="Damaged items"
        />
      ),
    },
    {
      key: 'something-else',
      component: (
        <MenuOption
          iconName="questionmark-duo"
          onPress={() => {
            trackActionChosen('something_else');
            navigateToMessageForm({
              messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
              subject: `Delivery from ${dateForSubjectLine}`,
              category: 'deliveries.something_else',
            });
          }}
          rightIconName="chevronright"
          text="Something else"
        />
      ),
    },
  ];

  const description = isPickupDelivery ? `Picked up on ${dateForTitle}` : `Delivered on ${dateForTitle}`;

  return (
    <ActionSheetV2
      title="What is the issue?"
      description={description}
      analyticsName="assistant report issue"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
