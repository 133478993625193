// @owners { team: patients-team }
import { SIZES } from '@alto/design-library-tokens';
import { SizedImage } from '@alto/design-system';
import React from 'react';

export const PhoneImage = () => {
  return (
    <SizedImage
      source={require('./phone@2x.png')}
      pixelSize={SIZES.ILLUSTRATION.LG.value}
    />
  );
};
