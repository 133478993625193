// @owners { team: patients-team }
import { Column, ListButton, SmSpacing } from '@alto/design-system';
import { useQueryFeaturedOtcs } from '@alto/features';
import React from 'react';
import { push } from 'react-router-redux';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { ProductScrollView } from '~web/features/essentials/components';
import { ProductCarousel } from '~web/features/essentials/pages/OrderSuccess/ProductCarousel';
import { useDeprecatedMediaQuery } from '~web/hooks';

type Props = {
  readonly origin: string;
  readonly shipmentID?: number | null;
};

export const StandaloneEssentialsCarousel = ({ shipmentID, origin }: Props) => {
  const dispatch = useDispatchShared();
  const isMediumScreen = useDeprecatedMediaQuery('MD');
  const isMobile = !useDeprecatedMediaQuery('SM');
  const shipment = useSelectorShared((state) => (shipmentID ? getShipmentWithID(state, { shipmentID }) : null));
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID }));
  const addressLoading = useSelectorShared((state) => state.addresses.lastRequestSucceededAt === 0);
  const { isError, products } = useQueryFeaturedOtcs({
    addressID: address?.id,
    date: shipment?.scheduled_date,
  });

  const handleNavToEssentials = () => {
    dispatch(push({ pathname: '/store', search: `?origin=${origin}${shipment ? '&order=' + shipment.id : ''}` }));
  };

  if (!address || addressLoading || isError || !products) {
    return null;
  }

  let productCount;
  if (isMobile) {
    productCount = 2;
  } else {
    productCount = 4;
  }

  return (
    <>
      {isMediumScreen ? (
        <ProductCarousel
          numOfItems={products.length}
          numOfColumns={productCount}
          products={products}
          categoryName="Featured"
        />
      ) : (
        <ProductScrollView
          count={productCount}
          products={products}
          categoryName="Featured"
          scrollOverflow
        />
      )}
      <SmSpacing />
      <Column flexGrow={1}>
        <ListButton
          label="See all products"
          rightIcon="chevronright"
          onPress={handleNavToEssentials}
        />
      </Column>
    </>
  );
};
