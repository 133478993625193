import {
  AddFamilyMember,
  AddInsurancePage,
  BenefitPage,
  CancelOrderForm,
  DeliveryAddressesPage,
  DeliverySelection,
  FamilyMemberAdded,
  FamilyMemberBenefits,
  FamilyMemberCompleteProfile,
  FamilyMemberMedicalInfo,
  FamilyMemberRequest,
  MedicationSelection,
  NotificationCenter,
  OnboardingLandingPage as OrganicOnboardingLandingPage,
  PriceDetails,
  QuestionPage,
  ReadOnlyThread,
  SupportCaseNotFound,
  SupportPage,
  TransferSuccess,
} from '@alto/features';
import React, { useEffect } from 'react';
import { IndexRedirect, Redirect, Route } from 'react-router';
import { CSSTransitionGroup } from 'react-transition-group';
import { SEGMENT_WRITE_KEY } from '~shared/config';
import {
  CREATE_ACCOUNT,
  INSURANCE_FOUND,
  INSURANCE_INPUT,
  INSURANCE_INPUT_MANUAL,
  INSURANCE_INPUT_PHOTO,
  MEDICAL_INFO,
  MED_CONFIRMATION,
  ONBOARD_SUCCESS,
  PHI_AUTHORIZATION,
  TARGETED_DIAGNOSES,
  TRANSFER,
  VERIFY,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { ROUTE_TO_URL } from '~shared/features/onboarding/helpers/routeToPath';
import { getAddFamilyMemberRelationship } from '~shared/selectors/ui/addFamilyMember';
import { useSelectorShared } from '~shared/store';
import Page from './components/PageContainer';
import { ReplyForm } from './features/alto-assistant/components/support-cases/ReplyForm';
import SupportCase from './features/alto-assistant/components/support-cases/SupportCase';
import AppModals from './features/app/components/AppModals';
import Banner from './features/app/components/BannerContainer';
import DashboardNavbar from './features/app/components/DashboardNavbarContainer';
import Footer from './features/app/components/Footer';
import { ImposterBanner } from './features/app/components/ImposterBanner';
import NoMatch from './features/app/components/NoMatch';
import { isAddFamilyMemberRoute, isRouteWithoutFooter } from './features/app/components/routeHelper';
import AutoRefillModal from './features/auto-refill/components/AutoRefillModalContainer';
import PhotoIDUpload from './features/checkout/components/PhotoIDUploadContainer';
import { DeliveriesList, OrderDetails } from './features/deliveries';
import DeliveryConfirmedPage from './features/deliveries/components/DeliveryConfirmedPage';
import LegacyOrderSuccess from './features/deliveries/components/OrderSuccessContainer';
import { OrderSuccess, StoreCategory, StoreProduct, StoreProducts } from './features/essentials';
import HomescreenContainer from './features/homescreen/components/HomescreenContainer';
import { InsurancePhotoFormPage } from './features/insurances/components/InsurancePhotoFormPage';
import { LandingPage as OnboardingLandingPage } from './features/onboarding/components/LandingPage';
import ProgynyLandingPage from './features/onboarding/components/ProgynyLandingPageContainer';
import SurveyPage from './features/onboarding/components/SurveyPageContainer';
import { OnboardingCreateAccountPage } from './features/onboarding/components/redesign/OnboardingCreateAccountPage';
import OnboardingInsuranceFound from './features/onboarding/components/redesign/OnboardingInsuranceFoundContainer';
import { OnboardingInsuranceInputContainer as OnboardingInsuranceInput } from './features/onboarding/components/redesign/OnboardingInsuranceInputContainer';
import OnboardingInsuranceInputManual from './features/onboarding/components/redesign/OnboardingInsuranceInputManual';
import OnboardingInsuranceInputPhoto from './features/onboarding/components/redesign/OnboardingInsuranceInputPhoto';
import { OnboardingMedConfirmation } from './features/onboarding/components/redesign/OnboardingMedConfirmation';
import OnboardingMedicalInfo from './features/onboarding/components/redesign/OnboardingMedicalInfo';
import OnboardingPhiAuthorization from './features/onboarding/components/redesign/OnboardingPhiAuthorization';
import OnboardingProviderSuccess from './features/onboarding/components/redesign/OnboardingProviderSuccessContainer';
import { OnboardingTargetedDiagnoses } from './features/onboarding/components/redesign/OnboardingTargetedDiagnoses';
import OnboardingTransfer from './features/onboarding/components/redesign/OnboardingTransferContainer';
import { OnboardingVerifyContainer as OnboardingVerify } from './features/onboarding/components/redesign/OnboardingVerifyContainer';
import PharmacyInfoModal from './features/profile/components/PharmacyInfoModalContainer';
import { ProfileAutoRefills } from './features/profile/components/ProfileAutoRefills';
import ProfileContactInfo from './features/profile/components/ProfileContactInfoContainer';
import ProfileContactSupport from './features/profile/components/ProfileContactSupportContainer';
import Profile from './features/profile/components/ProfileContainer';
import ProfileInstructionalVideos from './features/profile/components/ProfileInstructionalVideosContainer';
import ProfilePaymentMethods from './features/profile/components/ProfilePaymentMethodsContainer';
import { ProfileSupport } from './features/profile/components/ProfileSupport';
import ProfileUserProfile from './features/profile/components/ProfileUserProfileContainer';
import SendPrescriptionOptions from './features/profile/components/SendPrescriptionOptionsContainer';
import RateShipmentPage from './features/rate-shipment/RateShipmentPageContainer';
import NotMe from './features/subscription-management/NotMeContainer';
import SubscriptionsPage from './features/subscription-management/SubscriptionsPageContainer';
import CommsVerificationStatus from './features/subscription-management/components/CommsVerificationStatusContainer';
import UnauthCommsVerificationStatus from './features/subscription-management/components/UnauthCommsVerificationStatusContainer';
import IdentityVerification from './features/subscription-management/verification/IdentityVerificationContainer';
import { TransferPrescriptions } from './features/transfers/components/TransferPrescriptions';
import WebLandingPageTracker from './features/web-landing-page-tracker/components/WebLandingPageTrackerContainer';
import { WhatMedicationQuestion } from '~web/features/alto-assistant/components/medications/WhatMedicationQuestion';
import { MessageForm } from '~web/features/alto-assistant/components/messaging/MessageForm';
import requireAuthentication from '~web/features/auth/components/AuthenticatedComponent';
import { ForgotPasswordPage } from '~web/features/auth/components/ForgotPasswordPage';
import ResetPasswordPage from '~web/features/auth/components/ResetPasswordPage';
import { Welcome } from '~web/features/auth/components/Welcome';
import { CheckoutContainer } from '~web/features/checkout/components/CheckoutContainer';
import { CsatSurveyPage } from '~web/features/csat/CsatSurveyPage';
import InsuranceModal from '~web/features/insurances/components/InsuranceModalContainer';
import { MedSyncSelfServiceConfirmedPage } from '~web/features/med-sync-self-service/ConfirmedPage';
import { MedSyncSelfServiceCreatingPlanPage } from '~web/features/med-sync-self-service/CreatingPlanPage';
import { MedSyncSelfServiceInfoPage } from '~web/features/med-sync-self-service/InfoPage';
import { MedSyncSelfServiceRequestReceivedPage } from '~web/features/med-sync-self-service/RequestReceivedPage';
import { MedSyncSelfServiceReviewPage } from '~web/features/med-sync-self-service/ReviewPage';
import { MedSyncSelfServiceRoutingContainer } from '~web/features/med-sync-self-service/RoutingContainer';
import { MedSyncSelfServiceSelectMedicationsPage } from '~web/features/med-sync-self-service/SelectMedicationsPage';
import { MessagesInfoModalContainer } from '~web/features/messages/components/MessagesInfoModalContainer';
import { DrugInfo } from '~web/features/my-meds/components/DrugInfo';
import { MedDetailsWrapper } from '~web/features/my-meds/components/MedDetailsWrapper';
import { MedListWrapper } from '~web/features/my-meds/components/MedListWrapper';
import ContactRequestedPage from '~web/features/onboarding/components/ContactRequestedPage';
import OnboardingModal from '~web/features/onboarding/components/OnboardingModalContainer';
import OnboardingResume from '~web/features/onboarding/components/OnboardingResumeContainer';
import SignupPage from '~web/features/onboarding/components/SignupPageContainer';

type Props = {
  children: React.ReactNode;
  location: Location;
};

const App = ({ location, children }: Props) => {
  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'analytics' does not exist on type 'Window & typeof globalThis'.
    const { analytics } = window;
    if (analytics?.load && SEGMENT_WRITE_KEY) {
      analytics.load(SEGMENT_WRITE_KEY);
    } else {
      console.error('Not able to load analytics:', {
        SEGMENT_WRITE_KEY,
        analytics,
      });
    }
  }, []);

  // relationship is set in redux when adding a family member, hide footer in that flow
  const relationship = useSelectorShared(getAddFamilyMemberRelationship);
  const isAddingFamilyMember = isAddFamilyMemberRoute(window.location.pathname, relationship);
  const shouldHideFooter = isRouteWithoutFooter(window.location.pathname) || isAddingFamilyMember;
  const footer = shouldHideFooter ? null : <Footer />;

  return (
    <Page footer={footer}>
      <ImposterBanner>
        <DashboardNavbar location={location} />
        <Banner />
        {/* Add new modals to AppModals instead of adding them here */}
        <InsuranceModal />
        <OnboardingModal />
        <OnboardingResume />
        <MessagesInfoModalContainer />
        <AutoRefillModal />
        <PharmacyInfoModal />
        <PhotoIDUpload />
        <AppModals />

        <CSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
        >
          {/* @ts-expect-error no overload matches this call */}
          {React.cloneElement(children, {
            key: location.pathname,
          })}
        </CSSTransitionGroup>
      </ImposterBanner>
    </Page>
  );
};

let hasFetchedInitialData = false;

// @ts-expect-error TS(7006): Parameter 'component' implicitly has an 'any' type.
const appWrapper = (component, getInitialData, isUsingNextParam = true) => {
  if (!hasFetchedInitialData) {
    getInitialData();
    hasFetchedInitialData = true;
  }

  return requireAuthentication(component, getInitialData, isUsingNextParam);
};

// this looks like a react-component, but it's not used as one
// @ts-expect-error TS(7031): Binding element 'removeNotifications' implicitly has an 'any' type.
// eslint-disable-next-line react/prop-types
const Routes = ({ removeNotifications, getInitialData }) => (
  <div>
    <Route
      exact
      path="/start/progyny/:tempPassword"
      component={ProgynyLandingPage}
    />
    <Route
      exact
      path="/start/:tempPassword"
      component={WebLandingPageTracker}
    />
    <Route
      exact
      path="/begin/:tempPassword"
      component={OnboardingLandingPage}
    />
    <Route
      exact
      path="/survey/:uuid"
      component={SurveyPage}
    />
    <Route
      exact
      path="/subscriptions/:uuid"
      component={SubscriptionsPage}
    />
    <Route
      exact
      path="/csat/:hashID"
      component={CsatSurveyPage}
    />

    <Route
      path="/"
      component={App}
    >
      <IndexRedirect to="/login" />

      <Route
        path="rate-shipment/:uuid"
        component={RateShipmentPage}
      />

      <Route
        path="orders/cancel"
        component={CancelOrderForm}
      />

      <Redirect
        from="deliveries/cancel"
        to="orders/cancel"
      />

      <Route
        path="orders"
        component={appWrapper(DeliveriesList, getInitialData)}
        onLeave={removeNotifications}
      />

      <Route
        path="orders/:orderID"
        component={appWrapper(OrderDetails, getInitialData)}
      />

      <Route
        path="prescriptions"
        component={appWrapper(MedListWrapper, getInitialData)}
        onLeave={removeNotifications}
      />

      <Route
        path="prescriptions/:primaryPrescriptionId"
        component={appWrapper(MedDetailsWrapper, getInitialData)}
        onLeave={removeNotifications}
      />

      <Route
        path="prescriptions/:rx_number/drug_info"
        component={appWrapper(DrugInfo, getInitialData)}
        onLeave={removeNotifications}
      />

      <Route
        path="messages"
        component={appWrapper(ReadOnlyThread, getInitialData)}
      />
      <Route
        path="notifications"
        component={appWrapper(NotificationCenter, getInitialData)}
      />

      {/* Both /messages_inbox and /help route to alto assistant support page */}
      {/* With react-router v4 we should use <Redirect /> instead. messages_inbox is set on the backend */}
      {/* for deeplinking purposes on the mobile app */}
      <Route
        path="messages_inbox"
        component={appWrapper(SupportPage, getInitialData)}
      />
      <Route
        path="help"
        component={appWrapper(SupportPage, getInitialData)}
      />

      <Route
        path="help/message"
        component={appWrapper(MessageForm, getInitialData)}
      />

      <Route
        path="help/deliveries"
        component={appWrapper(DeliverySelection, getInitialData)}
      />

      <Route
        path="help/deliveries/price-details/:shipmentID"
        component={appWrapper(PriceDetails, getInitialData)}
      />

      <Route
        path="help/medications"
        component={appWrapper(MedicationSelection, getInitialData)}
      />

      <Route
        path="help/support/:supportCaseID"
        component={appWrapper(SupportCase, getInitialData)}
      />

      <Route
        path="help/support/:supportCaseID/reply"
        component={appWrapper(ReplyForm, getInitialData)}
      />

      <Route
        path="help/support/request_missing"
        component={appWrapper(SupportCaseNotFound, getInitialData)}
      />

      <Route
        path="help/medication_questions"
        component={appWrapper(WhatMedicationQuestion, getInitialData)}
      />

      <Route
        path="me"
        component={appWrapper(Profile, getInitialData)}
      />

      <Route
        path="me/:userID/add-benefit"
        component={appWrapper(AddInsurancePage, getInitialData)}
      />

      <Route
        path="me/auto-refills"
        component={appWrapper(ProfileAutoRefills, getInitialData)}
      />

      <Route
        path="me/contact-info"
        component={appWrapper(ProfileContactInfo, getInitialData)}
      />

      <Route
        path="me/payments"
        component={appWrapper(ProfilePaymentMethods, getInitialData)}
      />

      <Route
        path="me/family_member"
        component={appWrapper(AddFamilyMember, getInitialData)}
      />

      <Route
        path="me/family_member/added"
        component={appWrapper(FamilyMemberAdded, getInitialData)}
      />

      <Route
        path="me/:userID/medical-info"
        component={appWrapper(FamilyMemberMedicalInfo, getInitialData)}
      />

      <Route
        path="me/:userID/add-benefits"
        component={appWrapper(FamilyMemberBenefits, getInitialData)}
      />

      <Route
        path="me/:userID/complete-profile"
        component={appWrapper(FamilyMemberCompleteProfile, getInitialData)}
      />

      <Route
        path="/me/family_member/request"
        component={appWrapper(FamilyMemberRequest, getInitialData)}
      />

      <Route
        path="me/addresses"
        component={appWrapper(DeliveryAddressesPage, getInitialData)}
      />

      <Route
        path="me/faq"
        component={appWrapper(ProfileSupport, getInitialData)}
      />

      <Route
        path="me/faq/:faqKey"
        component={appWrapper(QuestionPage, getInitialData)}
      />

      <Route
        path="me/contact-support"
        component={appWrapper(ProfileContactSupport, getInitialData)}
      />

      <Route
        path="me/send-prescription-options"
        component={appWrapper(SendPrescriptionOptions, getInitialData)}
      />

      <Route
        path="me/fertility-videos"
        component={appWrapper(ProfileInstructionalVideos, getInitialData)}
      />

      <Route
        path="me/:id"
        component={appWrapper(ProfileUserProfile, getInitialData)}
      />

      <Route
        path="me/:userID/benefits/photos"
        component={appWrapper(InsurancePhotoFormPage, getInitialData)}
      />

      <Route
        path="me/:userID/benefits"
        component={appWrapper(BenefitPage, getInitialData)}
      />

      <Route
        path="checkout"
        component={appWrapper(CheckoutContainer, getInitialData)}
      />

      <Route
        path="checkout/success/:shipmentID"
        component={appWrapper(LegacyOrderSuccess, getInitialData)}
      />

      <Route
        path="transfer"
        component={TransferPrescriptions}
      />

      <Route
        path="transfer/success"
        component={appWrapper(TransferSuccess, getInitialData)}
      />

      <Route
        path="home"
        component={appWrapper(HomescreenContainer, getInitialData)}
      />

      <Route
        path="store"
        component={appWrapper(StoreProducts, getInitialData)}
      />
      <Route
        path="store/:id"
        component={appWrapper(StoreProduct, getInitialData)}
      />
      <Route
        path="store/order-success/:shipmentID"
        component={appWrapper(OrderSuccess, getInitialData)}
      />
      <Route
        path="store/category/:categoryName"
        component={appWrapper(StoreCategory, getInitialData)}
      />

      {/* MedSync Self Service routes */}
      <Route
        path="bundle-delivery"
        component={appWrapper(MedSyncSelfServiceRoutingContainer, getInitialData)}
      />
      <Route
        path="bundle-delivery/info"
        component={appWrapper(MedSyncSelfServiceInfoPage, getInitialData)}
      />
      <Route
        path="bundle-delivery/select-medications"
        component={appWrapper(MedSyncSelfServiceSelectMedicationsPage, getInitialData)}
      />
      <Route
        path="bundle-delivery/request-received"
        component={appWrapper(MedSyncSelfServiceRequestReceivedPage, getInitialData)}
      />
      <Route
        path="bundle-delivery/creating-plan"
        component={appWrapper(MedSyncSelfServiceCreatingPlanPage, getInitialData)}
      />
      <Route
        path="bundle-delivery/review"
        component={appWrapper(MedSyncSelfServiceReviewPage, getInitialData)}
      />
      <Route
        path="bundle-delivery/confirmed"
        component={appWrapper(MedSyncSelfServiceConfirmedPage, getInitialData)}
      />

      {/* Begin onboarding redesign routes */}
      {/* Verify is actually no longer explicitly part of the flow (it's a modal/drawer on the landing page), however
      this still exists in case the user is dropped into another onboarding page without verifying, they will redirect
      to this page */}
      <Route
        path={ROUTE_TO_URL[VERIFY]}
        component={OnboardingVerify}
      />

      <Route
        path={ROUTE_TO_URL[MED_CONFIRMATION]}
        component={appWrapper(OnboardingMedConfirmation, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[TARGETED_DIAGNOSES]}
        component={appWrapper(OnboardingTargetedDiagnoses, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[INSURANCE_FOUND]}
        component={appWrapper(OnboardingInsuranceFound, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[INSURANCE_INPUT]}
        component={appWrapper(OnboardingInsuranceInput, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[INSURANCE_INPUT_MANUAL]}
        component={appWrapper(OnboardingInsuranceInputManual, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[INSURANCE_INPUT_PHOTO]}
        component={appWrapper(OnboardingInsuranceInputPhoto, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[MEDICAL_INFO]}
        component={appWrapper(OnboardingMedicalInfo, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[TRANSFER]}
        component={appWrapper(OnboardingTransfer, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[PHI_AUTHORIZATION]}
        component={appWrapper(OnboardingPhiAuthorization, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[CREATE_ACCOUNT]}
        component={appWrapper(OnboardingCreateAccountPage, getInitialData, false)}
      />

      <Route
        path={ROUTE_TO_URL[ONBOARD_SUCCESS]}
        component={appWrapper(OnboardingProviderSuccess, getInitialData, false)}
      />
      {/* End onboarding redesign routes */}

      {/* Kept for backwards compatibility, make sure this is after the other onboarding routes to get matched last */}
      <Redirect
        from="onboard/:tempPassword"
        to="onboard/verify/:tempPassword"
      />

      <Route
        path="login"
        component={Welcome}
      />

      <Route
        path="signup"
        component={SignupPage}
      />

      <Route
        path="forgot_password"
        component={ForgotPasswordPage}
      />

      <Route
        path="reset_password"
        component={ResetPasswordPage}
      />

      <Route
        path="delivery_confirmed"
        component={DeliveryConfirmedPage}
      />

      <Route
        path="/communications/:verificationType/not_me"
        component={NotMe}
      />

      <Route
        path="/communications/verify_identity/:commsChannel"
        component={IdentityVerification}
      />

      <Route
        path="communications/verify_identity/:commsChannel/:verificationStatus"
        component={UnauthCommsVerificationStatus}
      />

      <Route
        path="communications/verify/:commsChannel/:token"
        component={appWrapper(CommsVerificationStatus, getInitialData)}
      />

      <Route
        path="contact_requested"
        component={ContactRequestedPage}
      />

      <Route
        path="onboarding_resume"
        component={OnboardingResume}
      />

      <Route
        path="patient-onboarding"
        component={OrganicOnboardingLandingPage}
      />

      <Route
        path="*"
        component={NoMatch}
      />
    </Route>
  </div>
);

export default Routes;
