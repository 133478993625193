import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  Danger,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  Form,
  Input,
  InputBlock,
  InputRow,
  LoadingButton,
  inputStylesMixin,
  spacing,
  toaster,
} from '@alto/pocky';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  createCreditCardPaymentMethod,
  createPaymentMethodFailed,
  creatingPaymentMethod,
} from '~shared/actions/paymentMethods';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';

const paddingMixin = css`
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
`;

const centerMixin = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputBlockCardNumberElement = InputBlock(styled(CardNumberElement)`
  ${inputStylesMixin}
  ${paddingMixin}
  ${centerMixin}
`);

const inputRowPhoneLeftAlignCss = css`
  > *:not(:last-child) {
    margin: 0 0 1rem;
  }
`;

const InputRowPhoneLeftAlign = styled(InputRow)<{ isSMScreenOrBigger: boolean }>`
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && inputRowPhoneLeftAlignCss}
`;

const InputBlockCardExpiryElement = styled(
  InputBlock(styled(CardExpiryElement)`
    ${inputStylesMixin}
    ${paddingMixin}
    ${centerMixin}
  `),
)`
  width: 6rem;
`;
const InputBlockCardCvcElement = InputBlock(styled(CardCvcElement)`
  ${inputStylesMixin}
  ${paddingMixin}
  ${centerMixin}
`);

const InputBlockZipcodeElement = InputBlock(styled(Input)`
  ${inputStylesMixin}
  ${paddingMixin}
  ${centerMixin}
  height: ${spacing.xlSpacing}
`);

export type CreditCardFormProps = {
  readonly onClose: () => void;
};

const CreditCardForm = ({ onClose }: CreditCardFormProps) => {
  const { trackEvent } = useAnalytics();
  const [zipCode, setZipCode] = React.useState('');
  const [zipCodeError, setZipCodeError] = React.useState('');
  const { isSMScreenOrBigger } = useScreenSize();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatchShared();
  const paymentMethodError = useSelectorShared(
    (state) =>
      state.ui.errors.fetchPaymentMethodsError ||
      state.ui.errors.createPaymentMethodError ||
      state.ui.errors.deletePaymentMethodError,
  );
  const loading = useSelectorShared((state) => state.ui.loading.createPaymentMethodLoading);

  const handleSubmit = async () => {
    if (loading || !stripe || !elements) {
      return;
    }

    const zip = zipCode ?? '';
    if (!zip.match(/[1-9]/g) || zip.length !== 5) {
      setZipCodeError('Billing zip code should be 5 digits');
      return;
    }

    /**
     * `createCreditCardPaymentMethod` also calls this eventually, but we need
     * to do it early to prevent duplicate form submissions and start the
     * loading state on the form while we make the async call to Stripe.
     */
    dispatch(creatingPaymentMethod());

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      // this should never happen, but it's here to satisfy typescript
      throw new Error('Error setting up your card');
    }

    const tokenResult = await stripe.createToken(cardElement, { address_zip: zip });
    if (tokenResult.error) {
      dispatch(createPaymentMethodFailed(tokenResult.error.message));
    } else {
      const apiSuccess = await dispatch(
        createCreditCardPaymentMethod({
          // @ts-expect-error this should be fine
          token: tokenResult.token,
        }),
      );
      if (apiSuccess) {
        onClose();
        toaster.toast({
          kind: 'success',
          title: 'Successfully added credit card!',
        });
        trackEvent({
          event: EVENTS.PAYMENT_METHOD_CREATED,
          params: {
            newStripeFormEnabled: false,
            type: 'StripeData',
          },
        });
      } else {
        trackEvent({
          event: EVENTS.CREATE_PAYMENT_METHOD_FAILED,
          params: {
            newStripeFormEnabled: false,
          },
        });
      }
    }
  };

  const error = paymentMethodError?.details?.message || zipCodeError || null;

  return (
    <Form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit}
      submitting={loading}
    >
      <DeprecatedModalBody>
        <InputRow>
          <InputBlockCardNumberElement
            label="Card number"
            placeholder="Enter Card Number"
            required
            options={{ showIcon: true }}
            meta={{}}
          />
        </InputRow>
        <InputRowPhoneLeftAlign isSMScreenOrBigger={isSMScreenOrBigger}>
          <InputBlockCardExpiryElement
            label="Exp date"
            required
            meta={{}}
          />
          <InputBlockCardCvcElement
            label="Security code"
            placeholder="CVV"
            tooltip="A three or four digit security code found on your card"
            required
            meta={{}}
          />
          <InputBlockZipcodeElement
            label="Billing zip code"
            placeholder="Zip code"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setZipCode(e.target.value);
            }}
            required
            maxLength={5}
            meta={{
              error: zipCodeError,
            }}
          />
        </InputRowPhoneLeftAlign>
        {error ? <Danger>{error}</Danger> : null}
      </DeprecatedModalBody>
      <DeprecatedModalFooter>
        <Button
          kind="tertiary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={loading}
        >
          Save
        </LoadingButton>
      </DeprecatedModalFooter>
    </Form>
  );
};

export default CreditCardForm;
