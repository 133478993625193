// @owners { team: patients-team }
import { BORDERS, COLORS, SIZES } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Column,
  Illustration,
  ListDescription,
  ListItem,
  Row,
  SmSpacing,
  Tag,
} from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import { isToday as isDateToday, parse } from 'date-fns';
import React, { useCallback, useContext } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { getItemQuantityForAddOnOtc } from '~shared/features/checkout/helpers';
import { getWindows } from '~shared/features/checkout/selectors/getCart';
import getCartAutoRefillForPrescription from '~shared/features/checkout/selectors/getCartAutoRefillForPrescription';
import { type ItemKey } from '~shared/features/checkout/types';
import { getUserTagText } from '~shared/features/family-accounts/selectors/getUserTagText';
import { getMedicationForPrescription } from '~shared/features/my-meds/selectors/getMedicationsFromPrescriptions';
import { useNextAvailableDatesForPrescriptions } from '~shared/features/next-available-date/queries/useNextAvailableDatesForPrescriptions';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getHasFamily } from '~shared/features/users/selectors/getHasFamily';
import { DATE_FORMATS } from '~shared/helpers/date';
import { formatQuantity, isControl } from '~shared/helpers/helper';
import { formatTimeToCutoff, getTimeToCutoff } from '~shared/helpers/timeCutoff';
import { useSelectorShared } from '~shared/store';
import { type LightDelivery } from '~shared/types';
import { AncillaryItemsActionSheet } from '../../ancillaries';
import { ORDER_TYPE_CONTEXT, type OrderTypeContext } from '../../auto-refill';
import { BackorderedTag, useBackorderedMedication } from '../../backordered-medications';
import { ManualCouponInfoBox } from '../../coupons';
import { EditMedicationItemButton } from './EditMedicationItemButton';
import { RefrigerationActionSheet } from './RefrigerationActionSheet';
import { SignatureRequiredActionSheet } from './SignatureRequiredActionSheet';
import { NextAvailableDateDescription } from './descriptions/NextAvailableDateDescription';
import { PriceDescription } from './descriptions/PriceDescription';
import { getMedImageURL } from './helpers';

const PlaceholderContainer = styled(Column).attrs({
  center: true,
})`
  border-radius: ${BORDERS.RADIUS.MD.px};
  overflow: hidden;
  width: ${SIZES.ILLUSTRATION.SM.px};
  height: ${SIZES.ILLUSTRATION.SM.px};
  background-color: ${COLORS.PALETTE.GREYSCALE.DEFAULT};
`;

const AddOnsQuantityTag = styled(View)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
`;

type Props = {
  readonly essentialsHash: Record<string, AddOnOtc>;
  readonly canEditMedication: boolean;
  readonly onChangeEssential?: () => void;
  readonly openEditMedicationFlow?: ({ itemKey, prescriptionID }: { itemKey: ItemKey; prescriptionID: number }) => void;
  readonly orderTypeContext: OrderTypeContext;
  readonly prescriptionID: number;
  readonly delivery?: LightDelivery;
  readonly userID: number;
  readonly includeNewItemTag?: boolean;
};

export const MedicationItem = ({
  essentialsHash,
  onChangeEssential,
  canEditMedication,
  openEditMedicationFlow,
  orderTypeContext,
  prescriptionID,
  delivery,
  userID,
  includeNewItemTag = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const windows = useSelectorShared(getWindows);
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const medication = useSelectorShared((state) => getMedicationForPrescription(state, prescription));
  const userTag = useSelectorShared((state) => getUserTagText(state, { userIDs: [userID] }));
  const { nextAvailableDate: nextAvailableDateInfo } = useNextAvailableDatesForPrescriptions({ prescriptionID });
  const nextAvailableDate = nextAvailableDateInfo?.earliest.date || '';
  const hasFamily = useSelectorShared((state) => getHasFamily(state));
  const autobillPrescriptionLoadingByID = useSelectorShared(
    (state) => state.ui.loading.autobillPrescriptionLoadingByID,
  );
  const fetchOrderPricingLoading = useSelectorShared((state) => state.ui.loading.fetchOrderPricingLoading);
  const earliestAvailableReason = nextAvailableDateInfo?.earliest?.reason || '';
  const cartAutoRefill = useSelectorShared((state) => getCartAutoRefillForPrescription(state, { prescriptionID }));

  let essential: AddOnOtc | undefined;
  let essentialQuantity;
  if (prescription?.is_add_on_otc && prescription.product_id) {
    essential = essentialsHash[prescription.product_id];
    const productQuantity = essential?.quantity || prescription.quantity;
    essentialQuantity = getItemQuantityForAddOnOtc(prescription, productQuantity);
  }

  if (!medication || !prescription) throw new Error('Invalid medication or prescription');
  const isPostCheckout = orderTypeContext === ORDER_TYPE_CONTEXT.POST_CHECKOUT;
  const isInCheckout = orderTypeContext === ORDER_TYPE_CONTEXT.CHECKOUT;

  const editMedicationOnPress = useCallback(() => {
    if (!canEditMedication) return;
    if (prescriptionID && openEditMedicationFlow) {
      openEditMedicationFlow({
        itemKey: { resource_id: prescriptionID, resource_type: 'Prescription' },
        prescriptionID,
      });
    }
  }, [canEditMedication, openEditMedicationFlow, prescriptionID]);
  const isBackorderedMedication = useBackorderedMedication(prescriptionID);
  // PH-1545: use the medication name from the specific rx or delivery, when available.
  const medicationName = prescription.medication_name || delivery?.medication_name || medication.medicationName;
  const imageUrl = medication ? getMedImageURL(medication) : null;
  const isAutoRefillEnabled = isPostCheckout ? !!prescription?.auto_refill : !!cartAutoRefill;
  const isToday = isDateToday(parse(nextAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0));
  const shouldShowNextAvailableDate =
    !isPostCheckout && !isInCheckout && !prescription.is_add_on_otc && !!nextAvailableDate;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildListItemDescriptions = () => {
    const listItemDescriptions: React.ReactElement<typeof ListDescription>[] = [];

    if (hasFamily) {
      listItemDescriptions.push(<ListDescription iconName="user-small">{userTag}</ListDescription>);
    }

    const autobillPrescriptionLoading = autobillPrescriptionLoadingByID[prescriptionID];

    if (fetchOrderPricingLoading || autobillPrescriptionLoading) {
      listItemDescriptions.push(
        <ListDescription
          iconName="pricetag-small"
          loading
        />,
      );
    } else {
      listItemDescriptions.push(
        <ListDescription iconName="pricetag-small">
          <>
            <PriceDescription
              delivery={delivery}
              orderTypeContext={orderTypeContext}
              prescriptionID={prescriptionID}
            />
          </>
        </ListDescription>,
      );
    }

    if (prescription) {
      listItemDescriptions.push(
        <ListDescription iconName="pillbottle-small">
          <>
            {formatQuantity(prescription, delivery)}
            {isPostCheckout && !prescription.is_add_on_otc ? <>&nbsp;&bull;&nbsp;Rx{prescription.rx_number}</> : null}
          </>
        </ListDescription>,
      );
    }

    if (delivery?.med_sync_status === 'short_fill') {
      listItemDescriptions.push(
        <ListDescription iconName="pills-small">
          <>Partial fill ({delivery.days_supply?.toString()} day supply)</>
        </ListDescription>,
      );
    }

    if (!prescription?.is_add_on_otc) {
      if (isAutoRefillEnabled) {
        listItemDescriptions.push(<ListDescription iconName="sync-small">Auto refill</ListDescription>);
      } else {
        listItemDescriptions.push(<ListDescription iconName="sync-small">One-time fill</ListDescription>);
      }
    }

    if (prescription?.is_refrigerated) {
      listItemDescriptions.push(
        <ListDescription
          iconName="snowflake-small"
          type="secondary"
          onPress={() => {
            setActiveActionSheet(<RefrigerationActionSheet medicationName={medicationName} />);
          }}
        >
          Refrigeration required
        </ListDescription>,
      );
    }

    if (prescription?.has_ancillary_items) {
      listItemDescriptions.push(
        <ListDescription
          iconName="syringe-small"
          type="secondary"
          onPress={() => {
            setActiveActionSheet(
              <AncillaryItemsActionSheet
                medicationName={medicationName}
                prescriptionID={prescriptionID}
              />,
            );
          }}
        >
          Supplies included
        </ListDescription>,
      );
    }

    if (prescription && isControl(prescription)) {
      listItemDescriptions.push(
        <ListDescription
          iconName="signature-small"
          type="secondary"
          onPress={() => {
            setActiveActionSheet(<SignatureRequiredActionSheet medicationName={medicationName} />);
          }}
        >
          Signature required upon delivery
        </ListDescription>,
      );
    }

    if (isBackorderedMedication) {
      listItemDescriptions.push(
        <BackorderedTag
          origin="Cart"
          prescriptionId={prescriptionID}
        />,
      );
    }

    if (shouldShowNextAvailableDate && !isBackorderedMedication) {
      listItemDescriptions.push(
        <NextAvailableDateDescription
          earliestNextAvailableDate={nextAvailableDate}
          earliestDateReason={earliestAvailableReason}
          prescriptionID={prescriptionID}
          orderTypeContext={orderTypeContext}
        />,
      );
    }

    const timeToCutOff = isToday ? formatTimeToCutoff(getTimeToCutoff(windows)) : '';
    if (shouldShowNextAvailableDate && !!timeToCutOff) {
      listItemDescriptions.push(<ListDescription type="success">{`Order ${timeToCutOff}`}</ListDescription>);
    }

    return listItemDescriptions;
  };

  return (
    <>
      <Row>
        <Column>
          {essentialQuantity ? (
            <AddOnsQuantityTag>
              <Tag
                label={essentialQuantity.toString()}
                type="counter"
                circle
              />
            </AddOnsQuantityTag>
          ) : null}
          {imageUrl ? (
            <Illustration
              size="sm"
              source={{ uri: imageUrl }}
              borderRadius={BORDERS.RADIUS.MD.value}
              accessibilityLabel={IMG_ALT_TEXTS.medication}
            />
          ) : (
            <PlaceholderContainer>
              <AltoIcon name="pills-duo" />
            </PlaceholderContainer>
          )}
        </Column>
        <SmSpacing />
        <Column
          flexGrow={1}
          flexShrink={1}
        >
          <ListItem
            tagPlacement="bottom"
            title={medicationName}
            descriptions={buildListItemDescriptions()}
            TitlePressableIcon={
              canEditMedication ? (
                <EditMedicationItemButton
                  essential={essential}
                  editMedicationOnPress={editMedicationOnPress}
                  onChangeEssential={onChangeEssential}
                />
              ) : undefined
            }
            fullBleed
            tags={
              includeNewItemTag
                ? [
                    <Tag
                      type="recommended"
                      key="new-item"
                      label="new item"
                    />,
                  ]
                : undefined
            }
          />
        </Column>
      </Row>
      <SmSpacing />
      {prescriptionID && userID ? (
        <ManualCouponInfoBox
          prescriptionID={prescriptionID}
          prescriptionUserID={userID}
        />
      ) : null}
    </>
  );
};
