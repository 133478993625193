// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, Column, ExpandableImage, LgPadding, PresentationListItem, XxsPadding } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import {
  getDeliveredAtText,
  getDeliveryDateText,
} from '~shared/features/delivery-info/helpers/getShipmentWithItemsRowProps';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { getUserTagText } from '~shared/features/family-accounts/selectors/getUserTagText';
import { getShipmentTotal } from '~shared/features/pricing/selectors/getOrderTotal';
import {
  getAsapShipmentFee,
  getOnDemandShipmentFee,
} from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import getHasFamily from '~shared/features/users/selectors/getHasFamily';
import { isPickup } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { CalendarRowV2, DeliveryInstructionsRow, DeliveryMethodRow, LocationRow, PriceRow } from '../../info-rows';
import { OrderStatusTracker } from './OrderStatusTracker';

type Props = {
  readonly failedPaymentMethods?: string;
  readonly shipment: ShipmentWithItems;
  readonly showTracking?: boolean;
  readonly status?: APIOrderStatus;
};

export const OrderDetailsLogistics = ({ failedPaymentMethods, shipment, showTracking = false, status }: Props) => {
  const userTagText = useSelectorShared((state) => getUserTagText(state, { userIDs: [shipment.user_id] }));
  const userTag = useSelectorShared(getHasFamily) ? userTagText : '';
  const price = useSelectorShared(getShipmentTotal)?.value;
  const isOnDemand = !!(shipment && getOnDemandShipmentFee(shipment));
  const isAsapDelivery = !!getAsapShipmentFee(shipment);
  const { value: useV2CalendarRow } = Experimentation.useFeatureFlag('v2_calendar_row');
  const requiresRefrigeration = shipment.items.some((item) => item.isRefrigerated);
  const hasAncillaryItems = shipment.items.some((item) => item.hasAncillaryItems);
  const calendarRow = useV2CalendarRow ? (
    <CalendarRowV2
      startTime={shipment.deliver_after}
      endTime={shipment.deliver_before}
      date={status === 'delivered' ? shipment.delivered_at : shipment.date}
      method={shipment.method}
      status={status}
      isOnDemand={isOnDemand}
      isAsapDelivery={isAsapDelivery}
    />
  ) : (
    <PresentationListItem
      LeftContent={
        <AltoIcon
          name="calendar"
          type="grey"
        />
      }
      text={
        status === 'delivered'
          ? getDeliveredAtText(shipment.delivered_at)
          : getDeliveryDateText({
              deliverAfter: shipment.deliver_after,
              deliverBefore: shipment.deliver_before,
              date: shipment.date,
              method: shipment.method,
              isOnDemand,
              isAsapDelivery,
            })
      }
    />
  );

  return (
    <Column
      flexShrink={1}
      flexGrow={1}
    >
      {userTag ? (
        <PresentationListItem
          LeftContent={
            <AltoIcon
              name="user"
              type="grey"
            />
          }
          text={userTag}
        />
      ) : null}
      {shipment.date ? calendarRow : null}
      <LocationRow
        address={shipment.address}
        isPickup={isPickup(shipment.method)}
        showMap={shipment.status !== 'delivered'}
      />
      <DeliveryMethodRow
        shipment={shipment}
        status={status}
      />
      <DeliveryInstructionsRow
        shipment={shipment}
        address={shipment.address}
        status={status}
      />
      <PriceRow price={price} />
      {failedPaymentMethods ? (
        <PresentationListItem
          backgroundColor={COLORS.BACKGROUND_COLORS.DANGER_LIGHTEST}
          LeftContent={
            <AltoIcon
              name="creditcard"
              type="grey"
            />
          }
          text={failedPaymentMethods}
        />
      ) : null}
      {requiresRefrigeration ? (
        <PresentationListItem
          LeftContent={
            <AltoIcon
              name="snowflake"
              type="grey"
            />
          }
          text="Refrigeration required"
        />
      ) : null}
      {hasAncillaryItems ? (
        <PresentationListItem
          LeftContent={
            <AltoIcon
              name="syringe"
              type="grey"
            />
          }
          text="Ancillary items included"
        />
      ) : null}
      {shipment.image_url && !isPickup(shipment.method) ? (
        <LgPadding
          topPadding={SPACING.STATIC.XS}
          bottomPadding={SPACING.STATIC.XXS}
          leftPadding={SPACING.STATIC.LG}
        >
          <ExpandableImage
            accessibilityLabel={IMG_ALT_TEXTS.deliveredDelivery}
            imageUrl={shipment.image_url}
            withThumbnailOverlay
          />
        </LgPadding>
      ) : null}
      {status ? (
        <LgPadding
          bottomPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
          topPadding={SPACING.STATIC.NONE}
          leftPadding={SPACING.STATIC.LG}
        >
          <OrderStatusTracker
            shipmentId={shipment?.id}
            deliveryMethod={shipment.method}
            deliveryStatus={status}
            showTracking={showTracking}
            shipmentTrackingLink={shipment?.tracking_link}
          />
        </LgPadding>
      ) : null}
      <XxsPadding />
    </Column>
  );
};
