// @owners { team: patient-health }
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { fetchInsurancesFailed, fetchInsurancesSucceeded } from '~shared/actions/insurances';
import { get } from '~shared/helpers/apiHelper';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';
import { useDispatchShared } from '~shared/store';
import { type APIError, type ReduxDispatchShared } from '~shared/types';
import { type Insurance } from '~shared/types/clients';

type FetchAllInsurancesResponse = Insurance[] & { error?: APIError };

export const insurances = createQueryKeys('insurances', {
  fetchAll: ({ dispatch }: { dispatch: ReduxDispatchShared }) => ({
    queryKey: ['fetchAll'],
    queryFn: async () => {
      const response: FetchAllInsurancesResponse = await get('/insurances');
      // FIXME: need to keep redux insurances in sync until all related selectors removed
      // i.e. getInsuranceByID, getHasInsurances
      if (response.error) {
        dispatch(fetchInsurancesFailed(response.error));
        throw new Error(getErrorMessageFromResponse({ error: response.error }));
      } else {
        dispatch(fetchInsurancesSucceeded(response));
      }
      return response;
    },
  }),
});

/**
 * Fetch all insurances for a patient, including family member insurances
 * @returns Array of insurances
 */
export const useGetInsurances = ({ enabled } = { enabled: true }) => {
  const dispatch = useDispatchShared();
  const { data, isPending, isSuccess, error, refetch } = useQuery({
    ...insurances.fetchAll({ dispatch }),
    enabled,
  });

  const fetchedInsurances = data ?? [];

  return {
    insurances: fetchedInsurances,
    error,
    isPending,
    isSuccess,
    hasInsurance: fetchedInsurances.length > 0,
    hasGovernmentInsurance: fetchedInsurances.some(({ is_government_insurance }) => !!is_government_insurance),
    refetch,
  };
};

/**
 * Fetch all insurances for a given patient
 * @param userID patient's user ID
 * @returns  Array of insurances
 */
export const useGetInsurancesByUserID = ({ userID }: { userID: number | null | undefined }) => {
  const getInsuranceByUserID = useCallback(
    (insurances: Insurance[]) => {
      return insurances.filter(({ user_id }) => user_id === userID);
    },
    [userID],
  );

  const dispatch = useDispatchShared();
  const { data, isPending, error } = useQuery({
    ...insurances.fetchAll({ dispatch }),
    select: getInsuranceByUserID,
    enabled: !!userID,
  });

  return { hasInsurances: !!data?.length, insurances: data ?? [], isPending, error };
};

/**
 * Fetch all insurances found via cardfinder service
 * @returns Array of insurances
 */
export const useGetCardFinderInsurances = () => {
  const getCardFinderInsurances = useCallback((insurances: Insurance[]) => {
    return insurances.filter(({ source }) => source === 'cardfinder');
  }, []);

  const dispatch = useDispatchShared();
  const { data, isPending, error } = useQuery({
    ...insurances.fetchAll({ dispatch }),
    select: getCardFinderInsurances,
  });
  const cardFinderInsurances = data ?? [];

  return {
    insurances: cardFinderInsurances,
    hasCardFinderInsurance: cardFinderInsurances.length > 0,
    isPending,
    error,
  };
};
