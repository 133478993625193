// @owners { team: patients-team }
import { SIZES } from '@alto/design-library-tokens';
import { SizedImage } from '@alto/design-system';
import React from 'react';

export const GooglePlayImage = () => {
  return (
    <SizedImage
      source={require('./google_play@2x.png')}
      pixelSize={SIZES.ILLUSTRATION.XL.value}
      maxHeight={SIZES.ILLUSTRATION.SM.value}
      accessibilityLabel="Download Alto Android app"
    />
  );
};
