// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, LgSpacing } from '@alto/design-system';
import React from 'react';
import { useDeliveryFeeCopy } from './useDeliveryFeeCopy';

export const TipPaymentActionSheet = () => {
  const illustrationSrc = require('~shared/assets/images/credit_card.png');
  const { fsa } = useDeliveryFeeCopy();

  return (
    <ActionSheetV2
      illustrationSrc={illustrationSrc}
      analyticsName="checkout tip payment"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body
          fontFamily="semibold"
          color={COLORS.TEXT_COLORS.PRIMARY}
        >
          Paying with a credit or debit card?
        </Body>
        <Body>No need to do anything. We can charge the same card you used for your medications.</Body>
        <LgSpacing />
        <Body
          fontFamily="semibold"
          color={COLORS.TEXT_COLORS.PRIMARY}
        >
          Paying with an FSA/HSA?
        </Body>
        <Body>{fsa}</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
