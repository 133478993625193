// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  Button,
  Column,
  Description,
  ExternalLink,
  InlineAlert,
  InputText,
  LgPadding,
  LgSpacing,
  MdPadding,
  XsSpacing,
} from '@alto/design-system';
import React, { useCallback, useContext, useState } from 'react';
// eslint-disable-next-line import/no-deprecated
import { applyManualCoupon, createManualCoupon, raiseSupportRequest } from '~shared/actions/manualCoupons';
import { closeCart } from '~shared/actions/ui/cart';
import { getNextDeliveryForPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { getErrorForPrescriptionID } from '~shared/features/manual-coupons/selectors/getErrors';
import { getAvailableManualCoupon } from '~shared/features/pricing/selectors/getAvailableManualCoupon';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useNavigateToAssistantLanding } from '../../alto-assistant/helpers/useNavigateToAssistantLanding';

type Form = {
  member_id: string;
  group: string;
  bin: string;
  pcn: string;
};

const validateForm = (form: Form) => {
  const errors: Form = { member_id: '', group: '', bin: '', pcn: '' };
  if (!form.member_id) errors.member_id = 'ID is required';
  if (!form.group) errors.group = 'Group is required';
  if (!form.bin) errors.bin = 'Rx BIN is required';
  if (!form.pcn) errors.pcn = 'Rx PCN is required';

  return { errors, valid: !Object.values(errors).some((error) => error) };
};

const isWeb = getPlatformOS() === 'web';

type Props = {
  readonly prescriptionID: number;
  readonly prescriptionUserID: number;
};

export const ApplyCouponForm = ({ prescriptionID, prescriptionUserID }: Props) => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const availableManualCoupon = useSelectorShared((state) => getAvailableManualCoupon(state, { prescriptionID }));
  const delivery = useSelectorShared((state) => getNextDeliveryForPrescriptionID(state, { prescriptionID }));
  const applyCouponError = useSelectorShared(getErrorForPrescriptionID(prescriptionID));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form, setForm] = useState<Form>({
    bin: availableManualCoupon?.bin || '',
    pcn: availableManualCoupon?.pcn || '',
    group: availableManualCoupon?.group || '',
    member_id: availableManualCoupon?.member_id || '',
  });
  const [error, setError] = useState<Form>({ bin: '', pcn: '', group: '', member_id: '' });
  const [submitError, setSubmitError] = useState<boolean>(false);
  const { navigateToAssistantLanding } = useNavigateToAssistantLanding();

  const navigateBack = useCallback(() => {
    closeActionSheet();
  }, [closeActionSheet]);

  const handleChange = useCallback(
    (key: keyof Form) => (value: string) => {
      setForm((prevForm) => ({ ...prevForm, [key]: value }));
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    setSubmitError(false);
    const validate = validateForm(form);
    setError(validate.errors);
    if (!validate.valid) return;

    try {
      let result = null;
      trackEvent({
        event: EVENTS.MANUAL_COUPONS_COUPON_SUBMITTED,
        params: { coupon_id: availableManualCoupon?.id },
        additionalFields: { deliveryId: delivery?.id, prescriptionId: prescriptionID },
      });
      setIsSubmitting(true);
      const insuranceID = await dispatch(
        // @ts-expect-error TS(2345): Argument of type '{ user_id: number; group: string; member_id: string; pcn: string; bin: string; per... (Delete me to see the full error)
        // eslint-disable-next-line import/no-deprecated
        createManualCoupon({
          user_id: prescriptionUserID,
          group: form.group,
          member_id: form.member_id,
          pcn: form.pcn,
          bin: form.bin,
          person_code: '01',
          relationship_code: '1',
          is_coupon: true,
          coupon_id: availableManualCoupon?.id,
          is_secondary: false,
        }),
      );

      if (delivery?.id) {
        // @ts-expect-error TS(2345): Argument of type 'number | null' is not assignable to parameter of type 'number'.
        // eslint-disable-next-line import/no-deprecated
        result = await dispatch(applyManualCoupon(delivery.id, insuranceID, prescriptionID));
      }
      if (insuranceID && result) {
        navigateBack();
      } else {
        setSubmitError(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [
    navigateBack,
    availableManualCoupon?.id,
    delivery?.id,
    dispatch,
    prescriptionID,
    prescriptionUserID,
    trackEvent,
    form,
  ]);

  const handleSubmitRequest = useCallback(async () => {
    try {
      setIsSubmitting(true);
      trackEvent({
        event: EVENTS.MANUAL_COUPONS_SUPPORT_REQUESTED,
        params: { coupon_id: availableManualCoupon?.id },
        additionalFields: { deliveryId: delivery?.id, prescriptionId: prescriptionID },
      });
      if (!delivery) throw new Error('missing delivery to submit a support request');
      // eslint-disable-next-line import/no-deprecated
      const response = await dispatch(raiseSupportRequest(delivery.id, applyCouponError?.insuranceID || null));
      if (!response) throw new Error('unable to create a support request');
      navigateBack();
    } catch (error) {
      if (isWeb) {
        closeActionSheet();
        dispatch(closeCart());
      }
      navigateToAssistantLanding();
    } finally {
      setIsSubmitting(false);
    }
  }, [
    applyCouponError?.insuranceID,
    availableManualCoupon?.id,
    delivery,
    dispatch,
    navigateBack,
    closeActionSheet,
    prescriptionID,
    trackEvent,
    navigateToAssistantLanding,
  ]);

  if (!availableManualCoupon) {
    return (
      <Column
        flexGrow={1}
        center
        backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}
      >
        <LgPadding>
          <Body center>Unfortunately, there are no coupons that can be applied to this prescription.</Body>
        </LgPadding>
      </Column>
    );
  }

  const buttons = [
    <Button
      testID="submit-coupon-form"
      key="submit"
      label="Submit coupon info"
      loading={isSubmitting}
      disabled={isSubmitting}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onPress={handleSubmit}
    />,
    ...(submitError
      ? [
          <Button
            key="support"
            type="secondary"
            label="Submit support request"
            loading={isSubmitting}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPress={handleSubmitRequest}
          />,
        ]
      : []),
    <Button
      key="cancel"
      type="tertiary"
      disabled={isSubmitting}
      label="Cancel"
      onPress={navigateBack}
    />,
  ];

  return (
    <ActionSheetV2
      title="Apply coupon"
      analyticsName="coupon form"
      buttons={buttons}
    >
      <LgPadding>
        <Body>
          After you sign up for the coupon on the{' '}
          <ExternalLink href={availableManualCoupon?.url || ''}>manufacturer website</ExternalLink>, check your
          confirmation and continue below. Check the manufacturer's website for terms and conditions.
        </Body>
        {submitError ? (
          <MdPadding
            leftPadding={SPACING.STATIC.NONE}
            rightPadding={SPACING.STATIC.NONE}
          >
            <InlineAlert type="error">
              <Description fontFamily="bold">Could not apply coupon</Description>

              <Description>
                Please ensure you entered the correct information or submit a support request and we will get back to
                you as soon as possible.
              </Description>
            </InlineAlert>
          </MdPadding>
        ) : null}
        <XsSpacing />
        <Body fontFamily="bold">Enter the billing information from the coupon card:</Body>
        <LgSpacing />
        <InputText
          accessibilityLabel="Member ID"
          testID="member-id"
          label="ID"
          autoCorrect={false}
          onChangeText={handleChange('member_id')}
          value={form.member_id}
          disabled={isSubmitting}
          required
          error={error.member_id}
          autoCapitalize="characters"
        />
        <LgSpacing />
        <InputText
          accessibilityLabel="Group"
          testID="group-id"
          label="Group"
          onChangeText={handleChange('group')}
          autoCorrect={false}
          value={form.group}
          disabled={isSubmitting}
          required
          error={error.group}
          autoCapitalize="characters"
        />
        <LgSpacing />
        <InputText
          accessibilityLabel="Rx BIN"
          testID="bin-id"
          label="Rx BIN"
          autoCorrect={false}
          onChangeText={handleChange('bin')}
          value={form.bin}
          disabled={isSubmitting}
          required
          error={error.bin}
          autoCapitalize="characters"
        />
        <LgSpacing />
        <InputText
          accessibilityLabel="Rx PCN"
          testID="pcn-id"
          label="Rx PCN"
          disabled={isSubmitting}
          autoCorrect={false}
          onChangeText={handleChange('pcn')}
          value={form.pcn}
          required
          error={error.pcn}
          autoCapitalize="characters"
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
