// @owners { team: patients-team }
import { BORDERS, COLORS, SHADOWS, SIZES, SPACING } from '@alto/design-library-tokens';
import { MdSpacing, SmSpacing, XxlSpacing } from '@alto/design-system';
import { AddOnsContext, useQueryEssentials } from '@alto/features';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { goBack } from 'react-router-redux';
import styled from 'styled-components';
import { type OriginValues } from '~shared/features/essentials/add_ons_consts';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { getURLParams } from '~shared/helpers/helper';
import { usePrevious } from '~shared/hooks';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { getRouteState } from '~shared/selectors/getRouteState';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Address, type Shipment } from '~shared/types';
import { ProductTitle } from './ProductTitle';
import { Carousel, ErrorPage, FullPage, LoadingPage, ProductFooter } from '~web/features/essentials/components';
import { useTrackViewedEvent } from '~web/features/essentials/hooks';
import { MissingAddress } from '~web/features/essentials/pages/StoreProducts/MissingAddress';
import { Icon, Image, Text, View } from '~web/features/essentials/primitives';
import { useDeprecatedMediaQuery, useDeprecatedWindowResize } from '~web/hooks';

const StyledImagePreview = styled.div<{ active: boolean }>`
  padding: ${SPACING.STATIC.XS.px};
  border-radius: ${BORDERS.RADIUS.LG.px};
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  margin: ${SPACING.STATIC.XXS.px};
  &:hover {
    cursor: pointer;
    background-color: ${({ active }) =>
      active ? COLORS.BACKGROUND_COLORS.WHITE : COLORS.BACKGROUND_COLORS.PRIMARY_LIGHT};
  }
  ${({ active }) => active && SHADOWS.BOTTOM_LIGHT}
`;

type Props = {
  readonly id: number;
  readonly address: Address;
  readonly shipment: Shipment | undefined;
  readonly origin: OriginValues | undefined;
};
const Content = ({ id, address, shipment, origin }: Props) => {
  const { isPending, error, essentialsHash } = useQueryEssentials({
    addressID: address.id,
    date: shipment?.scheduled_date,
  });
  const routeState = useSelectorShared(getRouteState);
  const product = essentialsHash[id];
  useTrackViewedEvent(EVENTS.ESSENTIALS__PRODUCT_DETAILS_VIEWED, shipment?.id, origin, routeState?.analyticsProps);
  const [imageIndex, setImageIndex] = useState(0);
  const prevImageIndex = usePrevious(imageIndex);
  const dispatch = useDispatchShared();
  const isMediumScreen = useDeprecatedMediaQuery('MD');
  const isMobile = !useDeprecatedMediaQuery('SM');
  const isLargeScreen = useDeprecatedMediaQuery('LG');
  const windowSize = useDeprecatedWindowResize();
  const defaultViewSidePadding = isMobile ? SPACING.STATIC.LG.px : SPACING.STATIC.XXL.px;
  const sidePadding = isLargeScreen
    ? `${(windowSize.width - parseFloat(SIZES.PAGE.WEB_NAVBAR_WIDTH.LG)) / 2}px`
    : defaultViewSidePadding;

  const addOnsContext = useMemo(
    () =>
      ({
        shipmentID: shipment?.id,
        origin,
      }) as const,
    [shipment?.id, origin],
  );

  const handleImagePress = (index: number) => () => {
    setImageIndex(index);
  };
  const handleNavBack = () => dispatch(goBack());

  const recordImageViewedEvent = useCallback(
    (index: number, prevIndex?: number) => {
      dispatch(
        sendAnalyticsEvent(
          createEvent(EVENTS.ESSENTIALS__OTC_DETAILS_CAROUSEL_IMAGE_VIEWED, {
            ...(routeState?.analyticsProps || {}),
            img_number: index,
            referral_img_number: prevIndex,
          }),
        ),
      );
    },
    [dispatch, routeState?.analyticsProps],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    recordImageViewedEvent(imageIndex, prevImageIndex);
  }, [imageIndex, prevImageIndex, recordImageViewedEvent]);

  if (isPending) return <LoadingPage />;
  if (error || !product)
    return (
      <ErrorPage
        title="Unable to load Essential"
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        description={`${error}`}
      />
    );
  return (
    <FullPage>
      <AddOnsContext.Provider value={addOnsContext}>
        <View
          padding={`${defaultViewSidePadding} ${defaultViewSidePadding} ${defaultViewSidePadding} ${sidePadding} `}
          flexBasis="50%"
          flexDirection="column"
          backgroundColor="PRIMARY_LIGHTEST"
        >
          <View
            flexDirection="row"
            alignItems="center"
            onClick={handleNavBack}
          >
            <Icon
              name="chevronleft"
              size="SM"
              interactive
              label="back icon"
            />
            <SmSpacing />
            <Text
              title="Back"
              variant="body-semibold"
              color="PRIMARY"
            />
          </View>
          {isMobile ? (
            <Carousel.Container
              numOfItems={product.image_urls.length}
              numOfColumns={1}
              iconSize="LG"
              onChange={recordImageViewedEvent}
              showDots
            >
              {product.image_urls.map((image) => (
                <Carousel.Item
                  key={image}
                  numOfColumns={1}
                >
                  <View
                    justifyContent="center"
                    flexWrap="wrap"
                  >
                    <Image
                      src={image}
                      alt={product.name}
                      width="100%"
                    />
                  </View>
                </Carousel.Item>
              ))}
            </Carousel.Container>
          ) : (
            <View
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              height="100%"
            >
              <Image
                src={product.image_urls[imageIndex]}
                alt={product.name}
                // eslint-disable-next-line react-native/no-inline-styles
                style={{ maxWidth: '100%' }}
              />
              <View
                justifyContent="center"
                flexWrap="wrap"
              >
                {product.image_urls.map((image, index) => (
                  <StyledImagePreview
                    key={`${image} preview`}
                    onClick={handleImagePress(index)}
                    active={index === imageIndex}
                  >
                    <Image
                      src={image}
                      alt={product.name}
                      width={SIZES.ILLUSTRATION.MD.px}
                      height={SIZES.ILLUSTRATION.MD.px}
                    />
                  </StyledImagePreview>
                ))}
              </View>
            </View>
          )}
        </View>
        <View
          flexDirection="column"
          justifyContent="center"
          padding={`${defaultViewSidePadding} ${sidePadding} ${defaultViewSidePadding} ${defaultViewSidePadding}`}
          flexBasis="50%"
        >
          <ProductTitle
            product={product}
            isMobile={isMobile}
          />
          <MdSpacing />
          <Text
            title={product.quantity_description || ''}
            variant="body-semibold"
          />
          <MdSpacing />
          <Text
            title={product.product_description || ''}
            variant="body"
          />
          <XxlSpacing />
          <View>
            <View
              flexDirection="column"
              flexBasis={isMediumScreen ? '360px' : '100%'}
            >
              <ProductFooter
                product={product}
                analyticsProps={routeState?.analyticsProps}
                onClose={handleNavBack}
                showPrice
              />
            </View>
          </View>
        </View>
      </AddOnsContext.Provider>
    </FullPage>
  );
};

type StoreProductProps = {
  readonly params: { id: string };
};
export const StoreProduct = ({ params: { id } }: StoreProductProps) => {
  const { order, origin } = getURLParams();
  const shipmentID = Number(order);
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID }));
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID }));
  const addressLoading = useSelectorShared((state) => state.addresses.lastRequestSucceededAt === 0);

  if (addressLoading) {
    return (
      <FullPage>
        <LoadingPage />
      </FullPage>
    );
  }

  if (!address) {
    return <MissingAddress shipment={shipment} />;
  }

  return (
    <FullPage>
      <Content
        id={Number(id)}
        address={address}
        shipment={shipment}
        origin={origin}
      />
    </FullPage>
  );
};
