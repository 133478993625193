import { capitalize } from 'lodash';

export function capitalizeFirstLetter(string: string): string {
  if (!string) {
    return '';
  }

  return string[0].toUpperCase() + string.slice(1);
}

export function capitalizeString(string?: string): string {
  if (!string?.trim()) {
    return '';
  }

  return string.trim().split(' ').map(capitalizeFirstLetter).join(' ');
}

export function kebabcase(string?: string): string {
  if (!string?.trim()) {
    return '';
  }
  return string.trim().toLowerCase().replace(/\s/, '-');
}

export function snakeToProperCase(string?: string): string {
  if (!string?.trim()) {
    return '';
  }

  return string.trim().split('_').map(capitalize).join(' ');
}

export function camelToProperCase(string?: string | null | undefined): string {
  if (!string?.trim()) {
    return '';
  }

  return string
    .trim()
    .split(/(?=[A-Z])/)
    .map(capitalize)
    .join(' ');
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
export const escapeRegExp = (textToEscape: string): string => {
  return textToEscape.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const getOriginalCase = (
  fullText: string | null | undefined,
  maybeWrongCasedStringToMatch: string | null | undefined,
): string | null | undefined => {
  if (!fullText || !maybeWrongCasedStringToMatch) {
    return null;
  }

  const matchingRegExp = new RegExp(escapeRegExp(maybeWrongCasedStringToMatch), 'i');
  const match = fullText.match(matchingRegExp);

  if (!match || match.length === 0) {
    return null;
  }

  return match[0];
};

export function replaceApostrophes(string?: string): string {
  if (!string?.trim()) {
    return '';
  }

  return string.replace(/[‘’]/g, "'");
}

export function ellipsize(string: string | null | undefined, maxCharacters = 25): string {
  let ellipsized = string || '';

  if (ellipsized.length > maxCharacters) {
    ellipsized = `${ellipsized.slice(0, maxCharacters - 3)}...`;
  }

  return ellipsized;
}

export function getPossessiveName(firstName?: string) {
  if (!firstName) return '';
  if (firstName.endsWith('s')) return `${firstName}’`;
  return `${firstName}’s`;
}

const NON_DIGIT_REGEX = /[^0-9]/g;
export function stripNonDigits(string: string) {
  return string.replace(NON_DIGIT_REGEX, '');
}
