// @owners { team: patients-team }
import { type ModeType } from '@alto/deliver_api/types/v1/delivery_methods/mode_type';
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  AltoIcon,
  Card,
  Description,
  H2,
  InputRadio,
  InputRadioGroup,
  LgPadding,
  LgSpacing,
  Pressable,
  Row,
  Separator,
  SmSpacing,
  XxsSpacing,
} from '@alto/design-system';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { updateOrder } from '~shared/actions/cart';
import { type PaymentMethodType } from '~shared/constants';
import { getOrder, getOrderAddressId, getOrderDate } from '~shared/features/checkout/selectors/getOrder';
import { getHasNoCopay } from '~shared/features/checkout/selectors/getPaymentBreakdown';
import { getTipAmountWithDefault } from '~shared/features/courier-tips/helpers';
import { getHasAppliedDeliveryFee } from '~shared/features/shipment-fees/selectors/getHasAppliedDeliveryFee';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { TipPaymentMethodPicker } from '../../../courier-tip/components/TipPaymentMethodPicker';
import { CheckoutPaymentSelector } from '../../../payment/components/CheckoutPaymentSelector';
import { TipPaymentActionSheet } from './TipPaymentActionSheet';
import { useDeliveryFeeCopy } from './useDeliveryFeeCopy';

export type PaymentMethodCardProps = {
  readonly handleNativePay?: (paymentMethodType: PaymentMethodType) => void;
  readonly disabled?: boolean;
  readonly deliveryMethodMode: ModeType;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PaymentMethodCard = ({ handleNativePay, deliveryMethodMode, disabled }: PaymentMethodCardProps) => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const order = useSelectorShared(getOrder);
  const dateSelected = useSelectorShared(getOrderDate);
  const hasNoCopay = useSelectorShared(getHasNoCopay);
  const addressID = useSelectorShared(getOrderAddressId);
  const { shipment_payment_methods, delivery_method, pay_at_pickup } = order;
  const isPickup = delivery_method === 'pickup';
  const [payAtPickupValue, setPayAtPickupValue] = useState<boolean | undefined>(pay_at_pickup);
  const tipAmount = getTipAmountWithDefault(order.tip_amount);
  const hasDeliveryFeeAmount = useSelectorShared(getHasAppliedDeliveryFee);
  const isCourierMethod = deliveryMethodMode === 'COURIER' && !isPickup;
  // show tip and delivery fee payment method section when courier has tip or delivery fee when date selected
  const isTipAndFeePaymentMethodPickerHidden = (!isCourierMethod || !tipAmount) && !hasDeliveryFeeAmount;
  // show entire payment method section when date selected and order has either copay, tip or delivery fee
  const isPaymentMethodPickerHidden = (hasNoCopay && isTipAndFeePaymentMethodPickerHidden) || !dateSelected;
  const { trackPageView } = useAnalytics();
  const { label } = useDeliveryFeeCopy();
  // show pickup pay in advance section when order is pickup and has copay
  const showPickupPayInAdvanceSection = !hasNoCopay && isPickup;
  // show copay payment method section when order has copay and date has been selected. for pickups, it also needs to be pay in advance
  const hidePaymentMethodSection = hasNoCopay || !dateSelected || (isPickup && payAtPickupValue !== false);

  const onMountRef = useRef(true);
  // Whenever address changes (and only changes), clear out the pay at pickup value
  // this effect should only run when the address changes, not on mount
  useEffect(() => {
    if (onMountRef.current) {
      onMountRef.current = false;
      return;
    }
    setPayAtPickupValue(undefined);
    dispatch(updateOrder({ pay_at_pickup: undefined }));
  }, [addressID, dispatch]);

  useEffect(() => {
    trackPageView({
      event: EVENTS.PAYMENT_METHOD_RENDERED,
      params: {
        origin: isEditingOrder ? 'edit delivery' : 'checkout',
        has_medication_payment: !hasNoCopay,
        has_non_hsa_or_fsa_payment: !isTipAndFeePaymentMethodPickerHidden,
      },
    });
  }, [hasNoCopay, isEditingOrder, isTipAndFeePaymentMethodPickerHidden, trackPageView]);

  const handleTipAndFeePaymentPress = useCallback(() => {
    setActiveActionSheet(<TipPaymentActionSheet />);
  }, [setActiveActionSheet]);

  const handlePayAtPickupValueChange = useCallback(
    (value: string | undefined) => {
      // convert "true"/"false" strings to boolean, otherwise leave as undefined;
      const payAtPickup = typeof value === 'string' ? value === 'true' : undefined;
      setPayAtPickupValue(payAtPickup);
      dispatch(updateOrder({ pay_at_pickup: payAtPickup }));
      // Clear payment method if user selects pay at pickup.
      if (payAtPickup) {
        dispatch(updateOrder({ payment_method_id: undefined, second_payment_method_id: undefined }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    // Clear pay at pickup if user selects a non-pickup option.
    if (pay_at_pickup !== null && !isPickup) {
      handlePayAtPickupValueChange(undefined);
      dispatch(updateOrder({ pay_at_pickup: undefined }));
    }
  }, [isPickup, pay_at_pickup, dispatch, handlePayAtPickupValueChange]);

  if (isPaymentMethodPickerHidden) {
    return null;
  }

  if (isPickup) {
    return (
      <Card overflow={'hidden'}>
        {showPickupPayInAdvanceSection ? (
          <>
            <LgPadding
              topPadding={SPACING.STATIC.MD}
              bottomPadding={SPACING.STATIC.MD}
            >
              <H2>Select a payment option</H2>
            </LgPadding>
            <InputRadioGroup
              value={typeof payAtPickupValue === 'boolean' ? payAtPickupValue.toString() : undefined}
              onValueChange={handlePayAtPickupValueChange}
            >
              <InputRadio
                value="false"
                label="Pay in advance"
                testID="pay-in-advance"
                descriptions={
                  <Description>Speed up your pickup! Payment will be charged when your order is ready.</Description>
                }
                radioPlacement="left"
                disabled={disabled}
              />
              <InputRadio
                value="true"
                label="Pay at pickup"
                testID={'pay-at-pickup'}
                descriptions={<Description>Pay at Alto with cash or card.</Description>}
                radioPlacement="left"
                disabled={disabled}
              />
            </InputRadioGroup>
          </>
        ) : null}
        {hidePaymentMethodSection ? null : (
          <>
            <LgPadding
              topPadding={SPACING.STATIC.MD}
              bottomPadding={SPACING.STATIC.MD}
            >
              <H2>Payment method</H2>
            </LgPadding>
            <LgPadding topPadding={SPACING.STATIC.NONE}>
              {isTipAndFeePaymentMethodPickerHidden ? null : (
                <Description
                  color={COLORS.TEXT_COLORS.PRIMARY}
                  fontFamily="semibold"
                >
                  Medication payment
                </Description>
              )}
              <CheckoutPaymentSelector
                disabled={disabled}
                handleNativePay={handleNativePay}
                shipmentPaymentMethods={shipment_payment_methods}
                hasNoCopay={hasNoCopay}
              />
            </LgPadding>
          </>
        )}
      </Card>
    );
  }

  return (
    <Card title="Payment method">
      {hidePaymentMethodSection ? null : (
        <>
          <LgPadding topPadding={SPACING.STATIC.NONE}>
            {isTipAndFeePaymentMethodPickerHidden ? null : (
              <Description
                color={COLORS.TEXT_COLORS.PRIMARY}
                fontFamily="semibold"
              >
                Medication payment
              </Description>
            )}
            <CheckoutPaymentSelector
              disabled={disabled}
              handleNativePay={handleNativePay}
              shipmentPaymentMethods={shipment_payment_methods}
              hasNoCopay={hasNoCopay}
            />
          </LgPadding>
        </>
      )}
      {isTipAndFeePaymentMethodPickerHidden ? null : (
        <>
          <Separator />
          <LgSpacing />
          <LgPadding topPadding={SPACING.STATIC.NONE}>
            <Pressable onPress={handleTipAndFeePaymentPress}>
              <Row centerVertically>
                <Description
                  color={COLORS.TEXT_COLORS.PRIMARY}
                  fontFamily="semibold"
                >
                  {label}
                </Description>
                <XxsSpacing />
                <AltoIcon name="info-small" />
              </Row>
            </Pressable>
            <SmSpacing />
            <TipPaymentMethodPicker handleNativePay={handleNativePay} />
          </LgPadding>
        </>
      )}
    </Card>
  );
};
