import { COLORS } from '@alto/design-library-tokens';
import { InputRadio, ListDescription } from '@alto/design-system';
import { PaymentMethodIcon } from '@alto/features';
import React from 'react';
import styled from 'styled-components';
import { deletePaymentMethod } from '~shared/actions/paymentMethods';
import { getPaymentMethodIconName } from '~shared/features/payment-methods/helpers';
import formatPaymentMethod from '~shared/features/payment-methods/helpers/formatPaymentMethod';
import { useDispatchShared } from '~shared/store';
import { type PaymentMethod } from '~shared/types/payment_methods';
import ConfirmLink from '~web/components/legacy/ConfirmLink';

const DeleteLink = styled(ConfirmLink)`
  color: ${COLORS.TEXT_COLORS.SECONDARY};
  font-size: 0.75rem;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
`;

type Props = {
  readonly deletable: boolean;
  readonly paymentMethod: PaymentMethod;
  readonly value: string;
};

export default function PaymentMethodRadioOption({ deletable, paymentMethod, value }: Props) {
  const dispatch = useDispatchShared();

  const paymentText = formatPaymentMethod(paymentMethod);
  const paymentMethodIconName = getPaymentMethodIconName(paymentMethod);
  const { details } = paymentMethod;
  let expirationText = '';

  if (details?.card_expiration_month && details?.card_expiration_year) {
    const expirationMonth = details.card_expiration_month;
    const expirationYear = details.card_expiration_year;
    expirationText = `Expires ${expirationMonth}/${expirationYear}`;
  }

  const handleDeletePaymentMethod = (paymentMethod: PaymentMethod) => {
    dispatch(deletePaymentMethod(paymentMethod));
  };

  const rightContent =
    deletable && handleDeletePaymentMethod ? (
      <DeleteLink
        confirmPrompt={`Are you sure you want to delete this? ${paymentText} ${expirationText}`}
        onClick={() => {
          handleDeletePaymentMethod(paymentMethod);
        }}
      >
        DELETE
      </DeleteLink>
    ) : undefined;

  return (
    <InputRadio
      radioPlacement="left"
      value={value}
      label={paymentText}
      descriptions={[<ListDescription key="expiration">{expirationText}</ListDescription>]}
      TitlePressableIcon={<PaymentMethodIcon paymentMethodIconName={paymentMethodIconName} />}
      titlePressableIconPlacement="right-aligned"
      RightContent={rightContent}
    />
  );
}
