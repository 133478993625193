// @owners { team: patients-team }
import { parse } from 'date-fns';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { signUp } from '~shared/actions/auth';
import { openModal } from '~shared/actions/modal';
import { setOnboardingVerificationUser } from '~shared/actions/verifications';
import { DATE_FORMATS } from '~shared/helpers/date';
import { getURLParams } from '~shared/helpers/helper';
import { connect } from '~shared/store';
import SignupForm, { type FormFields } from './SignupForm';

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => {
  return {
    ...state.auth,
    ...state.form,
    ...state.routing,
    loading: state.auth.signingUp,
  };
};

const mapDispatchToProps = {
  openModal,
  push,
  setOnboardingVerificationUser,
  // eslint-disable-next-line import/no-deprecated
  signUp,
};

// @ts-expect-error TS(7006): Parameter 'stateProps' implicitly has an 'any' type.
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { openModal, push, setOnboardingVerificationUser, signUp } = dispatchProps;
  return {
    ...stateProps,
    ...ownProps,
    onSubmit: (formFields: FormFields) => {
      const params = getURLParams();
      const tempPassword = params.token;
      const data = { ...formFields };
      delete params.token;

      if (params.utm_campaign || params.utm_source || params.utm_medium || params.utm_term || params.utm_content) {
        // @ts-expect-error TS(2339): Property 'utm_params' does not exist on type '{ first_name: string; last_name: string; date_of_birth... (Delete me to see the full error)
        data.utm_params = {
          name: params.utm_campaign,
          source: params.utm_source,
          medium: params.utm_medium,
          term: params.utm_term,
          content: params.utm_content,
        };
      }

      // @ts-expect-error TS(2339): Property 'current_password' does not exist on type '{ first_name: string; last_name: string; date_of... (Delete me to see the full error)
      data.current_password = tempPassword;

      if (data.date_of_birth) {
        data.date_of_birth = parse(data.date_of_birth, DATE_FORMATS.DATE_INPUT_FORMAT, 0).toISOString();
      }

      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'string'.
      data.referral_code = data.referral_code ? data.referral_code.trim() : null;

      function callback({ success, verificationRequired }: { success: boolean; verificationRequired: boolean }) {
        if (success) {
          if (ownProps.navigate) {
            push('/home');
          }
        } else if (verificationRequired) {
          setOnboardingVerificationUser(data);
          openModal('CONTACT_VERIFICATION');
        }
      }

      // eslint-disable-next-line promise/catch-or-return, promise/no-callback-in-promise
      signUp(data).then(callback);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SignupForm);
