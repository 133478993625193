// eslint-disable-next-line @alto/no-pocky-import
import { Text as PockyText, Tooltip, palette, spacing } from '@alto/pocky';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

export const Row = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: center;
`;

export const IconContainer = styled.div`
  height: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
`;

export const FAIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
`;

const TooltipIcon = styled(FontAwesomeIcon)`
  margin-left: ${spacing.xsSpacing};
  color: ${palette.royalBlue};
`;

type ColorProps = {
  success?: boolean | null | undefined;
  warning?: boolean | null | undefined;
  danger?: boolean | null | undefined;
  link?: boolean | null | undefined;
  light?: boolean | null | undefined;
};

function getColor({ success, warning, danger, link, light }: ColorProps): string {
  if (success) {
    return palette.teal1;
  }

  if (warning) {
    return palette.yellow1;
  }

  if (danger) {
    return palette.red1;
  }

  if (link) {
    return palette.royalBlue;
  }

  if (light) {
    return palette.grey1;
  }

  return palette.black1;
}

export const Text = styled(PockyText)<{
  italic?: boolean;
  link?: boolean;
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
  center?: boolean;
  small?: boolean;
}>`
  flex-grow: 2;
  ${({ italic }) => (italic ? 'font-style: italic;' : '')};
  color: ${({ link, success, warning, danger }) =>
    getColor({
      link,
      success,
      warning,
      danger,
    })};
`;

type Props = {
  readonly bold?: boolean;
  readonly children: string;
  readonly danger?: boolean;
  readonly icon: IconDefinition;
  readonly infoText?: string;
  readonly success?: boolean;
  readonly warning?: boolean;
  readonly link?: boolean;
  readonly lightText?: boolean;
};

export default class InfoRow extends PureComponent<Props> {
  static defaultProps = {
    bold: false,
    danger: false,
    infoText: '',
    link: false,
    success: false,
    warning: false,
  };

  render() {
    const { bold, children, danger, icon, infoText, success, warning, link, lightText } = this.props;

    if (!children) return null;

    return (
      <Row>
        <IconContainer>
          <FAIcon
            color={getColor({
              success,
              warning,
              danger,
              link,
              light: true,
            })}
            icon={icon}
          />
        </IconContainer>

        <Text
          bold={bold}
          danger={danger}
          small
          success={success}
          warning={warning}
          link={link}
          light={lightText}
        >
          {children}
          {infoText ? (
            <Tooltip content={infoText}>
              <TooltipIcon
                icon={faInfoCircle}
                color={palette.royalBlue}
              />
            </Tooltip>
          ) : null}
        </Text>
      </Row>
    );
  }
}
