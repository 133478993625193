// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Description, LgSpacing, SmSpacing, XsSpacing } from '@alto/design-system';
import React from 'react';
import { type PaymentMethodType } from '~shared/constants';
import { useCartUsers } from '~shared/features/cart/hooks/useCartUsers';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { getCheckoutPaymentAmountErrors, getCheckoutPaymentMethodErrors } from '~shared/features/checkout/helpers';
import { getShowingValidationErrors } from '~shared/features/checkout/selectors/getCart';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getIsPaymentMethodRequired } from '~shared/features/checkout/selectors/getPaymentBreakdown';
import { findMatchingPaymentMethod } from '~shared/features/payment-methods/helpers';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';
import { getItemsSubtotal } from '~shared/features/pricing/selectors/getOrderTotal';
import { getIsSplitPaymentEnabled } from '~shared/features/ui/selectors/getCart';
import { useSelectorShared } from '~shared/store';
import { type ShipmentPaymentMethod } from '~shared/types';
import { CheckoutSplitPaymentSelector } from './CheckoutSplitPaymentSelector';
import { PaymentMethodRow } from './PaymentMethodRow';

type Props = {
  readonly handleNativePay?: (paymentMethodType: PaymentMethodType) => void;
  readonly shipmentPaymentMethods?: ShipmentPaymentMethod[];
  readonly hasNoCopay: boolean;
  readonly disabled?: boolean;
};

export const CheckoutPaymentSelector = ({ handleNativePay, hasNoCopay, shipmentPaymentMethods, disabled }: Props) => {
  const showingValidationErrors = useSelectorShared(getShowingValidationErrors);
  const isSplitPaymentEnabled = useSelectorShared(getIsSplitPaymentEnabled);
  // the total cost used as the anchor point for split payments.
  // does not include tips and other fees (i.e delivery)
  const splitPaymentTotalCost = useSelectorShared(getItemsSubtotal);
  const order = useSelectorShared(getOrder);
  const { payment_method_id, second_payment_method_id } = order;
  const paymentMethods = useSelectorShared(getPaymentMethods);
  const paymentMethod = findMatchingPaymentMethod(paymentMethods, payment_method_id);
  const secondPaymentMethod = findMatchingPaymentMethod(paymentMethods, second_payment_method_id);
  const splitPaymentsTotal = useSelectorShared(getItemsSubtotal);
  const isPaymentMethodRequired = useSelectorShared(getIsPaymentMethodRequired);
  const { paymentMethodValidationErrors } = getCheckoutPaymentMethodErrors({
    order,
    paymentMethods,
    isPaymentMethodRequired,
    isSplitPaymentEnabled,
  });
  const { paymentAmountValidationErrors } = getCheckoutPaymentAmountErrors({
    order,
    isSplitPaymentEnabled,
    splitPaymentsTotal,
    isPaymentMethodRequired,
  });

  const { users } = useCartUsers();
  const { deliveryMethods } = useCartDeliveryMethods();
  const hasSplitContexts = deliveryMethods.length > 1 || users.length > 1;
  const showSplitPayment = true;
  const shouldDisplaySplitPayment = !!splitPaymentTotalCost && showSplitPayment && !hasSplitContexts && !hasNoCopay;
  const errors = showingValidationErrors ? [...paymentAmountValidationErrors, ...paymentMethodValidationErrors] : [];

  return (
    <>
      <SmSpacing />
      <PaymentMethodRow
        disabled={disabled}
        handleNativePay={handleNativePay}
        paymentMethod={paymentMethod}
        isSecondaryPaymentMethod={false}
        showSplitPayment={showSplitPayment}
        splitPaymentTotalCost={splitPaymentTotalCost}
        hasNoCopay={hasNoCopay}
        hasSplitContexts={hasSplitContexts}
        shipmentPaymentMethods={shipmentPaymentMethods}
        isSplitPaymentEnabled={isSplitPaymentEnabled}
      />
      {shouldDisplaySplitPayment && isSplitPaymentEnabled ? (
        <>
          <XsSpacing />
          <PaymentMethodRow
            isSecondaryPaymentMethod
            handleNativePay={handleNativePay}
            paymentMethod={secondPaymentMethod}
            showSplitPayment={showSplitPayment}
            splitPaymentTotalCost={splitPaymentTotalCost}
            hasNoCopay={hasNoCopay}
            hasSplitContexts={hasSplitContexts}
            shipmentPaymentMethods={shipmentPaymentMethods}
            isSplitPaymentEnabled={isSplitPaymentEnabled}
          />
        </>
      ) : null}
      {errors?.length ? (
        <>
          <XsSpacing />
          <Description color={COLORS.TEXT_COLORS.DANGER}>{errors.map((e) => e.message).join(' ')}</Description>
        </>
      ) : null}
      {shouldDisplaySplitPayment ? (
        <CheckoutSplitPaymentSelector
          isSplitPaymentEnabled={isSplitPaymentEnabled}
          paymentMethod={paymentMethod}
        />
      ) : (
        <LgSpacing />
      )}
    </>
  );
};
