// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Column,
  Description,
  InlineAlert,
  LgPadding,
  ListBase,
  LoadingEmptyState,
  MdPadding,
  PressableAltoIcon,
  Row,
  SecondaryPage,
  Separator,
} from '@alto/design-system';
import { type ButtonsGroupProps } from '@alto/design-system/src/components/buttons/buttonUtils';
import { NavBar } from '@alto/design-system/src/components/pages/src/NavBar';
import { type SupportCase } from '@alto/scriptdash/alto/customer_support/types/v1/support_case';
import { type WundercomMessage } from '@alto/scriptdash/alto/customer_support/types/v1/wundercom_message';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, Platform } from 'react-native';
import { updateSupportCaseWithUnreadMessages } from '~shared/actions/messages';
import { ASSISTANT_VIEWED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { type WundercomMessageMap } from '~shared/features/messages/constants';
import { getUnreadMessageIDs } from '~shared/features/messages/helpers/getUnreadMessageIDs';
import {
  type SupportCaseChatbot,
  transformWebsocketMessagePayload,
  transformWebsocketSupportCasePayload,
} from '~shared/features/messages/helpers/transformWebsocketMessagePayload';
import { getSupportCasesWithUnreadMessages } from '~shared/features/messages/selectors/getMessages';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import socket from '~shared/websocket/socket';
import { useGetSupportCases, useGetWundercomMessages } from '../../queries/queries';
import { useReadAllMessages } from '../../queries/useReadAllMessages';
import { useSendMessage } from '../../queries/useSendMessage';
import { LockedCaseInlineAlert } from './LockedCaseInlineAlert';
import { SupportCaseCard } from './SupportCaseCard';
import { SupportCaseContentFootnote } from './SupportCaseContentFootnote';
import { SupportCaseSplitPage } from './SupportCaseSplitPage';
import { ThreadMessage } from './ThreadMessage';
import { getChatbotLoadingMessage } from './utils';

const isNative = Platform.OS !== 'web';

type SupportCaseContentProps = {
  readonly supportCase: SupportCase;
  readonly handleNewRequestPress: () => void;
  readonly handleReplyPress: () => void;
  readonly handleBackPress?: () => void;
};

const SOCKET_CONNECTION_ERROR = "We can't seem to connect. Please refresh the page to see new messages.";

/**
 * Component for a single support case, this will render the case info (support case category/topic, messages, etc.)
 * and if the case is locked, it will render a locked case inline alert
 * @param {SupportCaseContentProps} props component props
 * @returns SupportCaseContent UI
 */
export const SupportCaseContent = ({
  supportCase,
  handleNewRequestPress,
  handleReplyPress,
  handleBackPress,
}: SupportCaseContentProps) => {
  const supportCaseID = supportCase.id;
  const dispatch = useDispatchShared();
  const { readAllMutate } = useReadAllMessages();
  const { trackPageView } = useAnalytics();
  const supportCasesWithUnreadMessages = useSelectorShared(getSupportCasesWithUnreadMessages);
  const user = useSelectorShared(getCurrentUser);
  const email = user?.email || '';

  const [supportCaseChatbot, setSupportCaseChatbot] = useState<SupportCaseChatbot>({
    id: supportCaseID,
    locked_at: supportCase.locked_at,
    chatbot_session_status: supportCase.chatbot_session_status,
  });
  const [error, setError] = useState<string | null>(null);
  const [allMessages, setAllMessages] = useState<WundercomMessage[]>([]);
  const { messages, isPending: isLoadingMessages, refetchMessages } = useGetWundercomMessages({ supportCaseID });
  const { sendMessageMutate, isPending: isSendingMessage } = useSendMessage();
  const disableQuickReplyOptions = isSendingMessage || supportCaseChatbot.chatbot_session_status === 'pending';
  const { refetchSupportCases } = useGetSupportCases();
  // this defaults the state of allMessages to the messages from the API call, we cant pass it in the default
  // of the useState since the call will be made async
  useEffect(() => {
    if (allMessages.length === 0 && messages.length > 0) {
      setAllMessages([...messages]);
    }
  }, [allMessages, messages]);

  // websocket to subscribe to new wundercom messages
  useEffect(() => {
    const wundercomChannelName = `users:${email}:wundercom`;

    const unsub = socket.bind(wundercomChannelName, (payload) => {
      if (payload.event_type === 'create') {
        const wundercomMessage = transformWebsocketMessagePayload(payload);
        if (!allMessages.some((message) => message.id === wundercomMessage.id)) {
          readAllMutate(supportCase.id);
          setAllMessages((updatedMessages) => [...updatedMessages, wundercomMessage]);
        }
      } else if (payload.event_type === 'update') {
        const wundercomMessage = transformWebsocketMessagePayload(payload);
        if (typeof wundercomMessage.support_case_id === 'number') {
          const updatedSet = new Set(supportCasesWithUnreadMessages);

          updatedSet.delete(wundercomMessage.support_case_id);
          dispatch(updateSupportCaseWithUnreadMessages(updatedSet));
          refetchSupportCases();
        }
      }
    });

    const errorUnsub = socket.bindError(() => {
      setError(SOCKET_CONNECTION_ERROR);
    });

    return () => {
      unsub();
      errorUnsub();
    };
  }, [
    allMessages,
    dispatch,
    email,
    readAllMutate,
    refetchSupportCases,
    supportCase.id,
    supportCasesWithUnreadMessages,
  ]);

  // websocket to subscribe to updates made to support case in order to determine chatbot session status
  useEffect(() => {
    const supportCaseChannelName = `users:${email}:support_case`;

    const unsub = socket.bind(supportCaseChannelName, (payload) => {
      if (payload.event_type === 'update') {
        const supportCase = transformWebsocketSupportCasePayload(payload);
        setSupportCaseChatbot(supportCase);
      }
    });

    const errorUnsub = socket.bindError(() => {
      setError(SOCKET_CONNECTION_ERROR);
    });

    return () => {
      unsub();
      errorUnsub();
    };
  }, [email]);

  const isLocked = Boolean(supportCase.locked_at);
  const unreadMessageIDs = getUnreadMessageIDs(messages);
  const list = useRef<FlatList | null>(null);

  const items: WundercomMessage[] = useMemo(() => {
    // reduce to only unique messages in case useEffect ran twice and updated state with the same message
    const uniqueMessages: WundercomMessageMap = {};

    allMessages.forEach((message) => {
      uniqueMessages[message.id] = message;
    });

    // we sort messages by id desc, so the first message is the last message sent
    // this is needed for web so that latest messages appear at the top
    // and then in native, we can just use flat list to invert the order
    const sortedMessages = Object.values(uniqueMessages).sort((a, z) => z.id - a.id);

    return supportCaseChatbot.chatbot_session_status === 'active' && !sortedMessages[0]?.admin_user_id
      ? [getChatbotLoadingMessage(), ...sortedMessages]
      : sortedMessages;
  }, [allMessages, supportCaseChatbot.chatbot_session_status]);

  const isLastMessageFromAlto = Boolean(items[0]?.admin_user_id);
  const hideButtons =
    supportCaseChatbot.chatbot_session_status === 'pending' ||
    (supportCaseChatbot.chatbot_session_status === 'active' && !isLastMessageFromAlto);

  const renderSeparator = useCallback(() => <Separator />, []);
  const handleScrollToBottom = useCallback(
    // do not scroll to the bottom if the last message sent was from Alto.
    // the message from Alto could be a long message so we want to maintain the scroll position rather than scrolling
    // to the bottom of the message
    () => items.length && !isLastMessageFromAlto && list.current?.scrollToEnd({ animated: true }),
    [list, items.length, isLastMessageFromAlto],
  );
  const invertedItems = [...items].reverse();
  const keyExtractor = useCallback((item: WundercomMessage) => `wundercom-message-${item.id}`, []);

  useEffect(() => {
    // NOTE: only run this affect if we find a support case in the cache or from API call
    if (!supportCase?.id || isLoadingMessages) return;
    trackPageView({
      event: EVENTS.ASSISTANT_COMPONENT_VIEWED,
      params: {
        name: ASSISTANT_VIEWED_EVENTS.SUPPORT_CASE,
        supportCaseID: supportCase.id,
        isLocked,
        messageIDs: unreadMessageIDs,
        unreadCount: unreadMessageIDs.length,
      },
    });
    // mark all messages on this case as read on render
    readAllMutate(supportCase.id);
    // NOTE: do not add unreadMessageIDs to dependencies to prevent duplicate event fire after mark messages as read
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, trackPageView, supportCase?.id, isLocked, unreadMessageIDs.length, isLoadingMessages]);

  const debouncedHandleQuickReplyPress = debounce(async ({ message }) => {
    if (disableQuickReplyOptions) return;
    await sendMessageMutate({
      body: message,
      supportCaseID,
      isQuickReply: true,
      includeSuccessToast: supportCaseChatbot.chatbot_session_status !== 'active',
    });
    await refetchMessages();
  }, 500);

  const handleQuickReplyPress = useCallback(
    ({ message }: { message: string }) => {
      debouncedHandleQuickReplyPress({ message });
    },
    [debouncedHandleQuickReplyPress],
  );

  const getBottomButtons = useCallback((): ButtonsGroupProps | undefined => {
    if (isLocked || hideButtons) {
      return undefined;
    }

    if (isLastMessageFromAlto) {
      // we sort messages by id desc, so the first message is the last message sent
      // this is needed for web so that latest messages appear at the top
      // and then in native, we can just use flat list to invert the order
      const lastMessage = items[0];
      const { suggested_responses: suggestedResponses } = lastMessage;
      if (suggestedResponses && suggestedResponses.length > 0) {
        return suggestedResponses.map((response) => (
          <Button
            key={`support-case-quick-reply-button-${response.id}`}
            label={response.title || response.message}
            onPress={() => {
              handleQuickReplyPress({ message: response.message });
            }}
            disabled={disableQuickReplyOptions}
            width="inline"
            small
          />
        ));
      }

      return [
        <Button
          key="support-case-quick-reply-button-thanks"
          label="Thanks"
          onPress={() => {
            handleQuickReplyPress({ message: 'Thanks' });
          }}
          type="secondary"
          disabled={disableQuickReplyOptions}
          width="inline"
          small
        />,
        <Button
          key="support-case-quick-reply-button-got-it"
          label="Got It"
          onPress={() => {
            handleQuickReplyPress({ message: 'Got It' });
          }}
          type="secondary"
          disabled={disableQuickReplyOptions}
          width="inline"
          small
        />,
        <Button
          key="support-case-reply-button"
          label="Reply"
          onPress={handleReplyPress}
          disabled={disableQuickReplyOptions}
          width={isLastMessageFromAlto ? 'inline' : undefined}
          small
        />,
      ];
    }
    return [
      <Button
        key="support-case-reply-button"
        label="Reply"
        onPress={handleReplyPress}
        disabled={disableQuickReplyOptions}
        width={isLastMessageFromAlto ? 'inline' : undefined}
        small
      />,
    ];
  }, [
    handleQuickReplyPress,
    handleReplyPress,
    hideButtons,
    isLastMessageFromAlto,
    isLocked,
    disableQuickReplyOptions,
    items,
  ]);

  if (isNative) {
    return (
      <Column flexShrink={1}>
        <NavBar
          title="Request"
          backgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
          LeftPressableAltoIcon={
            handleBackPress ? (
              <PressableAltoIcon
                name="chevronleft"
                onPress={handleBackPress}
                accessibilityLabel="Press to go back"
              />
            ) : undefined
          }
        />
        <SupportCaseSplitPage
          colorHeight={60}
          topColor="SECONDARY_LIGHTEST"
        >
          <MdPadding bottomPadding={SPACING.STATIC.NONE}>
            <SupportCaseCard
              supportCase={supportCase}
              pressable={false}
            />
          </MdPadding>
          {error ? (
            <MdPadding>
              <InlineAlert type="error">
                <Description>{error}</Description>
              </InlineAlert>
            </MdPadding>
          ) : null}
          {isLoadingMessages ? (
            <LoadingEmptyState />
          ) : (
            <FlatList
              keyExtractor={keyExtractor}
              ref={list}
              ItemSeparatorComponent={renderSeparator}
              onContentSizeChange={handleScrollToBottom}
              ListHeaderComponent={
                <LockedCaseInlineAlert
                  supportCaseLocked={isLocked}
                  handleNewRequestPress={handleNewRequestPress}
                />
              }
              data={invertedItems}
              renderItem={({ item }) => <ThreadMessage {...item} />}
            />
          )}
          <Separator />
          <MdPadding backgroundColor={COLORS.BACKGROUND_COLORS.WHITE}>
            <MdPadding
              topPadding={SPACING.STATIC.NONE}
              bottomPadding={SPACING.STATIC.NONE}
            >
              <SupportCaseContentFootnote
                withSpacing
                chatbotSessionStatus={supportCaseChatbot.chatbot_session_status}
              />
            </MdPadding>
            <MdPadding topPadding={SPACING.STATIC.NONE}>
              <Row
                wrap
                gap="SM"
              >
                {getBottomButtons()}
              </Row>
            </MdPadding>
          </MdPadding>
        </SupportCaseSplitPage>
      </Column>
    );
  }

  return (
    <SecondaryPage
      onDismiss={handleBackPress}
      dismissIcon="chevronleft"
      noContentPadding
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST}
      navBarTitle="Request"
      inlineButtons
      justifyButtons="left"
      Footnote={<SupportCaseContentFootnote chatbotSessionStatus={supportCaseChatbot.chatbot_session_status} />}
      buttons={getBottomButtons()}
      footerPlacementContext="webScreenWithoutPolicyFooter"
      withoutWebFramingElementHeights
    >
      <>
        <MdPadding topPadding={SPACING.STATIC.NONE}>
          <SupportCaseCard
            supportCase={supportCase}
            pressable={false}
          />
        </MdPadding>
        {error ? (
          <LgPadding topPadding={SPACING.STATIC.MD}>
            <Body color={COLORS.TEXT_COLORS.DANGER}>{error}</Body>
          </LgPadding>
        ) : null}
        {isLoadingMessages ? (
          <LoadingEmptyState />
        ) : (
          <ListBase
            items={items.map((message) => ({
              key: `wundercom-message-${message.id}`,
              component: (
                <ThreadMessage
                  id={message.id}
                  admin_user_id={message.admin_user_id}
                  body={message.body}
                  created_at={message.created_at}
                  is_support_chatbot={message.is_support_chatbot}
                  user_id={message.user_id}
                  read_at={message.read_at}
                />
              ),
            }))}
            separator={<Separator />}
          />
        )}
        <LockedCaseInlineAlert
          supportCaseLocked={isLocked}
          handleNewRequestPress={handleNewRequestPress}
        />
      </>
    </SecondaryPage>
  );
};
