import { BORDERS, SIZES } from '@alto/design-library-tokens';
import React from 'react';
import {
  Image,
  type ImageErrorEventData,
  type ImageSourcePropType,
  type NativeSyntheticEvent,
  Platform,
} from 'react-native';
import styled from 'styled-components/native';

export type SizeProps = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type ObjectFit = 'contain' | 'cover' | 'fill' | 'initial';

type Props = {
  readonly source: ImageSourcePropType | string;
  readonly accessibilityLabel?: string;
  readonly onError?: (error: NativeSyntheticEvent<ImageErrorEventData>) => void;
  readonly borderRadius?: number;
  readonly objectFit?: ObjectFit;
  readonly size?: SizeProps;
  readonly testID?: string;
};

type Size =
  | typeof SIZES.ILLUSTRATION.SM.px
  | typeof SIZES.ILLUSTRATION.MD.px
  | typeof SIZES.ILLUSTRATION.LG.px
  | typeof SIZES.ILLUSTRATION.XL.px
  | typeof SIZES.ILLUSTRATION.XXL.px;

const StyledIllustration = styled(Image)<{ borderRadius?: number; objectFit?: ObjectFit; size: Props['size'] }>`
  ${({ borderRadius, objectFit, size }) => {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    let imageSize: Size | string = SIZES.ILLUSTRATION.MD.px;

    if (size === 'sm') {
      imageSize = SIZES.ILLUSTRATION.SM.px;
    }
    if (size === 'md') {
      imageSize = SIZES.ILLUSTRATION.MD.px;
    }

    if (size === 'lg') {
      imageSize = SIZES.ILLUSTRATION.LG.px;
    }

    if (size === 'xl') {
      imageSize = SIZES.ILLUSTRATION.XL.px;
    }

    if (size === 'xxl') {
      imageSize = SIZES.ILLUSTRATION.XXL.px;
    }

    return `
      width: ${imageSize};
      height: ${imageSize};
      border-radius: ${borderRadius}px;
      ${Platform.OS === 'web' && objectFit && `object-fit:${objectFit};`}
    `;
  }}
`;

export const Illustration = ({
  source,
  accessibilityLabel,
  onError,
  borderRadius = BORDERS.RADIUS.NONE.value,
  objectFit,
  size,
  testID,
}: Props) => {
  const src: ImageSourcePropType = typeof source === 'string' ? { uri: source } : source;
  return (
    <StyledIllustration
      accessibilityLabel={accessibilityLabel}
      source={src}
      onError={onError}
      borderRadius={borderRadius}
      objectFit={objectFit}
      size={size}
      testID={testID}
    />
  );
};
