// @owners { team: patients-team }
// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModal, DeprecatedModalBody, FlexRow, InfoBox, Loader, Select, Text, spacing } from '@alto/pocky';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
// eslint-disable-next-line import/no-deprecated
import { fetchFacilities } from '~shared/actions/facilities';
import { DEFAULT_ERROR_ALERT_TITLE, FAX, PHONE } from '~shared/constants';
import { getIsLoggedIn } from '~shared/features/auth/selectors/getIsAuthorized';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type FacilityOptions } from '~shared/selectors/getFacilityOptions';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type APIError } from '~shared/types/APIError';
import Image from '~web/components/Image';
import rx from '~web/images/prescription@2x.png';

type Props = {
  readonly showPharmacyInfoModal?: boolean;
  readonly closePharmacyInfoModal: () => void;
  readonly facilityOptions: FacilityOptions;
  readonly fetchFacilitiesLoading: boolean;
  readonly fetchFacilitiesError: APIError | null | undefined;
};

const MinHeightDiv = styled.div`
  min-height: 10.5rem;
`;

export const PharmacyInfoModal = ({
  showPharmacyInfoModal,
  closePharmacyInfoModal,
  facilityOptions,
  fetchFacilitiesLoading,
  fetchFacilitiesError,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const dispatch = useDispatchShared();
  const { trackEvent, trackPageView } = useAnalytics();
  const [selectedFacilityID, setSelectedFacilityID] = useState<number | null | undefined>(null);
  const isLoggedIn = useSelectorShared(getIsLoggedIn);

  useEffect(() => {
    trackPageView({
      event: EVENTS.PHARMACY_INFO_VIEWED,
      params: {
        flow: isLoggedIn ? 'authenticated' : 'unauthenticated',
      },
      enabled: !!showPharmacyInfoModal,
    });
  }, [isLoggedIn, showPharmacyInfoModal, trackPageView]);

  useEffect(() => {
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchFacilities());
  }, [dispatch]);

  const selectFacility = useCallback(
    (facilityID: number) => {
      trackEvent({
        event: EVENTS.PHARMACY_INFO__LOCATION_SELECTED,
        params: {
          selectedFacilityId: facilityID,
        },
      });
      setSelectedFacilityID(facilityID);
    },
    [trackEvent],
  );

  const renderSelectedFacility = useCallback(
    (selectedFacilityID: number | null | undefined) => {
      let selectedFacility;

      if (selectedFacilityID && facilityOptions.length) {
        selectedFacility = facilityOptions.find((facility) => facility.data.id === selectedFacilityID);
      }

      if (selectedFacility) {
        return (
          <>
            <FlexRow>
              <Text bold>ADDRESS</Text>
              <Text>{selectedFacility.data.full_street_address}</Text>
              <Text>{selectedFacility.data.city_state_zip}</Text>
            </FlexRow>
            <FlexRow>
              <Text bold>CONTACT INFO</Text>
              <Text>{`Phone: ${PHONE}`}</Text>
              <Text>{`Fax: ${FAX}`}</Text>
            </FlexRow>
            {selectedFacility.data.npi ? (
              <FlexRow>
                <Text bold>NPI</Text>
                <Text>{selectedFacility.data.npi}</Text>
              </FlexRow>
            ) : null}
          </>
        );
      }

      return <MinHeightDiv />;
    },
    [facilityOptions],
  );

  const showLocations = !fetchFacilitiesLoading && fetchFacilitiesError === null;
  const showLoadingOrError = fetchFacilitiesLoading || fetchFacilitiesError;

  return showPharmacyInfoModal ? (
    <DeprecatedModal
      showing={!!showPharmacyInfoModal}
      onClose={closePharmacyInfoModal}
      title="Pharmacy Info"
    >
      <DeprecatedModalBody>
        <Image
          center
          src={rx}
          alt="Illustration of paper prescription"
        />
        <FlexRow spacing={spacing.mdSpacing}>
          <Text>
            Ask your doctor to send us your prescription directly. Select the Alto location closest to you and provide
            this info to your doctor’s office as your new preferred pharmacy.
          </Text>
          <FlexRow>
            <Text bold>LOCATIONS</Text>
            <Select
              options={facilityOptions as { label: string; value: number }[]}
              input={{
                onChange: selectFacility,
                value: selectedFacilityID || '',
              }}
              disabled={!!showLoadingOrError}
              reactSelectConfig={{
                placeholder: 'Select a location',
              }}
            />
          </FlexRow>
          {showLocations ? renderSelectedFacility(selectedFacilityID) : null}
          {showLoadingOrError ? (
            <MinHeightDiv>
              {fetchFacilitiesLoading ? <Loader /> : null}
              {fetchFacilitiesError ? (
                <InfoBox
                  danger
                  title={DEFAULT_ERROR_ALERT_TITLE}
                >
                  <Text>
                    Please try again or{' '}
                    <Link
                      to="/messages"
                      onClick={closePharmacyInfoModal}
                    >
                      send us a message
                    </Link>{' '}
                    to get information for your nearest Alto pharmacy location.
                  </Text>
                </InfoBox>
              ) : null}
            </MinHeightDiv>
          ) : null}
        </FlexRow>
      </DeprecatedModalBody>
    </DeprecatedModal>
  ) : null;
};
