// @owners { team: patients-team }
import { type BACKGROUND_COLORS_VALUES } from '@alto/design-library-tokens';
import { AltoIcon, PresentationListItem } from '@alto/design-system';
import React from 'react';
import { formatRelativeDateWithDow, formatWindowTime } from '~shared/helpers/date';

type CalendarRowProps = {
  readonly date: string | null | undefined;
  readonly startTime?: string | null;
  readonly endTime?: string | null;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
  readonly isUSPS?: boolean;
  readonly isEligibleForMailAutoRefill?: boolean;
  readonly isAsapDelivery?: boolean;
  readonly isPickup?: boolean;
};

export const CalendarRow = React.memo(function CalendarRow({
  date,
  startTime,
  endTime,
  backgroundColor,
  isUSPS,
  isEligibleForMailAutoRefill,
  isAsapDelivery = false,
  isPickup = false,
}: CalendarRowProps) {
  let datetime = formatRelativeDateWithDow(date || '');

  if (!!endTime && isAsapDelivery) {
    datetime += `, by ${formatWindowTime({ windowTime: endTime, meridiem: 'a', forceReturnMinutes: true })} (estimated)`;
  } else if (isPickup && !!startTime) {
    datetime += `, ready by ${formatWindowTime({ windowTime: startTime, meridiem: 'a', forceReturnMinutes: true, useUTC: false })} (estimated)`;
  } else if (!!startTime && !!endTime && !isEligibleForMailAutoRefill && !isUSPS) {
    datetime += `, ${formatWindowTime({ windowTime: startTime, meridiem: 'a' })} - ${formatWindowTime({ windowTime: endTime, meridiem: 'a' })}`;
  }

  if (isUSPS) {
    datetime = `Expected ${datetime}`;
  }

  return (
    <PresentationListItem
      backgroundColor={backgroundColor}
      testID="calendar-row"
      LeftContent=<AltoIcon
        name="calendar"
        type="grey"
      />
      text={datetime}
      type="grey"
    />
  );
});
