// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Button, Column, LgPadding, ListDescription, SmSpacing, Subway, SubwayStation } from '@alto/design-system';
import React, { useEffect } from 'react';
import { Linking } from 'react-native';
import { type APIOrderMethod } from '~shared/features/delivery-info/types';
import { SHIPMENT_STATUS_MAP } from '~shared/features/shipments/constants';
import { isMail, isPickup, isUSPS } from '~shared/helpers/order';
import { type TrackProps, useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type DeliveryStatus, type ShipmentStatus } from '~shared/types';

export const subwayDescription = ({
  isPastStop,
  isFutureStop,
  isMailDelivery,
  isPickupDelivery,
  isUSPSDelivery,
  shipmentStatus,
}: {
  isPastStop: boolean;
  isFutureStop: boolean;
  isMailDelivery: boolean;
  isPickupDelivery: boolean;
  isUSPSDelivery: boolean;
  shipmentStatus: ShipmentStatus;
}) => {
  if (isPastStop || shipmentStatus === 'delivered') {
    return undefined;
  } else if (isUSPSDelivery && isFutureStop) {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.uspsInfoTextFuture;
  } else if (isMailDelivery && isFutureStop) {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.mailInfoTextFuture;
  } else if (isMailDelivery) {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.mailInfoText;
  } else if (isPickupDelivery && isFutureStop) {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.pickupInfoTextFuture;
  } else if (isPickupDelivery) {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.pickupInfoText;
  } else if (isFutureStop) {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.infoTextFuture;
  } else {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.infoText;
  }
};

export const subwayTitle = ({
  isMailDelivery,
  isPickupDelivery,
  shipmentStatus,
}: {
  isMailDelivery: boolean;
  isPickupDelivery: boolean;
  shipmentStatus: ShipmentStatus;
}) => {
  if (isMailDelivery) {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.mailStatusString || '';
  } else if (isPickupDelivery) {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.pickupStatusString || '';
  } else {
    return SHIPMENT_STATUS_MAP[shipmentStatus]?.statusString || '';
  }
};

/**
 * Helper for getting the correct Title, Description (present or future tense), and possibly a
 * clickable button if there is a tracking link included on the shipment and it's 'shipped'
 */
export const getSubwayContent = ({
  isMailDelivery,
  isPickupDelivery,
  isUSPSDelivery,
  shipmentStatus,
  deliveryStatusIndex,
  currentIndex,
  shipmentTrackingLink,
  trackEvent,
  shipmentId,
}: {
  isMailDelivery: boolean;
  isPickupDelivery: boolean;
  isUSPSDelivery: boolean;
  shipmentStatus: ShipmentStatus;
  deliveryStatusIndex: number;
  currentIndex: number;
  shipmentTrackingLink: string | undefined;
  trackEvent: ({ event, params, additionalFields }: TrackProps) => void;
  shipmentId: number | undefined;
}) => {
  const isPastStop = currentIndex < deliveryStatusIndex;
  const isFutureStop = currentIndex > deliveryStatusIndex;

  let trackingButton = null;
  const title = subwayTitle({ isMailDelivery, isPickupDelivery, shipmentStatus });
  const description = subwayDescription({
    isPastStop,
    isFutureStop,
    isMailDelivery,
    isPickupDelivery,
    isUSPSDelivery,
    shipmentStatus,
  });

  // special case to show the tracking link if the delivery is on the way and there's a tracking link
  if (currentIndex === 2 && deliveryStatusIndex === 2 && shipmentStatus === 'shipped' && shipmentTrackingLink) {
    const onPressTrackingButton = () => {
      trackEvent({
        event: EVENTS.TRACK_DELIVERY_BUTTON_PRESSED,
        additionalFields: {
          shipmentId,
        },
      });
      Linking.openURL(shipmentTrackingLink);
    };

    trackingButton = (
      <Column>
        <SmSpacing />
        <Button
          small
          onPress={onPressTrackingButton}
        >
          Track your order
        </Button>
      </Column>
    );
  }

  return { title, description, trackingButton };
};

export type OrderStatusTrackerProps = {
  readonly shipmentId: number | undefined;
  readonly deliveryMethod: APIOrderMethod | undefined | null;
  readonly deliveryStatus: DeliveryStatus | ShipmentStatus;
  readonly showTracking: boolean;
  readonly shipmentTrackingLink?: string | undefined;
};

/**
 * Component for rendering the subway stations for a delivery. The number and titles of the subways depend on this
 * being a mail v courier v pick up order. deliveryStatus determines the active subway stop
 * @param OrderStatusTrackerProps props component props
 * @returns OrderStatusTracker UI
 */
export const OrderStatusTracker = ({
  shipmentId,
  deliveryMethod,
  deliveryStatus,
  showTracking,
  shipmentTrackingLink,
}: OrderStatusTrackerProps) => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent({
      event: EVENTS.DELIVERY_STATUS_TRACKER_VIEWED,
      params: {
        deliveryMethod,
        deliveryStatus,
        showTracking,
        shipmentTrackingLink,
      },
      additionalFields: {
        shipmentId,
      },
    });
  }, [deliveryMethod, deliveryStatus, shipmentId, shipmentTrackingLink, showTracking, trackEvent]);

  const terminalStatus = deliveryStatus === 'failed' ? 'failed' : 'delivered';
  const isPickupDelivery = isPickup(deliveryMethod);
  const isMailDelivery = isMail(deliveryMethod);
  const isUSPSDelivery = isUSPS(deliveryMethod);

  const stations: ShipmentStatus[] = ['upcoming', 'packaged', 'shipped', terminalStatus];
  const deliveryStatusIndex = SHIPMENT_STATUS_MAP[deliveryStatus].index;
  const activeStation = SHIPMENT_STATUS_MAP[deliveryStatus].activeSubwayStation;

  return (
    <LgPadding
      bottomPadding={SPACING.STATIC.NONE}
      leftPadding={SPACING.STATIC.NONE}
    >
      <Subway
        activeStation={activeStation}
        status={deliveryStatus === 'delivered' ? 'completed' : undefined}
      >
        {stations.map((shipmentStatus, index) => {
          const { title, description, trackingButton } = getSubwayContent({
            isPickupDelivery,
            isMailDelivery,
            isUSPSDelivery,
            shipmentStatus,
            deliveryStatusIndex,
            currentIndex: index,
            shipmentTrackingLink,
            trackEvent,
            shipmentId,
          });
          const descriptions = description
            ? [
                <ListDescription key={`${shipmentStatus}-info`}>
                  {trackingButton && showTracking && shipmentTrackingLink ? trackingButton : description}
                </ListDescription>,
              ]
            : [];
          return (
            <SubwayStation
              key={shipmentStatus}
              id={shipmentStatus}
              title={title}
              descriptions={descriptions}
            />
          );
        })}
      </Subway>
    </LgPadding>
  );
};
