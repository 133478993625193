import { type DeliveryMethod } from '@alto/deliver_api/types/v1/delivery_methods/delivery_method';
import { type SPACING_STATIC_SIZE } from '@alto/design-library-tokens';
import { Description, XsSpacing } from '@alto/design-system';
import React from 'react';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { ADDRESS_MESSAGES } from '~shared/features/checkout/constants';
import { getHasScheduleableDeliveryFeesInCart } from '~shared/features/checkout/selectors/getCart';
import { getIsOrderPickupMethod } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex, getOrdersByIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { type Order } from '~shared/features/checkout/types';
import { getAppliedDeliveryFee } from '~shared/features/shipment-fees/selectors/getAppliedDeliveryFee';
import { isFedEx, isUSPS } from '~shared/helpers/order';
import { useQueryShipmentFeesAvailable } from '~shared/queries';
import { useSelectorShared } from '~shared/store';
import { useCheckoutAddressInfoKeys } from '../../../hooks/useCheckoutAddressInfoKeys';
import { useSignatureConfirmation } from '../../../hooks/useSignatureConfirmation';
import { feeTypeToFormValue, getPreSelectRadioValueForOrder } from '../../select-date-card/utils';
import { AddressInfoAlert } from './AddressInfoAlert';

export const AddressInfo = ({ topPadding }: { topPadding?: SPACING_STATIC_SIZE }) => {
  const ordersByIndex = useSelectorShared(getOrdersByIndex);
  const orderIndex = useSelectorShared(getOrderIndex);
  const order: Order | undefined = ordersByIndex[orderIndex];
  const hasSchedulableDeliveryFeesInCart = useSelectorShared(getHasScheduleableDeliveryFeesInCart);
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const { dateToFeeHash } = useQueryShipmentFeesAvailable();
  const { earliestAvailableDate } = useCartNextAvailableDate();
  const { keys: addressInfoKeys, isOutOfZone } = useCheckoutAddressInfoKeys();
  const { deliveryMethods } = useCartDeliveryMethods();
  const deliveryMethod: DeliveryMethod | undefined = deliveryMethods[orderIndex];
  const { homeToSignConfirmationRequired } = useSignatureConfirmation();

  // determine if order date is not selected for mail orders
  const appliedDeliveryFee = useSelectorShared(getAppliedDeliveryFee);
  const preSelect = getPreSelectRadioValueForOrder({
    hasSchedulableDeliveryFeesInCart,
    orderDate: order?.date,
    dateToFeeHash,
    earliestAvailableDate,
    radioValue: appliedDeliveryFee ? feeTypeToFormValue[appliedDeliveryFee.fee_type] : '',
  });

  // if the address is out of zone or the patient needs to confirm the signature this information will appear elsewhere
  // if the patient selects the pickup option, they don't need to be home or sign for the package
  if (isOutOfZone || homeToSignConfirmationRequired || isOrderPickupMethod) return null;

  if (deliveryMethod?.mode === 'MAIL') {
    const hasControls = !!addressInfoKeys.has_controls;
    const type = hasControls ? 'warning' : 'neutral';
    const { title, message } = ADDRESS_MESSAGES.outside_courier_zone({
      isFedEx: isFedEx(deliveryMethod?.name),
      isUSPS: isUSPS(deliveryMethod?.name),
      isExpensive: !!addressInfoKeys.expensive,
      isMailCarrierUnknown: preSelect === 'no-selection',
      hasControls,
    });
    return (
      <AddressInfoAlert
        type={type}
        topPadding={topPadding}
      >
        {hasControls ? (
          <>
            <Description fontFamily="semibold">{title}</Description>
            <XsSpacing />
          </>
        ) : null}
        <Description>{message}</Description>
      </AddressInfoAlert>
    );
  }

  if (addressInfoKeys.has_controls)
    return (
      <AddressInfoAlert
        type="warning"
        topPadding={topPadding}
      >
        <Description>{ADDRESS_MESSAGES.a_signature_required}</Description>
      </AddressInfoAlert>
    );

  return (
    <AddressInfoAlert
      type="neutral"
      topPadding={topPadding}
    >
      <Description>{ADDRESS_MESSAGES.no_need_to_be_home}</Description>
    </AddressInfoAlert>
  );
};
