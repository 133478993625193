// @owners { team: patients-team }
import {
  Body,
  Button,
  Description,
  ExternalLink,
  H2,
  InlineAlert,
  InputText,
  Row,
  Toast,
  ToastContext,
  XsSpacing,
} from '@alto/design-system';
import { useRegisterWaitlistUser } from '@alto/features';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { push } from 'react-router-redux';
import location from '~shared/assets/images/location.png';
import { ALTO_LOCATIONS_URL, PRIVACY_POLICY_URL } from '~shared/constants';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import { validateEmail } from '~shared/validations';
import Image from '~web/components/Image';

type Props = {
  readonly zip: string;
};

export const OnboardingGeoUncovered = ({ zip }: Props) => {
  const dispatch = useDispatchShared();
  const { trackPageView } = useAnalytics();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { addToast } = useContext(ToastContext);
  const { mutateAsync } = useRegisterWaitlistUser();

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING_OOZ_ZIP_CODE,
      params: {
        zip,
      },
    });
  }, [zip, trackPageView]);

  const handleSubmit = useCallback(async () => {
    if (validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    setError('');
    const response = await mutateAsync({ zip, email, first_name: null, last_name: null });
    if (response.errors) {
      addToast(<Toast variant="error">Error saving email address</Toast>);
      return;
    }
    addToast(<Toast variant="success">Email address saved</Toast>);
    dispatch(push('/login'));
  }, [zip, email, mutateAsync, addToast, dispatch]);

  const handleEmailChange = (text: string) => {
    setEmail(text);
    if (error && !validateEmail(text)) {
      setError('');
    }
  };

  return (
    <>
      <Image
        center
        src={location}
        alt="Coming Soon"
      />
      <Row center>
        <H2 center>We'll be in your area soon!</H2>
        <Body center>Enter your email address and we’ll notify you when we arrive.</Body>
      </Row>
      <InputText
        onChangeText={handleEmailChange}
        placeholder="Enter email address"
        error={error}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Button onPress={handleSubmit}>Submit</Button>
      <Row center>
        <ExternalLink href={ALTO_LOCATIONS_URL}>See Alto's current locations</ExternalLink>
      </Row>
      <InlineAlert type="neutral">
        <Description fontFamily="semibold">Using Alto as your fertility pharmacy?</Description>
        <XsSpacing />
        <Description>
          If you selected Alto as your fertility pharmacy, we’ll reach out to set up your account once we receive your
          prescription from your provider.
        </Description>
      </InlineAlert>
      <Row center>
        <ExternalLink href={PRIVACY_POLICY_URL}>Privacy Policy</ExternalLink>
      </Row>
    </>
  );
};
