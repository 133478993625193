// @owners { team: patients-team }
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import { useMemo } from 'react';
const fsaEligibleImage = require('../assets/fsa-eligible.png');

type Props = {
  addOnOtc?: AddOnOtc;
};

export const useEssentialsFsaBadge = ({ addOnOtc }: Props = {}) => {
  const showFsaBadge = addOnOtc?.fsa_hsa_eligible;

  return useMemo(
    () => ({
      showFsaBadge,
      fsaEligibleImage,
    }),
    [showFsaBadge],
  );
};
