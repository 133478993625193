/**
 * This file takes care of managing all loading state. When an action conforms
 * the convention SNAKE_CASE_ACTION_LOADING, this reducer will set
 * camelCaseActionLoading to true in the ui.loading state. When an action
 * conforms to the convention SNAKE_CASE_ACTION_SUCCEEDED or
 * SNAKE_CASE_ACTION_FAILED, camelCaseActionLoading will be set to false.
 *
 * Optionally, for endpoints that operate on a single entity (ie you can hit the
 * same endpoint for different id's), putting an id in the action will add an
 * entry to camelCaseActionLoadingByID so that loading states can be shown
 * individually.
 *
 * Everything under the ui namespace should be blocked from persisting so
 * users don't get stuck in loading states between sessions.
 */
import { type UIAction, type UIActionType, getBaseName, isFailure, isLoading, isSuccess } from './helpers';
import { CLEAR_DELIVERIES_ERROR } from '~shared/actions/deliveries';

export type StateUiLoading = typeof initialState;
const initialState = {
  archivePrescriptionLoading: false,
  autobillPrescriptionLoadingByID: {} as Record<number, boolean>,
  cancelDeliveriesLoading: false,
  cancelRenewalLoading: false,
  completeVerificationLoading: false,
  createAddressLoading: false,
  createFeedbackLoading: false,
  createInsuranceLoading: false,
  createPaymentMethodLoading: false,
  createPlaidLinkTokenLoading: false,
  createPhotoInsuranceLoading: false,
  createShipmentTipLoading: false,
  createSurveyResponseLoading: false,
  declineInsuranceLoading: false,
  deleteAddressLoading: false,
  deleteInsuranceLoading: false,
  deletePaymentMethodLoading: false,
  deleteTransferLoading: false,
  enableBiometricsLoading: false,
  fetchActiveDeliveriesLoading: false,
  fetchActivePrescriptionsLoading: false,
  fetchAddressesLoading: false,
  fetchAncillariesLoading: false,
  fetchAppDownloadLinkLoading: false,
  fetchAvailableDatesLoading: false,
  fetchCategorizedAddOnsLoading: false,
  fetchCopaysLoading: false,
  fetchDeliveriesLoading: false,
  fetchDeliveryWindowsLoading: false,
  fetchEnterCartLoading: false,
  fetchExpensiveLoading: false,
  fetchFacilitiesLoading: false,
  fetchFsaReceiptLoading: false,
  fetchMedicationNamesLoading: false,
  fetchNextAvailableDatesLoading: false,
  fetchOnboardStateLoading: false,
  fetchPaymentMethodsLoading: false,
  fetchPrescriptionsLoading: false,
  fetchQuantityOptionsLoading: false,
  fetchRenewalRequestTimelineLoading: false,
  fetchServiceAvailabilityLoading: false,
  fetchS3PresignedPostLoading: false,
  fetchShipmentsLoading: false,
  fetchShipmentPaymentMethodsLoading: false,
  fetchShipmentPricing: false,
  fetchShipmentTipsLoading: false,
  fetchTransfersLoading: false,
  fetchUsersLoading: false,
  fetchUserSubscriptionsLoading: false,
  fetchPrescriptionPricingLoading: false,
  fetchOrderPricingLoading: false,
  fetchEnterCheckoutLoading: false,
  forgottenPasswordLoading: false,
  identityVerificationNeededLoading: false,
  loginLoading: false,
  logoutLoading: false,
  markingWrongUserLoading: false,
  requestRenewalLoadingByID: {} as Record<number, boolean>,
  resetPasswordLoading: false,
  saveOrderLoading: false,
  sendMessageLoading: false,
  signupLoading: false,
  startVerificationLoading: false,
  syncBiometricsLoading: false,
  unarchivePrescriptionLoading: false,
  updateAddressLoading: false,
  updateFertilityCycleLoading: false,
  updateInsuranceLoading: false,
  updatePaymentMethodLoading: false,
  updateOnboardingUserLoading: false,
  updatePrescriptionLoading: false,
  updateQuantityLoading: false,
  updateShipmentTipLoading: false,
  updateUserLoading: false,
  updateUserSubscriptionsLoading: false,
  uploadAvatarLoading: false,
  uploadFeedbackPhotoLoading: false,
  uploadImageToS3Loading: false,
  uploadPhotoIDsLoading: false,
  validatingUserLoading: false,
  fetchSudoLoading: false,
  setSudoLoading: false,
  updateAutoRefillLoading: false,
  verifyCommsLoading: false,
  verifyIdentityAndCommsLoading: false,
  verifySignatureLoading: false,
  requestVerificationLoading: false,
};

const getLoadingName = (type: UIActionType) => `${getBaseName(type)}Loading`;

const getLoadingByIDName = (type: UIActionType) => `${getLoadingName(type)}ByID`;

const byIDState = (action: UIAction, state: StateUiLoading, value: boolean) => {
  const { type, id } = action;

  if (!id) {
    return null;
  }

  return {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
    [getLoadingByIDName(type)]: { ...state[getLoadingByIDName(type)], [id]: value },
  };
};

const uiLoading = (state: StateUiLoading = initialState, action: UIAction): StateUiLoading => {
  const { type } = action;

  if (type === CLEAR_DELIVERIES_ERROR) {
    return { ...state, cancelDeliveriesLoading: false };
  }

  if (isLoading(type)) {
    return { ...state, ...byIDState(action, state, true), [getLoadingName(type)]: true };
  }

  if (isSuccess(type)) {
    return { ...state, ...byIDState(action, state, false), [getLoadingName(type)]: false };
  }

  if (isFailure(type)) {
    return { ...state, ...byIDState(action, state, false), [getLoadingName(type)]: false };
  }

  return { ...state };
};

export default uiLoading;
