// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, Description, InlineAlert, LgPadding, Link, XsSpacing } from '@alto/design-system';
import { type PickupData } from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import React, { useContext } from 'react';
import { LocationRow } from '../../../info-rows';
import { PharmacyHoursActionSheet } from './PharmacyHoursActionSheet';
import { formatStoreTime } from './utils';

type Props = {
  pickupData?: PickupData | null;
};

export const PickupInfo = ({ pickupData }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  if (!pickupData) {
    return null;
  }

  const {
    facility_address,
    facility_weekday_store_open,
    facility_weekday_store_close,
    facility_weekend_store_open,
    facility_weekend_store_close,
  } = pickupData;

  const today = new Date().getDay();
  const storeOpenTime = today === 0 || today === 6 ? facility_weekend_store_open : facility_weekday_store_open;
  const storeCloseTime = today === 0 || today === 6 ? facility_weekend_store_close : facility_weekday_store_close;

  const handleSeeAllHours = () => {
    setActiveActionSheet(<PharmacyHoursActionSheet pickupData={pickupData} />);
  };

  return (
    <LgPadding
      topPadding={SPACING.STATIC.MD}
      bottomPadding={SPACING.STATIC.MD}
    >
      <InlineAlert type="neutral">
        <>
          <Description>Pickup address:</Description>
          <LocationRow
            address={facility_address}
            isPickup
            showMap
            copyOnly
          />
          <XsSpacing />
          <Description>{`Open today: ${formatStoreTime(storeOpenTime)} - ${formatStoreTime(storeCloseTime)}`}</Description>
          {!!pickupData && (
            <Link
              onPress={handleSeeAllHours}
              textSize="mini"
            >
              See all hours
            </Link>
          )}
        </>
      </InlineAlert>
    </LgPadding>
  );
};
