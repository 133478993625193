// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { H2, Pressable } from '@alto/design-system';
import { AddOnsContext, type AddOnsContextProps } from '@alto/features';
import { useQueryEssentials } from '@alto/features';
import React, { useMemo } from 'react';
import { goBack } from 'react-router-redux';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { getURLParams } from '~shared/helpers/helper';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { ErrorPage, GridProductList, LoadingPage, ProductItem } from '~web/features/essentials/components';
import { useTrackViewedEvent } from '~web/features/essentials/hooks';
import { Icon, View } from '~web/features/essentials/primitives';

type Props = {
  readonly params: { categoryName: string };
};

export const StoreCategory = ({ params: { categoryName } }: Props) => {
  const dispatch = useDispatchShared();
  const { order, origin } = getURLParams();
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID: Number(order) }));
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID: Number(order) }));
  const { isPending, error, categories } = useQueryEssentials({
    addressID: address?.id,
    date: shipment?.scheduled_date,
  });
  const category = useMemo(
    () => categories.find((category) => category.name === categoryName),
    [categories, categoryName],
  );
  const addOnsContext = useMemo<AddOnsContextProps>(
    () => ({
      shipmentID: shipment?.id,
      origin,
    }),
    [shipment?.id, origin],
  );

  useTrackViewedEvent(EVENTS.ESSENTIALS__STORE_CATEGORY_VIEWED, order, origin);

  const handleNavBack = () => {
    dispatch(goBack());
  };

  if (isPending) {
    return <LoadingPage />;
  }
  if (error) {
    return (
      <ErrorPage
        title="Unable to load Essentials"
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, sonarjs/no-gratuitous-expressions
        description={`${error || 'unknown error'}`}
      />
    );
  }
  if (!categories || !category) {
    return (
      <ErrorPage
        title="Unable to load Essentials"
        description="missing categories"
      />
    );
  }

  return (
    <AddOnsContext.Provider value={addOnsContext}>
      <View
        flexDirection="column"
        backgroundColor="WHITE"
      >
        <View
          padding={SPACING.STATIC.LG.px}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Pressable
            accessibilityLabel="navigate back"
            backgroundColor={COLORS.BACKGROUND_COLORS.TRANSPARENT}
            hoverBackgroundColor={COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST}
            onPress={handleNavBack}
            pressedBackgroundColor={COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST}
          >
            <Icon
              interactive
              label="navigate back"
              name="chevronleft"
              size="LG"
            />
          </Pressable>
          <H2>{categoryName}</H2>
          <View opacity={0}>
            <Icon
              interactive={false}
              label="hidden nav icon"
              name="chevronleft"
              size="LG"
            />
          </View>
        </View>
        <GridProductList>
          {category.add_on_otcs.map((product, index) => (
            <ProductItem
              key={product.sku}
              categoryName={categoryName}
              count={1}
              product={product}
              index={index}
              isStoreItem
            />
          ))}
        </GridProductList>
      </View>
    </AddOnsContext.Provider>
  );
};
