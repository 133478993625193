// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Caption, Column, Description, Row, XsSpacing } from '@alto/design-system';
import React, { useEffect, useMemo } from 'react';
import { getDeliveriesForPrescriptionID } from '~shared/features/delivery/selectors/getDeliveriesForPrescriptionID';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';

export type PricingRowInfo = {
  readonly title: string;
  readonly price: string;
  readonly detail: string | undefined;
};

export type PricingRowProps = {
  readonly pricingInfo: PricingRowInfo;
  /* For analytics */
  readonly prescriptionId: number;
};

export const PricingRow = ({ pricingInfo, prescriptionId }: PricingRowProps) => {
  const { trackEvent } = useAnalytics();
  // Deliveries and deliveryId only used for analytics
  const deliveries = useSelectorShared((state) =>
    getDeliveriesForPrescriptionID(state, {
      prescriptionID: prescriptionId,
    }),
  );
  const deliveryId = useMemo(() => (deliveries?.length ? deliveries.map((d) => d.id)[0] : undefined), [deliveries]);

  const { title, price, detail } = pricingInfo;

  useEffect(() => {
    trackEvent({
      event: EVENTS.PRICE_INFO_VIEWED,
      params: {
        price,
        price_type: title,
        origin: ORIGIN_NAMES.NEW_MED_DETAILS,
      },
      additionalFields: { prescriptionId, deliveryId },
    });
  }, [deliveryId, prescriptionId, price, title, trackEvent]);

  return (
    <Column flexShrink={1}>
      <Row
        spaceBetween
        wrap={false}
      >
        <Row flexShrink={1}>
          <Description>{title}</Description>
          <XsSpacing />
        </Row>
        <Description
          fontFamily="semibold"
          color={COLORS.TEXT_COLORS.PRIMARY}
        >
          {price}
        </Description>
      </Row>
      {detail ? (
        <Row>
          <Caption fontFamily="italic">{detail}</Caption>
        </Row>
      ) : null}
    </Column>
  );
};
