// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding } from '@alto/design-system';
import React from 'react';
import flaskTestTube from '~shared/assets/images/flask-test-tube.png';

export const CompoundedMedicationActionSheet = ({
  medicationName,
}: {
  readonly medicationName: string | undefined;
}) => (
  <ActionSheetV2
    title="Compounded medication"
    illustrationSrc={flaskTestTube}
  >
    <LgPadding
      topPadding={SPACING.STATIC.NONE}
      bottomPadding={SPACING.STATIC.NONE}
    >
      <Body>
        <Body fontFamily="semibold">{medicationName}</Body> will be specifically prepared for you by our partner
        compounding pharmacy.
      </Body>
    </LgPadding>
  </ActionSheetV2>
);
