// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  Button,
  Column,
  Description,
  InlineAlert,
  InputText,
  LgSpacing,
  MdSpacing,
  Row,
  Toast,
  ToastContext,
  XlSpacing,
  XxlSpacing,
  XxsSpacing,
  useScreenSize,
} from '@alto/design-system';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type * as stripeJs from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useContext, useMemo, useState } from 'react';
import {
  createCreditCardPaymentMethod,
  createPaymentMethodFailed,
  creatingPaymentMethod,
} from '~shared/actions/paymentMethods';
import { STRIPE_PUBLISHABLE_KEY } from '~shared/config';
import { type PaymentMethodType } from '~shared/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { StripeInputLabel } from './StripeInputLabel';
import { StripeInputText } from './StripeInputText';

type Props = {
  readonly onClose: () => void;
  readonly paymentMethodType: PaymentMethodType;
};

export const stripeBaseStyle = {
  base: {
    backgroundColor: COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST,
    color: COLORS.TEXT_COLORS.BLACK,
    lineHeight: '52px',
    '::placeholder': {
      color: COLORS.TEXT_COLORS.DISABLED,
    },
  },
  invalid: {
    color: COLORS.TEXT_COLORS.DANGER,
  },
};

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const AddPaymentCardForm = ({ onClose, paymentMethodType }: Props) => {
  const { trackEvent } = useAnalytics();
  const { isMDScreenOrBigger } = useScreenSize();
  const [stripeFieldComplete, setStripeFieldComplete] = useState({ cardNumber: false, expDate: false, cvc: false });
  const [zipCode, setZipCode] = useState<string>('');
  const stripe = useStripe();
  const elements = useElements();
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatchShared();
  const paymentMethodError = useSelectorShared(
    (state) =>
      state.ui.errors.fetchPaymentMethodsError ||
      state.ui.errors.createPaymentMethodError ||
      state.ui.errors.deletePaymentMethodError,
  );

  const loading = useSelectorShared((state) => state.ui.loading.createPaymentMethodLoading);

  const validZipCode = useMemo(() => {
    const validZip = zipCode.match(/[0-9]/g);
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (validZip && validZip.length === 5) {
      return true;
    }
    return false;
  }, [zipCode]);

  const handleSubmit = async () => {
    if (loading || !stripe || !elements) {
      return;
    }
    trackEvent({ event: EVENTS.ADD_CARD_TAPPED });

    /**
     * `createCreditCardPaymentMethod` also calls this eventually, but we need
     * to do it early to prevent duplicate form submissions and start the
     * loading state on the form while we make the async call to Stripe.
     */
    dispatch(creatingPaymentMethod());

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      // this should never happen, but it's here to satisfy typescript
      throw new Error('Error setting up your card');
    }

    const tokenResult = await stripe.createToken(cardElement, { address_zip: zipCode });
    if (tokenResult.error) {
      dispatch(createPaymentMethodFailed(tokenResult.error.message));
    } else {
      const apiSuccess = await dispatch(
        createCreditCardPaymentMethod({
          // @ts-expect-error this should be fine
          token: tokenResult.token,
          paymentMethodType,
        }),
      );
      if (apiSuccess) {
        onClose();
        addToast(<Toast variant="success">Successfully added credit card!</Toast>);
        trackEvent({
          event: EVENTS.PAYMENT_METHOD_CREATED,
          params: {
            newStripeFormEnabled: false,
            type: 'StripeData',
          },
        });
      } else {
        trackEvent({
          event: EVENTS.CREATE_PAYMENT_METHOD_FAILED,
          params: {
            newStripeFormEnabled: false,
          },
        });
      }
    }
  };

  const validateElement = ({ complete }: stripeJs.StripeElementChangeEvent, elementType: string) => {
    setStripeFieldComplete((prev) => ({ ...prev, [elementType]: complete }));
  };

  return (
    <>
      {isMDScreenOrBigger ? (
        <>
          <StripeInputText text="Card number">
            <CardNumberElement
              onChange={(e) => {
                validateElement(e, 'cardNumber');
              }}
              options={{
                showIcon: true,
                style: stripeBaseStyle,
              }}
            />
          </StripeInputText>
          <XlSpacing />
          <Row>
            <Column flexGrow={1}>
              <StripeInputText text="Exp date">
                <CardExpiryElement
                  onChange={(e) => {
                    validateElement(e, 'expDate');
                  }}
                  options={{
                    style: stripeBaseStyle,
                  }}
                />
              </StripeInputText>
            </Column>
            <MdSpacing />
            <Column flexGrow={1}>
              <StripeInputText text="Security code">
                <CardCvcElement
                  onChange={(e) => {
                    validateElement(e, 'cvc');
                  }}
                  options={{
                    style: stripeBaseStyle,
                  }}
                />
              </StripeInputText>
            </Column>
            <MdSpacing />
            <Column flexGrow={1}>
              <StripeInputLabel text="Billing zip code" />
              <XxsSpacing />
              <InputText
                accessibilityLabel="Zip"
                autoCapitalize="none"
                autoComplete="postal-code"
                keyboardType="number-pad"
                maxLength={5}
                onChangeText={(value) => {
                  setZipCode(value);
                }}
                placeholder="Zip code"
                required
                textContentType="postalCode"
              />
            </Column>
          </Row>
        </>
      ) : (
        <>
          <StripeInputText text="Card number">
            <CardNumberElement
              onChange={(e) => {
                validateElement(e, 'cardNumber');
              }}
              options={{
                showIcon: true,
                style: stripeBaseStyle,
              }}
            />
          </StripeInputText>
          <MdSpacing />
          <Row>
            <Column flexGrow={1}>
              <StripeInputText text="Exp date">
                <CardExpiryElement
                  onChange={(e) => {
                    validateElement(e, 'expDate');
                  }}
                  options={{
                    style: stripeBaseStyle,
                  }}
                />
              </StripeInputText>
            </Column>
            <MdSpacing />
            <Column flexGrow={1}>
              <StripeInputText text="Security code">
                <CardCvcElement
                  onChange={(e) => {
                    validateElement(e, 'cvc');
                  }}
                  options={{
                    style: stripeBaseStyle,
                  }}
                />
              </StripeInputText>
            </Column>
          </Row>
          <MdSpacing />
          <StripeInputLabel text="Billing zip code" />
          <XxsSpacing />
          <InputText
            accessibilityLabel="Zip"
            autoCapitalize="none"
            autoComplete="postal-code"
            keyboardType="number-pad"
            maxLength={5}
            onChangeText={(value) => {
              setZipCode(value);
            }}
            placeholder="Zip code"
            required
            textContentType="postalCode"
          />
        </>
      )}
      {paymentMethodError && typeof paymentMethodError === 'string' ? (
        <>
          <LgSpacing />
          <InlineAlert type="error">
            <Description>{paymentMethodError}</Description>
          </InlineAlert>
        </>
      ) : null}
      <XxlSpacing />
      <Button
        key="add-card-submit"
        disabled={loading || Object.values(stripeFieldComplete).includes(false) || !validZipCode}
        label="Add card"
        loading={loading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={handleSubmit}
      />
      <MdSpacing />
      <Button
        key="add-card-cancel-button"
        disabled={loading}
        label="Cancel"
        onPress={onClose}
        type="tertiary"
      />
    </>
  );
};

export const AddPaymentCard = ({ onClose, paymentMethodType }: Props) => (
  <Elements stripe={stripePromise}>
    <AddPaymentCardForm
      onClose={onClose}
      paymentMethodType={paymentMethodType}
    />
  </Elements>
);
