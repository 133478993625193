// @owners { team: patients-team }
import { KeyDateTypeMap } from '@alto/operations_api/v1/types';
import { DateRestrictionReasonMap } from '@alto/scriptdash/alto/scheduling/types/v1/date_restriction_reason';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { formatRelativeDate } from '~shared/helpers/date';
import { useSelectorShared } from '~shared/store';

type Props = {
  prescriptionID?: number;
  excludeDateFromMessage?: boolean;
};

export const useCartNextAvailableDateMessaging = ({ prescriptionID, excludeDateFromMessage = false }: Props = {}) => {
  const { earliestAvailableDate, earliestAvailableDateReason } = useCartNextAvailableDate();
  const { prescriptions } = usePrescriptionsInCart();
  const earliestDatePrescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  let message = '';

  if (!earliestAvailableDate) {
    if (earliestAvailableDateReason === DateRestrictionReasonMap.OUT_OF_ZONE) {
      message =
        'The selected address is outside of our service area and not eligible for delivery. Please select a different address.';
    }

    return { message };
  }

  // The server currently does not return which prescription in the order is responsible for the earliest available
  // date & reason for the order. Only specify the prescription name if earliestDatePrescription was passed into the
  // selector or if there is only one prescription in the order. One way this can be improved is if the server
  // response orders the prescription ids such that the first one in the array corresponds to the order's
  // earliest available date & reason.
  const singlePrescriptionInOrder = prescriptions.length === 1 ? prescriptions[0] : undefined;
  const prescription = earliestDatePrescription || singlePrescriptionInOrder;
  const medicationName = prescription?.medication_name ? prescription.medication_name : 'a medication in your order';
  const formattedEarliestAvailableDate = formatRelativeDate(earliestAvailableDate, {
    includeRelative: true,
  });

  switch (earliestAvailableDateReason) {
    case DateRestrictionReasonMap.COMPOUND:
      message = `The earliest we can deliver your order is ${formattedEarliestAvailableDate} because ${medicationName} is a compound, which are mixed specifically for you according to your prescription and take an extra few days.`;
      break;

    case DateRestrictionReasonMap.DELIVERED_RECENTLY:
    case DateRestrictionReasonMap.REFILL_TOO_SOON:
      message = `You had ${medicationName} filled ${
        prescription?.last_delivered_at
          ? // eslint-disable-next-line @typescript-eslint/no-useless-template-literals
            `${formatRelativeDate(prescription.last_delivered_at, { includeRelative: true })}`
          : 'recently'
      }. Due to insurance restrictions on how often a prescription can be filled, the earliest we can deliver your order is ${formattedEarliestAvailableDate}.`;
      break;

    case DateRestrictionReasonMap.DELIVERY_UPCOMING:
      message = `You already have ${medicationName} scheduled. Due to insurance restrictions on how often a prescription can be filled, the earliest we can deliver your next order is ${formattedEarliestAvailableDate}.`;
      break;

    case DateRestrictionReasonMap.OUT_OF_STOCK:
      if (excludeDateFromMessage) {
        message = `This is the earliest we can deliver your order because we are temporarily out of ${medicationName}.`;
      } else {
        message = `The earliest we can deliver your order is ${formattedEarliestAvailableDate} because we are temporarily out of ${medicationName}.`;
      }
      break;

    case DateRestrictionReasonMap.MAIL_DELIVERY_METHOD:
      message = `The earliest we can deliver your order is ${formattedEarliestAvailableDate} because the delivery will be sent by mail.`;
      break;

    case DateRestrictionReasonMap.HOLIDAY:
      message = `The earliest we can deliver your order is ${formattedEarliestAvailableDate} because the pharmacy is closed for a holiday.`;
      break;

    case DateRestrictionReasonMap.EMERGENCY_CLOSURE:
    case KeyDateTypeMap.EMERGENCY_CLOSURE_PATIENT_ONLY:
      message = `The earliest we can deliver your order is ${formattedEarliestAvailableDate} because the pharmacy is temporarily closed.`;
      break;

    default:
  }

  return { message };
};
