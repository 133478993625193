// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/v1/delivery_methods/delivery_method';
import { SPACING } from '@alto/design-library-tokens';
import { Body, Column, H3, LgPadding } from '@alto/design-system';
import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { getPrescriptionsByIDs } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { useSelectorShared } from '~shared/store';

type Props = {
  readonly orderIndex: number;
};

export const CheckoutSplitShipmentHeader = ({ orderIndex }: Props) => {
  const { cartItemsByDeliveryMethod, deliveryMethods } = useCartDeliveryMethods();
  const deliveryMethod: DeliveryMethod | undefined = deliveryMethods[orderIndex];
  const deliveryMethodName = deliveryMethod?.name;
  const prescriptionItemsForShipment = deliveryMethodName ? cartItemsByDeliveryMethod[deliveryMethodName] ?? [] : [];
  const prescriptionIds = prescriptionItemsForShipment.map((item) => item.resource_id);
  const prescriptions = useSelectorShared((state) => getPrescriptionsByIDs(state, prescriptionIds));
  const shipmentHasCompounds = useMemo(() => {
    return prescriptions.find((prescription) => prescription.is_compound);
  }, [prescriptions]);

  const deliveredFrom = shipmentHasCompounds && orderIndex === 0 ? 'compound pharmacy' : 'Alto Pharmacy';
  const deliveryMethodLabel = `${deliveryMethod?.patient_facing_name} from ${deliveredFrom}`;

  if (Platform.OS === 'web') {
    return (
      <Column wrap={false}>
        <H3>
          Delivery {orderIndex + 1} of {deliveryMethods.length}
        </H3>
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          leftPadding={SPACING.STATIC.NONE}
        >
          <Body>{deliveryMethodLabel}</Body>
        </LgPadding>
      </Column>
    );
  }

  return (
    <Column center>
      <H3>
        Delivery {orderIndex + 1} of {deliveryMethods.length}
      </H3>
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body center>{deliveryMethodLabel}</Body>
      </LgPadding>
    </Column>
  );
};
