import { COLORS, SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line import/no-deprecated
import { getScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, Link, Text, spacing } from '@alto/pocky';
import { getYear } from 'date-fns';
import React from 'react';
import styled, { css } from 'styled-components';
import { PRIVACY_POLICY_URL, PRIVACY_PRACTICES_URL, TERMS_OF_SERVICE_URL } from '~shared/constants';
import logoPharmacy from '~web/images/altoLogo@2x.png';

const FooterPhoneCss = css`
  padding: ${SPACING.STATIC.LG.rem} ${SPACING.STATIC.LG.rem} ${SPACING.STATIC.MD.rem};
`;

const ColPhoneCss = css`
  flex-direction: column;
`;

const Logo = styled.div`
  height: 29px;
  width: 85px;
  margin-bottom: ${SPACING.STATIC.LG.rem};
  background-image: url(${logoPharmacy});
  background-size: 85px 29px;
`;

const StyledFooter = styled.footer<{ isSMScreenOrBigger: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.xlSpacing} ${spacing.xlSpacing} ${SPACING.STATIC.LG.rem};
  background-color: ${COLORS.PALETTE.GREYSCALE.DEFAULT};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && FooterPhoneCss}
`;

const ScalingCol = styled(FlexColumn)<{ isMDScreenOrBigger: boolean }>`
  ${({ isMDScreenOrBigger }) => !isMDScreenOrBigger && ColPhoneCss}
`;

const FooterLink = styled(Link)`
  color: ${COLORS.TEXT_COLORS.DISABLED};
`;

// Spacers to help with wrapping
const Spacer = styled.div`
  height: ${SPACING.STATIC.LG.rem};
  width: ${SPACING.STATIC.LG.rem};
`;

export default class Footer extends React.Component {
  render() {
    // eslint-disable-next-line import/no-deprecated
    const { isSMScreenOrBigger, isMDScreenOrBigger } = getScreenSize();
    return (
      <StyledFooter isSMScreenOrBigger={isSMScreenOrBigger}>
        <Logo />
        <ScalingCol
          wrap
          evenlySpaceContent
          isMDScreenOrBigger={isMDScreenOrBigger}
        >
          <ScalingCol
            wrap
            isMDScreenOrBigger={isMDScreenOrBigger}
          >
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href={PRIVACY_POLICY_URL}
            >
              Privacy Policy
            </FooterLink>
            <Spacer />
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href={PRIVACY_PRACTICES_URL}
            >
              Notice of Privacy Practices
            </FooterLink>
            <Spacer />
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href={TERMS_OF_SERVICE_URL}
            >
              Terms & Conditions
            </FooterLink>
            <Spacer />
          </ScalingCol>
          <FlexColumn>
            <Text light>{`\u00A9 ${getYear(new Date())} Alto Pharmacy. All rights reserved.`}</Text>
          </FlexColumn>
        </ScalingCol>
      </StyledFooter>
    );
  }
}
