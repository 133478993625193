// @owners { team: patients-team }
import { Toast, ToastContext } from '@alto/design-system';
import { type WundercomMessage } from '@alto/scriptdash/alto/customer_support/types/v1/wundercom_message';
import { useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { type AssistantCategory, type AssistantChatOrigin } from '~shared/features/alto-assistant/constants';
import {
  MESSAGE_ERROR_TOASTS,
  type MESSAGE_FORM_TYPES,
  MESSAGE_SUCCESS_TOASTS,
  type MessageTag,
} from '~shared/features/messages/constants';
import { post } from '~shared/helpers/apiHelper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { Sentry } from '~shared/sentry';

export type SendMessageParams = {
  body: string;
  tag?: MessageTag | null;
  supportCaseID?: number;
  patientID?: number;
  topic?: string;
  familyMemberID?: number | null | undefined;
  formType?: typeof MESSAGE_FORM_TYPES;
  isQuickReply?: boolean;
  category?: AssistantCategory;
  origin?: AssistantChatOrigin;
  includeSuccessToast?: boolean;
};

export const useSendMessage = () => {
  const { addToast } = useContext(ToastContext);
  const { trackEvent } = useAnalytics();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async ({
      body,
      tag,
      supportCaseID,
      topic,
      familyMemberID = undefined,
    }: SendMessageParams): Promise<WundercomMessage> => {
      return await post('/wundercom_messages/', {
        wundercom_message: {
          body,
          tag,
        },
        support_case_id: supportCaseID,
        topic,
        family_member_id: familyMemberID,
      });
    },
    onError: (error: Error) => {
      Sentry.captureException(error);
      addToast(<Toast variant="error">{MESSAGE_ERROR_TOASTS.NEW_SUPPORT_CASE}</Toast>);
    },
    onSuccess: (
      wundercomData,
      {
        body,
        tag,
        familyMemberID,
        supportCaseID,
        formType,
        isQuickReply = false,
        origin,
        category,
        topic,
        includeSuccessToast = true,
      },
    ) => {
      if (supportCaseID === undefined) {
        trackEvent({
          event: EVENTS.ASSISTANT_SUPPORT_CASE_CREATED,
          params: {
            patientID: wundercomData.user_id,
            supportCaseID: wundercomData.support_case_id,
            characterCount: body.length,
            topic,
            category,
            origin,
          },
        });
      }
      trackEvent({
        event: EVENTS.ASSISTANT_MESSAGE_SENT,
        params: {
          patientID: wundercomData.user_id,
          supportCaseID: wundercomData.support_case_id,
          characterCount: body.length,
          formType,
          isQuickReply,
          wundercomMessageID: wundercomData.id,
          familyMemberID,
          category,
          origin,
        },
      });
      let toastMessage: string = MESSAGE_SUCCESS_TOASTS.NEW_SUPPORT_CASE;
      if (tag === 'medication_question') toastMessage = MESSAGE_SUCCESS_TOASTS.FOR_PHARMACIST;
      if (supportCaseID) {
        toastMessage = MESSAGE_SUCCESS_TOASTS.SUPPORT_CASE_REPLY;
      }

      if (includeSuccessToast) {
        addToast(<Toast>{toastMessage}</Toast>);
      }
    },
  });
  return { sendMessageMutate: mutateAsync, isPending };
};
