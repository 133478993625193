// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  Card,
  Column,
  Description,
  InlineAlert,
  LgPadding,
  ListItem,
  MdSpacing,
  Row,
  Tag,
  TagText,
  XsSpacing,
  XxsSpacing,
} from '@alto/design-system';
import { type MedDetailsPrescription } from '@alto/scriptdash/alto/medications/patients/v1/types';
import React, { useEffect } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { CTAs } from './CTAs';
import { PrescriptionDescriptions } from './PrescriptionDescriptions';
import { PricingFlatCard } from './PricingFlatCard';

export const PrescriptionCard = ({
  isPrimary,
  prescription,
  useAddToCartInterstitialSheets,
  medicationName,
  primaryPrescriptionId,
}: {
  readonly isPrimary: boolean;
  readonly prescription: MedDetailsPrescription;
  readonly useAddToCartInterstitialSheets: any;
  readonly medicationName?: string;
  readonly primaryPrescriptionId: number;
}) => {
  const { trackEvent } = useAnalytics();
  const { isPrescriptionInCart } = usePrescriptionsInCart();
  const { status, prescribed_date, ctas, id, next_billable_date, quantity } = prescription;
  const { title: statusTitle, description: statusDescription, color_variant: statusColorVariant } = status || {};
  const isInCart = statusTitle === 'Active' && isPrescriptionInCart(id);
  const showPricing = statusTitle === 'Active';
  const hasCTAs = ctas.primary || ctas.secondary || ctas.tertiary;

  useEffect(() => {
    trackEvent({
      event: EVENTS.MED_DETAILS__PRESCRIPTION_CARD_VIEWED,
      params: {
        isPrimary,
        prescriptionStatus: statusTitle,
        prescriptionId: prescription.id,
        addToCartEligible: statusTitle && !statusDescription,
        primaryCTA: ctas.primary,
        secondaryCTA: ctas.secondary,
        tertiaryCTA: ctas.tertiary,
      },
    });
  }, [
    ctas.primary,
    ctas.secondary,
    ctas.tertiary,
    isPrimary,
    prescription.id,
    statusDescription,
    statusTitle,
    trackEvent,
  ]);

  return (
    <Card>
      <LgPadding>
        {statusTitle && statusDescription ? (
          <>
            <InlineAlert
              type={statusColorVariant}
              borderless
            >
              <Row>
                <TagText>{statusTitle}</TagText>
              </Row>
              <XxsSpacing />
              <Description color={COLORS.TEXT_COLORS.BLACK}>{statusDescription}</Description>
            </InlineAlert>
            <XsSpacing />
          </>
        ) : null}
        <ListItem
          title={prescribed_date}
          tags={
            statusTitle && !statusDescription
              ? [
                  <Tag
                    key={`${statusTitle}-${statusColorVariant}`}
                    label={isInCart ? 'Added to cart' : statusTitle || ''}
                    type={statusColorVariant}
                  />,
                ]
              : undefined
          }
          tagPlacement="top"
          fullBleed
          descriptions={
            <PrescriptionDescriptions
              medicationName={medicationName ?? 'your medication'}
              prescription={prescription}
            />
          }
        />
        {showPricing ? <XsSpacing /> : null}
        <Column gap="MD">
          {showPricing ? (
            <PricingFlatCard
              id={id}
              nextBillableDate={next_billable_date}
              quantity={quantity}
              medicationName={medicationName || 'This medication'}
              data-testid="pricing-flat-card"
            />
          ) : null}
          <Column>
            {!showPricing && hasCTAs ? <MdSpacing /> : null}
            <CTAs
              useAddToCartInterstitialSheets={useAddToCartInterstitialSheets}
              prescriptionId={prescription.id}
              primaryPrescriptionId={primaryPrescriptionId}
              ctas={ctas}
              medicationName={medicationName || 'This medication'}
            />
          </Column>
        </Column>
      </LgPadding>
    </Card>
  );
};
