// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Caption, Description, MdSpacing, XsSpacing } from '@alto/design-system';
import React from 'react';
import { View } from 'react-native';
import { PAYMENT_METHOD_TYPES, type PaymentMethodType } from '~shared/constants';
import { useCheckoutTipErrors } from '~shared/features/checkout/hooks/useCheckoutTipErrors';
import { getShowingValidationErrors } from '~shared/features/checkout/selectors/getCart';
import { useSelectorShared } from '~shared/store';
import { useDeliveryFeeCopy } from '../../checkout/components/payment-method-card/useDeliveryFeeCopy';
import { PaymentMethodSelector } from '../../payment/components/PaymentMethodSelector';

type Props = {
  readonly handleNativePay?: (paymentMethodType: PaymentMethodType) => void;
  readonly disabled?: boolean;
};

export const TipPaymentMethodPicker = ({ handleNativePay, disabled }: Props) => {
  const showingValidationErrors = useSelectorShared(getShowingValidationErrors);
  const { tipValidationErrors } = useCheckoutTipErrors();
  const errors = showingValidationErrors ? tipValidationErrors : [];
  const { description } = useDeliveryFeeCopy();

  return (
    <View>
      <PaymentMethodSelector
        disabled={disabled}
        handleNativePay={handleNativePay}
        paymentMethodType={PAYMENT_METHOD_TYPES.PRE_TIP}
      />
      {errors?.length ? (
        <>
          <XsSpacing />
          <Description color={COLORS.TEXT_COLORS.DANGER}>{errors[0].message}</Description>
        </>
      ) : null}
      <MdSpacing />
      <Caption>{description}</Caption>
    </View>
  );
};
