// @owners { team: patients-team }
import { Illustration } from '@alto/design-system';
import React from 'react';

export const AltoMedBagImage = () => {
  return (
    <Illustration
      size="xl"
      source={require('./alto_bag_with_medications@2x.png')}
      testID={'alto-med-bag-image'}
    />
  );
};
