import { SHIPMENT_STATUS_MAP } from '~shared/features/shipments/constants';
import { isCourier, isMail, isPickup, isUSPS } from '~shared/helpers/order';
import { type ShipmentMethod, type ShipmentStatus } from '~shared/types';

export const getWhatToExpectCopy = (method: ShipmentMethod | undefined, shipmentStatus?: ShipmentStatus) => {
  let copy = '';

  if (isPickup(method)) {
    copy =
      shipmentStatus !== 'shipped'
        ? SHIPMENT_STATUS_MAP[shipmentStatus || 'processing']?.pickupInfoTextFuture || ''
        : SHIPMENT_STATUS_MAP[shipmentStatus]?.pickupInfoText || '';
  }

  if (isMail(method)) {
    if (isUSPS(method)) {
      copy =
        shipmentStatus !== 'shipped' ? 'We’ll send a tracking link via email when your order has been shipped.' : '';
    } else {
      copy = 'We’ll send a tracking link via email the day before your delivery.';
    }
  }

  if (isCourier(method)) {
    copy =
      'You’ll get updates via text when your courier is on the way, including tracking information and an updated arrival time.';
  }

  return copy;
};
