// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  Body,
  Button,
  LgPadding,
  Row,
  XlSpacing,
  XsSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { capitalize } from 'lodash';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  type ActionSheetProviderStackCacheKey,
  type AssistantDeliveryAction,
  type AssistantMessagePrompt,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { formatShortAddress } from '~shared/helpers/address';
import { formatDateWithoutDowWithoutYear } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type Address } from '~shared/types';
import { SafePlaceConfirmationActionSheet } from './SafePlaceConfirmationActionSheet';

type Props = {
  readonly address: Address;
  readonly shipment: ShipmentWithItemsAndAdditionalFields;
};
export const AddressConfirmationActionSheet = ({ address, shipment }: Props) => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { saveStack, setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const { date, shipment_method, image_url } = shipment;
  const { safe_place_instructions } = address;

  const navigateToMessages = (messagePrompt: AssistantMessagePrompt, action: AssistantDeliveryAction) => {
    const title = capitalize(action.split('_').join(' '));
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    closeActionSheet();
    navigate('RouteAssistantMessage', {
      category: `${ASSISTANT_CATEGORIES.DELIVERIES}.${action}`,
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_DELIVERY_ACTION_MENU,
      messagePrompt,
      subject: `Delivery from ${formatDateWithoutDowWithoutYear(date)} - ${title}`,
    });
  };

  const handleConfirmAddress = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.ADDRESS_CONFIRMATION_CORRECT,
        type: 'button',
      },
    });

    const isCourierDelivery = shipment_method === 'courier' || shipment_method === 'onfleet';
    const hasSafePlaceDetails = image_url || safe_place_instructions;

    if (!isCourierDelivery || !hasSafePlaceDetails) {
      navigateToMessages(MESSAGE_PROMPTS.DELIVERY_NEVER_ARRIVED, 'never_arrived');
    } else {
      setActiveActionSheet(
        <SafePlaceConfirmationActionSheet
          date={shipment.date}
          safePlaceInstructions={shipment.address?.safe_place_instructions}
          imageUrl={shipment.image_url}
        />,
      );
    }
  };

  const handleRejectAddress = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.ADDRESS_CONFIRMATION_INCORRECT,
        type: 'button',
      },
    });
    navigateToMessages(MESSAGE_PROMPTS.DELIVERY_WRONG_ADDRESS, 'wrong_address');
  };

  return (
    <ActionSheetV2
      title="Is the following address correct for this delivery?"
      description="This is the address you selected at checkout"
      analyticsName="assistant address confirmation"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Row>
          <AltoIcon name="mapmarker-duo" />
          <XsSpacing />
          <Body
            color={COLORS.TEXT_COLORS.PRIMARY}
            fontFamily="semibold"
          >
            {formatShortAddress(address)}
          </Body>
        </Row>
        <XlSpacing />
        <Button
          width="full"
          label="Yes, it is correct"
          onPress={handleConfirmAddress}
        />
        <Button
          width="full"
          type="tertiary"
          label="No, it is not correct"
          onPress={handleRejectAddress}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
