import { COLORS, SPACING } from '@alto/design-library-tokens';
import { XxsSpacing, useScreenSize } from '@alto/design-system';
import {
  CompletedTransferSection,
  PendingTransferSection,
  useEssentialsStoreCard,
  useFetchHomescreen,
} from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Loader, FlexRow as PockyRow } from '@alto/pocky';
import { type DeliveredShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/delivered_shipment';
import { type Patient } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/patient';
import { type PatientTask } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/patient_task';
import { type Prescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import { type RenewalPrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/renewal_prescription';
import { type Section } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/section';
import { SectionTypeMap } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/section_type';
import { type SourcePrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/source_prescription';
import { type UpcomingShipment as UpcomingShipmentType } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/upcoming_shipment';
import { PatientTaskTypeMap } from '@alto/scriptdash/alto/patient_app/patient_tasks/types/v1/patient_task_type';
import React, { useEffect, useMemo } from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { fillHomescreenBanner } from '~shared/actions/homescreenFillBanner';
import { changeModalStep, openModal } from '~shared/actions/modal';
import { type BadgeCounts } from '~shared/actions/notices';
import { generateSectionCounts } from '~shared/features/homescreen/analytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { EssentialsStoreCard } from './EssentialsStoreCard';
import { ContentWrapper, Main, PageWithHeader, Sidebar } from './PageWithHeader';
import { type Props as PrescriptionTrackerProps } from './prescription-tracker/PrescriptionTrackerModal';
import { DeliveredShipmentSection } from '~web/features/homescreen/sections/DeliveredShipment';
import DueForRefillPrescriptions from '~web/features/homescreen/sections/DueForRefillPrescriptions';
import { FeaturedEssentialsCard } from '~web/features/homescreen/sections/FeaturedEssentialsCard';
import { MedSyncUpsellSection } from '~web/features/homescreen/sections/MedSyncUpsellSection';
import { NeedsInsuranceCard } from '~web/features/homescreen/sections/NeedsInsuranceCard';
import NewPrescriptions from '~web/features/homescreen/sections/NewPrescriptions';
import { NewYearInsuranceCard } from '~web/features/homescreen/sections/NewYearInsuranceCard';
import NoNewUpdates from '~web/features/homescreen/sections/NoNewUpdates';
import { OutOfRefillsCard } from '~web/features/homescreen/sections/OutOfRefillsCard';
import { QuickLinks } from '~web/features/homescreen/sections/QuickLinks';
import SendMedications from '~web/features/homescreen/sections/SendMedications';
import UnconfirmedShipment from '~web/features/homescreen/sections/UnconfirmedShipment';
import UpcomingShipment from '~web/features/homescreen/sections/UpcomingShipment';
import UpdatePaymentMethod from '~web/features/homescreen/sections/UpdatePaymentMethod';
import altoBox from '~web/images/altoBoxRotated@1x.png';

type Props = {
  readonly currentUserID: number | null;
  readonly navigateToMedList: () => void;
  readonly onViewDelivery: (id: number) => void;
  readonly navigateToSendPrescriptionOptions: () => void;
  readonly openPharmacyInfoModal: () => void;
  readonly openPrescriptionTrackerModal: (data: PrescriptionTrackerProps) => void;
  readonly sendAnalyticsEvent: (event: Event) => void;
  readonly setBadgeCounts: (badgeCounts: BadgeCounts) => void;
  readonly editShipment: (shipmentID: number) => void;
};

const FlexRow = styled(PockyRow)<{ isSMScreenOrBigger: boolean }>`
  padding: 0 ${SPACING.STATIC.MD.px};

  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `padding: 0 ${SPACING.STATIC.LG.px};`}

  > *:not(:last-child) {
    margin-bottom: ${SPACING.STATIC.LG.px};
  }
`;

const LoaderContainer = styled.div`
  z-index: 1;
  margin-top: -7rem;
`;

const StyledAltoBox = styled.div<{ isMDScreenOrBigger: boolean }>`
  display: flex;
  max-height: 465px;
  max-width: 357px;
  margin: auto;

  img {
    width: 100%;
    margin: 0;
    vertical-align: top;
  }
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `display: none;`}
`;

export const Homescreen = ({
  onViewDelivery,
  navigateToSendPrescriptionOptions,
  openPharmacyInfoModal,
  openPrescriptionTrackerModal,
  navigateToMedList,
  currentUserID,
  sendAnalyticsEvent,
  setBadgeCounts,
  editShipment,
}: Props) => {
  const dispatch = useDispatchShared();
  const { isLoading, data: response, refetch } = useFetchHomescreen(Number(currentUserID));
  const { isSMScreenOrBigger, isMDScreenOrBigger } = useScreenSize();
  const { showEssentialCard } = useEssentialsStoreCard();
  const onboarding = useSelectorShared((state) => state.auth.onboarding);

  useEffect(() => {
    if (!isLoading) {
      sendAnalyticsEvent(createEvent(EVENTS.HOMESCREEN_VIEWED, generateSectionCounts(response?.data.sections)));
    }
  }, [sendAnalyticsEvent, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const patient_tasks = response?.data.sections.find((section) => section.type === SectionTypeMap.PATIENT_TASK);
    setBadgeCounts({
      patientTasks: patient_tasks ? patient_tasks.patient_tasks?.length : 0,
    });
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  // open onboarding modal
  useEffect(() => {
    if (!onboarding) return;
    dispatch(openModal('ONBOARDING_MODAL'));
    dispatch(changeModalStep(0, 'ONBOARDING_MODAL'));
  }, [onboarding, dispatch]);

  const hasPatientTasks = useMemo(
    () => (response?.data?.sections || []).some((section) => section.type === SectionTypeMap.PATIENT_TASK),
    [response?.data.sections],
  );

  const hasFeaturedTasks = useMemo(
    () => (response?.data?.sections || []).some((section) => section.type === SectionTypeMap.FEATURED_OTCS),
    [response?.data.sections],
  );

  if (isLoading || !response) {
    return (
      <PageWithHeader
        title=""
        bgColor={COLORS.BACKGROUND_COLORS.WHITE}
      >
        <LoaderContainer aria-label="loading">
          <Loader text="" />
        </LoaderContainer>
      </PageWithHeader>
    );
  }

  const {
    patient,
    sections,
  }: {
    patient: Patient;
    sections: Section[];
  } = response.data;

  const renderPatientTasks = (tasks: PatientTask[]) => {
    return tasks.map((task) => {
      switch (task.type) {
        case PatientTaskTypeMap.CONFIRM_SHIPMENT: {
          return (
            task.unconfirmed_shipment && (
              <UnconfirmedShipment
                key={task.unconfirmed_shipment.id}
                shipment={task.unconfirmed_shipment}
                sendAnalyticsEvent={sendAnalyticsEvent}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                refetchHomescreen={refetch}
                onViewDelivery={onViewDelivery}
              />
            )
          );
        }

        case PatientTaskTypeMap.NEEDS_INSURANCE: {
          // @ts-expect-error: Properties do not exist on type 'NeedsInsurancePatientTask | null | undefined'.
          const { user_id, family_member_name } = task.needs_insurance;
          return (
            <NeedsInsuranceCard
              key={`${user_id}-${family_member_name}`}
              userID={user_id}
              name={family_member_name}
            />
          );
        }

        case PatientTaskTypeMap.NEW_YEAR_INSURANCE: {
          // @ts-expect-error: Properties do not exist on type 'NewYearInsurancePatientTask | null | undefined'.
          const { user_id } = task.new_year_insurance;
          return (
            <NewYearInsuranceCard
              userID={user_id}
              taskID={Number(task.id)}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onInsuranceDismissal={refetch}
            />
          );
        }

        case PatientTaskTypeMap.UPDATE_PAYMENT_METHOD: {
          const updatePayment = task.update_payment_method;
          const paymentMethods = updatePayment?.shipment ? updatePayment.payment_methods : [];
          return (
            updatePayment?.shipment &&
            paymentMethods.length > 0 && (
              <UpdatePaymentMethod
                key={updatePayment.shipment.id}
                shipment={updatePayment.shipment}
                paymentMethods={paymentMethods}
                onUpdateNow={editShipment}
                onViewDelivery={onViewDelivery}
              />
            )
          );
        }

        default:
          return '';
      }
    });
  };

  const renderSectionContent = (section: Section, isTopSection: boolean) => {
    switch (section.type) {
      case SectionTypeMap.PATIENT_TASK: {
        return section.patient_tasks && renderPatientTasks(section.patient_tasks);
      }

      case SectionTypeMap.DELIVERED_SHIPMENTS: {
        // When the section type is DELIVERED_SHIPMENTS, we know prescriptions will be of type Array<DeliveredShipment>
        // Manually refine the type to be of type Array<DeliveredShipment> instead of UnionTypeShipment[]
        const { shipments } = section;
        return (
          shipments && (
            <DeliveredShipmentSection
              key="delivered_shipment"
              delivery={shipments[0] as DeliveredShipment}
              onViewDelivery={onViewDelivery}
            />
          )
        );
      }

      case SectionTypeMap.UPCOMING_SHIPMENTS: {
        // When the section type is UPCOMING_SHIPMENTS, we know prescriptions will be of type Array<UpcomingShipment>
        // Manually refine the type to be of type Array<UpcomingShipment> instead of UnionTypeShipment[]
        const { shipments } = section;
        return (
          shipments && (
            <UpcomingShipment
              key="upcoming_shipment"
              upcomingShipment={shipments[0] as UpcomingShipmentType}
            />
          )
        );
      }

      case SectionTypeMap.PENDING_TRANSFERS: {
        if (!section.transfers || section.transfers.length === 0) {
          return null;
        }

        return (
          <PendingTransferSection
            key="pending_transfers"
            transfers={section.transfers}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            refetchHomescreen={refetch}
          />
        );
      }

      case SectionTypeMap.CLOSED_TRANSFERS:
        if (!section.transfers || section.transfers.length === 0) {
          return null;
        }

        return (
          <CompletedTransferSection
            key="completed_transfers"
            transfers={section.transfers}
            onPress={() => dispatch(push('/messages'))}
          />
        );

      case SectionTypeMap.NEW_PRESCRIPTIONS: {
        // When the section type is NEW_PRESCRIPTIONS, we know prescriptions will be of type Array<SourcePrescription | Prescription>
        // Manually refine the type to be of type Array<SourcePrescription | Prescription> instead of UnionTypePrescription[]
        const { prescriptions } = section;
        return (
          section.prescriptions && (
            <NewPrescriptions
              key="new_prescriptions"
              prescriptions={prescriptions as (SourcePrescription | Prescription)[]}
              openPrescriptionTrackerModal={openPrescriptionTrackerModal}
            />
          )
        );
      }

      case SectionTypeMap.DUE_FOR_REFILL: {
        // When the section type is DUE_FOR_REFILL, we know prescriptions will be of type Array<RenewalPrescription | Prescription>
        // Manually refine the type to be of type Array<RenewalPrescription | Prescription> instead of UnionTypePrescription[]
        const { prescriptions } = section;
        return (
          <DueForRefillPrescriptions
            key="due_for_refill"
            prescriptions={prescriptions as Prescription[]}
            isTopSection={isTopSection}
          />
        );
      }

      case SectionTypeMap.OUT_OF_REFILLS: {
        // When the section type is OUT_OF_REFILLS, we know prescriptions will be of type Array<RenewalPrescription | Prescription>
        // Manually refine the type to be of type Array<RenewalPrescription | Prescription> instead of UnionTypePrescription[]
        const { prescriptions } = section;
        return (
          <OutOfRefillsCard
            key="out_of_refills"
            renewalPrescriptions={prescriptions as RenewalPrescription[]}
          />
        );
      }

      case SectionTypeMap.MED_SYNC_UPSELL: {
        return (
          <MedSyncUpsellSection
            key="med_sync_upsell"
            status={section.med_sync_status}
          />
        );
      }

      case SectionTypeMap.FEATURED_OTCS: {
        if (showEssentialCard) return null;
        return <FeaturedEssentialsCard />;
      }

      default:
        return '';
    }
  };
  const renderSections = (sections: Section[]) => {
    return sections.map((section, i) => {
      const isTopSection = i === 0 && !showEssentialCard;
      const renderTopSpacing = () => (!isTopSection ? <XxsSpacing /> : null);
      return (
        <>
          {renderTopSpacing()}
          {renderSectionContent(section, isTopSection)}
        </>
      );
    });
  };

  const homescreenBanner = fillHomescreenBanner(sections, patient.name);

  return (
    <PageWithHeader
      title={homescreenBanner.copy}
      bgColor={COLORS.BACKGROUND_COLORS.WHITE}
      headerBgColor={COLORS.BACKGROUND_COLORS[homescreenBanner.backgroundColor]}
    >
      <ContentWrapper>
        <Main>
          <FlexRow isSMScreenOrBigger={isSMScreenOrBigger}>
            {!hasPatientTasks && (
              <EssentialsStoreCard
                hasPatientTasks={hasPatientTasks}
                hasFeaturedTasks={hasFeaturedTasks}
              />
            )}
            {sections.length > 0 ? renderSections(sections) : <NoNewUpdates />}
            <SendMedications
              navigateToSendPrescriptionOptions={navigateToSendPrescriptionOptions}
              openPharmacyInfoModal={openPharmacyInfoModal}
            />
            {sections.length === 0 && (
              <StyledAltoBox isMDScreenOrBigger={isMDScreenOrBigger}>
                <img
                  alt="Box with meds"
                  src={altoBox}
                />
              </StyledAltoBox>
            )}
          </FlexRow>
        </Main>
        <Sidebar>
          <QuickLinks
            navigateToSendPrescriptionOptions={navigateToSendPrescriptionOptions}
            openPharmacyInfoModal={openPharmacyInfoModal}
            navigateToMedList={navigateToMedList}
          />
        </Sidebar>
      </ContentWrapper>
    </PageWithHeader>
  );
};
