// @owners { team: patients-team }

// eslint-disable-next-line @alto/no-pocky-import
import { Select, palette } from '@alto/pocky';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { findMatchingPaymentMethod } from '~shared/features/payment-methods/helpers';
import { type PaymentMethod } from '~shared/types';
import DeliveryPaymentMethodRow from '~web/features/deliveries/components/DeliveryPaymentMethodRow';
import StyledPaymentMethodRow from '~web/features/deliveries/components/StyledPaymentMethodRow';

export const ADD_PAYMENT_METHOD_ID = 'add_payment_method';

const createPaymentMethodOption = (paymentMethod: PaymentMethod) => ({
  label: (
    <DeliveryPaymentMethodRow
      key={paymentMethod.id}
      paymentMethod={paymentMethod}
    />
  ),
  value: paymentMethod.id,
});

const createAddPaymentMethodOption = () => {
  const icon = (
    <FontAwesomeIcon
      icon={faPlus}
      color={palette.altoColorsS1}
    />
  );

  return {
    label: (
      <StyledPaymentMethodRow
        icon={icon}
        paymentMethodName="Add Payment Method"
        textColor={palette.altoColorsS1}
        active
      />
    ),
    value: ADD_PAYMENT_METHOD_ID,
  };
};

type Props = {
  readonly openCreditCardModal: () => void;
  readonly paymentMethodID: number | null | undefined;
  readonly paymentMethods: PaymentMethod[];
  readonly selectPaymentMethodID: (arg0: number) => void;
};

export default function PaymentMethodDropdown({
  openCreditCardModal,
  paymentMethodID,
  paymentMethods,
  selectPaymentMethodID,
}: Props) {
  const handleSelectPaymentMethodID = useCallback(
    (selectedPaymentMethodID: number | typeof ADD_PAYMENT_METHOD_ID) => {
      if (selectedPaymentMethodID === ADD_PAYMENT_METHOD_ID) {
        openCreditCardModal();
      } else {
        selectPaymentMethodID(selectedPaymentMethodID);
      }
    },
    [openCreditCardModal, selectPaymentMethodID],
  );

  const paymentMethodOptions = useCallback(() => {
    const options: { value: string | number; label: React.ReactElement }[] = paymentMethods.map((paymentMethod) =>
      createPaymentMethodOption(paymentMethod),
    );
    options.push(createAddPaymentMethodOption());
    return options;
  }, [paymentMethods]);

  const isValidPaymentMethodID = !!findMatchingPaymentMethod(paymentMethods, paymentMethodID);

  if (!isValidPaymentMethodID) {
    handleSelectPaymentMethodID(paymentMethods[0].id);
  }

  return (
    <Select
      input={{
        onChange: handleSelectPaymentMethodID,
        value: paymentMethodID || undefined,
      }}
      menuPlacement="top"
      options={paymentMethodOptions()}
    />
  );
}
