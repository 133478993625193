// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { Row } from '@alto/pocky';
import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faStar as faStarDark } from '@fortawesome/pro-solid-svg-icons/faStar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { type ElementRef, type RefObject, useState } from 'react';
import styled from 'styled-components';
import TransparentButtonWrapper from './TransparentButtonWrapper';

const StarWrapper = styled(TransparentButtonWrapper)<{
  isModal: boolean | null | undefined;
  isSMScreenOrBigger: boolean;
}>`
  ${({ isModal }) => `
    &:not(:last-child) > * {
      margin-right: ${isModal ? SPACING.STATIC.XS.rem : SPACING.STATIC.MD.rem};
    }
  `}
  &:not(:last-child) > * {
    margin-right: ${SPACING.STATIC.MD.rem};
  }
  /* necessary to avoid awkward overflow in small screen size layout */
  ${({ isSMScreenOrBigger, isModal }) =>
    !isSMScreenOrBigger &&
    `
      &:not(:last-child) > * {
        margin-right: ${isModal ? SPACING.STATIC.XXS.rem : SPACING.STATIC.XS.rem};
      }
  `}
`;

type Props = {
  readonly rating: number;
  readonly setRating: (rating: number) => void;
  readonly isModal: boolean | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  readonly firstFocusableElementRef: ElementRef<any> | null | undefined;
};

function getStarColor(isSelected = false, isHovered = false) {
  if (isSelected || isHovered) return COLORS.TEXT_COLORS.SECONDARY;
  return COLORS.TEXT_COLORS.DISABLED;
}

const NUM_STARS = 5;

const Stars = ({ rating, setRating, isModal, firstFocusableElementRef }: Props) => {
  const [hoveredStarIndex, setHoveredStarIndex] = useState(-1);
  const { isSMScreenOrBigger } = useScreenSize();
  const stars = Array.from(
    {
      length: NUM_STARS,
    },
    (_, index) => {
      const isSelected = rating > index;
      const isHovered = hoveredStarIndex > index;
      const icon = isSelected ? faStarDark : faStar;
      const color = getStarColor(isSelected, isHovered);
      const firstStarRef = index === 0 ? firstFocusableElementRef : undefined;
      return (
        <StarWrapper
          onClick={() => {
            setRating(index + 1);
          }}
          onMouseEnter={() => {
            setHoveredStarIndex(index + 1);
          }}
          onMouseLeave={() => {
            setHoveredStarIndex(-1);
          }}
          ref={firstStarRef as RefObject<HTMLButtonElement>}
          isModal={isModal}
          key={index}
          isSMScreenOrBigger={isSMScreenOrBigger}
        >
          <FontAwesomeIcon
            tabIndex={-1}
            icon={icon}
            color={color}
            size="2x"
          />
        </StarWrapper>
      );
    },
  );
  return <Row>{stars}</Row>;
};

export default Stars;
