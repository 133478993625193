// @owners { team: patients-team }
import { Description, InlineAlert, Link } from '@alto/design-system';
import React from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { ASSISTANT_CHAT_ORIGINS } from '~shared/features/alto-assistant/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';

type MessagesReadOnlyAlertProps = {
  readonly openWizardActionSheet: () => void;
};

/**
 * Render read-only message history (aka Alto Updates) for support case patients
 * that includes a link to the start of the Alto Assistant Wizard if the patient has a
 * new issue to start a thread
 * @returns MessagesReadOnlyAlert UI
 */
export const MessagesReadOnlyAlert = ({ openWizardActionSheet }: MessagesReadOnlyAlertProps) => {
  const { trackEvent } = useAnalytics();

  const handleNewRequestPress = () => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: { name: ASSISTANT_PRESSED_EVENTS.SUPPORT_CASE_NEW_REQUEST, origin: ASSISTANT_CHAT_ORIGINS.ALTO_UPDATES },
    });
    openWizardActionSheet();
  };

  return (
    <InlineAlert>
      <Description>
        Read only messages. Have a question?{' '}
        <Link
          underline
          onPress={handleNewRequestPress}
          accessibilityLabel="Click to start a new request"
          testID="mini"
        >
          Start a new request
        </Link>
      </Description>
    </InlineAlert>
  );
};
