import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, LgSpacing } from '@alto/design-system';
import React from 'react';
import { useFetchAncillariesByPrescriptionId } from '../queries/queries';

type Props = {
  readonly prescriptionID: number;
  readonly medicationName: string;
};

export const AncillaryItemsActionSheet = ({ medicationName, prescriptionID }: Props) => {
  const { ancillaries } = useFetchAncillariesByPrescriptionId({ prescriptionId: prescriptionID });

  return (
    <ActionSheetV2
      title="Ancillary items included"
      analyticsName="checkout ancillary items"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>We’ll include the necessary supplies to help you administer {medicationName} safely and correctly.</Body>
        {ancillaries.length > 0 && (
          <>
            <LgSpacing />
            {ancillaries.map((ancillary) => (
              <Body key={ancillary.description}>• {ancillary.description}</Body>
            ))}
          </>
        )}
      </LgPadding>
    </ActionSheetV2>
  );
};
