// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, LgPadding, LgSpacing } from '@alto/design-system';
import React from 'react';

export const FeeDetailsActionSheet = () => {
  const illustrationSrc = require('~shared/assets/images/service-fee.png');

  return (
    <ActionSheetV2
      illustrationSrc={illustrationSrc}
      analyticsName="fee details"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body
          fontFamily="semibold"
          color={COLORS.TEXT_COLORS.PRIMARY}
        >
          About our delivery fees
        </Body>
        <LgSpacing />
        <Body>
          As part of our commitment to serving you with convenience and reliability, we charge delivery fees*. This
          allows us to provide urgent medication delivery when needed.
        </Body>
        <LgSpacing />
        <Body>
          *Some prescription savings and discount cards (like the Team Cuban card) are subject to additional delivery
          fees.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
