// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, LgPadding, MdSpacing, Tag } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import {
  CalendarRow as CalendarRowV1,
  CalendarRowV2,
  DeliveryCardCTA,
  DeliveryInstructionsRow,
  DeliveryMethodRow,
  LocationRow,
  MedRow,
  PriceRow,
} from '@alto/features';
import React from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { type APIOrderMethod } from '~shared/features/delivery-info/types';
import { getUpcomingOrderLabel } from '~shared/features/homescreen/utils';
import { getShipmentTotal } from '~shared/features/pricing/selectors/getOrderTotal';
import { getAsapShipmentFee } from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { getShipmentStatusString } from '~shared/features/shipments/helpers/getShipmentStatusConfig';
import { getWhatToExpectCopy } from '~shared/features/shipments/helpers/getWhatToExpectCopy';
import { getShipmentAddress } from '~shared/features/shipments/selectors/getShipments';
import { getShipmentWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { formatFullAddress } from '~shared/helpers/address';
import { isPickup } from '~shared/helpers/order';
import { getRouteState } from '~shared/selectors/getRouteState';
import { useSelectorShared } from '~shared/store';
import { type Shipment } from '~shared/types';
import { useDeprecatedMediaQuery } from '~web/hooks';

const orderCompleteImage = 'https://assets.prod.alto.com/otc_categories/box.svg';

type Props = {
  shipment: Shipment;
  showTitleIllustration: boolean;
};

export const OrderCard = ({ shipment, showTitleIllustration }: Props) => {
  const isMobile = !useDeprecatedMediaQuery('SM');
  const { value: isSelfScheduledPickupEnabled } = Experimentation.useFeatureFlag('self_scheduled_pickup_fe');
  const { value: isV2CalendarRowEnabled } = Experimentation.useFeatureFlag('v2_calendar_row');

  const isAsapDelivery = !shipment ? false : !!getAsapShipmentFee(shipment);

  const routeState = useSelectorShared(getRouteState);
  const newPrescriptionIDs = routeState?.bundledPrescriptionIDs || [];
  const shipmentWithItems = useSelectorShared((state) =>
    getShipmentWithItemsByID(state, {
      id: shipment.id,
    }),
  );
  const address = useSelectorShared((state) => getShipmentAddress(state, { shipmentID: shipment.id }));
  const medicationNames = shipmentWithItems
    ? shipmentWithItems.items.map((item) => {
        if (!newPrescriptionIDs.includes(item.prescription.id) && item.medication) {
          return item.medication.medicationName;
        }
        return '';
      })
    : [];
  const newMedicationNames = newPrescriptionIDs
    ? newPrescriptionIDs.map((id: number) => {
        const item = shipmentWithItems?.items.find((item) => item.prescription.id === id);
        return item?.medication ? item.medication.medicationName : item?.prescription.medication_name;
      })
    : [];

  const price = useSelectorShared(getShipmentTotal)?.value;
  const shipmentStatusString = getShipmentStatusString(shipment?.status, shipment?.is_pickup);

  const CalendarRow = isV2CalendarRowEnabled ? (
    <CalendarRowV2
      date={shipment?.scheduled_date}
      startTime={shipment?.scheduled_deliver_after}
      endTime={shipment?.scheduled_deliver_before}
      method={shipment?.shipment_method}
      status={shipment?.status}
      origin="order_success"
      isAsapDelivery={isAsapDelivery}
    />
  ) : (
    <CalendarRowV1
      date={shipment?.scheduled_date}
      startTime={shipment?.scheduled_deliver_after}
      endTime={shipment?.scheduled_deliver_before}
      isPickup={isSelfScheduledPickupEnabled && isPickup(shipment?.shipment_method)}
      isAsapDelivery={isAsapDelivery}
    />
  );

  return (
    <Card
      title={getUpcomingOrderLabel({
        isPickup: isPickup(shipment?.shipment_method as APIOrderMethod | undefined),
      })}
      description={getWhatToExpectCopy(shipment?.shipment_method)}
      illustrationSrc={showTitleIllustration && !isMobile ? { uri: orderCompleteImage } : undefined}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.orderBox}
      tag={
        <Tag
          label={shipmentStatusString}
          type="recommended"
        />
      }
    >
      {CalendarRow}
      <LocationRow
        address={formatFullAddress(address)}
        isPickup={shipment?.is_pickup}
        showMap={shipment?.status !== 'delivered'}
      />
      <DeliveryMethodRow shipment={shipment} />
      <MedRow
        medicationNames={medicationNames}
        newMedicationNames={newMedicationNames}
        showAllMeds
      />
      {shipmentWithItems && (
        <DeliveryInstructionsRow
          shipment={shipmentWithItems}
          address={address}
        />
      )}
      <PriceRow price={price} />
      <MdSpacing />
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <DeliveryCardCTA shipmentID={shipment.id} />
      </LgPadding>
    </Card>
  );
};
