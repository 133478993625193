// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, Link as PockyLink, Text, palette, spacing } from '@alto/pocky';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { type ReactElement } from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';

const LinkIcon = styled(FontAwesomeIcon)`
  color: ${palette.royalBlue};
`;
const LinkText = styled(Text)`
  color: ${palette.royalBlue};
`;

const StyledPockyLink = styled(PockyLink)<{ kind: string }>``;

type Props = {
  readonly href?: string;
  readonly to: string;
  readonly text: string;
};

type LinkProps = Props & {
  readonly children: ReactElement | ReactElement[];
};

const LinkWrapper = ({ href, to, text, children }: LinkProps) =>
  href ? (
    <StyledPockyLink
      kind="primary"
      href={href}
    >
      {children}
    </StyledPockyLink>
  ) : (
    <Link
      to={to}
      aria-label={text}
    >
      {children}
    </Link>
  );

export default class BackLink extends React.PureComponent<Props> {
  static defaultProps = {
    to: '/',
  };

  render() {
    const { href, to, text } = this.props;
    return (
      <LinkWrapper
        href={href}
        to={to}
        text={text}
      >
        <FlexColumn
          verticallyAlignContent
          spacing={spacing.xsSpacing}
        >
          <LinkIcon icon={faChevronLeft} />
          <LinkText small>{text}</LinkText>
        </FlexColumn>
      </LinkWrapper>
    );
  }
}
