// @owners { team: patients-team }
import { COLORS, SIZES } from '@alto/design-library-tokens';
import { Description, Illustration } from '@alto/design-system';
import React from 'react';
import styled from 'styled-components/native';
import { getUser } from '~shared/features/users/selectors/getUser';
import { useSelectorShared } from '~shared/store';

type UserIconProps = {
  readonly admin_user_id: number | null | undefined;
  readonly is_support_chatbot: boolean;
  readonly user_id: number | null | undefined;
};

const StyledIcon = styled.View`
  justify-content: center;
  align-items: center;
  width: ${SIZES.ILLUSTRATION.SM.px};
  height: ${SIZES.ILLUSTRATION.SM.px};
  border-radius: ${SIZES.ILLUSTRATION.SM.px};
  background-color: ${COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTER};
`;

export const ThreadUserIcon = ({ admin_user_id, is_support_chatbot, user_id }: UserIconProps) => {
  const user = useSelectorShared((state) => getUser(state, { userID: user_id }));
  const userInitials = `${user?.first_name?.charAt(0) || ''}${user?.last_name?.charAt(0) || ''}`;

  if (is_support_chatbot) {
    return (
      <Illustration
        size="sm"
        accessibilityLabel="Alto Assistant icon"
        source={require('./alto-assistant.png')}
      />
    );
  }

  if (admin_user_id) {
    return (
      <Illustration
        size="sm"
        accessibilityLabel="Alto Care Team Member icon"
        source={require('./care-team.png')}
      />
    );
  }

  return (
    <StyledIcon
      accessible={false}
      testID="thread-user-initials"
    >
      <Description
        fontFamily="semibold"
        color={COLORS.TEXT_COLORS.PRIMARY}
      >
        {userInitials}
      </Description>
    </StyledIcon>
  );
};
