// @owners { team: patients-team }
import { type User } from '~shared/types';

// flowlint-next-line deprecated-utility:off
export type NewUserPayload = Omit<User, 'id'>;

export const UI_ONBOARDING_UPDATE_USER = 'UI_ONBOARDING_UPDATE_USER';
export type ActionUiOnboardingUpdateUser = {
  type: typeof UI_ONBOARDING_UPDATE_USER;
  payload: NewUserPayload;
};
export function updateUser(payload: NewUserPayload): ActionUiOnboardingUpdateUser {
  return {
    type: UI_ONBOARDING_UPDATE_USER,
    payload,
  };
}

export const UI_ONBOARDING_SET_ZIP = 'UI_ONBOARDING_SET_ZIP';
export type ActionUiOnboardingSetZip = {
  type: typeof UI_ONBOARDING_SET_ZIP;
  payload: string;
};
export function setZip(zip: string): ActionUiOnboardingSetZip {
  return {
    type: UI_ONBOARDING_SET_ZIP,
    payload: zip,
  };
}

export const UI_ONBOARDING_ENTER = 'UI_ONBOARDING_ENTER';
export type ActionUiOnboardingEnter = {
  type: typeof UI_ONBOARDING_ENTER;
};
export function enterOnboarding(): ActionUiOnboardingEnter {
  return {
    type: UI_ONBOARDING_ENTER,
  };
}

export const UI_ONBOARDING_EXIT = 'UI_ONBOARDING_EXIT';
export type ActionUiOnboardingExit = {
  type: typeof UI_ONBOARDING_EXIT;
};
export function exitOnboarding(): ActionUiOnboardingExit {
  return {
    type: UI_ONBOARDING_EXIT,
  };
}
