// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, ListBase } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  type ActionSheetProviderStackCacheKey,
  type AssistantCategory,
  type AssistantMessageSubject,
  type AssistantPastDeliveryAction,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { DELIVERY_METHOD_PICKUP } from '~shared/features/checkout/constants';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { formatDateWithDowWithoutYear } from '~shared/helpers/date';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { MenuOption } from '../MenuOption';
import { ReportIssueActionSheet } from './ReportIssueActionSheet';

type Props = {
  readonly shipment: ShipmentWithItemsAndAdditionalFields;
  readonly deliveredAt?: string; // used when deliveries first enabled
};

export const PastDeliveryActionSheet = ({ shipment, deliveredAt }: Props) => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();

  const { saveStack, setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);

  const isEditable = isShipmentEditable(shipment);
  const isPickupDelivery = shipment.shipment_method === DELIVERY_METHOD_PICKUP;
  const dateForTitle = formatDateWithDowWithoutYear(shipment.date);

  const trackActionChosen = (action: AssistantPastDeliveryAction) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        action,
        name: ASSISTANT_PRESSED_EVENTS.PAST_DELIVERY_ACTION_CHOSEN,
        isEditable,
        shipmentID: shipment.id,
      },
    });
  };

  const navigateToMessageForm = (params: { subject: AssistantMessageSubject; category: AssistantCategory }) => {
    saveStack<ActionSheetProviderStackCacheKey>('assistant');
    navigate('RouteAssistantMessage', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_PAST_DELIVERY_ACTION_MENU,
      messagePrompt: MESSAGE_PROMPTS.LET_US_HELP,
      ...params,
    });
  };

  const items = [
    {
      key: 'report-issue',
      component: (
        <MenuOption
          iconName="alert-duo"
          onPress={() => {
            trackActionChosen('report_issue');
            setActiveActionSheet(<ReportIssueActionSheet shipment={shipment} />);
          }}
          text="Report an issue"
        />
      ),
    },
    {
      key: 'pricing-question',
      component: (
        <MenuOption
          iconName="pricetag-duo"
          onPress={() => {
            trackActionChosen('pricing_question');
            closeActionSheet();
            navigateToMessageForm({
              subject: ASSISTANT_MESSAGE_SUBJECTS.PRICING_PAYMENT,
              category: 'deliveries.pricing_question',
            });
          }}
          text="Pricing question"
        />
      ),
    },
    {
      key: 'request-receipt',
      component: (
        <MenuOption
          iconName="signpaper-duo"
          onPress={() => {
            trackActionChosen('request_receipt');
            closeActionSheet();
            navigateToMessageForm({
              subject: ASSISTANT_MESSAGE_SUBJECTS.RECEIPTS,
              category: 'deliveries.request_receipt',
            });
          }}
          text="Request a receipt"
        />
      ),
    },
    {
      key: 'something-else',
      component: (
        <MenuOption
          iconName="questionmark-duo"
          onPress={() => {
            trackActionChosen('something_else');
            closeActionSheet();
            navigateToMessageForm({
              subject: ASSISTANT_MESSAGE_SUBJECTS.PAST_DELIVERY,
              category: 'deliveries.something_else',
            });
          }}
          text="Something else"
        />
      ),
    },
  ];

  return (
    <ActionSheetV2
      title={`How can we help you with this ${isPickupDelivery ? 'pickup' : 'delivery'}?`}
      description={deliveredAt ?? (isPickupDelivery ? `Picked up on ${dateForTitle}` : `Delivered on ${dateForTitle}`)}
      analyticsName="assistant past delivery"
    >
      <ListBase items={items} />
    </ActionSheetV2>
  );
};
