// @owners { team: patients-team }
import { COLORS, type ColorVariant, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, Coachmark, Description, useScreenSize } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useFetchAllNotifications, useGetSupportCases } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { type ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { openCart } from '~shared/actions/ui/cart';
import { setCoachmarkViewed } from '~shared/actions/ui/coachmarks';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { getSupportCasesWithUnreadMessages } from '~shared/features/messages/selectors/getMessages';
import { VERIFY } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getBadgeCounts } from '~shared/selectors/getBadgeCounts';
import { getAddFamilyMemberRelationship } from '~shared/selectors/ui/addFamilyMember';
import { getIsCartShowing } from '~shared/selectors/ui/cart';
import { getCoachmarkViewedStatus } from '~shared/selectors/ui/coachmarks';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import {
  isAccountRoute,
  isAddFamilyMemberRoute,
  isHomeRoute,
  isMedsRoute,
  isNotificationsRoute,
  isOrdersRoute,
  isSupportRoute,
} from './routeHelper';
import { Container, LinkLogo, Navbar } from '~web/features/app/components/DashboardNavbar';
import { Cart } from '~web/features/checkout/components/Cart';

const getColor = (isActive: boolean) => COLORS.TEXT_COLORS[isActive ? 'SECONDARY' : 'GREY'];

const setIconType = (isActive: boolean): ColorVariant => (isActive ? 'secondary' : 'grey');

const NavItems = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const selectedLinkCss = css`
  color: ${COLORS.TEXT_COLORS.SECONDARY};
  text-decoration: none;
`;

const unselectedLinkCss = css`
  color: ${COLORS.PALETTE.GREYSCALE.DARKER};
`;

const NavLink = styled(Link)<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  ${({ selected }) => (selected ? selectedLinkCss : unselectedLinkCss)}

  &:hover,
  &:active,
  &:focus {
    ${({ selected }) => (selected ? selectedLinkCss : unselectedLinkCss)}
  }
`;

const NavListItem = styled.li<{ selected?: boolean; isSMScreenOrBigger: boolean; isMDScreenOrBigger: boolean }>`
  position: relative;
  display: flex;
  min-width: 60px;
  justify-content: center;

  &:not(:last-child) {
    margin-right: ${SPACING.STATIC.LG.rem};
    ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `margin-right: ${SPACING.STATIC.SM.rem};`}
  }

  > a {
    color: ${({ selected }) => (selected ? COLORS.TEXT_COLORS.PRIMARY : COLORS.TEXT_COLORS.DISABLED)};
    &:hover,
    &:active,
    &:focus {
      color: ${({ selected }) => (selected ? COLORS.TEXT_COLORS.PRIMARY : COLORS.TEXT_COLORS.DISABLED)};
    }
  }

  ${({ isMDScreenOrBigger }) => !isMDScreenOrBigger && `min-width: 20px;`}
`;

const NavBadge = styled.div<{ isSMScreenOrBigger: boolean }>`
  position: absolute;
  top: -4px;
  right: 14px;
  font-size: 8px;
  justify-content: center;
  align-items: center;
  height: 14px;
  min-width: 14px;
  line-height: 10px;
  border-radius: 14px;
  border: 2px solid ${COLORS.BACKGROUND_COLORS.WHITE};
  text-align: center;
  background: ${COLORS.BACKGROUND_COLORS.DANGER};
  color: ${COLORS.TEXT_COLORS.WHITE};
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `top: -4px; right: 0px;`}
`;

const NotificationsNavBadge = styled(NavBadge)<{ isSMScreenOrBigger: boolean }>`
  right: 28px;
  ${({ isSMScreenOrBigger }) => !isSMScreenOrBigger && `top: -4px; right: 0px;`}
`;

type Props = {
  readonly authorized: boolean;
  readonly closeBanner?: () => void;
  readonly location: Record<string, any>;
  readonly onboarding: boolean;
  readonly showBanner: boolean;
  readonly showProgyny: boolean;
};

type WrapperProps = {
  readonly showProgynyBackground: boolean;
  readonly authorized: boolean;
  readonly isOnboarding: boolean;
  readonly children: ReactNode;
};

const NavBarWrapper = ({ children, showProgynyBackground, authorized, isOnboarding }: WrapperProps) => {
  return (
    <Navbar
      authorized={authorized}
      showProgynyBackground={showProgynyBackground}
      isOnboarding={isOnboarding}
    >
      <div className="branch-journeys-top" />
      {children}
    </Navbar>
  );
};

export const AuthorizedDashboardNavbar = ({
  authorized,
  closeBanner,
  onboarding,
  showBanner,
  showProgyny,
  location,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const dispatch = useDispatchShared();
  const { getCurrentRouteName } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { isSMScreen, isSMScreenOrBigger, isMDScreenOrBigger, isLGScreenOrBigger } = useScreenSize();
  const { value: isNotificationCenterEnabled } = Experimentation.useFeatureFlag('notification_center');
  const notificationCoachmarkViewed = useSelectorShared((state) =>
    getCoachmarkViewedStatus(state, 'COACHMARK_NOTIFICATION_CENTER'),
  );
  const { cartSize } = useCartData();
  const isCartShowing = useSelectorShared(getIsCartShowing);
  const badgeCounts = useSelectorShared(getBadgeCounts);
  const supportCasesWithUnreadMessages = useSelectorShared(getSupportCasesWithUnreadMessages);
  const routeName = getCurrentRouteName() || '';
  const isAccountIconActive = isAccountRoute(routeName);
  const isMedsIconActive = isMedsRoute(routeName);
  const isOrderIconActive = isOrdersRoute(routeName);
  const isHelpIconActive = isSupportRoute(routeName);
  const isHomeIconActive = isHomeRoute(routeName);
  const isNotificationsIconActive = isNotificationsRoute(routeName);
  const currentRoute = routeFromPath(routeName);
  const brandLink = authorized ? '/home' : '/';
  const showProgynyBackground = showProgyny && onboarding;
  const isOnboardingVerify = currentRoute === VERIFY;
  const isOnboarding = onboarding && !isOnboardingVerify;
  const pathname: string = location.pathname ?? '';
  const inCheckout = useMemo(() => pathname.match(/checkout/g), [pathname]);
  // relationship is set in redux when adding a family member, hide nav bar items when in that flow
  const relationship = useSelectorShared(getAddFamilyMemberRelationship);
  const isAddingFamilyMember = isAddFamilyMemberRoute(pathname, relationship);
  const { patientTasks = 0 } = badgeCounts || {};
  const { unreadNotificationsCount } = useFetchAllNotifications();
  const showNotificationsDot = unreadNotificationsCount > 0;
  const { supportCases } = useGetSupportCases();
  const unreadSupportCases = supportCases.filter(
    ({ most_recent_message }) => most_recent_message?.admin_user_id && !most_recent_message.read_at,
  ).length;

  const showHelpDot = useMemo(() => {
    // supportCasesWithUnreadMessages tracks unread messages that are coming through the websocket while patient
    // is just on the help page whereas unreadSupportCases tracks unread messages that are called from the
    // support cases API. We only call the API when tabs are switched so if the patient stayed on the help page
    // and new messages are coming through, we wouldnt have a way to notify the patient without using the websocket
    return supportCasesWithUnreadMessages.size > 0 || unreadSupportCases > 0;
  }, [supportCasesWithUnreadMessages, unreadSupportCases]);

  const onNavItemClick = useCallback(
    (tabName: string) => {
      trackEvent({
        event: EVENTS.TAB_BOTTOM_BAR_BUTTON_PRESSED,
        params: {
          tabName,
        },
      });
    },
    [trackEvent],
  );

  const onCoachmarkHide = useCallback(() => {
    dispatch(setCoachmarkViewed('COACHMARK_NOTIFICATION_CENTER'));
  }, [dispatch]);

  const onCartClick = () => {
    dispatch(openCart());
    onNavItemClick('Cart');
  };

  const handleKeyPressOpenCart = (e: KeyboardEvent) => {
    if (e.keyCode !== 13) return;
    dispatch(openCart());
    onNavItemClick('Cart');
  };

  useEffect(() => {
    if (inCheckout && showBanner) {
      closeBanner?.();
    }
  }, [inCheckout, closeBanner, showBanner]);

  if (onboarding) {
    return null;
  }

  return (
    <NavBarWrapper
      showProgynyBackground={showProgynyBackground}
      authorized={authorized}
      isOnboarding={isOnboarding}
    >
      <Container
        evenlySpaceContent
        isMDScreenOrBigger={isMDScreenOrBigger}
        isLGScreenOrBigger={isLGScreenOrBigger}
      >
        <LinkLogo to={brandLink} />
        {isAddingFamilyMember || inCheckout ? null : (
          <NavItems>
            <NavListItem
              isSMScreenOrBigger={isSMScreenOrBigger}
              isMDScreenOrBigger={isMDScreenOrBigger}
              selected={isHomeIconActive}
            >
              <NavLink
                to="/home"
                selected={isHomeIconActive}
                aria-current={isHomeIconActive}
                tabIndex="0"
                onClick={() => {
                  onNavItemClick('Home');
                }}
              >
                <AltoIcon
                  testID="home-icon"
                  name={isHomeIconActive ? 'home-duo' : 'home'}
                  accessibilityLabel="navigate to home"
                  type={setIconType(isHomeIconActive)}
                />
                {isSMScreen ? null : (
                  <Description
                    color={getColor(isHomeIconActive)}
                    fontFamily={isHomeIconActive ? 'semibold' : undefined}
                  >
                    Home
                  </Description>
                )}
                {patientTasks > 0 ? <NavBadge isSMScreenOrBigger={isSMScreenOrBigger}>{patientTasks}</NavBadge> : null}
              </NavLink>
            </NavListItem>
            <NavListItem
              isSMScreenOrBigger={isSMScreenOrBigger}
              isMDScreenOrBigger={isMDScreenOrBigger}
              selected={isMedsIconActive}
            >
              <NavLink
                to="/prescriptions"
                selected={isMedsIconActive}
                aria-current={isMedsIconActive}
                tabIndex="0"
                onClick={() => {
                  onNavItemClick('Medications');
                }}
              >
                <AltoIcon
                  testID="meds-icon"
                  name={isMedsIconActive ? 'pillbottle-duo' : 'pillbottle'}
                  accessibilityLabel="navigate to medications"
                  type={setIconType(isMedsIconActive)}
                />
                {isSMScreen ? null : (
                  <Description
                    color={getColor(isMedsIconActive)}
                    fontFamily={isMedsIconActive ? 'semibold' : undefined}
                  >
                    Meds
                  </Description>
                )}
              </NavLink>
            </NavListItem>
            <NavListItem
              isSMScreenOrBigger={isSMScreenOrBigger}
              isMDScreenOrBigger={isMDScreenOrBigger}
              selected={isOrderIconActive}
            >
              <NavLink
                to="/orders"
                selected={isOrderIconActive}
                aria-current={isOrderIconActive}
                tabIndex="0"
                onClick={() => {
                  onNavItemClick('Orders');
                }}
              >
                <AltoIcon
                  testID="deliveries-icon"
                  name={isOrderIconActive ? 'box-duo' : 'box'}
                  accessibilityLabel="navigate to orders"
                  type={setIconType(isOrderIconActive)}
                />
                {isSMScreen ? null : (
                  <Description
                    color={getColor(isOrderIconActive)}
                    fontFamily={isOrderIconActive ? 'semibold' : undefined}
                  >
                    Orders
                  </Description>
                )}
              </NavLink>
            </NavListItem>
            <NavListItem
              isSMScreenOrBigger={isSMScreenOrBigger}
              isMDScreenOrBigger={isMDScreenOrBigger}
              selected={isHelpIconActive}
            >
              <NavLink
                to={{
                  pathname: '/help',
                  state: { supportCasesWithUnreadMessages },
                }}
                selected={isHelpIconActive}
                aria-current={isHelpIconActive}
                tabIndex="0"
                onClick={() => {
                  onNavItemClick('Help');
                }}
              >
                <AltoIcon
                  testID="help-icon"
                  name={isHelpIconActive ? 'questionmark-duo' : 'questionmark'}
                  accessibilityLabel="navigate to help"
                  type={setIconType(isHelpIconActive)}
                />
                {isSMScreen ? null : (
                  <Description
                    color={getColor(isHelpIconActive)}
                    fontFamily={isHelpIconActive ? 'semibold' : undefined}
                  >
                    Support
                  </Description>
                )}
                {showHelpDot ? (
                  <NavBadge isSMScreenOrBigger={isSMScreenOrBigger}>
                    {unreadSupportCases + supportCasesWithUnreadMessages.size}
                  </NavBadge>
                ) : null}
              </NavLink>
            </NavListItem>
            <NavListItem
              isSMScreenOrBigger={isSMScreenOrBigger}
              isMDScreenOrBigger={isMDScreenOrBigger}
              selected={isAccountIconActive}
            >
              <NavLink
                to="/me"
                selected={isAccountIconActive}
                aria-current={isAccountIconActive}
                tabIndex="0"
                onClick={() => {
                  onNavItemClick('Account');
                }}
              >
                <AltoIcon
                  testID="account-icon"
                  name={isAccountIconActive ? 'usercircle-duo' : 'usercircle'}
                  accessibilityLabel="navigate to account"
                  type={setIconType(isAccountIconActive)}
                />
                {isSMScreen ? null : (
                  <Description
                    color={getColor(isAccountIconActive)}
                    fontFamily={isAccountIconActive ? 'semibold' : undefined}
                  >
                    Account
                  </Description>
                )}
              </NavLink>
            </NavListItem>
            <NavListItem
              isMDScreenOrBigger={isMDScreenOrBigger}
              isSMScreenOrBigger={isSMScreenOrBigger}
            >
              <NavLink
                onClick={onCartClick}
                onKeyDown={handleKeyPressOpenCart}
                tabIndex="0"
              >
                <AltoIcon
                  testID="cart-icon"
                  name="cart"
                  accessibilityLabel="open cart"
                  type="grey"
                />
                {isSMScreen ? null : <Description color={COLORS.TEXT_COLORS.GREY}>Cart</Description>}
                {cartSize > 0 ? <NavBadge isSMScreenOrBigger={isSMScreenOrBigger}>{cartSize}</NavBadge> : null}
              </NavLink>
              {isCartShowing ? <Cart /> : null}
            </NavListItem>
            {isNotificationCenterEnabled ? (
              <NavListItem
                isSMScreenOrBigger={isSMScreenOrBigger}
                isMDScreenOrBigger={isMDScreenOrBigger}
                selected={isNotificationsIconActive}
              >
                <Coachmark
                  content="New: View all your notifications here"
                  visible={!notificationCoachmarkViewed && showNotificationsDot}
                  position="bottom-right"
                  dismissible
                  onHide={onCoachmarkHide}
                >
                  <NavLink
                    to={{
                      pathname: '/notifications',
                      state: { unreadNotificationsCount },
                    }}
                    selected={isNotificationsIconActive}
                    aria-current={isNotificationsIconActive}
                    tabIndex="0"
                    onClick={() => {
                      onNavItemClick('Notifications');
                    }}
                  >
                    <AltoIcon
                      testID="notifications-icon"
                      name={isNotificationsIconActive ? 'bell-duo' : 'bell'}
                      accessibilityLabel="navigate to notifications"
                      type={setIconType(isNotificationsIconActive)}
                    />
                    {showNotificationsDot ? <NotificationsNavBadge isSMScreenOrBigger={isSMScreenOrBigger} /> : null}
                    {isSMScreen ? null : (
                      <Description
                        color={getColor(isNotificationsIconActive)}
                        fontFamily={isNotificationsIconActive ? 'semibold' : undefined}
                      >
                        Notifications
                      </Description>
                    )}
                  </NavLink>
                </Coachmark>
              </NavListItem>
            ) : null}
          </NavItems>
        )}
      </Container>
    </NavBarWrapper>
  );
};
