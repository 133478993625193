// @owners { team: patients-team }
import { faSyringe } from '@fortawesome/pro-light-svg-icons/faSyringe';
import React from 'react';
import InfoRow from '~web/components/InfoRow';

type Props = {
  readonly medicationName: string;
};

export const AncillaryItemsTag = ({ medicationName }: Props) => {
  return (
    <InfoRow
      icon={faSyringe}
      infoText={`We'll include the necessary supplies to help you administer ${medicationName} safely and correctly. Examples of ancillary supplies include needles and syringes.`}
    >
      Ancillary items included
    </InfoRow>
  );
};
