// @owners { team: patients-team }
import { type UnconfirmedShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/unconfirmed_shipment';
import { type UpcomingShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/upcoming_shipment';
import { type Shipment as OrderSuccessShipment } from '@alto/scriptdash/alto/patient_app/order_success/types/v1/shipment';
import { useIsAutoRefillOnlyShipment } from '~shared/features/auto-refill/hooks/useIsAutoRefillOnlyShipment';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { numberOfDaysUntil } from '~shared/helpers/date';
import { useSelectorShared } from '~shared/store';

/**
 * @param shipment - shipment (either UpcomingShipment or OrderSuccessShipment)
 * @returns boolean - is the shipment eligible for mail auto refill
 */

type Shipment = UpcomingShipment | OrderSuccessShipment | UnconfirmedShipment | null;

export const useEligibleForMailAutoRefill = ({ shipment }: { shipment: Shipment }) => {
  const DAYS_BEFORE_SHIPMENT = 5;
  const fullShipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID: shipment?.id }));
  const isCourier = fullShipment?.shipment_method === 'courier';
  const isAutoRefill = useIsAutoRefillOnlyShipment({ shipmentID: shipment?.id });
  const enoughTimeBeforeShipment = shipment?.date ? numberOfDaysUntil(shipment?.date) > DAYS_BEFORE_SHIPMENT : false;

  return isCourier && isAutoRefill && enoughTimeBeforeShipment;
};
