import { type PaymentMethod as PatientTaskPaymentMethod } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/update_payment_method_patient_task';
import { PAYMENT_TYPES, type PaymentType } from '~shared/features/payment-methods/constants';
import { type PaymentMethod } from '~shared/types/payment_methods';

const defaultCCAbbreviation = 'UNK';

export function ccAbbreviation(stripeData: { card_type?: string }) {
  if (!stripeData) return defaultCCAbbreviation;

  switch (stripeData.card_type) {
    case 'Visa':
      return 'VISA';

    case 'MasterCard':
      return 'MC';

    case 'Discover':
      return 'DISC';

    case 'American Express':
      return 'AMEX';

    case 'JCB':
      return 'JCB';

    case 'Diners Club':
      return 'DC';

    default:
      return defaultCCAbbreviation;
  }
}

export function getDefaultPaymentMethodID(paymentMethods: PaymentMethod[]): number | null | undefined {
  const numPaymentMethods = paymentMethods.length;

  if (numPaymentMethods === 0) {
    return null;
  }

  if (numPaymentMethods === 1) {
    return paymentMethods[0].id;
  }

  const primaryPaymentMethod = paymentMethods.find((pm) => pm.is_primary);

  if (primaryPaymentMethod) {
    return primaryPaymentMethod.id;
  }

  return paymentMethods[numPaymentMethods - 1].id;
}

// Apple Pay gives us a tokenized card through Stripe to protect the customer's
// information, so the expiration date given to us is not the real expiration
// date.
// https://support.stripe.com/questions/expiration-dates-for-tokenized-apple-pay-cards
export function shouldShowExpiration(paymentType: PaymentType | null | undefined): boolean {
  return paymentType !== PAYMENT_TYPES.APPLE_PAY;
}

export const formatCreditCards = (paymentMethods: PatientTaskPaymentMethod[]): string => {
  // eslint-disable-next-line @typescript-eslint/no-useless-template-literals
  return `${paymentMethods
    .map((paymentMethod) => `${paymentMethod.card_type} - ${paymentMethod.card_last_4}`)
    .join(', ')}`;
};
