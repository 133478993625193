// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, Description, InlineAlert, LgPadding, LgSpacing } from '@alto/design-system';
import { DeliveryLogistics, useCheckoutValidationErrors, useOrderBundlingOptions } from '@alto/features';
import { OrderBundlingOriginMap } from '@alto/scriptdash/alto/patient_app/scheduling/order_bundling/types/v1/order_bundling_origin';
import React from 'react';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { DELIVERY_METHOD_COURIER } from '~shared/features/checkout/constants';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { useSelectorShared } from '~shared/store';
import { EditCartButton } from './EditCartButton';
import { MedSummaryList } from './MedSummaryList';

export const OrderBundlingCheckoutDeliverySummary = () => {
  const order = useSelectorShared(getOrder);
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const { cartItems } = useCartData();
  const { hasValidationErrors } = useCheckoutValidationErrors();

  const orderBundlingDeliveryMethod = DELIVERY_METHOD_COURIER;

  const { earliestUpcomingShipment, isPending } = useOrderBundlingOptions({
    address_id: order.address_id,
    origin: isEditingOrder ? OrderBundlingOriginMap.EDIT_ORDER : OrderBundlingOriginMap.CHECKOUT,
  });

  if (isPending || !earliestUpcomingShipment) {
    return null;
  }

  const upcomingBundlePrescriptionIDs = earliestUpcomingShipment.prescriptions.map((rx) => rx.id);

  return (
    <Card title="Updated order summary">
      <div>
        {!hasValidationErrors ? (
          <DeliveryLogistics
            date={order.date}
            homeToSignConfirmation={order.home_to_sign_confirmation}
            method={orderBundlingDeliveryMethod}
            status="upcoming"
            deliverAfter={order.deliver_after}
            deliverBefore={order.deliver_before}
          />
        ) : (
          <LgPadding>
            <InlineAlert type="warning">
              <Description>Please complete your order to view your order details.</Description>
            </InlineAlert>
          </LgPadding>
        )}
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <MedSummaryList
            cartItems={cartItems}
            includeSharpsContainer={order.include_sharps_container || false}
            upcomingBundlePrescriptionIDs={upcomingBundlePrescriptionIDs}
          />
          <EditCartButton />
          <LgSpacing />
        </LgPadding>
      </div>
    </Card>
  );
};
