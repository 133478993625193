import { ANIMATION, type BACKGROUND_COLORS_VALUES, BORDERS, SIZES, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { useHover } from '../../../hooks/useHover';
import { type ColorVariant } from '../../alerts';
import { Border } from '../../borders';
import { type PressableAltoIcon } from '../../buttons';
import { Row, XxsPadding } from '../../containers';
import { type AltoIcon } from '../../icon';
import { XxsSpacing } from '../../separators';
import { TagText } from '../../typography';
import { getBackgroundColor, getBorderColor, getHoveredBackgroundColor, getLabelColor } from './tagUtils';

type StyledTagProps = {
  backgroundColor: BACKGROUND_COLORS_VALUES;
  circle: boolean;
  icon: Props['icon'];
  includesBorder?: boolean;
  label: Props['label'];
};

// manually overriding with non-standard padding-top and bottom to match tag-specific design needs
// please don't do this without explicit design approval
const StyledTag = styled(XxsPadding)<StyledTagProps>`
  ${({ label, icon, circle, includesBorder }) => {
    const defaultPadding = SPACING.STATIC.XXS.value - (includesBorder ? BORDERS.SIZE : 0);

    if (circle) {
      const defaultSize = SIZES.ICON.LG.value - (includesBorder ? 2 * BORDERS.SIZE : 0);
      return `
        height: ${defaultSize}px;
        width: ${defaultSize}px;
        padding: ${defaultPadding}px;
      `;
    }

    if (!!icon && !label) {
      return `padding: ${defaultPadding + 1}px;`;
    }

    return `
      padding-top: ${defaultPadding}px;
      padding-bottom: ${defaultPadding}px;
    `;
  }}
  align-self: flex-start;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${BORDERS.RADIUS.CIRCLE.px};
  flex-shrink: 1;
  max-width: 100%;
  transition: background-color ${ANIMATION.SPEEDS.FAST}ms ease-in-out;
`;

const StyledBorder = styled(Border)`
  max-width: 100%;
`;

type Props = {
  /**
   * This should be used to force labels into a 24x24 circle, commonly used if the label is a number.
   */
  readonly circle?: boolean;
  readonly icon?: React.ReactElement<typeof AltoIcon | typeof PressableAltoIcon>;
  /**
   * 🕸️ WEB ONLY: This indicates whether the tag should be pressable or not, so
   * we can show the hover state. Hover states aren't handled on native.
   *
   * 🥐 It's more work to see if we want to bake pressability into this
   * component itself, so we can tackle that later if we want to.
   */
  readonly isPressable?: boolean;
  readonly label?: string | React.ReactElement<typeof TagText>;
  /**
   * This is used to position the icon to the right.
   */
  readonly right?: boolean;
  readonly type?: ColorVariant;
};

export const Tag = ({ circle, icon, isPressable, label, right, type }: Props) => {
  const ref = React.useRef<View>(null);
  const { isHovered } = useHover(ref);
  const backgroundColor = isPressable && isHovered ? getHoveredBackgroundColor(type) : getBackgroundColor(type);
  const textColor = getLabelColor(type);
  const borderColor = getBorderColor(type);
  const hasBorder = type === 'action';

  return (
    <View ref={ref}>
      <Row>
        <StyledBorder
          color={borderColor}
          hideBottom={!hasBorder}
          hideLeft={!hasBorder}
          hideRight={!hasBorder}
          hideTop={!hasBorder}
          radius="CIRCLE"
        >
          <StyledTag
            backgroundColor={backgroundColor}
            icon={icon}
            label={label}
            circle={circle || false}
            includesBorder={hasBorder}
          >
            <Row
              center
              flexShrink={1}
              wrap={false}
            >
              {!!label && <XxsSpacing />}
              {!!icon && !right && (
                <>
                  {icon}
                  {!!label && <XxsSpacing />}
                </>
              )}
              {!!label && <TagText color={textColor}>{label}</TagText>}
              {!!icon && right ? (
                <>
                  {!!label && <XxsSpacing />}
                  {icon}
                </>
              ) : null}
              {!!label && <XxsSpacing />}
            </Row>
          </StyledTag>
        </StyledBorder>
      </Row>
    </View>
  );
};
