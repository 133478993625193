// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, H2, LgPadding, ListBase, MdSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React, { useCallback, useMemo } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import { ASSISTANT_CATEGORIES, type AssistantShortcut } from '~shared/features/alto-assistant/constants';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import { MenuOption } from '../wizard/MenuOption';

export const Shortcuts = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const user = useSelectorShared(getCurrentUser);

  const trackShortcutPress = useCallback(
    (action: AssistantShortcut) => {
      trackEvent({
        event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
        params: {
          name: ASSISTANT_PRESSED_EVENTS.SHORTCUT,
          action,
          origin: ORIGIN_NAMES.MESSAGES,
        },
      });
    },
    [trackEvent],
  );

  const shortcuts = useMemo(
    () => [
      {
        key: 'edit-insurance',
        component: (
          <MenuOption
            iconName="idcard-duo"
            text="Edit insurance"
            onPress={() => {
              trackShortcutPress('edit_insurance');
              if (!user) return;
              navigate('RouteUserProfile', { userID: user.id });
            }}
          />
        ),
      },
      {
        key: 'edit-an-upcoming-order',
        component: (
          <MenuOption
            iconName="box-duo"
            text="Edit an upcoming order"
            onPress={() => {
              trackShortcutPress('edit_upcoming_delivery');
              navigate('RouteMessagesDeliverySelection', {
                origin: ORIGIN_NAMES.MESSAGES,
                category: ASSISTANT_CATEGORIES.DELIVERIES,
              });
            }}
          />
        ),
      },
      {
        key: 'transfer-prescription-to-alto',
        component: (
          <MenuOption
            iconName="swap-duo"
            text="Transfer prescription to Alto"
            onPress={() => {
              trackShortcutPress('transfer_in');
              navigate('RouteTransferPrescription', { origin: ORIGIN_NAMES.MESSAGES });
            }}
          />
        ),
      },
      {
        key: 'download-receipts',
        component: (
          <MenuOption
            iconName="receipt-duo"
            text="Download receipts"
            onPress={() => {
              trackShortcutPress('download_receipts');
              navigate('RoutePayments');
            }}
          />
        ),
      },

      {
        key: 'frequently-asked-questions',
        component: (
          <MenuOption
            iconName="questionmark-duo"
            text="Frequently asked questions"
            onPress={() => {
              trackShortcutPress('faq');
              navigate('RouteAccountFaq', { origin: ORIGIN_NAMES.MESSAGES });
            }}
          />
        ),
      },
    ],
    [navigate, trackShortcutPress, user],
  );

  return (
    <Card overflow="hidden">
      <MdSpacing />
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <H2>Shortcuts</H2>
      </LgPadding>
      <MdSpacing />
      <ListBase items={shortcuts} />
    </Card>
  );
};
