// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import { Column, Row, Text, spacing } from '@alto/pocky';
import { type AncillaryItemPrescription } from '@alto/scriptdash/alto/prescriptions/types/v3/ancillary_item_prescription';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

type Props = {
  readonly collapsible?: boolean;
  readonly ancillaries: AncillaryItemPrescription[];
};

const AncillariesColumn = styled(Column)<{ collapsible?: boolean }>`
  background: ${({ collapsible }) => (!collapsible ? COLORS.PALETTE.PRIMARY.LIGHTEST : 'none')};
  border-radius: ${BORDERS.RADIUS.SM.px};
  padding: ${spacing.xsSpacing};
  padding-left: ${({ collapsible }) => (!collapsible ? spacing.xsSpacing : 0)};
`;

const Header = styled(Row)<{ collapsible?: boolean }>`
  cursor: ${({ collapsible }) => (collapsible ? 'pointer' : 'default')};
  justify-content: space-between;
`;

const UL = styled.ul`
  padding-left: ${SPACING.STATIC.LG.px};
  list-style-type: disc;
`;

export const Ancillaries = ({ collapsible, ancillaries }: Props) => {
  const [isListVisible, setIsListVisible] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  const handleToggleList = () => collapsible && setIsListVisible(!isListVisible);

  const showList = !collapsible || isListVisible;
  const mappedAncillaries = ancillaries.map((ancillary) => (
    <li key={ancillary.id}>
      <Text small>{ancillary.description}</Text>
    </li>
  ));

  return (
    <AncillariesColumn collapsible={collapsible}>
      <Header
        collapsible={collapsible}
        onClick={handleToggleList}
        verticallyAlignContent
      >
        <Text
          bold
          small
        >
          Supplies Included
        </Text>
        {collapsible ? <FontAwesomeIcon icon={isListVisible ? faChevronUp : faChevronDown} /> : null}
      </Header>
      {showList ? <UL>{mappedAncillaries}</UL> : null}
    </AncillariesColumn>
  );
};
